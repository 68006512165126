import { deployApi } from "../api";

export const getRoles = async () => {
  return await deployApi.get("/api/roles");
};
export const createRole = async (data) => {
  return await deployApi.post("/api/roles", data);
};
export const updateRole = async (data) => {
  return await deployApi.put("/api/roles", data);
};







