import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/atoms/Button";
import DotsLoader from "../../components/atoms/DotsLoader";
import { generate_arn } from "../../redux/domainSlice";
import Select from "react-select";
import { MdCancel } from "react-icons/md";

function ARNGenerator({
  domains,
  onCloseModal,
  templates,
  onSingleselect,
  unSelect,
  deployeOn
}) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.domains);

  const initial = {
    template_id: "",
    template_name: "",
    github_full_name: "",
    github_id: "",
    domains: [],
    endPoint:deployeOn
  };

  const [data, setData] = React.useState(initial);

  const genrateARN = async () => {
    let domain = [];
    // eslint-disable-next-line
    domains.map((val) => {
      domain = [...domain, val.domain];
    });
    if (
      domains.filter(
        ({ deploy_with }) =>
          deploy_with === "vercel" || deploy_with === "docker"
      ).length > 0&&deployeOn==="generate_arn"
    ) {
      alert("ARN generate only for AWS deployed domains");
    } else {
      try {
        const res = await dispatch(generate_arn({ ...data, domains: domain }));
        if (res.payload.status === 200) {
          unSelect();
          onCloseModal();
        }
      } catch (error) {
        console.log("ImagesList.jsx:48 ~ deleteRecordHandler ~ error", error);
      }
    }
  };
  React.useEffect(() => {
    const close = (e) => {
      if(e.keyCode === 27){
        onCloseModal()
      }
    }
    window.addEventListener('keydown', close)
  return () => window.removeEventListener('keydown', close)
},[])
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
      {isLoading && <DotsLoader text="Please Wait" />}
      <div className="container-fluid mt-2 text-center ">
      
        <div className="inline-block bg-white w-2/4 my-0 mx-auto p-5 bg-red-600 rounded  max-h-[87vh]">
       
       <header className="flex items-center justify-center shadow-lg bg-slate-900 text-white h-[50px] mb-2 text-xl">{`${deployeOn==="generate_arn"?"ARN Generate":"Deploy on Server"}`}</header>
         
         {/* </div> */}
         <div className="pb-3 font-medium text-base text-right ">Total {domains.length} Records</div>
          <Select
            onChange={(e) => {
              setData({
                ...data,
                template_id: e?.id,
                template_name: e?.template_name,
                github_full_name: e?.github_full_name,
                github_id: e?.github_id,
              });
            }}
            name={"templates"}
            options={templates}
            getOptionLabel={(options) => options.template_name}
            getOptionValue={(options) => options.template_name}
            placeholder={"Select Template"}
            className="mb-4"
          />
          <div className="flex flex-wrap  overflow-y-auto max-h-[55vh]  mb-2">
            {domains?.map((domain, index) => {
             
              return (
                <div
                  key={index}
                  className="border !px-2 rounded-xl !mx-1.5 mb-1.5 "
                >
                  <div className="h-6 flex items-center cursor-default ">
                    <span className="text-black-900">{domain.domain}</span>
                    <MdCancel
                      size={20}
                      className="ml-1 cursor-pointer"
                      color="#7c3aed"
                      onClick={() => onSingleselect(domain)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <Button text="Save" variant="btn_submit" onClick={genrateARN} />
          <Button
            text="Cancel"
            variant="btn_cancel"
            className="ml-3"
            onClick={onCloseModal}
          />
        </div>
      </div>
    </div>
  );
}

export default ARNGenerator;
