import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/molecules/DataTable";
import DotsLoader from "../../components/atoms/DotsLoader";

import {
  get_cloudfront_domains_by_id,
  get_cloud_front,
} from "../../redux/domainSlice";
import Filter from "../../components/molecules/Filter";
import { toast } from "react-toastify";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";
function CloudfrontDomains() {
  const { isLoading, cloudfront, searchedCloudfront } = useSelector(
    (state) => state.domains
  );
  console.log("🚀 ~ file: Domains.jsx:15 ~ CloudfrontDomains ~ cloudfront:", cloudfront)
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = React.useState("");

  useEffect(() => {
    dispatch(get_cloud_front());
    // eslint-disable-next-line
  }, []);
  const [filterData, setFilterData] = React.useState();

  const columnDefs = [
    { headerName: "#", field: "counter", width: 60 },
    // { headerName: "Domain", field: "domain",flex:1,minWidth:200  },
    {
      field: "domain",
      headerName: "Domain",

      renderCell: (params) => {
        console.log("🚀 ~ file: Domains.jsx:35 ~ CloudfrontDomains ~ params:", params)
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.value}`}
          >
            {params?.value}
          </a>
        );
      },
      flex: 1,
      minWidth: 200,
    },
    { headerName: "Phone", field: "phone" ,flex:1,minWidth:100 },
    { headerName: "Status", field: "status",flex:1,minWidth:100  },
    { headerName: "NS Updated", field: "NS_updated" ,flex:1,minWidth:100 },
    { headerName: "Zonde ID", field: "zone_id",flex:1,minWidth:100  },

    { headerName: "CNAME Updated", field: "cname_updated",flex:1,minWidth:100  },
    { headerName: "SSL Updated", field: "SSL_updated" ,flex:1,minWidth:100 },
    { headerName: "Account ID", field: "account_id" ,flex:1,minWidth:150 },
    { headerName: "Auth Email", field: "auth_email" ,flex:1,minWidth:150 },
    { headerName: "Auth Key", field: "auth_key",flex:1,minWidth:150  },
  ];
  const data = searchedCloudfront?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
       
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  const onSubmit = async () => {
    
    try {
      const res = await dispatch(get_cloudfront_domains_by_id(filterData));
      if (res.payload.status === 200) {
       
      } else {
        toast.error("Data couldn't be loaded");
      }
    } catch (error) {
      toast.error("Couldn't get data");
    }
  };
  const onReset = () => {
    setFilterData("");
    dispatch(get_cloudfront_domains_by_id(""));
  };

  const filterForm = [
    {
      name: "domain",
      placeholder: "Select Domain",
      isSelect: true,
      options: cloudfront,
      onchange: (e) => {
        setFilterData(e.id);
      },
      getOptionLabel: (options,index) => {
        const serialNumber = cloudfront?.findIndex((opt) => opt.certificate_arn === options.certificate_arn) + 1;
        const certificateArn =`${options.certificate_arn.split("/")[1]}`
        return `${serialNumber}. ${certificateArn}`;
      },
      getOptionValue: (options) => options.id,
      
      isVisible: true,
      
    },
  ];

  return (
    <>
      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
       

        {isLoading && <DotsLoader text="Please Wait" />}
        <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
          <h1 className="text-2xl font-extrabold">AWS Certificate Domains</h1>
        </div>
        <Filter
          filterForm={filterForm}
          onReset={onReset}
          onSubmit={onSubmit}
          className="mt-2"
          showFilter={true}
        />
        <div className="bg-white !my-3 border rounded">
         
            <MuiGrid
              // rowSelection="multiple"
              columnDefs={columnDefs}
              items={data?.map((item, index) => {
                let counter = index + 1;
                const {
                  domain,
                  phone,
                  zone_id,
                  account_id,
                  auth_email,
                  auth_key,
                } = item;
                let status = item.is_validate
                  ? "Success"
                  : "Validation Pending";
                let NS_updated = item.is_name_server_updated ? "Yes" : "No";
                let cname_updated = item?.is_cname_updated_in_registrar
                  ? "Yes"
                  : "No";
                let SSL_updated = item.is_ssl_updated ? "Yes" : "No";

                return {
                  records: {...item,id:index,},
                  counter,
                  domain,
                  phone,
                  status,
                  NS_updated,
                  zone_id,
                  cname_updated,
                  SSL_updated,
                  account_id,
                  auth_email,
                  auth_key,
                };
              })}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              pagination="No"
            />
         
        </div>
      </div>
    </>
  );
}

export default CloudfrontDomains;
