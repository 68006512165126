import React from 'react'
import { NavLink } from "react-router-dom";

 
const SubDropdown = (props) => {
    const { options, to } = props;
   

    const user = JSON.parse(localStorage.getItem("user"));
  
    const isAllow = (item) => {
      if (item?.permission?.length > 0) {
        if (
          item?.permission?.filter(
            (item) => item === "ALL" ||
            item?.toUpperCase() === user?.role?.name?.toUpperCase()
          ).length > 0
        ) {
          return true;
        }
      }
      return false;
    };
  return (
    <div className="w-full transition-all">
    <div className="w-full transition-all">
      {options.map((item, key) => {
        let Icon = item?.Icon;
        
        return isAllow(item) ? (
          <div className="w-full transition-all" key={key}>
            <NavLink
              title={item.label}
              to={item.too?`${to}/${item.too}`:to}
              className="hover:no-underline hover:text-[#828282] page_link group text-[#828282] flex items-center py-[3px] pl-10 w-full outline-none border-0 cursor-pointer transition-all"
              
            >
              <Icon className="group-hover:text-primary-100 icons" />
              <span className="ml-1">{item.label}</span>
            </NavLink>
          </div>
        ) : null;
      })}
    </div>
  </div>
  )
}

export default SubDropdown