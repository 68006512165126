import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/molecules/Modal";
import { toast } from "react-toastify";
import DotsLoader from "../../components/atoms/DotsLoader";
import InputForm from '../../components/atoms/InputForm'
import SelectInput from "../../components/atoms/SelectInput";
import { create_server, get_servers, update_server } from "../../redux/serverSlice";
function AddUpdateUsers({ editingRecord, onCancelForm, modalTitle }) {
  const dispatch = useDispatch();
  const { isLoading, } = useSelector((state) => state.servers);

  let initialData = {
    name: "",
    type: "",
    host: "",
    app_folder_name: "",
    pm2_process_name: "",
    api_url: "",
  };

  if (editingRecord) {
    let arr = [];
    // eslint-disable-next-line
    editingRecord?.industries?.map((val) => {
      arr = [...arr, val?.id];
    });
    const { id, name, type, host, app_folder_name, pm2_process_name, api_url } =
      editingRecord;
    initialData = {
      id,
      name,
      type,
      host,
      app_folder_name,
      pm2_process_name,
      api_url,
    };
  }
  const [data, setData] = useState(initialData);
  console.log("🚀 ~ file: AddUpdateUsers.jsx:46 ~ AddUpdateUsers ~ data:", data)
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const onSubmit = async () => {
    if (!editingRecord) {
      try {
        const res = await dispatch(create_server(data));
        if (res.payload.status === 200) {
          dispatch(get_servers());
          onCancelForm();
        }
      } catch (error) {
        toast.error("Server couldn't be added");
        console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
      }
    } else {
      try {
        const res = await dispatch(update_server(data));
        if (res.payload.status === 200) {
          dispatch(get_servers())
          onCancelForm();
        }
      } catch (error) {
        toast.error("Server couldn't be updated");
        console.log("~ file: AddImage.jsx:120 ~ onSubmit ~ error", error);
      }
    }
  };
  const options = [
    { value: "backend", label: "Backend" },
    { value: "frontend", label: "Frontend" },
    { value: "cron", label: "Cron" },
  ];
  return (
    <Modal
      isUpdate={editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={onSubmit}
      onClick={onCancelForm}
    >
      {isLoading && <DotsLoader />}
      <div className="container-fluid max-w-[900px] border rounded bg-white py-3 w-full flex flex-row justify-between">
        <div className="w-full !mr-2 grid grid-cols-2 gap-x-2">
          <div className="col-span-1">
            <InputForm
              className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="Name"
              name="name"
              value={data.name}
              onChange={handleInput}
              
            />
          </div>
          <div className="col-span-1">
            <InputForm
              className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="Host"
              name="host"
              value={data.host}
              onChange={handleInput}
            />
          </div>
          <div className="col-span-1 !mt-3">
            <InputForm
              className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="App Folder Name"
              name="app_folder_name"
              value={data.app_folder_name}
              onChange={handleInput}
            />
          </div>
          <div className="col-span-1 !mt-3">
            <InputForm
              className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="pm2 Process Name"
              name="pm2_process_name"
              value={data.pm2_process_name}
              onChange={handleInput}
            />
          </div>
          <div className="col-span-1 !mt-3">
            <SelectInput
              onChange={(e) => {
                setData({
                  ...data,
                  type: e,
                });
              }}
              name={"type"}
              options={options}
              labelProp={'label'}
              valueProp={'value'}
              placeholder="Select Type"
              label="Select Type"
              className="!mt-3"
              value={data?.type}
              // nullOption="No"
            />
          </div>
          <div className="col-span-2 !mt-3">
            <InputForm
              className="form-control-sm border  w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="API URL"
              name="api_url"
              value={data.api_url}
              onChange={handleInput}
            />
          </div>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
}

export default AddUpdateUsers;
