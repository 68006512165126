import dayjs from "dayjs";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import DotsLoader from "../../components/atoms/DotsLoader";
import Breadcrumbs from "../../components/molecules/Breadcrumbs";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";
import { download_db, update_database } from "../../redux/databaseSlice";
import { update_domain_images } from "../../redux/domainSlice";
import {
  get_industries,
  regenrate_domain_db,
  updateTotalDeployedCount,
} from "../../redux/industriesSlice";
import ImportExcel from "./ImportExcel";
import { FiChevronDown } from "react-icons/fi";
import { deployApi } from "../../api";
import QueryResponseModal from "./Modal";
import Loader from "../../components/atoms/Loader";
function Industries() {
  const dispatch = useDispatch();
  const { isLoading, industries } = useSelector((state) => state.industries);
  const database = useSelector((state) => state.database);
  const [editingRecord, setEditingRecord] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isImport, setIsImport] = useState(false);
  const [searchInput, setSearchInput] = React.useState("");
  const [reloadPage, setReloadPage] = React.useState({
    isReload: "No",
    interval: -1,
  });
  const [isLoader, setIsLoader] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSmallLoader, setIsSmallLoader] = useState(false);
  const [queryResponse, setQueryResponse] = useState(null);
  const [queryResult, setQueryResult] = useState({
    queries: [],
    industry_name: "",
  });
  const [oldQueryResult, setOldQueryResult] = useState(null);
  const [secondsLeft, setSecondsLeft] = React.useState(null);
  const openImportFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsImport(true);
  };
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsImport(false);
  };
  React.useEffect(() => {
    dispatch(get_industries());
    // eslint-disable-next-line
  }, []);

  const handleCloseModal = () => {
    setQueryResult(null);
    setModalOpen(false);
  };

  const hasDesiredStatus =
    industries &&
    industries?.filter(
      ({ db_update, regenerate_domain_db }) =>
        regenerate_domain_db > 0 || db_update > 1
    )?.length > 0;
  React.useEffect(() => {
    const timerID = setInterval(() => {
      if (hasDesiredStatus && reloadPage?.isReload !== "No") {
        if (secondsLeft && secondsLeft > 0) {
          setSecondsLeft((prevSeconds) => prevSeconds - 1);
        } else {
          if (secondsLeft === 0) {
            // Reset the counter and perform the rendering or dispatch the necessary action
            setSecondsLeft(reloadPage.interval);
            dispatch(get_industries());
          }
        }
      } else {
        clearInterval(timerID);
      }
    }, 1000);
    return () => {
      clearInterval(timerID);
    };
    // eslint-disable-next-line
  }, [industries, secondsLeft]);
  React.useEffect(() => {
    if (hasDesiredStatus) {
      setReloadPage({
        isReload: "10",
        interval: 10,
      });
      setSecondsLeft(10);
    } else if (!hasDesiredStatus) {
      setReloadPage({
        isReload: "No",
        interval: -1,
      });
      setSecondsLeft(10);
    }
  }, [hasDesiredStatus]);
  const regenrateDomainDB = async (id) => {
    try {
      const res = await dispatch(regenrate_domain_db(id));
      if (res.payload.status === 200) {
        dispatch(get_industries());
        toast.success(res.payload?.data?.message);
      } else {
        toast.error(
          res.payload?.data?.message
            ? res.payload?.data?.message
            : res.payload?.message
            ? res.payload?.message
            : res.payload
            ? res.payload
            : `Database regenerate process couldn't be started`
        );
      }
    } catch (error) {
      console.log("~ file: ImportExcel.jsx:94 ~ onSubmit ~ error", error);
      toast.error(
        error.payload?.data?.message
          ? error.payload?.data?.message
          : error.payload?.message
          ? error.payload?.message
          : error.payload
          ? error.payload
          : `Database regenerate process couldn't be started`
      );
    }
  };
  const updateDomainDB = async (id) => {
    try {
      const res = await dispatch(update_database(id));
      if (res.payload.status === 200) {
        dispatch(get_industries());
        toast.success(res.payload?.data?.message);
      } else {
        toast.error(
          res.payload?.data?.message
            ? res.payload?.data?.message
            : res.payload?.message
            ? res.payload?.message
            : res.payload
            ? res.payload
            : `Database regenerate process couldn't be started`
        );
      }
    } catch (error) {
      console.log("~ file: ImportExcel.jsx:94 ~ onSubmit ~ error", error);
      toast.error(
        error.payload?.data?.message
          ? error.payload?.data?.message
          : error.payload?.message
          ? error.payload?.message
          : error.payload
          ? error.payload
          : `Database regenerate process couldn't be started`
      );
    }
  };
  const allTotalDeployed = async () => {
    if (isSmallLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    setIsSmallLoader(true);
    for (const item of industries) {
      const { runner_path, industry_name, runner_name } = item;

      if (runner_name == null) {
        continue;
      }
      const query = `select count(*) as total_domain from domains`;
      try {
        const response = await deployApi.post(
          `${runner_path}/api/setup_front_backend/run_query_on_server`,
          { sql_query: query }
        );
        if (response.status === 200) {
          if (query === `select count(*) as total_domain from domains`) {
            dispatch(
              updateTotalDeployedCount({
                industry: industry_name,
                deployedCount: response?.data?.data[0]?.total_domain,
              })
            );
            // toast.success(`Query completed for ${industry_name}`);
          } else {
            setQueryResponse({
              industry: industry_name,
              data: response.data?.data,
            });
          }
        } else {
          console.error(
            `Request for ${industry_name} failed with status ${response.status}`
          );
          toast.error(
            `Query for ${industry_name} failed with status ${
              response.status
            }, ${
              response.data?.data?.message
                ? response.data?.data?.message
                : response.data
            } `
          );
        }
      } catch (error) {
        toast.error(
          `Query for ${industry_name} failed with status ${
            error?.response?.status
          }, ${
            error?.response?.data?.data?.message
              ? error?.response?.data?.data?.message
              : error?.response?.data
          } `
        );
        console.log("🚀 ~ file: index.jsx:250 ~ Industries ~ error:", error);
      }
    }
    setIsSmallLoader(false);
  };
  const allTotalDeployedTest = async () => {
    if (isSmallLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    setIsSmallLoader(true);
    for (const item of industries) {
      const { runner_path, industry_name, runner_name } = item;

      if (runner_name == null) {
        dispatch(
          updateTotalDeployedCount({
            industry: industry_name,
            deployedCount: 0,
          })
        );
        continue;
      }
      const query = `select count(*) as total_domain from domains`;
      try {
        const promises = runner_path?.split(",")?.map(async (runner) => {
          console.log("🚀 ~ file: index.jsx:308 ~ promises ~ runner:", runner);

          const response = await deployApi.post(
            `${runner}api/setup_front_backend/run_query_on_server`,
            { sql_query: query }
          );
          if (response.status === 200) {
            if (query === `select count(*) as total_domain from domains`) {
              return response.data?.data[0]?.total_domain || 0;
            } else {
              return response.data?.data;
            }
          } else {
            console.error(
              `Request for ${runner.runner_name} failed with status ${response.status}`
            );
            toast.error(
              `Query for ${runner.runner_name} failed with status ${
                response.status
              }, ${
                response.data?.data?.message
                  ? response.data?.data?.message
                  : response.data
              }`
            );
            return 0;
          }
        });

        const results = await Promise.all(promises);
        const deployedCount = results.reduce(
          (total, count) => total + count,
          0
        );
        if (query === `select count(*) as total_domain from domains`) {
          dispatch(
            updateTotalDeployedCount({ industry: industry_name, deployedCount })
          );
          toast.success(`Query completed for ${industry_name}`);
        } else {
          setQueryResponse({ industry: industry_name, data: results });
        }
      } catch (error) {
        toast.error(
          `Query for ${industry_name} failed with status ${
            error?.response?.status
          }, ${
            error?.response?.data?.data?.message
              ? error?.response?.data?.data?.message
              : error?.response?.data
          }`
        );
        console.log("🚀 ~ Industries ~ error:", error);
      } finally {
        setIsLoader(false);
      }
    }
    setIsSmallLoader(false);
  };

  const handleRunQuery = async ({ item, query, industry }) => {
    if (!item) {
      dispatch(updateTotalDeployedCount({ industry, deployedCount: 0 }));
      return toast.error("No runner found for this industry");
    }
    setIsLoader(true);
    try {
      const promises = item?.split(",")?.map(async (runner) => {
        const response = await deployApi.post(
          `${runner}/api/setup_front_backend/run_query_on_server`,
          { sql_query: query }
        );
        if (response.status === 200) {
          if (query === `select count(*) as total_domain from domains`) {
            return response.data?.data[0]?.total_domain || 0;
          } else {
            return response.data?.data;
          }
        } else {
          console.error(
            `Request for ${runner.runner_name} failed with status ${response.status}`
          );
          toast.error(
            `Query for ${runner.runner_name} failed with status ${
              response.status
            }, ${
              response.data?.data?.message
                ? response.data?.data?.message
                : response.data
            }`
          );
          return 0;
        }
      });

      const results = await Promise.all(promises);
      const deployedCount = results.reduce((total, count) => total + count, 0);
      if (query === `select count(*) as total_domain from domains`) {
        dispatch(updateTotalDeployedCount({ industry, deployedCount }));
        toast.success(`Query completed for ${industry}`);
      } else {
        setQueryResponse({ industry: industry, data: results });
      }
    } catch (error) {
      toast.error(
        `Query for ${industry} failed with status ${error?.response?.status}, ${
          error?.response?.data?.data?.message
            ? error?.response?.data?.data?.message
            : error?.response?.data
        }`
      );
      console.log("🚀 ~ Industries ~ error:", error);
    } finally {
      setIsLoader(false);
    }
  };
  const handleConfirm = async ({ item, query, industry }) => {
    setIsLoader(true);
    const runners = item?.split(",");
    let array = [...runners];
    let newData = [];
    for (const item of array) {
      const extractedText = item.match(/https:\/\/(.+?)\.ecommcube\.com/);
      // If a match is found, the extracted text will be in the first capturing group
      const result = extractedText ? extractedText[1] : null;
      try {
        const response = await deployApi.post(
          `${item}/api/setup_front_backend/run_query_on_server`,
          { sql_query: query }
        );

        if (response.status === 200) {
          newData = [
            ...(newData || []), // Existing data (if any)
            { ...response?.data?.data[0], runner: result },
          ];
          setQueryResponse({ industry: industry, data: newData });
        } else {
          console.error(
            `Request for ${item.name} failed with status ${response.status}`
          );
          toast.error(
            `Query for ${result} failed with status ${response.status}, ${
              response.data?.data?.message
                ? response.data?.data?.message
                : response.data
            } `
          );
        }
      } catch (error) {
        console.error(`Request for ${result} encountered an error:`, error);
        toast.error(
          `Query for ${result} failed with status ${error?.response?.status}, ${
            error?.response?.data?.data?.message
              ? error?.response?.data?.data?.message
              : error?.response?.data
          } `
        );
      }
    }
    if (array?.length === 0) {
      toast.success("Process Complete");
    }
    setIsLoader(false);
  };
  // React.useEffect(() => {
  // if(industries?.length>0){
  //   const testDomains=industries?.map(({testing_domain})=>`https://${testing_domain}`)
  //   console.log("🚀 ~ file: index.jsx:399 ~ React.useEffect ~ testDomains:", testDomains)
  // }
  //   }, [industries])
  const saveBlobAsExcelFile = (blobData, dbName) => {
    const downloadLink = window.URL.createObjectURL(new Blob([blobData]));

    const link = document.createElement("a");
    link.href = downloadLink;
    link.setAttribute("download", `${dbName}.xlsx`); // Set the filename

    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadLink);
  };
  const handleExport = async (dbName) => {
    setLoading(true);
    try {
      const response = await deployApi.get(
        `/api/industries/export_database_new/${dbName}`,
        {
          responseType: "blob", // Specify the response type as Blob
        }
      );
      if (response?.status === 200) {
        const blobData = response.data;
        saveBlobAsExcelFile(blobData, dbName);
        toast.success("Database export successfully");
      } else {
        toast.error("Database couldn't be export");
      }

      setLoading(false);
    } catch (error) {
      toast.error("Database couldn't be export");
      console.error("Error fetching Excel data:", error);
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 60 },
    { headerName: "Industry Name", field: "industry_name", minWidth: 150 },
    { headerName: "Runner Name", field: "runner_name", minWidth: 80 },
    {
      headerName: "Total Purchased",
      field: "totalPurchased",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Total Deployed",
      field: "deployedCount",
      minWidth: 120,
      renderCell: (params) => {
        if (params.row.deployedCount > -1) {
          return (
            <span
              className={`${
                params?.row?.totalPurchased === params.row.deployedCount
                  ? ""
                  : "bg-red-300 p-1"
              }`}
            >
              {params.row.deployedCount}
            </span>
          );
        } else if (
          (!params.row.deployedCount || params.row.deployedCount === 0) &&
          isSmallLoader
        ) {
          return <Loader />;
        } else if (params.row.runner_name === null) {
          // Deployed Count is not clickable when runner_name is null
          return <span>{params.row.deployedCount}</span>;
        } else {
          return (
            <span
              onClick={() => {
                handleRunQuery({
                  item: params.row.records?.runner_path,
                  query: `select count(*) as total_domain from domains`,
                  industry: params.row.industry_name,
                });
              }}
              className="underline text-blue-600 cursor-pointer"
              title="api/industries/curd_operation_for_database/{id}"
            >
              Deployed Count
            </span>
          );
        }
      },
    },
    {
      headerName: "Database Name",
      field: " db_name",
      minWidth: 200,
      renderCell: (params) => (
        <NavLink
          rel="noopener noreferrer"
          to={`/industries/${params?.row?.db_name}`}
        >
          {params?.row?.db_name}
        </NavLink>
      ),
    },
    {
      headerName: "Update database",
      field: "database",
      minWidth: 160,
      renderCell: (params) => {
        const handleOpenModal = async (buttonType) => {
          try {
            setIsLoader(true);
            const response = await deployApi.post(
              "https://api.cmsdeployer.com/api/industries/get_industry_query_pending_list",
              {
                industry_id: params?.row?.id,
                query_type: buttonType,
              }
            );

            if (response.status === 200) {
              setQueryResult({
                queries: response.data.records,
                industry_name: params?.row?.industry_name,
              });
              setModalOpen(true);
            } else {
              toast.error("Some error occurred");
            }
            setIsLoader(false);
          } catch (error) {
            setIsLoader(false);
            console.error("Error fetching data:", error);
          }
        };
        const totalQuery = params?.row?.totalQuery;

        if (
          params.row.db_update === 0 &&
          params.row.regenerate_domain_db === 0
        ) {
          return (
            <div>
              <span
                // onClick={() => {
                //   const c = window.confirm(`Are you sure want to update database for ${params.row.industry_name}?`);
                //   if (!c) {
                //     return;
                //   }
                //   updateDomainDB(params?.row?.id);
                // }}
                title="nothing to update"
              >
                N/A
              </span>
            </div>
          );
        } else if (
          params.row.db_update === 1 &&
          params.row.regenerate_domain_db === 0
        ) {
          return (
            <div>
              <span
                onClick={() => {
                  const c = window.confirm(
                    `Are you sure want to update database for ${params.row.industry_name}?`
                  );
                  if (!c) {
                    return;
                  }
                  updateDomainDB(params?.row?.id);
                }}
                className="underline text-blue-600 cursor-pointer p-1"
                title="api/industries/curd_operation_for_database/{id}"
              >
                Update Needed
              </span>
              <span
                onClick={() => handleOpenModal("Pending")}
                className="text-black-600 cursor-pointer no-underline hover:underline bg-blue-300 hover:bg-blue-600 px-2 py-1 rounded font-bold ml-2"
                title="api/industries/get_industry_query_pending_list"
              >
                {totalQuery}
              </span>
            </div>
          );
        } else if (
          params.row.db_update > 1 &&
          params.row.regenerate_domain_db === 0
        ) {
          return (
            <div>
              <span
                onClick={() => {
                  handleConfirm({
                    item: params.row.runner_path,
                    query: `SELECT COUNT(*) AS total_domain, SUM(CASE WHEN sql_log_status = 0 THEN 1 ELSE 0 END) AS ready_domain_db, COUNT(*)- SUM(CASE WHEN sql_log_status = 0 THEN 1 ELSE 0 END) AS pending_domain_db FROM domains`,
                    industry: params.row.industry_name,
                  });
                }}
                className="underline text-blue-600 cursor-pointer bg-[lightgreen] p-1"
                title="api/industries/curd_operation_for_database/{id}"
              >
                In Process
              </span>
              <span
                onClick={() => handleOpenModal("Processing")}
                className="text-black-600 cursor-pointer no-underline hover:underline bg-blue-300 hover:bg-blue-600 px-2 py-1 rounded font-bold ml-2"
                title="api/industries/get_industry_query_pending_list"
              >
                {totalQuery}
              </span>
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      headerName: "Export Excel",
      field: "excel",
      flex: 1,
      minWidth: 100,
      renderCell: (params) =>
        params?.row?.db_name ? (
          <span
            onClick={() => handleExport(params?.row?.db_name)}
            className="underline text-blue-600 cursor-pointer"
          >
            Export
          </span>
        ) : null,
    },
    {
      headerName: "Update Images",
      flex: 1,
      minWidth: 120,
      renderCell: (params) =>
        params?.row?.db_name &&
        params?.row?.s3_image_update_status === "complete" ? (
          <span
            onClick={() => {
              const c = window.confirm(
                `Are you sure want to update images for ${params.row.industry_name}?`
              );
              if (c) {
                dispatch(update_domain_images(params?.row?.id));
              }
            }}
            className="underline text-blue-600 cursor-pointer"
          >
            Update
          </span>
        ) : null,
    },

    {
      field: "testing_domain",
      headerName: "Test Link",
      renderCell: (params) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.row.testing_domain}`}
          >
            {params?.row.testing_domain}
          </a>
        );
      },
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Import Excel",
      field: "actions",
      renderCell: (params) => (
        <div className="flex items-center w-full">
          <span
            onClick={openImportFormHandler(params?.row?.id)}
            className="underline text-blue-600 cursor-pointer"
          >
            Import Complete Excel
          </span>
        </div>
      ),
      width: 150,
    },
    {
      headerName: "Regenerate Domains DB",
      field: "regenrate",
      renderCell: (params) =>
        params.row.regenerate_domain_db === 0 && params.row.db_update <= 1 ? (
          <div className="flex items-center w-full">
            <span
              onClick={() => {
                const c = window.confirm(
                  `Are you sure want to Regenerate database for ${params.row.industry_name}?`
                );
                if (!c) {
                  return;
                }
                regenrateDomainDB(params?.row?.id);
              }}
              className="underline text-blue-600 cursor-pointer"
            >
              Regenerate DB
            </span>
          </div>
        ) : params.row.regenerate_domain_db !== 0 &&
          params.row.db_update <= 1 ? (
          <div className="flex items-center w-full">
            <span
              onClick={() => {
                handleConfirm({
                  item: params.row.runner_path,
                  query: `SELECT COUNT(*) AS total_domain, SUM(CASE WHEN db_exist = 1 THEN 1 ELSE 0 END) AS ready_domain_db, COUNT(*)- SUM(CASE WHEN db_exist = 1 THEN 1 ELSE 0 END) AS pending_domain_db FROM domains`,
                  industry: params.row.industry_name,
                });
              }}
              className="underline text-blue-600 cursor-pointer"
            >
              In Process
            </span>
          </div>
        ) : null,
      width: 150,
    },
  ];
  const data = industries?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  const option = [
    { label: "Don't Reload", value: "No" },
    { label: "10 Sec", value: "10" },
    { label: "20 Sec", value: "20" },
    { label: "30 Sec", value: "30" },
    { label: "Custom", value: "custom" },
  ];
  const [showExportOptions, setShowExportOptions] = React.useState(false);
  const optionsRef = React.useRef();
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setShowExportOptions(false);
    }
  };
  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setShowExportOptions(false);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const LineOne = () => {
    return (
      <div className="w-auto ml-3 justify-center md:justify-end flex md:!mt-0 pr-1">
        {reloadPage?.isReload === "custom" ? (
          <input
            type="text"
            className="h-[31px] mr-2 bg-white border border-gray-300 text-gray-900 text-sm rounded-[0.2rem] w-[119px] p-2.5 "
            placeholder="Seconds"
            value={reloadPage?.interval}
            onChange={(e) => {
              setReloadPage({ ...reloadPage, interval: e.target.value });
            }}
            onFocus={() => setSecondsLeft("")}
            onBlur={(e) => {
              setSecondsLeft(e.target.value);
            }}
            autoFocus={reloadPage.isReload === "custom" && secondsLeft !== ""}
          />
        ) : null}
        <div className="relative pr-1">
          <button
            onClick={() => setShowExportOptions(!showExportOptions)}
            text="Export"
            className="relative float-right border min-w-[125px] w-full h-[31px] text-sm flex justify-between items-center px-0.5 rounded-[0.2rem] text-black font-regular"
          >
            <span>
              {" "}
              {
                option?.find(({ value }) => value === reloadPage.isReload)
                  ?.label
              }
            </span>
            <span>
              {" "}
              <FiChevronDown />
            </span>
          </button>
          {showExportOptions && (
            <div className="absolute top-full rounded-t-[0.2rem] !mt1.25 bg-white z-10 border-x border-b border-[#ddd] -ml-px overflow-hidden transition ease-in-out delay-150">
              <ul ref={optionsRef} className="!pl-0 !mb-0 w-full">
                {option?.map((option, index) => {
                  return (
                    <li
                      key={index}
                      className="w-full cursor-pointer px-2.5 !py-1.25 border-t border-[#ddd] hover:bg-[#e1e1e1]"
                      onClick={(e) => {
                        setReloadPage({
                          isReload: option?.value,
                          interval:
                            option?.value === "custom"
                              ? ""
                              : option?.value === "No"
                              ? -1
                              : +option?.value,
                        });
                        setSecondsLeft(
                          option?.value === "custom"
                            ? ""
                            : option?.value === "No"
                            ? -1
                            : +option?.value
                        );
                        setShowExportOptions(!showExportOptions);
                      }}
                    >
                      {option.label}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  };

  function QueryModal({ queries }) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 top-0 left-0 right-0 bottom-0 px-4 bg-black/60">
        <div className="relative bg-white p-4 rounded-lg shadow-md max-w-[80%] w-full">
          <button
            className="absolute top-3 right-7 text-red-600 text-3xl"
            onClick={handleCloseModal}
          >
            <i className="fas fa-times"></i>
          </button>
          <h1 className="text-center border-b pb-2 font_pop font-semibold text-lg">
            Total Queries for {queries?.industry_name}:{" "}
            {queries?.queries?.length}
          </h1>
          <div className="max-h-[50vh] overflow-y-auto">
            {queries?.queries?.map((record, index) => (
              <div className="border-b mt-3 border-[#b6b6b6]" key={index}>
                <div className="text-sm font-medium flex flex-col">
                  {record?.username ? (
                    <span className="text-lg font-bold text-red-600">
                      {`${record?.username}`}
                    </span>
                  ) : null}

                  <span>{`${index + 1}. ${record?.sql_query}`}</span>
                  {record?.old_query_json ? (
                    <span
                      className="ml-1 text-blue-600 hover:underline cursor-pointer"
                      onClick={() => setOldQueryResult(record)}
                    >
                      See old record
                    </span>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
          <div className="mt-2 flex justify-end items-center">
            <button
              className="border rounded bg-red-600 text-white p-2 text-sm"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
  console.log(JSON.stringify(oldQueryResult?.old_query_json, null, 2));
  function OldQueryModal() {
    const formattedJson = JSON.stringify(
      JSON.parse(oldQueryResult?.old_query_json),
      null,
      2
    );

    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 top-0 left-0 right-0 bottom-0 px-4 bg-black/60">
        <div className="relative bg-white p-4 rounded-lg shadow-md max-w-[70%] w-full">
          <button
            className="absolute top-3 right-7 text-red-600 text-3xl"
            onClick={() => setOldQueryResult(null)}
          >
            <i className="fas fa-times"></i>
          </button>
          <h1 className="text-center border-b pb-2 font_pop font-semibold text-lg">
            {queryResult?.industry_name}:{" "}
          </h1>
          <div className="max-h-[50vh] overflow-y-auto">
            <div className="border-b mt-3 border-[#b6b6b6]">
              <div className="text-sm font-medium flex flex-col">
                {/* <span>{formattedJson}</span> */}
                <pre>{formattedJson}</pre>
              </div>
            </div>
          </div>
          <div className="mt-2 flex justify-end items-center">
            <button
              className="border rounded bg-red-600 text-white p-2 text-sm"
              onClick={() => setOldQueryResult(null)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
  // Grouping by "id" and "industry_name"
  const groupedData = data.reduce((acc, obj) => {
    const key = obj.id + "_" + obj.industry_name;

    if (!acc[key]) {
      acc[key] = {
        ...obj,
        runners: [],
      };
    }
    acc[key].runners.push({
      runner_name: obj?.runner_name,
      runner_path: obj?.runner_path,
    });
    return acc;
  }, {});

  // Converting the grouped object back to an array
  const groupedArray = Object.values(groupedData);
  return (
    <>
      {isImport && (
        <ImportExcel
          editingRecord={editingRecord}
          modalTitle="Import Excel"
          onCancelForm={cancelFormHandler}
        />
      )}
      {queryResponse ? (
        <QueryResponseModal
          industry={queryResponse?.industry}
          data={queryResponse?.data}
          handleClose={() => setQueryResponse(null)}
        />
      ) : null}

      {isModalOpen && (
        <QueryModal queries={queryResult} onClose={handleCloseModal} />
      )}
      {oldQueryResult && <OldQueryModal />}

      {isLoading || database.isLoading || isLoader || loading ? (
        <DotsLoader text="Please Wait" />
      ) : null}
      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
        <Breadcrumbs />
        <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
          <h1 className="text-2xl font-extrabold">Industries</h1>
          <button
            className="border rounded bg-red-600 text-white p-2 hover:bg-red-700"
            onClick={allTotalDeployedTest}
          >
            Show Total Deploy
          </button>
        </div>

        <div className="bg-white !my-3 border rounded">
          <MuiGrid
            columnDefs={columnDefs}
            items={groupedArray?.map((record, index) => {
              let counter = index + 1;
              let id = record.id;
              let industry_name = record?.industry_name;
              let runner_name = record?.runners?.map(
                ({ runner_name, runner_path }) => runner_name
              );
              let db_name = record?.db_name;
              let totalDeployed = record?.totalDeployed;
              let totalPurchased = record?.totalPurchased;
              let s3_image_update_status = record?.s3_image_update_status;
              let PORT = record?.PORT;
              let created_at = record?.created_at
                ? dayjs(record.created_at).format("ddd, MMM D, YYYY h:mm A")
                : null;
              let live_ready = record?.live_ready ? "Yes" : "No";
              const {
                db_update,
                regenerate_domain_db,
                totalQuery,
                runner_path,
                deployedCount,
                testing_domain,
              } = record;
              return {
                records: record,
                counter,
                id,
                PORT,
                industry_name,
                runner_name,
                db_name,
                created_at,
                totalDeployed,
                totalPurchased,
                s3_image_update_status,
                live_ready,
                db_update,
                regenerate_domain_db,
                totalQuery,
                runner_path,
                deployedCount,
                testing_domain,
              };
            })}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            LineOne={LineOne}
            additionalMsg={` ${
              hasDesiredStatus && reloadPage?.isReload !== "No"
                ? `| Reload time ${secondsLeft}`
                : ""
            }`}
            pagination="No"
          />
        </div>
      </div>
    </>
  );
}

export default Industries;
