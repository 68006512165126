import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DotsLoader from "../../components/atoms/DotsLoader";
import Modal from "../../components/molecules/Modal";
import { create_email, update_email } from "../../redux/emailsSlice";
import { create_role, update_role } from "../../redux/roleSlice";

function AddUpdateRoles({ editingRecord, onCancelForm, modalTitle }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.roles);
  let initialData = {
    email: "",
    password: "",
  };

  if (editingRecord) {
    const { id, email, password } = editingRecord;
    initialData = {
      id,
      email,
      password,
    };
  }
  const [data, setData] = useState(initialData);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const onSubmit = async () => {
    if (!editingRecord) {
      try {
        const res = await dispatch(create_email({emails:[data]}));
        if (res.payload.status === 201) {
          toast.success("Email added successfully")
          onCancelForm();
        }else{
          toast.error("Email cloudn't added")
        }
      } catch (error) {
        console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
      }
    } else {
      try {
        const res = await dispatch(update_email(data));
        if (res.payload.status === 200) {
          toast.success("Email updated successfully")
          onCancelForm();
        }else{
          toast.error("Email cloudn't updated")
        }
      } catch (error) {
        console.log("~ file: AddImage.jsx:120 ~ onSubmit ~ error", error);
      }
    }
  };

  return (
    <Modal
      isUpdate={editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={onSubmit}
      onClick={onCancelForm}
    >
      {isLoading && <DotsLoader />}
      <div className=" ">
        <div className="container-fluid  border rounded bg-white py-3 w-full flex flex-row justify-between">
          <div className="w-full !mr-2 grid grid-cols-2 gap-x-2">
            <div className="col-span-1">
              <input
                className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
                placeholder="Email"
                name="email"
                value={data.email}
                onChange={handleInput}
              />
            </div>
            <div className="col-span-1">
              <input
                className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
                placeholder="Password"
                name="password"
                value={data.password}
                onChange={handleInput}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddUpdateRoles;
