import React from "react";

function CheckDomainModal({
  onCloseModal,
  setDomain_count,
  domain_count,
  handleConfirm,
}) {
  return (
    <>
      <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-50 bg-black/60">
        <div className="bg-white p-8 rounded-lg shadow-lg relative w-[400px]">
          <h2 className="text-lg font-semibold mb-4">
            Check Domains working status by email
          </h2>
          <label className="block">Please enter the domain count:</label>
          <input
            className="w-full form-control border-[1px] border-slate-300"
            onChange={(e) => {
              setDomain_count(e.target.value);
            }}
            value={domain_count}
            placeholder="Enter Count"
          />
          <div className="flex justify-end !mt-4">
            <button
              className="px-4 py-2 mr-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={handleConfirm}
            >
              OK
            </button>
            <button
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
              onClick={onCloseModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default CheckDomainModal;
