import { sideMenu } from "./menu.config";
import { FaPhone, FaPowerOff } from "react-icons/fa";
import { Link, NavLink, useLocation } from "react-router-dom";
import Dropdown from "./Dropdown.js";
import React from "react";
import { FaAngleDown } from "react-icons/fa";
import logo from "../../../img/logo2.png";
import { logout } from "../../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import DotsLoader from "../../atoms/DotsLoader";

const Sidebar2 = ({ setModal }) => {
  const { isLoading } = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = React.useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();
  const handleLogout = async () => {
    dispatch(logout());
  };
  const isAllow = (item) => {
    if (item?.permission?.length > 0) {
      if (
        item?.permission?.filter(
          (item) =>
            item === "ALL" ||
            item?.toUpperCase() === user?.role?.name?.toUpperCase()
        ).length > 0
      ) {
        return true;
      }
    }

    return false;
  };
  const location = useLocation();
  const [clickedOn, setClickedOn] = React.useState("");

  React.useEffect(() => {
    let route = location.pathname.split("/")[1];
    setClickedOn(`/${route}`);
  }, [location.pathname]);

  return (
    <div
      className={`
         bg-[#f7f7f7] fixed w-[230px] top-0 bottom-0 border-r-2 border-[#e8e9eb] z-10 !overflow-y-hidden transition-all main-sidebar`}
    >
      {isLoading && <DotsLoader text={"Loging Out"} />}
      <div className="p-3.5 border-b border-[#d5d5d5] !overflow-y-hidden">
        <Link to="/add_Project">
          <img src={logo} alt="Callrings CRM" />
        </Link>
      </div>
      <div className={`${"h-[calc(100vh-100px)]"} overflow-y-auto scrollbar`}>
        {sideMenu.map((item, key) => {
          const { label, to, Icon, children, onClick } = item;

          return isAllow(item) ? (
            <div className="w-full" key={key}>
              {item?.children ? (
                <>
                  <div
                    className="transition-all page_link group hover:text-[#828282] hover:no-underline text-[#828282] transition flex items-center justify-between py-[3px] !px-3.5 w-full outline-none border-0 cursor-pointer transition-all"
                    onClick={() => {
                      setClickedOn(clickedOn === to ? "" : to);
                      setIsOpen(!isOpen);
                    }}
                  >
                    <div className="flex items-center">
                      <Icon className="icons group-hover:text-primary-100" />
                      <span className="!ml-1">{label}</span>
                    </div>
                    <FaAngleDown />
                  </div>
                  {clickedOn === to && (
                    <Dropdown
                      label={label}
                      main={to}
                      Icon={Icon}
                      options={children}
                      key={key}
                    />
                  )}
                </>
              ) : (
                <NavLink
                  title={label}
                  to={to}
                  onClick={onClick}
                  className="page_link group hover:text-[#828282] hover:no-underline text-[#828282] flex items-center py-[3px] !px-3.5 w-full outline-none border-0 cursor-pointer transition-all"
                >
                  <Icon className="icons group-hover:text-primary-100" />

                  <span className="ml-1">
                    {label}
                    {user?.pending_domains_phone_count &&
                    label === "Pending Phones" ? (
                      <span className="animate-blink bg-red-700 px-1.5 text-center text-white rounded-lg ml-0.5">
                        {`${user?.pending_domains_phone_count}`}
                      </span>
                    ) : null}
                  </span>
                </NavLink>
              )}
            </div>
          ) : null;
        })}
        {user?.role?.name?.toUpperCase() === "SUPER ADMINISTRATOR" && (
          <NavLink
            to
            onClick={() => setModal(true)}
            className="group hover:text-[#828282] hover:no-underline text-[#828282] flex items-center py-[3px] !px-3.5 w-full outline-none border-0 cursor-pointer transition-all"
          >
            <FaPhone className="group-hover:text-primary-100" />
            <span className="ml-1">Update Phone</span>
          </NavLink>
        )}

        <NavLink
          to
          onClick={handleLogout}
          className="group hover:text-[#828282] hover:no-underline text-[#828282] flex items-center py-[3px] !px-3.5 w-full outline-none border-0 cursor-pointer transition-all"
        >
          <FaPowerOff className="group-hover:text-primary-100" />
          <span className="ml-1">Logout</span>
        </NavLink>
      </div>
    </div>
  );
};

export default Sidebar2;
