import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/atoms/Button";
import DotsLoader from "../../components/atoms/DotsLoader";
import { generate_arn } from "../../redux/domainSlice";
import Select from "react-select";
import { MdCancel } from "react-icons/md";
import { updateDomainsCloudflare } from "../../services/domainServices";
import { toast } from "react-toastify";

function SelectCloudFlare({
  domains,
  onCloseModal,
  accounts,
  onSingleselect,
  unSelect,
  handleNewFilter,
  crossIcon
}) {
  const dispatch = useDispatch();

const [isLoading,setIsLoading]=React.useState(false)
  const initial = {
    registrar_account_id: "",
    domains: [],
  };

  const [data, setData] = React.useState(initial);

  const genrateARN = async () => {

    let domain = [];
    domains.map((val) => {
      domain = [...domain, val.domain?val.domain:val];
    });
    setIsLoading(true)
    try {
      const res = await updateDomainsCloudflare({ ...data, domains: domain });
      console.log("🚀 ~ file: SetCloudFlare.jsx:34 ~ genrateARN ~ res:", res)
      if (res.status === 200) {
        unSelect();
        toast.success("Cloudflare account updated Successfully")
        handleNewFilter()
        onCloseModal();
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast.error("Cloudflare account couldn't be updated")
      console.log("ImagesList.jsx:48 ~ deleteRecordHandler ~ error", error);
    }
  };
  React.useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCloseModal();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
      {isLoading && <DotsLoader text="Please Wait" />}
      <div className="container-fluid mt-2 text-center ">
        <div className="inline-block bg-white w-2/4 my-0 mx-auto p-5 bg-red-600 rounded  max-h-[87vh]">
          <header className="flex items-center justify-center shadow-lg bg-slate-900 text-white h-[50px] mb-2 text-xl">Update Domain cloudflare accounts</header>

          {/* </div> */}
          <div className="pb-3 font-medium text-base text-right ">
            Total {domains.length} Records
          </div>
          <Select
            onChange={(e) => {
              setData({
                registrar_account_id: e?.id,
              });
            }}
            name={"accounts"}
            options={accounts}
            getOptionLabel={(options) => options.auth_email}
            getOptionValue={(options) => options.id}
            placeholder={"Select cloudflare account"}
            className="mb-4"
          />
          <div className="flex flex-wrap  overflow-y-auto max-h-[55vh]  mb-2">
            {domains?.map((domain, index) => {
              return (
                <div
                  key={index}
                  className="border !px-2 rounded-xl !mx-1.5 mb-1.5 "
                >
                  <div className="h-6 flex items-center cursor-default ">
                    <span className="text-black-900">{domain.domain?domain.domain:domain}</span>
                    {
                      crossIcon!=="No"?
                      <MdCancel
                      size={20}
                      className="ml-1 cursor-pointer"
                      color="#7c3aed"
                      onClick={() => onSingleselect(domain)}
                    />:null
                    }
                    
                  </div>
                </div>
              );
            })}
          </div>
          <Button text="Save" variant="btn_submit" onClick={genrateARN} />
          <Button
            text="Cancel"
            variant="btn_cancel"
            className="ml-3"
            onClick={onCloseModal}
          />
        </div>
      </div>
    </div>
  );
}

export default SelectCloudFlare;
