import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DotsLoader from "../../components/atoms/DotsLoader";
import DataTable from "../../components/molecules/DataTable";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";
import { add_in_db, get_github_repo } from "../../redux/githubRepoSlice";

function GithubRepos() {
  const dispatch = useDispatch();
  const { isLoading, repos } = useSelector((state) => state.repos);
const [searchInput, setSearchInput] = React.useState("")
  React.useEffect(() => {
    dispatch(get_github_repo());
    // eslint-disable-next-line
  }, []);
  const data = repos?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  const columnDefs = [
    { headerName: "#", field: "counter" },
    { headerName: "Name", field: "name" ,flex:1,minWidth:20},
    { headerName: "Full Name", field: "full_name" ,flex:1,minWidth:30},
    {
      headerName: "Home Page",
      field: "homepage",
      renderCell: (params) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${params?.row?.homepage}`}
        >
          {params?.row?.homepage}
        </a>
      ),
      flex:1,minWidth:100
    },
    {
      headerName: "Github URL",
      field: "html_url",

      renderCell: (params) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${params?.row?.html_url}`}
        >
          {params?.row?.html_url}
        </a>
      ),
      flex:1,minWidth:30
    },
    {
      field: "Add in DB",
      renderCell: (params) => {
        return (
          <>
            {!params.row.is_added_in_db ? (
              <button
                className="!px-3 !py-2 align-middle bg-red-600 text-white font-normal focus:outline-none hover:bg-red-700"
                onClick={() => dispatch(add_in_db(params.row.full_name))}
              >
                Add in DB
              </button>
            ) : null}
          </>
        );
      },width:150
    },
  ];
  return (
    <>
      {isLoading && <DotsLoader text="Please Wait" />}
      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
        <h1 className="text-2xl font-extrabold mt-3">Github Templates</h1>
        <div className="bg-white !my-3 border rounded">
            <MuiGrid
              columnDefs={columnDefs}
              items={data?.map((record, index) => {
                let counter = index + 1;
                let {
                  id,
                  name,
                  full_name,
                  html_url,
                  is_added_in_db,
                  homepage,
                } = record;
                return {
                  records: record,
                  counter,
                  id,
                  name,
                  full_name,
                  html_url,
                  is_added_in_db,
                  homepage,
                };
              })}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              pagination="No"
            />
         
        </div>
      </div>
    </>
  );
}

export default GithubRepos;
