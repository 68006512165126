import { deployApi } from "../api";

export const getServers = async (data) => {
  return await deployApi.get(`/api/runner_setup/server_list`);
};
export const createServer = async (values) => {
  return await deployApi.post("/api/runner_setup/add_server", values);
};

export const updateServer = async (values) => {
  return await deployApi.post("/api/runner_setup/edit_server", values);
};

export const deleteServer = async (id) => {
  return await deployApi.delete("/api/runner_setup/delete_server", { data: { id } });
};
export const getRunners = async () => {
  return await deployApi.get(`api/runner_setup/runner_list`);
};

export const getNationSiteZips = async (id) => {
  return await deployApi.get(`api/custom_vendors/nation_site_zips/${id}`);
};
export const addNationSiteZips = async (values) => {
  return await deployApi.post("api/custom_vendors/nation_site_zips", values);
};
