import React, { useEffect, useState } from "react";
import DotsLoader from "../../components/atoms/DotsLoader";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";
import { useDispatch, useSelector } from "react-redux";
import { get_alternate_domains_phone } from "../../redux/domainSlice";
import Toolbar from "../../components/molecules/DataTable/Toolbar";
import * as XLSX from "xlsx";
import { deployApi } from "../../api";
import { toast } from "react-toastify";
import dayjs from "dayjs";
function AlternateDomainPhone() {
  const { isLoading, alternatePhoneArea } = useSelector(
    (state) => state.domains
  );
  console.log("🚀 ~ file: index.jsx:13 ~ AlternateDomainPhone ~ alternatePhoneArea:", alternatePhoneArea)

  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [alternateModal, setAlternateModal] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  useEffect(() => {
    dispatch(get_alternate_domains_phone());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    
    {
      field: "areacode",
      headerName: "Area Codes",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Alternate Code",
      field: "alternate_code",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Active",
      field: "isActive",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "deleted_on",
      headerName: "Delete On",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    
    {
      headerName: "Created At",
      field: "created_at",
      flex: 1,
      minWidth: 100,
    },
  ];
  const data = alternatePhoneArea?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  function exportToExcel() {
    const data = alternatePhoneArea;
    const rows = [
      ["Sr.", "Domains", "Industry Name", "Area Codes"], // Add headings
    ];

    data.forEach((item, index) => {
      const { areaCodes, domain, industry_name } = item;
      rows.push([index + 1, domain, industry_name, areaCodes]); // Add a row without domains
    });

    const worksheet = XLSX.utils.aoa_to_sheet(rows);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const fileName = "pending_domains_phone.xlsx";

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    if (typeof window.navigator.msSaveBlob !== "undefined") {
      // For IE browser
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    }
  }
  function exportToExcelUnique() {
    const uniqueAreaCodes = new Set();

    // Loop through each object in the array
    alternatePhoneArea.forEach((item) => {
      const codes = item.areaCodes.split(","); // Split area codes if there are multiple
      codes.forEach((code) => uniqueAreaCodes.add(code.trim())); // Add each code to the set
    });
    const uniqueAreaCodesArray = [...uniqueAreaCodes];
    const data = uniqueAreaCodesArray;
    const rows = [
      ["Sr.", "Area Codes"], // Add headings
    ];

    data.forEach((item, index) => {
      rows.push([index + 1, item]); // Add a row without domains
    });

    const worksheet = XLSX.utils.aoa_to_sheet(rows);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const fileName = "pending_domains_phone_by_area_code.xlsx";

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    if (typeof window.navigator.msSaveBlob !== "undefined") {
      // For IE browser
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    }
  }
  const exportOption = [
    {
      label: "Complete Report",
      value: "csv",
      action: exportToExcel,
    },
    {
      label: "Unique Area Codes",
      value: "csv",
      action: exportToExcelUnique,
    },
  ];

  const handleUpdatePhone = async () => {
    const c = window.confirm(`Are you sure want to update alternate phone list?`);
    if (!c) {
      return;
    }
    const array = data?.map(({alternate_code})=>+alternate_code)
    setIsLoader(true);
    try {
      const res = await deployApi.post("api/domains/filter_alternate_area_codes_by_phone", {alternate_area_codes:array});
      if (res.status === 200) {
        toast.success("Alternate phone list updated successfully");
        dispatch(get_alternate_domains_phone());
      } else {
        toast.error("Alternate phone list couldn't be updated");
      }
      setIsLoader(false);
    } catch (err) {
      toast.error("Alternate phone list couldn't be updated");
      console.log("🚀 ~ file: Modal.jsx:79 ~ handleSubmit ~ err:", err);
      setIsLoader(false);
    }
  };


  return (
    <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
      {isLoading || isLoader ? <DotsLoader text="Please Wait" /> : null}
      <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
        <h1 className="text-2xl font-extrabold">Alternate Domains Phone</h1>
         <div>
          {/* <button
            className="border rounded bg-red-600 text-white p-2 w-fit text-sm"
            onClick={() => setAlternateModal(!alternateModal)}
          >
            Set Alternate Codes
          </button> */}
          <button
            className="border rounded bg-red-600 text-white p-2 w-fit text-sm"
            onClick={handleUpdatePhone}
            // disabled={selectedData?.length > 0 ? false : true}
          >
            Filter alternate Phone
          </button>
        </div> 
      </div>
      <div className="bg-white !my-3 border rounded">
        <MuiGrid
          columnDefs={columnDefs}
          items={data?.map((item, index) => {
            let counter = index + 1;
            const {
              areacode,
              alternate_code,
              deleted_on,
              created_at,
              isActive,
            } = item;
            return {
              records: { ...item, id: index + 1 },
              counter,
              areacode,
              alternate_code,
              deleted_on:deleted_on
              ? dayjs(deleted_on).format("ddd, MMM D, YYYY h:mm A")
              : null,
              created_at:created_at
              ? dayjs(created_at).format("ddd, MMM D, YYYY h:mm A")
              : null,
              isActive:isActive?"Yes":"No",
            };
          })}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          pagination="No"
          totalItems={
            alternatePhoneArea?.totalItems
              ? alternatePhoneArea?.totalItems
              : alternatePhoneArea?.length
          }
          // customToolbar={Toolbar}
          // componentsProps={{
          //   exportToExcel,
          //   exportDropdown: true,
          //   exportOption: exportOption,
          // }}
          // exportOption={exportOption}
        />
      </div>
    </div>
  );
}

export default AlternateDomainPhone;
