import { deployApi } from "../api";

export const getUsers = async (data) => {
  return await deployApi.get(`/api/users`, {
    params: data,
  });
};
export const createUser = async (values) => {
  return await deployApi.post("/api/users", values);
};

export const updateUser = async (values) => {
  return await deployApi.put("/api/users", values);
};

export const deleteUser = async (id) => {
  return await deployApi.delete("/api/users", { data: { id } });
};
