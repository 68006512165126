import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get_db_tables } from "../../../redux/databaseSlice";
import { get_industries } from "../../../redux/industriesSlice";
import DotsLoader from "../../atoms/DotsLoader";

function Breadcrumbs() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const optionsRef = React.useRef();
  const dispatch = useDispatch();
  let paths = location.pathname.split("/");
  const { isLoading, dbTables } = useSelector((state) => state.database);
  const { industries } = useSelector((state) => state.industries);
  const [selectedIndex, setSelectedIndex] = useState();
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setSelectedIndex("");
    }
  };
  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setSelectedIndex("");
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const clickedPath = (index) => {
    let clickOn;
    clickOn = paths
      .slice(1, index + 1)
      .map((path) => "/" + path)
      .toString()
      .replaceAll(",", "");
    navigate(clickOn);
  };
  React.useEffect(() => {
    if (industries?.length === 0 || industries === undefined) {
      dispatch(get_industries());
    }
    if (params.dbName && dbTables?.length === 0) {
      dispatch(get_db_tables(params.dbName));
    }
    // eslint-disable-next-line
  }, []);

  const MenuDropdown = (props) => {
    return (
      <>
        {isLoading && <DotsLoader text="Please Wait" />}

        <ul
          ref={optionsRef}
          className="min-w-[200px] max-h-[500px] overflow-y-auto absolute bg-white text-violet-600 z-50 border-l border-[#ddd] border-r shadow-lg"
        >
          <li className="bg-red-600 text-white p-2.5 border-t border-[#ddd]">
            {selectedIndex === 2 ? params.dbName : params.tableName}
          </li>
          {props?.options?.map((option, index) => {
            return (
              <Fragment key={index}>
                {(option.db_name && option.db_name !== params.dbName) ||
                (option.tableName && option.tableName !== params.tableName) ? (
                  <li
                    className="text-black p-2.5 border-t border-[#ddd] hover:bg-[#e1e1e1] cursor-pointer"
                    onClick={() => {
                      navigate(
                        selectedIndex === 2
                          ? `/industries/${option.db_name}`
                          : selectedIndex === 3
                          ? `/industries/${params.dbName}/${option.tableName}`
                          : null
                      );
                      setSelectedIndex("");
                    }}
                  >
                    {option.db_name ? option.db_name : option.tableName}
                  </li>
                ) : null}
              </Fragment>
            );
          })}
        </ul>
      </>
    );
  };

  return (
    <div className="flex flex-row !mt-2 bg-white !p-2 rounded-xl">
      {paths.map((path, index) => {
        return (
          <div key={index} className="cursor-pointer">
            {index > 1 ? (
              <span
                onClick={() => setSelectedIndex(index)}
                className="px-2 relative"
              >
                {`>>`}
                {index === selectedIndex && (
                  <MenuDropdown
                    options={selectedIndex === 2 ? industries : dbTables.data}
                  />
                )}
              </span>
            ) : null}
            <span
              onClick={() => clickedPath(index)}
              className={`${
                index === paths.length - 1
                  ? "text-black"
                  : "text-gray-400 hover:underline hover:text-red-600"
              }`}
            >
              {path}
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default Breadcrumbs;
