import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AddVendor, createEmail, deleteEmail, deleteVendors, getEmails, getVendors, updateEmail, updateVendors } from "../services";

const initialState = {
    isError: false,
    isLoading: false,
    vendor: [],
    message: "",
  };
  
// vendors
export const get_vendors = createAsyncThunk(
  "get_vendors",
  async ( thunkAPI) => {
    try {
      return await getVendors();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
  // Create New emails
  export const add_vendor = createAsyncThunk(
    "add_vendor",
    async ( data,thunkAPI) => {
      try {
        return await AddVendor(data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  // Update emails
  export const update_vendor = createAsyncThunk(
    "update_vendor",
    async ( data,thunkAPI) => {
      try {
        return await updateVendors(data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const delete_vendors = createAsyncThunk(
    "delete_vendors",
    async (id, thunkAPI) => {
      try {
        return await deleteVendors(id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  
  export const vendorSlice = createSlice({
    name: "vendors",
    initialState,
    reducers: {
      reset: (state) => {
        state.isError = false;
        state.isLoading = false;
        state.vendor = [];
        state.message = "";
      },
    },
    extraReducers: (builder) => {
      builder
      .addCase(get_vendors.pending, (state) => {
        state.isLoading = true;
        state.vendor = [];
      })
      .addCase(get_vendors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vendor = action.payload?.data;
      })
      .addCase(get_vendors.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.vendor = [];
      })
      .addCase(add_vendor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_vendor.fulfilled, (state, action) => {
       
        state.isLoading = false;
        state.vendor.push(action.payload.data[0]);
      })
      .addCase(add_vendor.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_vendor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_vendor.fulfilled, (state, action) => {
        console.log("🚀 ~ file: roleSlice.js:491 ~ .addCase ~ action:", action)
        state.isLoading = false;
        const result = state.vendor.findIndex(
          ({ id }) => id === action?.payload?.data[0]?.id
        );
        state.vendor[result] = {
          ...state.vendor[result],
          ...action.payload.data[0],
        };
      })
      .addCase(update_vendor.rejected, (state) => {
        state.isLoading = false;
      })
        .addCase(delete_vendors.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(delete_vendors.fulfilled, (state, action) => {
          state.vendor = state?.vendor?.filter(({id}) => id !== action?.payload?.data?.id);
          state.isLoading = false;
        })
        .addCase(delete_vendors.rejected, (state) => {
          state.isLoading = false;
        });  
    },
  });
  
  export const { reset } = vendorSlice.actions;
  export default vendorSlice.reducer;