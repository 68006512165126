import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  copyDomains,
  deleteDatabases,
  deleteDeployment,
  deleteDomain,
  deleteDomainImages,
  deployAwsDomains,
  exportDomainSampleTable,
  exportDomainTable,
  generateARN,
  getAllDomainDrd,
  getCloudFront,
  getDomainDbTables,
  getDomainImages,
  getDomainList,
  getDomainTableRows,
  getPendingDeployments,
  getPurchasedDomains,
  getVerification,
  importDomainExcel,
  reDeploy,
  redeployCloudFront,
  getDomainWtOPhoneNum,
  updateDomainPhoneNum,
  setDomainImages,
  singleDeploy,
  templateDeploy,
  templateReDeploy,
  updateDomainImages,
  updateTemplate,
  getCloudFlareAccount,
  getCloudDomainCount,
  getCloudFrontById,
  getDetachDomain,
  DeleteDetachDomain,
  PostDetachDomain,
  getDomainRunners,
  setTestDomains,
} from "../services";
import {
  addCloudFlare,
  addDomainRunner,
  addGoogleSearchDomain,
  addNewDomain,
  deleteVirtualHosts,
  getAlternateDomainPhone,
  getDomainNumbers,
  getDomainPerIndustry,
  getGoogleConsoles,
  getPendingDomainsPhone,
  listVirtualHosts,
  setAlternateAreaCode,
  updateCloudFlare,
  updateDomain,
  updateDomainNumbers,
  updateDomainRunner,
} from "../services/domainServices";

const initialState = {
  isError: false,
  isLoading: false,
  message: "",
  domainList: [],
  domainDrd: [],
  isVerified: null,
  domainTableRows: [],
  domainDbTables: [],
  imageList: [],
  pendingDeployments: [],
  purchasedDomains: [],
  cloudfront: [],
  wtoPhonenum: [],
  searchedCloudfront: [],
  cloudfrontAccount: [],
  domainCount: [],
  detachDomain: [],
  virtualHosts: [],
  searchConsole: [],
  domainNumbers: [],
  domainRunner: [],
  domainPerIndustry:[],
  pendingPhone:[],
  alternatePhoneArea:[]
};

// Get Domain List
export const get_domain_list = createAsyncThunk(
  "get_domain_list",
  async (data, thunkAPI) => {
    try {
      return await getDomainList(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_pending_domains_phone = createAsyncThunk(
  "get_pending_domains_phone",
  async (data, thunkAPI) => {
    try {
      return await getPendingDomainsPhone(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_alternate_domains_phone = createAsyncThunk(
  "get_alternate_domains_phone",
  async (thunkAPI) => {
    try {
      return await getAlternateDomainPhone();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const set_alternate_area_codes = createAsyncThunk(
  "set_alternate_area_codes",
  async (data, thunkAPI) => {
    try {
      return await setAlternateAreaCode(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Domain Runners List
export const get_domain_runners = createAsyncThunk(
  "get_domain_runners",
  async (data, thunkAPI) => {
    try {
      return await getDomainRunners(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_verification = createAsyncThunk(
  "get_domains",
  async (id, thunkAPI) => {
    try {
      return await getVerification(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Database Tables
export const get_domain_db_tables = createAsyncThunk(
  "get_domain_db_tables",
  async (dbName, thunkAPI) => {
    try {
      return await getDomainDbTables(dbName);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_domain_table_rows = createAsyncThunk(
  "get_domain_table_rows",
  async ({ dbName, tableName }, thunkAPI) => {
    try {
      return await getDomainTableRows(dbName, tableName);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const export_domain_table = createAsyncThunk(
  "export_domain_table",
  async ({ dbName, tableName }, thunkAPI) => {
    try {
      return await exportDomainTable(dbName, tableName);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const export_domain_sample_table = createAsyncThunk(
  "export_domain_sample_table",
  async ({ dbName, tableName }, thunkAPI) => {
    try {
      return await exportDomainSampleTable(dbName, tableName);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const import_domain_excel = createAsyncThunk(
  "import_domain_excel",
  async ({ file, dbName, tableName }, thunkAPI) => {
    try {
      return await importDomainExcel(file, dbName, tableName);
    } catch (error) {
      const message =
        error.response ||
        error.response.data ||
        error.response.data.message ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const redeploy = createAsyncThunk("redeploy", async (id, thunkAPI) => {
  try {
    return await reDeploy(id);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const get_domain_drd = createAsyncThunk(
  "get_domain_drd",
  async (thunkAPI) => {
    try {
      return await getAllDomainDrd();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const add_domains = createAsyncThunk(
  "add_domains",
  async (data, thunkAPI) => {
    try {
      return await addNewDomain(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const update_domains = createAsyncThunk(
  "update_domains",
  async (data, thunkAPI) => {
    try {
      return await updateDomain(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const add_domain_runner = createAsyncThunk(
  "add_domain_runner",
  async (data, thunkAPI) => {
    try {
      return await addDomainRunner(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const update_domain_runner = createAsyncThunk(
  "update_domain_runner",
  async (data, thunkAPI) => {
    try {
      return await updateDomainRunner(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const delete_virtual_hots = createAsyncThunk(
  "delete_virtual_hots",
  async (data, thunkAPI) => {
    try {
      return await deleteVirtualHosts(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_virtual_hots = createAsyncThunk(
  "get_virtual_hots",
  async (data, thunkAPI) => {
    try {
      return await listVirtualHosts(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const delete_domains = createAsyncThunk(
  "delete_domains",
  async (id, thunkAPI) => {
    try {
      return await deleteDomain(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const update_template = createAsyncThunk(
  "update_template",
  async (data, thunkAPI) => {
    try {
      return await updateTemplate(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const single_deploy = createAsyncThunk(
  "single_deploy",
  async (id, thunkAPI) => {
    try {
      return await singleDeploy(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const delete_domain_images = createAsyncThunk(
  "delete_domain_images",
  async (id, thunkAPI) => {
    try {
      return await deleteDomainImages(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const generate_arn = createAsyncThunk(
  "generate_arn",
  async (id, thunkAPI) => {
    try {
      return await generateARN(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const add_google_search_domains = createAsyncThunk(
  "add_google_search_domains",
  async (data, thunkAPI) => {
    try {
      return await addGoogleSearchDomain(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_google_search_console = createAsyncThunk(
  "get_google_search_console",
  async (data, thunkAPI) => {
    try {
      return await getGoogleConsoles(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const set_domain_images = createAsyncThunk(
  "set_domain_images",
  async (id, thunkAPI) => {
    try {
      return await setDomainImages(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const update_domain_images = createAsyncThunk(
  "update_domain_images",
  async (id, thunkAPI) => {
    try {
      return await updateDomainImages(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const delete_databases = createAsyncThunk(
  "delete_databases",
  async (id, thunkAPI) => {
    try {
      return await deleteDatabases(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const delete_deployment = createAsyncThunk(
  "delete_deployment",
  async (id, thunkAPI) => {
    try {
      return await deleteDeployment(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_domain_images = createAsyncThunk(
  "get_domain_images",
  async (id, thunkAPI) => {
    try {
      return await getDomainImages(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_pending_deployments = createAsyncThunk(
  "get_pending_deployments",
  async (thunkAPI) => {
    try {
      return await getPendingDeployments();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_cloud_front = createAsyncThunk(
  "get_cloud_front",
  async (thunkAPI) => {
    try {
      return await getCloudFront();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_domain_per_industry = createAsyncThunk(
  "get_domain_per_industry",
  async (count,thunkAPI) => {
    try {
      return await getDomainPerIndustry(count);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deploy_aws_domais = createAsyncThunk(
  "deploy_aws_domais",
  async (arn, thunkAPI) => {
    try {
      return await deployAwsDomains(arn);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const template_deploy = createAsyncThunk(
  "template_deploy",
  async (data, thunkAPI) => {
    try {
      return await templateDeploy(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const template_re_deploy = createAsyncThunk(
  "template_re_deploy",
  async (id, thunkAPI) => {
    try {
      return await templateReDeploy(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_purchased_domains = createAsyncThunk(
  "get_purchased_domains",
  async (thunkAPI) => {
    try {
      return await getPurchasedDomains();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const redeploy_cloudFront = createAsyncThunk(
  "redeploy_cloudFront",
  async (id, thunkAPI) => {
    try {
      return await redeployCloudFront(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const cloudfront_account = createAsyncThunk(
  "cloudfront_account",
  async (thunkAPI) => {
    try {
      return await getCloudFlareAccount();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const cloud_domain_count = createAsyncThunk(
  "cloud_domain_count",
  async (id, thunkAPI) => {
    try {
      return await getCloudDomainCount(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const add_cloud_flare = createAsyncThunk(
  "add_cloud_flare",
  async (data, thunkAPI) => {
    try {
      return await addCloudFlare(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const update_cloud_flare = createAsyncThunk(
  "update_cloud_flare",
  async (data, thunkAPI) => {
    try {
      return await updateCloudFlare(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const domain_without_phonenum = createAsyncThunk(
  "domain_without_phonenum",
  async (thunkAPI) => {
    try {
      return await getDomainWtOPhoneNum();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const update_domain_phonenum = createAsyncThunk(
  "update_domain_phonenum",
  async (id, thunkAPI) => {
    try {
      return await updateDomainPhoneNum(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const copy_domains = createAsyncThunk(
  "copy_domains",
  async (ids, thunkAPI) => {
    try {
      return await copyDomains(ids);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_cloudfront_domains_by_id = createAsyncThunk(
  "get_cloud_frontdomains_by_id",
  async (id, thunkAPI) => {
    try {
      return await getCloudFrontById(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_detach_domain = createAsyncThunk(
  "get_detach_domain",
  async (thunkAPI) => {
    try {
      return await getDetachDomain();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const post_detach_domain = createAsyncThunk(
  "put_detach_domain",
  async (domain, thunkAPI) => {
    try {
      return await PostDetachDomain(domain);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const delete_detach_domain = createAsyncThunk(
  "delete_detach_domain",
  async (id, thunkAPI) => {
    try {
      return await DeleteDetachDomain(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_domain_numbers = createAsyncThunk(
  "get_domain_numbers",
  async (thunkAPI) => {
    try {
      return await getDomainNumbers();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const update_domain_numbers = createAsyncThunk(
  "update_domain_numbers",
  async (data,thunkAPI) => {
    try {
      return await updateDomainNumbers(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const set_test_domains = createAsyncThunk(
  "set_test_domains",
  async (domain, thunkAPI) => {
    try {
      return await setTestDomains(domain);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const domainSlice = createSlice({
  name: "domains",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_domain_list.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_domain_list.fulfilled, (state, action) => {
        state.isLoading = false;
        state.domainList = action.payload.data;
      })
      .addCase(get_domain_list.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_domain_runners.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_domain_runners.fulfilled, (state, action) => {
        state.isLoading = false;
        state.domainRunner = action.payload.data;
      })
      .addCase(get_domain_runners.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_verification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_verification.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_verification.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(add_domains.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_domains.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Domain added successfully");
      })
      .addCase(add_domains.rejected, (state) => {
        state.isLoading = false;
        toast.error("Domain couldn't be Added");
      })
      .addCase(delete_domain_images.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_domain_images.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Images deleted successfully");
      })
      .addCase(delete_domain_images.rejected, (state) => {
        state.isLoading = false;
        toast.error("Images couldn't be deleted");
      })
      .addCase(delete_domains.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_domains.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.domainList.records.findIndex(
          ({ id }) => id === action?.payload?.data?.id
        );
        state.domainList.records[result] = {
          ...state.domainList.records[result],
          ...action.payload.data,
        };
        toast.success("Domain deleted successfully");
      })
      .addCase(delete_domains.rejected, (state) => {
        state.isLoading = false;
        toast.error("Domain couldn't be deleted");
      })
      .addCase(update_template.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_template.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.domainList.records.findIndex(
          (domain) => domain.id === action?.payload?.data?.id
        );
        state.domainList.records[result] = {
          ...state.domainList.records[result],
          ...action.payload.data,
        };
        toast.success("Template updated successfully");
      })
      .addCase(update_template.rejected, (state) => {
        state.isLoading = false;
        toast.error("Template couldn't be updated");
      })
      .addCase(single_deploy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(single_deploy.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.domainList.records.findIndex(
          ({ id }) => id === action?.payload?.data?.id
        );
        state.domainList.records[result] = {
          ...state.domainList.records[result],
          ...action.payload.data,
        };
        toast.success("Deploy successfully");
      })
      .addCase(single_deploy.rejected, (state) => {
        state.isLoading = false;
        toast.error("Project couldn't be Deployed");
      })
      .addCase(redeploy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(redeploy.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Project Redeploy successfully");
      })
      .addCase(redeploy.rejected, (state) => {
        state.isLoading = false;
        toast.error("Project couldn't be Redeployed");
      })
      .addCase(generate_arn.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(generate_arn.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Process Completed successfully");
      })
      .addCase(generate_arn.rejected, (state) => {
        state.isLoading = false;
        toast.error("Process couldn't be Completed");
      })
      .addCase(set_domain_images.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(set_domain_images.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Images set to domains successfully");
      })
      .addCase(set_domain_images.rejected, (state) => {
        state.isLoading = false;
        toast.error("Images couldn't be Assigned");
      })
      .addCase(get_domain_drd.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_domain_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.domainDrd = action.payload.data;
      })
      .addCase(get_domain_drd.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_domain_images.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_domain_images.fulfilled, (state, action) => {
        state.isLoading = false;
        state.imageList = action.payload.data;
      })
      .addCase(get_domain_images.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_domain_db_tables.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_domain_db_tables.fulfilled, (state, action) => {
        state.isLoading = false;
        state.domainDbTables = action.payload.data;
      })
      .addCase(get_domain_db_tables.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_domain_table_rows.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_domain_table_rows.fulfilled, (state, action) => {
        state.isLoading = false;
        state.domainTableRows = action.payload.data;
      })
      .addCase(get_domain_table_rows.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(export_domain_table.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(export_domain_table.fulfilled, (state, action) => {
        state.isLoading = false;
        window.open(action?.payload?.data?.path, "_blank")?.focus();
        toast.success("Table export successfully");
      })
      .addCase(export_domain_table.rejected, (state) => {
        state.isLoading = false;
        toast.error("Table couldn't be exported");
      })
      .addCase(export_domain_sample_table.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(export_domain_sample_table.fulfilled, (state, action) => {
        state.isLoading = false;
        window.open(action.payload.data.path, "_blank")?.focus();
        toast.success("Sample Table export successfully");
      })
      .addCase(export_domain_sample_table.rejected, (state) => {
        state.isLoading = false;
        toast.error("Sample Table couldn't be exported");
      })
      .addCase(import_domain_excel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(import_domain_excel.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Table import successfully");
      })
      .addCase(import_domain_excel.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(
          action.payload.data ? action.payload.data : "Table couldn't be import"
        );
      })
      .addCase(get_pending_deployments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_pending_deployments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.pendingDeployments = action.payload.data;
      })
      .addCase(get_pending_deployments.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deploy_aws_domais.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deploy_aws_domais.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Domains deploy successfully");
      })
      .addCase(deploy_aws_domais.rejected, (state, action) => {
        state.isLoading = false;
        toast.error("Domains couldn't be deployed");
      })
      .addCase(template_deploy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(template_deploy.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Project Deployed successfully");
      })
      .addCase(template_deploy.rejected, (state) => {
        state.isLoading = false;
        toast.error("Project couldn't be Deployed");
      })
      .addCase(template_re_deploy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(template_re_deploy.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Project Deployed successfully");
      })
      .addCase(template_re_deploy.rejected, (state) => {
        state.isLoading = false;
        toast.error("Project couldn't be Deployed");
      })
      .addCase(get_purchased_domains.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_purchased_domains.fulfilled, (state, action) => {
        state.isLoading = false;
        state.purchasedDomains = action.payload.data;
      })
      .addCase(get_purchased_domains.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(copy_domains.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(copy_domains.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Copy domains successfully");
      })
      .addCase(copy_domains.rejected, (state) => {
        state.isLoading = false;
        toast.error("Domain couldn't be copied");
      })
      .addCase(update_domain_images.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_domain_images.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Images updated successfully");
        window.location.reload();
      })
      .addCase(update_domain_images.rejected, (state) => {
        state.isLoading = false;
        toast.error("Images couldn't be updated");
      })
      .addCase(delete_databases.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_databases.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Databases deleted successfully");
      })
      .addCase(delete_databases.rejected, (state) => {
        state.isLoading = false;
        toast.error("Databases couldn't be deleted");
      })
      .addCase(delete_deployment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_deployment.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Deployment deleted successfully");
      })
      .addCase(delete_deployment.rejected, (state) => {
        state.isLoading = false;
        toast.error("Deployment couldn't be deleted");
      })
      .addCase(get_cloud_front.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_cloud_front.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cloudfront = action.payload.data;
      })
      .addCase(get_cloud_front.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(redeploy_cloudFront.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(redeploy_cloudFront.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("CloudFront Domain Redeployed successfully");
      })
      .addCase(redeploy_cloudFront.rejected, (state) => {
        state.isLoading = false;
        toast.error("CloudFront Domain couldn't be Redeployed");
      })
      .addCase(domain_without_phonenum.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(domain_without_phonenum.fulfilled, (state, action) => {
        state.isLoading = false;
        state.wtoPhonenum = action.payload.data;
      })
      .addCase(domain_without_phonenum.rejected, (state) => {
        state.isLoading = false;
        toast.error("Couldn't get the data");
      })
      .addCase(update_domain_phonenum.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_domain_phonenum.fulfilled, (state, action) => {
        state.isLoading = false;
        
        toast.success("Phone number update successfull");
      })
      .addCase(update_domain_phonenum.rejected, (state) => {
        state.isLoading = false;
        toast.error("Phone number couldn't be updated");
      })
      .addCase(get_cloudfront_domains_by_id.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_cloudfront_domains_by_id.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchedCloudfront = action.payload.data;
      })
      .addCase(get_cloudfront_domains_by_id.rejected, (state) => {
        state.isLoading = false;
        toast.error("Couldn't get the data");
      })
      .addCase(cloudfront_account.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cloudfront_account.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cloudfrontAccount = action.payload.data;
      })
      .addCase(cloudfront_account.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(cloud_domain_count.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cloud_domain_count.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.info(
          `Total domains count of ${
            state.cloudfrontAccount.find(({ id }) => id === action.meta.arg)
              ?.auth_email
          } is ${action.payload.data}`,
          {
            position: "top-center",
            autoClose: false,
          }
        );
        state.domainCount = action.payload.data;
      })
      .addCase(cloud_domain_count.rejected, (state) => {
        state.isLoading = false;
        toast.error("Cloudn't get the domain count");
      })
      .addCase(get_detach_domain.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_detach_domain.fulfilled, (state, action) => {
        state.isLoading = false;
        state.detachDomain = action.payload.data;
      })
      .addCase(get_detach_domain.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(post_detach_domain.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(post_detach_domain.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(post_detach_domain.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_detach_domain.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_detach_domain.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_detach_domain.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(update_domains.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_domains.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Domain updated successfully");
      })
      .addCase(update_domains.rejected, (state) => {
        state.isLoading = false;
        toast.error("Domain couldn't be updated");
      })

      .addCase(add_domain_runner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_domain_runner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.domainRunner.push(action.payload.data[0])
      })
      .addCase(add_domain_runner.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(update_domain_runner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_domain_runner.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log("🚀 ~ file: domainSlice.js:1305 ~ .addCase ~ action:", action)
        const result = state.domainRunner.findIndex(
          ({ id }) => id === action?.payload?.data[0]?.id
        );
        state.domainRunner[result] = {
          ...state.domainRunner[result],
          ...action.payload.data[0],
        };
      })
      .addCase(update_domain_runner.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_virtual_hots.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_virtual_hots.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Virtual Hosts deleted successfully");
      })
      .addCase(delete_virtual_hots.rejected, (state) => {
        state.isLoading = false;
        toast.error("Virtual Hosts couldn't be deleted");
      })
      .addCase(get_virtual_hots.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_virtual_hots.fulfilled, (state, action) => {
        state.isLoading = false;
        state.virtualHosts = action.payload.data?.domains;
      })
      .addCase(get_virtual_hots.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_google_search_console.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_google_search_console.fulfilled, (state, action) => {
        console.log(
          "🚀 ~ file: domainSlice.js:1200 ~ .addCase ~ action:",
          action
        );
        state.isLoading = false;
        state.searchConsole = action.payload.data;
      })
      .addCase(get_google_search_console.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(add_google_search_domains.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_google_search_domains.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(add_google_search_domains.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(update_cloud_flare.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_cloud_flare.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.cloudfrontAccount.findIndex(
          (domain) => domain.id === action?.payload?.data[0]?.id
        );
        state.cloudfrontAccount[result] = {
          ...state.cloudfrontAccount[result],
          ...action.payload.data[0],
        };
        toast.success("Cloud Flare updated successfully");
      })
      .addCase(update_cloud_flare.rejected, (state) => {
        state.isLoading = false;
        toast.error("Cloud Flare couldn't be updated");
      })
      .addCase(add_cloud_flare.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_cloud_flare.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cloudfrontAccount.push(action.payload.data[0]);
        toast.success("Cloud Flare added successfully");
      })
      .addCase(add_cloud_flare.rejected, (state) => {
        state.isLoading = false;
        toast.error("Cloud Flare couldn't be Added");
      })
      .addCase(get_domain_numbers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_domain_numbers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.domainNumbers = action.payload.data;
      })
      .addCase(get_domain_numbers.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(update_domain_numbers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_domain_numbers.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.domainNumbers.findIndex(
          (domain) => domain.domain === action?.payload?.data?.domain
        );
        if(result>-1){
          state.domainNumbers[result] = {
            ...state.domainNumbers[result],
            ...action.payload.data,
          };
        }
        toast.success("Phone number update successfull");
      })
      .addCase(update_domain_numbers.rejected, (state) => {
        state.isLoading = false;
        toast.error("Phone number couldn't be updated");
      }).addCase(get_domain_per_industry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_domain_per_industry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.domainPerIndustry = action.payload.data;
      })
      .addCase(get_domain_per_industry.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(set_test_domains.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(set_test_domains.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(set_test_domains.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_pending_domains_phone.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_pending_domains_phone.fulfilled, (state, action) => {
        state.isLoading = false;
        state.pendingPhone=action.payload.data.records
      })
      .addCase(get_pending_domains_phone.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(set_alternate_area_codes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(set_alternate_area_codes.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(set_alternate_area_codes.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_alternate_domains_phone.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_alternate_domains_phone.fulfilled, (state, action) => {
        state.isLoading = false;
        state.alternatePhoneArea=action.payload.data
      })
      .addCase(get_alternate_domains_phone.rejected, (state) => {
        state.isLoading = false;
      })
       
  },
});

export const { reset } = domainSlice.actions;
export default domainSlice.reducer;
