import React from "react";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import * as _ from "lodash";
import Toolbar from "./Toolbar";
import { FaBorderAll } from "react-icons/fa";
import IndustryFilter from "./test";
const MuiGrid = (props) => {
  const {
    totalItems,
    checkboxSelection,
    items,
    columnDefs,
    apiRef,
    getRowId,
    onRowSelectionModelChange,
    gridOptions,
    autoHeight,
    onRowClick,
    customToolbar,
    componentsProps,
    viewButton,
    setView,
    CustomComponent,
    onFilterChange,
    LineOne,
    additionalMsg,
    onPageSizeChange,
    onPaginationModelChange,
    paginationMode,
    customSize,
    pagination,
    searchCounter,
    paginationModel,
    height,
    showToolbar,
    rowHeight,
    LineOne2
  } = props;
  const recordsPerPage = 15000;
  const [page, setPage] = React.useState(1);
  const pagedTableData = _.chunk(props?.items, recordsPerPage);
  const offset = (page - 1) * recordsPerPage;
  const lastItemIndex = offset + pagedTableData[page - 1]?.length;
  return (
    <div className="bg-white p-2">
      <div className="ml-auto mr-auto" style={{ overflowX: "hidden" }}>
        <>
          <div className="flex justify-between items-center mb-2">
            <div className="flex flex-row items-center">
              <div className={["result"].join(" ")}>
                <div className="w-fit py-[5px] text-base font-normal font-mont text-heading my-2">
                  {/* Displaying {props?.items?.length > 0 ? offset + 1 : 0}-
                  {totalItems?totalItems:lastItemIndex ? lastItemIndex : 0} of { props?.items?.length}{" "}
                  result {additionalMsg ? additionalMsg : ""} */}
                  {items?.length > 0
                      ? searchCounter === "Yes"
                        ? `Displaying 1-${items?.length} of ${
                            totalItems ? totalItems : items?.length
                          } result ${additionalMsg ? additionalMsg : ""}`
                        : pagination === "No"
                        ? `Displaying 1-${items?.length} of ${
                            totalItems ? totalItems : items?.length
                          } result ${additionalMsg ? additionalMsg : ""}`
                        : `Displaying ${
                            (paginationModel?.page - 1) *
                              paginationModel?.pageSize +
                            1
                          }-${Math.min(
                            paginationModel?.page * paginationModel?.pageSize,
                            totalItems ? totalItems : items?.length
                          )} of ${
                            totalItems ? totalItems : items?.length
                          } result ${additionalMsg ? additionalMsg : ""}`
                      : `Displaying 0-0 of ${
                          totalItems
                            ? totalItems
                            : items?.length
                            ? items?.length
                            : 0
                        } result ${additionalMsg ? additionalMsg : ""}`}
                </div>
              </div>
              {CustomComponent ? <CustomComponent /> : null}
            </div>
            <div className="flex flex-row">
            {LineOne2 ? <LineOne2 /> : null}
            <div className="flex mt-2 mt-md-0 w-fit">
            
              <div className="relative w-full col-span-12 md:col-span-8">
                
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  className="h-[31px] bg-white border border-gray-300 text-gray-900 text-sm rounded-[0.2rem] w-full pl-12 p-2.5 "
                  placeholder="Search"
                  value={props.searchInput}
                  onChange={(e) => {
                    props?.setSearchInput(e.target.value);
                  }}
                />
              </div>
              {LineOne ? <LineOne /> : null}
              {viewButton ? (
                <button
                  className="ml-2 bg-black text-white p-1 rounded text-xl px-2"
                  onClick={() => setView(2)}
                >
                  {<FaBorderAll />}
                </button>
              ) : null}
              
            </div>
            </div>
          </div>
        </>

        <div style={{ height: height?height:680, width: "100%" }}>
          <DataGridPro
            apiRef={apiRef}
            rows={props.isLoading ? [] : items ? items : []}
            columns={columnDefs}
            onPaginationModelChange={onPaginationModelChange}
            onPageSizeChange={onPageSizeChange}
            paginationMode={paginationMode ? paginationMode : "server"}
            components={{
              Toolbar: showToolbar==="No"?false:customToolbar ? customToolbar : GridToolbar,
            }}
            loading={props.isLoading}
            checkboxSelection={checkboxSelection ? checkboxSelection : false}
            componentsProps={{
              toolbar: {
                showColumnSelector: false,
                ...componentsProps,
              },
            }}
            toolbarComponents={[
              {
                // icon: IndustryIcon,
                label: "Industry Filter",
                render: (api, params) => (
                  <IndustryFilter api={api} applyValue={params.applyValue} />
                ),
              },
            ]}
            getRowId={(row) => (getRowId ? getRowId : row?.records?.id)}
            headerHeight={30} // Set the height of the header
            rowHeight={rowHeight?rowHeight:40}
            classes={{
              columnHeader: "bg-[#f8f8f8] shadow-sm",
              columnHeaderTitle: "tableHead font-bold text-md capitalize px-0",
              row: "text-md",
            }}
            pageSizeOptions={[
              25,
              50,
              75,
              100,
              250,
              500,
              1000,
              1500,
              customSize ? customSize : totalItems?totalItems:0,
            ]}
            autoHeight={autoHeight}
            getRowHeight={gridOptions}
            onRowClick={onRowClick}
            rowSelection={true}
            onRowSelectionModelChange={onRowSelectionModelChange}
            onFilterModelChange={onFilterChange}
            pagination={pagination === "No" ? false : true}
            paginationModel={
              pagination === "No"
                ? { page: 1, pageSize: items?.length }
                : {
                    page: +paginationModel?.page - 1,
                    pageSize: +paginationModel?.pageSize,
                  }
            }
            page={pagination === "No" ? 1 : paginationModel?.page}
            pageSize={
              pagination === "No" ? items?.length : paginationModel?.pageSize
            }
            rowCount={totalItems ? totalItems : 0}
          />
        </div>
      </div>
    </div>
  );
};

export default MuiGrid;
