import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/atoms/Button";
import DotsLoader from "../../components/atoms/DotsLoader";
import {
  deploy_aws_domais,
  get_pending_deployments,
} from "../../redux/domainSlice";

function PendingDeployments() {
  const { isLoading, pendingDeployments } = useSelector(
    (state) => state.domains
  );
  const [selectedData, setSelectedData] = React.useState([]);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(get_pending_deployments());
  }, []);

  const onSingleselect = (val) => {
    if (selectedData?.length > 0) {
      if (
        selectedData.filter(
          ({ certificate_arns }) => certificate_arns === val.certificate_arns
        ).length > 0
      ) {
        const arr = selectedData.filter(
          ({ certificate_arns }) => certificate_arns !== val.certificate_arns
        );
        setSelectedData(arr);
      } else {
        setSelectedData([...selectedData, val]);
      }
    } else {
      setSelectedData([...selectedData, val]);
    }
  };

  const isChecked = (val) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter(({ certificate_arns }) => certificate_arns === val)
          .length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  const bulkDeploy = async () => {
    if (selectedData?.length > 0) {
      let certificate_arns = [];
      // eslint-disable-next-line
      selectedData.map((val) => {
        certificate_arns = [...certificate_arns, val.certificate_arns];
      });
      try {
        await dispatch(
          deploy_aws_domais({
            certificate_arns: certificate_arns,
          })
        );
      } catch (error) {
        console.log("ImagesList.jsx:48 ~ deleteRecordHandler ~ error", error);
        // toast.error("Template Image couldn't be deleted");
      }
    } else {
      alert("Please select data");
    }
  };
  
  return (
    <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
      {isLoading && <DotsLoader />}
      
      <div className="my-1 flex justify-between ites-center mt-3">
      <h1 className="text-xl font-semibold font_pop p-1">
          Pending Deployment Domains
        </h1>
        <button
          className="btn  bg-red-600 text-white"
          style={{
            height: 36,
          }}
          onClick={bulkDeploy}
          type="button"
          disabled={selectedData?.length > 0 ? false : true}
        >
          Deploy on AWS
        </button>
      </div>
      <div>
        {pendingDeployments?.length > 0 ? (
          <div className="flex flex-wrap grid grid-cols-3 gap-2 bg-white !my-3 border rounded w-full p-2">
            {pendingDeployments?.map((data, index) => {
              return (
                <div key={index} className="border rounded p-2">
                  <div
                    className="cursor-pointer"
                    onClick={() => onSingleselect(data)}
                  >
                    <div className="flex flex-row justify-between">
                      <input
                        type="checkbox"
                        className="cursor-pointer"
                        checked={isChecked(data.certificate_arns)}
                        onChange={() => onSingleselect(data)}
                      />
                      <div className="text-medium font-semibold font_pop text-violet-600">
                        Total Domains:{" "}
                        <span className="text-black font_pop text-sm font-semibold">
                          {data?.domains?.length}
                        </span>
                      </div>
                    </div>

                    <div className="text-medium font-semibold font_pop text-violet-600">
                      Template Name:{" "}
                      <span className="text-black font_mont text-sm font-normal">
                        {data?.template_name}
                      </span>
                    </div>
                    <div className="text-medium font-semibold font_pop text-violet-600">
                      Github Name:{" "}
                      <span className="text-black font_mont text-sm font-normal">
                        {data?.github_full_name}
                      </span>
                    </div>

                    <div className="flex flex-col border-t mt-2">
                      <span className="text-center text-xl font-semibold font_pop text-violet-600">
                        Domains
                      </span>

                      {data?.domains?.map((domain, key) => {
                        return (
                          <div className="flex flex-row" key={key}>
                            <span>{key + 1}.</span>
                            <span className="ml-1">{domain}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="flex justify-center mt-3 items-end">
                    <Button
                      text="Deploy"
                      className="text-center"
                      variant="btn_submit"
                      onClick={() =>
                        dispatch(
                          deploy_aws_domais({
                            certificate_arns: [data.certificate_arns],
                          })
                        )
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-center mt-3 text-3xl min-w-[200px] h-[calc(76vh-50px)] border-t border-[#ddd] overflow-y-auto bg-white text-red-600 z-50 border-l border-[#ddd] border-r shadow-lg flex flex-wrap justify-center items-center">
            No record found
          </div>
        )}
      </div>
    </div>
  );
}

export default PendingDeployments;
