import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/molecules/DataTable";
import DotsLoader from "../../components/atoms/DotsLoader";
import AddUpdateRoles from "./AddUpdateRoles";
import { get_roles } from "../../redux/roleSlice";
import dayjs from "dayjs";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";

function Roles() {
  const { isLoading, record } = useSelector((state) => state.roles);
  const dispatch = useDispatch();
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchInput, setSearchInput] = React.useState("");

  useEffect(() => {
    dispatch(get_roles());
    // eslint-disable-next-line
  }, []);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);

    setIsEditing(true);
  };
 
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Name", field: "name", flex: 1 ,minWidth:20},
    { headerName: "Level", field: "level",flex: 1 ,minWidth:10 },
    { headerName: "Created At", field: "created_at",flex: 1 ,minWidth:10 },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex items-center w-full">
          <FaEdit
            onClick={openFormHandler(params.row.records)}
            className="my_navIcon cursor-pointer"
            size={20}
          />
          
        </div>
      ),
      width: 100,
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",flex: 1 ,minWidth:20
      },
    },
  ];
  const data = record?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
       
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  return (
    <>
      {isEditing && (
        <AddUpdateRoles
          editingRecord={editingRecord}
          modalTitle="Add Roles"
          onCancelForm={cancelFormHandler}
        />
      )}
      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
        {isLoading && <DotsLoader text="Please Wait" />}
        <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
        
        <h1 className="text-2xl font-extrabold">Roles</h1>
          <button
            className="border rounded bg-red-600 text-white p-2"
            onClick={openFormHandler(0)}
          >
            Add Role
          </button>
        </div>

        <div className="bg-white !my-3 border rounded">
         
            <MuiGrid
              columnDefs={columnDefs}
              items={data?.map((record, index) => {
                let counter = index + 1;
                let id = record.id;
                let name = record.name;
                let level = record.level;
                let created_at = record.created_at
                  ? dayjs(record.created_at).format("ddd, MMM D, YYYY h:mm A")
                  : null;
               
                return {
                  records: record,
                  id,
                  counter,
                  name,
                  level,
                  created_at,
                };
              })}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              pagination="No"
            />
         
        </div>
      </div>
    </>
  );
}

export default Roles;
