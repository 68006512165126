import { deployApi } from "../api";

export const login = async (user) => {
  return await deployApi.post(`/api/auth`, user);
};

export const logout = async () => {
  return await deployApi.get(`/api/logout`, {
    headers: {
      refreshToken: localStorage.getItem("refreshToken"),
    },
  });
};

export const sendResetEmail = async (email) => {
  return await deployApi.post(`/api/auth/recover`, email);
};