import React, { useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { useSelector } from 'react-redux';

const IndustryFilter = ({ api, applyValue }) => {
    const { industries } = useSelector((state) => state.industries);
    console.log("🚀 ~ file: test.jsx:9 ~ IndustryFilter ~ industries:", industries)
  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = async (event) => {
    const newValue = event.target.value;

    // Make the API request and update the grid's data using the api object
    try {
      const response = await axios.get(`your_api_endpoint?industryId=${newValue}`);
      const newData = response.data; // Update this based on your API response
      api.applyTransaction({
        add: newData,
      });
      applyValue({ id: newValue });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <Select
      value={selectedValue}
      onChange={handleChange}
    >
      {industries.map((industry) => (
        <MenuItem key={industry.id} value={industry.id}>
          {industry.industry_name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default IndustryFilter;
