import React, { Fragment } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import AddImage from "./AddImage";
import { useDispatch, useSelector } from "react-redux";
import { get_industries } from "../../redux/industriesSlice";
import { get_templates } from "../../redux/templateImagesSlice";
import {
  delete_bulk_image,
  delete_by_folders,
  get_images_by_type,
  get_image_types,
  get_industries_by_temp,
} from "../../redux/bulkImagesSlice";
import DotsLoader from "../../components/atoms/DotsLoader";
import { FaArrowLeft, FaEdit, FaTrash, FaDownload } from "react-icons/fa";
import { toast } from "react-toastify";
import CopyImages from "./CopyImages";
function BulkImages() {
  const templates = useSelector((state) => state.images);
  const bulkImages = useSelector((state) => state.bulkImages);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [record, setRecord] = React.useState([]);
  const { tempId, industryId, folder } = params;
  const [isAddNew, setIsAddNew] = React.useState(false);
  const [isHover, setIsHover] = React.useState(1);
  const [bg_color, setBg_color] = React.useState("#fff");
  const [editingRecord, setEditingRecord] = React.useState(null);
  const [imgUrl, setImgUrl] = React.useState();
  const [selectedData, setSelectedData] = React.useState([]);
  const [copyData, setCopyData] = React.useState(false);
  const [wrongSize, setWrongSize] = React.useState([]);
  console.log("🚀 ~ file: index.jsx:35 ~ BulkImages ~ wrongSize:", wrongSize)

  const dispatch = useDispatch();

  const getIndustries = async () => {
    try {
      const res = await dispatch(get_industries_by_temp(tempId));
      if (res.payload.status === 200) {
        const uniqueArray = Array.from(new Set(res.payload.data)).filter(
          (item) => item !== ""
        );
        setRecord(uniqueArray);
      }
    } catch (error) {
      console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
    }
  };
  const getTemplates = async () => {
    try {
      const res = await dispatch(get_templates());
      if (res.payload.status === 200) {
        const uniqueArray = Array.from(new Set(res.payload.data)).filter(
          (item) => item !== ""
        );
        setRecord(uniqueArray);
      }
    } catch (error) {
      console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
    }
  };
  const getImageType = async () => {
    try {
      const res = await dispatch(
        get_image_types({ template_name: tempId, industry_name: industryId })
      );
      if (res.payload.status === 200) {
        const uniqueArray = Array.from(new Set(res.payload.data)).filter(
          (item) => item !== ""
        );
        setRecord(uniqueArray);
      }
    } catch (error) {
      console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
    }
  };

  React.useEffect(() => {
    if (!tempId) {
      getTemplates();
      setSelectedData([]);
    }
    if (tempId && !industryId) {
      getIndustries();
      setSelectedData([]);
    }
    if (tempId && industryId && !folder) {
      getImageType();
      setSelectedData([]);
    }
    if (tempId && industryId && folder) {
      dispatch(
        get_images_by_type({
          template_name: tempId,
          industry_name: industryId,
          image_type: folder,
        })
      );
      setSelectedData([]);
    }
    // eslint-disable-next-line
  }, [params]);
  const openFormHandler = (record) => () => {
    setEditingRecord(record);

    setIsAddNew(true);
  };
  const cancelFormHandler = () => {
    setIsAddNew(false);
    setEditingRecord("");
    setSelectedData([]);
    setCopyData(false);
  };
  React.useEffect(() => {
    dispatch(get_industries());
    // eslint-disable-next-line
  }, []);

  const onhandleNavigation = (path) => {
    if (!tempId) {
      navigate(`/bulk_images/${path.template_name}`);
    }
    if (tempId && !industryId) {
      navigate(`/bulk_images/${tempId}/${path}`);
    }
    if (tempId && industryId && !folder) {
      navigate(`/bulk_images/${tempId}/${industryId}/${path}`);
    }
  };

  const deleteRecordHandler = (data) => async () => {
    let ids = [];
    // eslint-disable-next-line
    data.map((val) => {
      ids = [...ids, val?.value?.id];
    });
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_bulk_image(ids));
        if (res.payload.status === 200) {
          toast.success("Template Image deleted");
          setSelectedData([]);
        } else {
          toast.error("Template Image couldn't be deleted");
        }
      } catch (error) {
        console.log("ImagesList.jsx:48 ~ deleteRecordHandler ~ error", error);
        toast.error("Template Image couldn't be deleted");
      }
    }
  };
  const selectAll = () => {
    let id = [];
    if (selectedData?.length !== record.length) {
      // eslint-disable-next-line
      record.map((val) => {
        id = [...id, { value: val, label: val }];
      });
      setSelectedData(id);
    } else if (selectedData?.length === record.length) {
      setSelectedData([]);
    }
  };
  const onSingleselect = (val) => {
    if (selectedData?.length > 0) {
      if (selectedData.filter(({ value }) => value === val).length > 0) {
        const arr = selectedData.filter(({ value }) => value !== val);
        setSelectedData(arr);
      } else {
        setSelectedData([...selectedData, { value: val, label: val }]);
      }
    } else {
      setSelectedData([...selectedData, { value: val, label: val }]);
    }
  };

  const isChecked = (val) => {
    if (selectedData?.length > 0) {
      if (selectedData?.filter(({ value }) => value === val).length > 0) {
        return true;
      }
    }
    return false;
  };

  const handleDel = async () => {
    let ids = [];
    // eslint-disable-next-line
    selectedData.map((val) => {
      ids = [...ids, val.value];
    });
    let payload;

    if (tempId && !industryId) {
      payload = {
        template_name: tempId,
        industry_name: ids,
      };
    } else if (tempId && industryId) {
      payload = {
        template_name: tempId,
        industry_name: [industryId],
        image_type: ids,
      };
    }
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_by_folders(payload));
        if (res.payload.status === 200) {
          toast.success("Template Image deleted");
          setSelectedData([]);
        } else {
          toast.error("Template Image couldn't be deleted");
        }
      } catch (error) {
        console.log("ImagesList.jsx:48 ~ deleteRecordHandler ~ error", error);
        toast.error("Template Image couldn't be deleted");
      }
    }
  };

  const checkImageDiamension = ({ width, height }) => {
    if (width === 512 && height === 512) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
      {bulkImages.isLoading || templates.isLoading ? (
        <DotsLoader text="Please Wait" />
      ) : null}

      {isAddNew && (
        <AddImage
          modalTitle="Add Image"
          onCancelForm={cancelFormHandler}
          params={params}
          editingRecord={editingRecord}
        />
      )}
      {copyData && (
        <CopyImages
          modalTitle="Tranfer Images"
          onCancelForm={cancelFormHandler}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      )}
      {imgUrl && (
        <div
          className="bg-[rgba(0,_0,_0,_0.6)] absolute top-0 bottom-0 left-0 right-0 z-20 flex justify-center items-center cursor-pointer max-h-screen"
          onClick={() => setImgUrl()}
        >
          <div className="w-auto h-auto bg-contain bg-no-repeat bg-center bg-violet-100 max-w-[80vw] max-h-[90vh]">
            <img
              src={imgUrl}
              className="w-full h-full max-w-[80vw] max-h-[90vh]"
              alt="img"
            />
          </div>
        </div>
      )}
      <div className="bg-white !my-3 border rounded flex flex-row items-center">
        {location?.pathname?.split("/")?.length > 2 && (
          <FaArrowLeft
            className="mx-2 hover:text-violet-600 cursor-pointer"
            size={20}
            onClick={() => {
              navigate(-1);
            }}
          />
        )}

        <h1 className="text-xl font-semibold font_pop p-1">Images and Icons</h1>
      </div>

      <div className="bg-white !my-3 border rounded h-[88vh] p-2">
        <div
          className={`flex flex-row items-center ${
            !tempId && !industryId && !folder
              ? "justify-end"
              : "justify-between"
          }`}
        >
          {tempId && industryId && folder && (
            <div className="flex items-center text-xl font-semibold font_pop text-left">
              Total Images:{" "}
              <span className="ml-2">
                {bulkImages?.images?.length > 0
                  ? bulkImages?.images?.length
                  : "0"}
              </span>
            </div>
          )}

          {tempId && !folder && (
            <div className="flex items-center justify-center text-xl font-semibold font_pop text-left">
              <input
                id="colorSelector"
                type="checkbox"
                className="border-none rounded mx-3 cursor-pointer overflow-hidden"
                value={bg_color}
                onChange={selectAll}
                checked={
                  record?.length > 0 && selectedData?.length === record?.length
                    ? true
                    : false
                }
              />
              <label htmlFor="colorSelector" className="!mb-0">
                Select All
              </label>
            </div>
          )}
          <div className="flex items-center ">
            {tempId && industryId && folder && (
              <>
                <label htmlFor="colorSelector">Change Background</label>
                <input
                  id="colorSelector"
                  type="color"
                  className="border-none h-[40px] rounded mx-3 cursor-pointer overflow-hidden"
                  value={bg_color}
                  onChange={(e) => setBg_color(e.target.value)}
                />
              </>
            )}
            {tempId && (
              <>
                {tempId && !folder && selectedData?.length > 0 && (
                  <button
                    className="btn flex float-right bg-black text-white mr-2"
                    style={{ height: 40 }}
                    type="button"
                    onClick={handleDel}
                  >
                    Delete Images
                  </button>
                )}
                {tempId && folder && industryId && selectedData?.length > 0 && (
                  <button
                    className="btn flex float-right bg-black text-white mr-2"
                    style={{ height: 40 }}
                    type="button"
                    onClick={deleteRecordHandler(selectedData)}
                  >
                    Delete Images
                  </button>
                )}
                <button
                  className="btn flex float-right bg-red-600 text-white mr-2"
                  style={{ height: 40 }}
                  type="button"
                  onClick={() => setCopyData(true)}
                >
                  Copy Images
                </button>
              </>
            )}
            <button
              className="btn flex float-right bg-red-600 text-white"
              style={{ height: 40 }}
              type="button"
              onClick={() => setIsAddNew(true)}
            >
              Add Images
            </button>
          </div>
        </div>
        <div className="w-full mt-2">
          {tempId && industryId && folder ? (
            bulkImages?.images?.length > 0 ? (
              <ul className="min-w-[200px] h-[calc(85vh-50px)] border-t border-[#ddd] overflow-y-auto bg-white text-violet-600 z-50 border-l border-[#ddd] border-r shadow-lg flex flex-wrap justify-center">
                {bulkImages?.images?.map((temp, index) => {
                  return (
                    <Fragment key={index}>
                      <li
                        className=" transition ease-in-out delay-150 relative text-black p-2.5 border-b border-[#ddd] cursor-pointer flex flex-col items-center justify-center w-[210px] max-h-[210px] shadow-lg"
                        onMouseEnter={() => setIsHover(index)}
                        onMouseLeave={() => setIsHover("")}
                      >
                        <img
                          src={temp?.img_path}
                          alt={temp?.alt}
                          style={{
                            backgroundColor:
                              !wrongSize?.filter((ind) => ind === index)
                                .length > 0
                                ? bg_color
                                : "rgba(255,0,0,0.3)",
                                padding:
                              !wrongSize?.filter((ind) => ind === index)
                                .length > 0
                                ? 10
                                : 20,
                          }}
                          className="relative"
                          onLoad={(e) => {
                            !checkImageDiamension({
                              width: e.target.naturalWidth,
                              height: e.target.naturalHeight,
                            }) && setWrongSize([...wrongSize, index]);
                          }}
                        />

                        {isHover === index || isChecked(temp) ? (
                          <>
                            <div
                              className={`absolute flex flex-col justify-center top-0 left-0 right-0 bottom-0 bg-black/70 !p-2 text-sm text-white
                          ${isHover === index ? "slide-down" : "slide-up"}
                          `}
                              onClick={() => setImgUrl(temp?.img_path)}
                            >
                              <div className="text-sm text-white font-medium">
                                TagName:{" "}
                                <span className="text-xs font-normal">
                                  {temp.tag_name}
                                </span>
                              </div>
                              <div className="text-sm text-white font-medium">
                                Title:{" "}
                                <span className="text-xs">{temp.title}</span>
                              </div>
                              <div className="text-sm text-white font-medium">
                                Alt: <span className="text-xs">{temp.alt}</span>
                              </div>
                              <div className="text-sm text-white font-medium">
                                Size:{" "}
                                <span className="text-xs">{temp.size}</span>
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <div className="flex items-center justify-center absolute bg-white w-full left-0 bottom-0 h-[24px] shadow-md">
                                {!isChecked(temp) && (
                                  <FaTrash
                                    onClick={deleteRecordHandler([
                                      { value: temp, label: temp },
                                    ])}
                                    className="mx-2 cursor-pointer hover:text-violet-600"
                                  />
                                )}

                                <FaEdit
                                  onClick={openFormHandler(temp)}
                                  className="mx-2 cursor-pointer hover:text-violet-600"
                                />
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={temp?.img_path}
                                >
                                  <FaDownload className="mx-2 cursor-pointer hover:text-violet-600" />
                                </a>

                                <input
                                  type="checkbox"
                                  className="mx-2 cursor-pointer"
                                  checked={isChecked(temp)}
                                  onChange={() => onSingleselect(temp)}
                                />
                              </div>
                            </div>
                          </>
                        ) : null}
                      </li>
                    </Fragment>
                  );
                })}
              </ul>
            ) : (
              <div className="text-center mt-3 text-3xl justify-center items-center">
                No images found
              </div>
            )
          ) : record?.length > 0 ? (
            <ul className="min-w-[200px] h-[calc(85vh-50px)] border-t border-[#ddd] overflow-y-auto bg-white text-violet-600 z-50 border-l border-[#ddd] border-r shadow-lg">
              {record?.map((temp, index) => {
                return (
                  <li
                    className="text-black p-2.5 border-b border-[#ddd] flex flex-row items-center "
                    key={index}
                  >
                    {tempId && (
                      <input
                        type="checkbox"
                        className="mr-2 cursor-pointer"
                        checked={isChecked(temp)}
                        onChange={() => onSingleselect(temp)}
                      />
                    )}

                    <div
                      className="flex flex-row items-center hover:text-red-600 cursor-pointer"
                      onClick={() => onhandleNavigation(temp)}
                    >
                      <svg
                        viewBox="0 0 40 40"
                        fill="none"
                        role="img"
                        className="dig-ContentIcon dig-ContentIcon--small sl-list-icon mr-2"
                        focusable="false"
                        width="40"
                        height="40"
                        data-testid="FolderBaseDefaultSmall"
                      >
                        <path
                          d="M15.002 7.004c.552.018.993.252 1.295.7l.785 2.12c.145.298.363.576.561.779.252.257.633.4 1.156.4H35.5l-.002 18c-.027.976-.3 1.594-.836 2.142-.565.577-1.383.858-2.41.858H8.748c-1.026 0-1.844-.28-2.409-.858-.564-.577-.838-1.415-.838-2.465V7.003h9.502Z"
                          fill="#ffda73"
                        ></path>
                        <path
                          d="M15.002 7.001c.552.018.993.252 1.295.7l.785 2.12c.145.298.363.576.561.779.252.257.633.4 1.156.4H35.5l-.002 16.84c-.027.976-.3 1.754-.836 2.302-.565.577-1.383.858-2.41.858H8.748c-1.026 0-1.844-.28-2.409-.858-.564-.577-.838-1.415-.838-2.465V7l9.502.001Z"
                          fill="#ffda73"
                        ></path>
                      </svg>
                      {temp.template_name ? temp.template_name : temp}
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className="text-center mt-3 text-3xl justify-center items-center">
              No record found
            </div>
          )}
        </div>
        {/* )} */}
      </div>
    </div>
  );
}

export default BulkImages;
