import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/molecules/DataTable";
import DotsLoader from "../../components/atoms/DotsLoader";
 

import dayjs from "dayjs";
import AddTollfree from "./AddTollfree";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";
import { get_tollfree } from "../../redux/tollSlice";

const VendorTollfree = () => {
    const { isLoading, toll } = useSelector((state) => state.toll);
    const dispatch = useDispatch();
    const [editingRecord, setEditingRecord] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [searchInput, setSearchInput] = React.useState("");
  
    useEffect(() => {
      dispatch(get_tollfree());
      // eslint-disable-next-line
    }, []);
    const cancelFormHandler = () => {
      setEditingRecord(null);
      setIsEditing(false);
    };
  
    const openFormHandler = (toll) => () => {
      setEditingRecord(toll);
  
      setIsEditing(true);
    };
   
    const columnDefs = [
      { headerName: "#", field: "counter", width: 100 },
      { headerName: "Tollfree", field: "tollfree", flex: 1  ,minWidth:100},
    //   { headerName: "Level", field: "level" },
      { headerName: "Created At", field: "created_at",flex: 1 ,minWidth:100 },
      { headerName: "Updated At", field: "updated_at" ,flex: 1 ,minWidth:100},
     
    ];
    const data = toll?.filter((e) => {
      return Object.keys(e)?.some(
        (key) =>
          e[key] &&
         
          e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
      );
    });

  return (
    <>
    {isEditing && (
      <AddTollfree
        editingRecord={editingRecord}
        modalTitle="Add Tollfree"
        onCancelForm={cancelFormHandler}
      />
    )}
    <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
      {isLoading && <DotsLoader text="Please Wait" />}
      <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
      
      <h1 className="text-2xl font-extrabold">Tollfree </h1>
        <button
          className="border rounded bg-red-600 text-white p-2"
          onClick={openFormHandler(0)}
        >
          Add TollFree
        </button>
      </div>

      <div className="bg-white !my-3 border rounded">
        
          <MuiGrid
            columnDefs={columnDefs}
            items={data?.map((record, index) => {
                
              let counter = index + 1;
              let id = record.id;
              let tollfree = record.tollfree;
            //   let level = record.level;
              let created_at = record.created_at
                ? dayjs(record.created_at).format("ddd, MMM D, YYYY h:mm A")
                : null;
                let updated_at = record.updated_at
                ? dayjs(record.updated_at).format("ddd, MMM D, YYYY h:mm A")
                : null;
             
              return {
                records: record,
                id,
                counter,
                tollfree,
                // level,
                created_at,
                updated_at
              };
            })}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            pagination="No"
          />
      
      </div>
    </div>
  </>
  )
}

export default VendorTollfree