import dayjs from "dayjs";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import DotsLoader from "../../components/atoms/DotsLoader";
import Breadcrumbs from "../../components/molecules/Breadcrumbs";
// import DataTable from "../../components/molecules/DataTable";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";
import { delete_callerId, get_callerId } from "../../redux/CallerIdSlice";
import { download_db } from "../../redux/databaseSlice";



const CallerId = () => {
    const dispatch = useDispatch();
    const { isLoading, callerId } = useSelector((state) => state.caller);
    const [editingRecord, setEditingRecord] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [searchInput, setSearchInput] = React.useState("");
    const [selectedData, setSelectedData] = React.useState([]);
  
    const openFormHandler = (record) => () => {
      setEditingRecord(record);
  
      setIsEditing(true);
    };
    const cancelFormHandler = () => {
      setEditingRecord(null);
      setIsEditing(false);
    };
    React.useEffect(() => {
      dispatch(get_callerId());
      // eslint-disable-next-line
    }, []);
    const columnDefs = [
      { headerName: "#", field: "counter" },
      { headerName: "Job Number", field: "job_number", flex: 1 },
      { headerName: "Cust Number", field: "cust_number", flex: 1 },
      { headerName: "Locked On", field: "locked_on", flex: 1 },
      { headerName: "Number", field: "number", flex: 1 },
      { headerName: "Created At", field: "created_at", flex: 1 },
      { headerName: "Updated At", field: "updated_at", flex: 1 },
  
    
     
    ];
    const data = callerId?.filter((e) => {
      return Object.keys(e)?.some(
        (key) =>
          e[key] &&
          
          e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
      );
    });
    console.log("dataaaaa",data)

    const onRowSelectionModelChange=(params)=>{
      const selectedData = params
          setSelectedData(selectedData);
  
    }
    const deleteLogs = async () => {
      const c = window.confirm(`Are you sure want to delete?`);       
      if (c) {
        try {
          for(const item of selectedData) {
            console.log("delete", item);
            await dispatch(delete_callerId(item));
          }
          window.location.reload();
        } catch (error) {
          console.log("ImagesList.jsx:48 ~ deleteRecordHandler ~ error", error);
          // toast.error("Template Image couldn't be deleted");
        }
      }
    };   
  return (
    <>
      {/* {isEditing && (
        <AddIndustries
          editingRecord={editingRecord}
          modalTitle="Add Industries"
          onCancelForm={cancelFormHandler}
        />
      )} */}
      {isLoading && <DotsLoader text="Please Wait" />}
      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
        <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
          <h1 className="text-2xl font-extrabold">Caller ID</h1>
          <button
            className="border rounded bg-red-600 text-white p-2 hover:bg-red-700"
            onClick={deleteLogs}
          >
            Delete
          </button>
        </div>

        <div className="bg-white !my-3 border rounded">
         
            <MuiGrid
              columnDefs={columnDefs}
              items={data?.map((record, index) => {
                console.log("caleridddd",record)
                let counter = index + 1;
                let id = record.id;
                let job_number= record.job_number;
                let cust_number = record.cust_number;
                let locked_on = record.locked_on;
                let number = record.number;
                let created_at = record.created_at
                  ? dayjs(record.created_at).format("ddd, MMM D, YYYY h:mm A")
                  : null;
                let updated_at = record.updated_at
                  ? dayjs(record.updated_at).format("ddd, MMM D, YYYY h:mm A")
                  : null;
                
                return {
                  records: record,
                  counter,
                  id,
                  job_number,
                  cust_number,
                  locked_on,
                  number,
                  created_at,
                  updated_at,
                };
              })}
              onRowSelectionModelChange={onRowSelectionModelChange}
              totalItems={callerId?.totalItems?callerId?.totalItems:0}
              isLoading={isLoading}
              searchInput={searchInput}
              checkboxSelection
              setSearchInput={setSearchInput}
              pagination="No"
            />
         
        </div>
      </div>
    </>
  )
}

export default CallerId