import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/molecules/DataTable";
import DotsLoader from "../../components/atoms/DotsLoader";
import dayjs from "dayjs";
import { get_cloud_front, redeploy_cloudFront } from "../../redux/domainSlice";
import Cloudmodal from "./Cloudmodal";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";
import { useGridApiRef, GridApi } from "@mui/x-data-grid-pro";
import { FaBorderAll, FaListUl } from "react-icons/fa";
import GridView from "./GridView";
import { get_industries } from "../../redux/industriesSlice";
function Cloudfront() {
  const { isLoading, cloudfront } = useSelector((state) => state.domains);
  console.log("🚀 ~ file: index.jsx:16 ~ Cloudfront ~ cloudfront:", cloudfront)
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = React.useState("");
  const [selectedIndustry, setSelectedIndustry] = React.useState(1);
  const [closeModal, setCloseModal] = React.useState(false);
  const [domainData, setDomainData] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState([]);
  console.log("🚀 ~ file: index.jsx:20 ~ Cloudfront ~ selectedData:", selectedData)
  const [view, setView] = React.useState(1);
  const { industries } = useSelector((state) => state.industries);
  useEffect(() => {
    dispatch(get_cloud_front());
    dispatch(get_industries());
    // eslint-disable-next-line
  }, []);
  const statusOption=[
    {label:"Success",value:"success"},
    {label:"In Progress",value:"inProgress"},
    {label:"Failed",value:"failed"},
    {label:"Pending",value:"pending"},
    {label:"Not Ready",value:"notReady"},
  ]
  const apiRef = useGridApiRef();
  const onRowSelectionModelChange = (params) => {
    console.log("paramssss", params);
    const selectedNodes = apiRef.current.getSelectedRows();
    console.log("paramschange3", selectedNodes);

    const selectedData = params;
    setSelectedData(selectedData);
    console.log("sleectionchangee", selectedData);
  };

  const columnDefs = [
    { headerName: "#", field: "counter", width: 60,filterable: false, },
    {
      field: "industry_name",
      headerName: "Industry",
      type: "singleSelect",
      flex: 1,
      minWidth: 100,
      getOptionValue: (value) => value?.id,
      getOptionLabel: (value) => value.industry_name,
      valueOptions: industries,
      renderCell: (params) => params.value.industry_name,
      valueGetter: (params) => (params.row.industry_name),
      valueFormatter: (params) => params.value.industry_name,
      filterable: false,
    },
    // { headerName: "Industry", field: "industry_name", width: 150 },
    {
      headerName: "Certificate #",
      field: "certificate",
      flex: 1,
      minWidth: 150,
    },
    { headerName: "Template", field: "template_name", flex: 1, minWidth: 150 },
    {
      headerName: "Domains",
      field: "domains_count",
      width: 100,
    },
    { headerName: "Active", field: "active_count", width: 100 },
    {
      field: "status",
      headerName: "Status",
      type: "singleSelect",
      flex: 1,
      minWidth: 100,
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: statusOption,
      renderCell: (params) => params.value.status,
      valueGetter: (params) => params.row.status,
      valueFormatter: (params) => params.value.status,
    },
    // { headerName: "Status", field: "status", width: 100 },

    { headerName: "Created At", field: "created_at", flex: 1, minWidth: 150 },
    { headerName: "Updated At", field: "updated_at", flex: 1, minWidth: 150 },
  ];
  const data = cloudfront?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())&& e.industry_id===+selectedIndustry
    );
  });
  const redeployDomains = async () => {
    const c = window.confirm(
      `Are you sure want to Redeploy ${selectedData?.length} cloudfront domains`
    );
    if (c) {
      try {
        const res = await dispatch(redeploy_cloudFront(selectedData));
        if (res.payload.status === 200) {
        }
      } catch (error) {
        console.log("ImagesList.jsx:48  deleteRecordHandler  error", error);
      }
    }
  };
  const getCount=(id)=>{
    const count=cloudfront?.filter(({industry_id})=>industry_id===id)?.length
    return count
  }
  const SingleSelectRenderer = () => {
    return (
      <select value={selectedIndustry} onChange={(e)=>setSelectedIndustry(e.target.value)} className="border p-1 rounded h-[35px] ml-2">
        <option value="">Select Industry</option>
        {industries.map((option) => (
          <option key={option.id} value={option.id} className="flex justify-between">
            {option.industry_name} {`(${getCount(option?.id)})`}
          </option>
        ))}
      </select>
    );
  };
  return (
    <>
      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
        {closeModal && (
          <Cloudmodal
            domain={domainData}
            onClosemodal={() => setCloseModal(false)}
          />
        )}

        {isLoading && <DotsLoader text="Please Wait" />}
        <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
          <h1 className="text-2xl font-extrabold">AWS Certificates</h1>
          <button
            className="border rounded bg-red-600 text-white p-2"
            onClick={redeployDomains}
            disabled={selectedData.length > 0 ? false : true}
            type="button"
          >
            Redeploy
          </button>
        </div>
        {view === 1 ? (
          <div className="bg-white !my-3 border rounded">
            <MuiGrid
              apiRef={apiRef}
              onRowSelectionModelChange={onRowSelectionModelChange}
              columnDefs={columnDefs}
              items={data?.map((item, index) => {
                let counter = index + 1;
                const {
                  id,
                  template_name,
                  industry_name,
                  status,
                  domains_count,
                  active_count,
                } = item;
                let created_at = item.created_at
                  ? dayjs(item.created_at).format("ddd, MMM D, YYYY h:mm A")
                  : null;
                let certificate = item.certificate_arn.split("/")[1];
                let updated_at = item.updated_at
                  ? dayjs(item.updated_at).format("ddd, MMM D, YYYY h:mm A")
                  : null;
                return {
                  records: item,
                  id,
                  counter,
                  industry_name,
                  template_name,
                  domains_count,
                  certificate,
                  status,
                  created_at,
                  updated_at,
                  active_count,
                };
              })}
              checkboxSelection
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              setView={setView}
              viewButton
              CustomComponent={SingleSelectRenderer}
              pagination="No"
            />
          </div>
        ) : (
          <GridView
            data={data}
            setView={setView}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            totalItems={cloudfront?.length}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            CustomComponent={SingleSelectRenderer}
            selectedIndustry={selectedIndustry}
          />
        )}
      </div>
    </>
  );
}

export default Cloudfront;
