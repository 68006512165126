import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../components/atoms/Button";
import DotsLoader from "../../components/atoms/DotsLoader";
import DataTable from "../../components/molecules/DataTable";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";
import { template_re_deploy } from "../../redux/domainSlice";
import { get_templates, update_template_names } from "../../redux/templateImagesSlice";
import DeploymentForm from "./DeploymentForm";

function Templates() {
  const dispatch = useDispatch();
  const { isLoading, templates } = useSelector((state) => state.images);
  const navigate = useNavigate();
  const [tempId, setTempId] = React.useState();
  const [searchInput, setSearchInput] = React.useState("");
  React.useEffect(() => {
    dispatch(get_templates());
    // eslint-disable-next-line
  }, []);
  const columnDefs = [
    { headerName: "#", field: "counter", width: 60},
    { headerName: "Templates Name", field: "template_name",flex: 1 ,minWidth: 200 },
    
    { headerName: "Github Full Name", field: "github_full_name",flex: 1,minWidth: 200 },
    
    {
      headerName: "Github Html URL",
      field: "github_html_url",
      renderCell: (params) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${params?.row?.github_html_url}`}
        >
          {params?.row?.github_html_url}
        </a>
      ),
      flex: 1,
      minWidth: 250 ,
    },
    {
      headerName: "Testing Domain",
      field: "testing_domain",
      renderCell: (params) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://${params?.row?.testing_domain}`}
        >
          {params?.row?.testing_domain}
        </a>
      ),
      flex: 1,
      minWidth: 200 
    },
    { headerName: "Domain", field: "domain" ,flex: 1},
    { headerName: "Industry Id", field: "industry_id",flex: 1 },
    {
      headerName: "Images",
      field: "images",
      renderCell: (params) => (
        <span
          onClick={() => navigate(`/bulk_images/${params.row.template_name}`)}
          className="cursor-pointer hover:underline text-blue-600"
        >
          View Images
        </span>
      ),
      flex: 1,
      minWidth: 100 
    },
    {
      headerName: "Action",
      field: "action",
      renderCell: (params) => {
      
        return (<>
          	{!params.row.records.is_deployed ? (
            	<span
              	onClick={() => setTempId(params.row.id)}
              	className="cursor-pointer hover:underline text-blue-600"
            	>
              	Deploy
            	</span>
          	) : params.row.records.is_deployed===1 ? (
            	<span
              	onClick={() => dispatch(template_re_deploy(params.row.id))}
              	className="cursor-pointer hover:underline text-blue-600"
            	>
              	Redeploy
            	</span>
          	) : null}
        	</>
      	)
      },
      flex: 1,
      minWidth: 100 
    },
  ];

  const data = templates?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
       
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  return (
    <>
      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
        {tempId && (
          <DeploymentForm tempId={tempId} onCloseModal={() => setTempId()} />
        )}
              {isLoading && <DotsLoader text="Please Wait" />}
        <div className="flex flex-row justify-between">
          <h1 className="text-2xl font-extrabold mt-3">Templates</h1>
          <Button
            text="Update Template Names"
            onClick={()=>dispatch(update_template_names())}
            variant="btn_submit"
            className="mt-2"
          />
        </div>
        <div className="bg-white !my-3 border rounded">
        
            <MuiGrid
              columnDefs={columnDefs}
              items={data?.map((record, index) => {
                let counter = index + 1;
                let id = record.id;
                let template_name = record.template_name;
                let github_full_name = record.github_full_name;
                let github_html_url = record.github_html_url;
                let testing_domain = record.testing_domain;
                let industry_id = record.industry_id;
                let domain=record?.domain
                return {
                  records: record,
                  counter,
                  id,
                  template_name,
                  github_full_name,
                  github_html_url,
                  testing_domain,
                  domain
                };
              })}
              // checkboxSelection
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              pagination="No"
            />
          
        </div>
      </div>
    </>
  );
}

export default Templates;
