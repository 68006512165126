import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/molecules/Modal";
import { toast } from "react-toastify";
import DotsLoader from "../../components/atoms/DotsLoader";
import {
    add_nation_site_zips,
} from "../../redux/serverSlice";
import TagsInput from "../../components/atoms/CustomTagsInput";
function AddUpdateZips({ editingRecord, onCancelForm, modalTitle,getData }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.servers);
  const { industries } = useSelector((state) => state.industries);
  //   const [zips, setZips] = useState([]);
  let initialData = {
    industry_id: "",
    zips: [],
  };

  if (editingRecord) {
    // eslint-disable-next-line
    const { industry_id, zips } = editingRecord;
    initialData = {
      industry_id,
      zips,
    };
  }
  const [data, setData] = useState(initialData);

  const onSubmit = async () => {
      try {
        const res = await dispatch(add_nation_site_zips(data));
        if (res.payload.status === 200) {
          dispatch(getData());
          onCancelForm();
        }
      } catch (error) {
        toast.error("Server couldn't be added");
        console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
      }
  };
  return (
    <Modal
      isUpdate={editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={onSubmit}
      onClick={onCancelForm}
    >
      {isLoading && <DotsLoader />}
      <div className="container-fluid max-w-[900px] border rounded bg-white py-3 w-full flex flex-row justify-between">
        <div className="w-full !mr-2 grid grid-cols-2 gap-x-2">
          <div className="col-span-2 !mt-3 flex justify-between">
            <select
              value={data.industry_id}
              onChange={(e) =>
                setData({ ...data, industry_id: e.target.value })
              }
              className="border p-1 rounded h-[35px] ml-2"
            >
              <option value="">Select Industry</option>
              {industries.map((option) => (
                <option
                  key={option.id}
                  value={option.id}
                  className="flex justify-between"
                >
                  {option.industry_name}
                </option>
              ))}
            </select>
            <span className="font_pop text-lg font-semibold">Total Zips: {data?.zips?.length}</span>
          </div>
          <div className="col-span-2 !mt-3">
            <TagsInput
              tags={data?.zips}
              setTags={(tag) => setData({ ...data, zips: tag })}
              placeholder="Add Zip"
            />
          </div>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
}

export default AddUpdateZips;
