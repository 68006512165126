import React from "react";
import { MdCancel } from "react-icons/md";
const TagsInput = (props) => {
  const {
    tags,
    setTags,
    placeholder,
    className,
    disable,
    readOnly,
    phoneInput,
    label,
    name,
    isFloat
  } = props;
  const removeTags = (selectedTag) => {
    setTags([...tags?.filter((tag) => tag !== selectedTag)]);
  };
  const addTags = (event) => {
    event.preventDefault();
    if (event.target.value !== "" && !phoneInput) {
      setTags([...tags, event.target.value]);
      event.target.value = "";
    }
    if (event.target.value !== "" && phoneInput) {
      setTags([...tags, `+1${event.target.value}`]);
      event.target.value = "";
    }
  };
  function handlePaste(event) {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    if (text.trim().indexOf(" ") !== -1) {
      event.preventDefault(); // prevent space from being pasted in input
    }
    const demo = text.replaceAll("\n", ",");
    const cities = demo.split(",").map((city) => city.trim());
    const uniqueCities = [...new Set(cities)];
    setTags([...tags, ...uniqueCities]);
  }
  return (
    <div className={`form-control inline-flex flex-wrap relative min-h-[40px] max-h-[430px] h-aut overflow-y-auto !border-[#a9a9a9] ${className}`}>
      {label && (
        <label htmlFor={name} className={[`small`, isFloat?"text-[rgba(0,_0,_0,_0.6)] absolute -left-[5px] top-0 font-normal text-base truncate max-w-[calc(133%-24px)] translate-x- -translate-y-[13px] bg-white scale-75 px-1":''].join(" ")}>
          {label}
        </label>
      )}
      {tags?.map((tag, index) => (
        <div
          className="badge me-1 pe-1 badge me-1 pe-1 flex items-center justify-between bg-secondary !text-white !mr-2 my-0.5 h-[26.39px]"
          key={index}
        >
          {tag}
          <button
            data-testid="tag-clean-element"
            aria-label="remove path fragment"
            tabindex="-1"
            class="border-0 bg-transparent ps-auto pe-0"
          >
            <MdCancel
              size={20}
              className="ml-0.5 cursor-pointer text-white"
              onClick={() => removeTags(tag)}
            />
          </button>
        </div>
      ))}
      
    
        <input
          type="text"
          onKeyUp={(event) => (event.key === "Enter"|| event.key === "," ? addTags(event) : null)}
          placeholder={placeholder ? placeholder : "Add new data"}
          onPaste={handlePaste}
          className="border-0 w-auto flex-fill input-tags"
          onBlur={(event) => addTags(event)}
          readOnly={readOnly}
          disabled={disable}
        />
    </div>
  );
};

export default TagsInput;
