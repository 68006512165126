import dayjs from "dayjs";
import React from "react";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import DotsLoader from "../../components/atoms/DotsLoader";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";
import { cloudfront_account } from "../../redux/domainSlice";
import AddUpdateCloudFlare from "./AddUpdateCloudFlare";

const CloudFrontAccount = () => {
  const dispatch = useDispatch();
  const { isLoading, cloudfrontAccount } = useSelector(
    (state) => state.domains
  );
  const [searchInput, setSearchInput] = React.useState("");
  const [editingRecord, setEditingRecord] = React.useState(null);
  const [isEditing, setIsEditing] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const togglePasswordVisibility = (id) => {
    if (showPassword === id) {
      setShowPassword(null);
    } else {
      setShowPassword(id);
    }
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);

    setIsEditing(true);
  };
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  React.useEffect(() => {
    dispatch(cloudfront_account());
    // eslint-disable-next-line
  }, []);

  const columnDefs = [
    { headerName: "#", field: "counter", width: 60 },
    { headerName: "Email", field: "auth_email", flex: 1, minWidth: 200 },
    {
      headerName: "Domain Counter",
      field: "domain_counter",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Password",
      field: "email_password",
      flex: 1,
      minWidth: 150,
      renderCell: (params) =>
        params.row.email_password ? (
          <div className="flex flex-row">
            {showPassword === params.row.id ? (
              <span>{params.value}</span>
            ) : (
              <span>********</span>
            )}
            <button
              className="ml-2 text-blue-500 underline cursor-pointer focus:outline-none"
              onClick={() => togglePasswordVisibility(params.row.id)}
            >
              {showPassword === params.row.id ? "Hide" : "Show"}
            </button>
          </div>
        ) : null,
    },
    { headerName: "Phone", field: "email_phone", flex: 1, minWidth: 150 },
    {
      headerName: "Recovery Email",
      field: "reconvery_email",
      flex: 1,
      minWidth: 150,
    },
    { headerName: "Auth Key", field: "auth_key", flex: 1, minWidth: 150 },
    { headerName: "Available", field: "is_available", flex: 1, minWidth: 100 },
    {
      headerName: "Last Updated",
      field: "last_updated",
      flex: 1,
      minWidth: 200,
    },
    { headerName: "Account Id", field: "account_id", flex: 1, minWidth: 150 },
    { headerName: "Token", field: "token", flex: 1, minWidth: 125 },
    { headerName: "Blocked", field: "is_blocked", flex: 1, minWidth: 100 },
    {
      headerName: "Blocked Reason",
      field: "blocked_reason",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Note",
      field: "note",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex items-center w-full">
          <FaEdit
            onClick={openFormHandler(params.row.records)}
            className="my_navIcon cursor-pointer"
            size={20}
          />
        </div>
      ),
      width: 100,
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 10,
      },
    },
  ];

  const data = cloudfrontAccount?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });

  return (
    <>
      {isLoading && <DotsLoader text="Please Wait" />}

      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
        <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
          <h1 className="text-2xl font-extrabold">Cloudflare Accounts</h1>
          <button
            className="border rounded bg-red-600 text-white p-2"
            onClick={openFormHandler(0)}
          >
            Add New
          </button>
        </div>
        {isEditing && (
          <AddUpdateCloudFlare
            editingRecord={editingRecord}
            modalTitle="Add Cloud Flare"
            onCancelForm={cancelFormHandler}
          />
        )}
        <div className="bg-white !my-3 border rounded">
          <MuiGrid
            columnDefs={columnDefs}
            items={data?.map((record, index) => {
              let counter = index + 1;
              let domain_counter = record?.domain_counter;
              let id = record?.id;
              let auth_key = record?.auth_key;
              let auth_email = record?.auth_email;
              let is_available = record?.is_available ? "Yes" : "No";
              let last_updated = record?.last_updated
                ? dayjs(record.last_updated).format("ddd, MMM D, YYYY h:mm A")
                : null;
              let account_id = record?.account_id;
              let token = record?.token;
              let is_blocked = record?.is_blocked ? "Yes" : "No";
              let blocked_reason = record?.blocked_reason;
              let email_password = record?.email_password;
              let email_phone = record?.email_phone;
              let reconvery_email = record?.reconvery_email;
              let note = record?.note;

              return {
                counter,
                records: record,
                domain_counter,
                id,
                auth_key,
                auth_email,
                is_available,
                last_updated,
                account_id,
                token,
                is_blocked,
                blocked_reason,
                email_password,
                email_phone,
                reconvery_email,
                note,
              };
            })}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            pagination="No"
          />
        </div>
      </div>
    </>
  );
};

export default CloudFrontAccount;
