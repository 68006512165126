import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DotsLoader from "../../components/atoms/DotsLoader";
import Modal from "../../components/molecules/Modal";
import { create_role, update_role } from "../../redux/roleSlice";

function AddUpdateRoles({ editingRecord, onCancelForm, modalTitle }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.roles);
  let initialData = {
    roleName: "",
    level: "",
  };

  if (editingRecord) {
    const { id, name, level } = editingRecord;
    initialData = {
      id,
      roleName: name,
      level,
    };
  }
  const [data, setData] = useState(initialData);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const onSubmit = async () => {
    if (!editingRecord) {
      try {
        const res = await dispatch(create_role(data));
        if (res.payload.status === 201) {
          onCancelForm();
        }
      } catch (error) {
        console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
      }
    } else {
      try {
        const res = await dispatch(update_role(data));
        if (res.payload.status === 200) {
          onCancelForm();
        }
      } catch (error) {
        console.log("~ file: AddImage.jsx:120 ~ onSubmit ~ error", error);
      }
    }
  };

  return (
    <Modal
      isUpdate={editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={onSubmit}
      onClick={onCancelForm}
    >
      <div className=" ">
        <div className="container-fluid  border rounded bg-white py-3 w-full flex flex-row justify-between">
          <div className="w-full !mr-2 grid grid-cols-2 gap-x-2">
            <div className="col-span-1">
              <input
                className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
                placeholder="Role"
                name="roleName"
                value={data?.roleName}
                onChange={handleInput}
              />
            </div>
            <div className="col-span-1">
              <input
                className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
                placeholder="Level"
                name="level"
                value={data?.level}
                onChange={handleInput}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddUpdateRoles;
