import React from "react";
import Button from "../../atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import { GrAddCircle } from "react-icons/gr";

import {
  domain_without_phonenum,
  update_domain_phonenum,
} from "../../../redux/domainSlice";
import { get_industries } from "../../../redux/industriesSlice";
import { useState } from "react";
import DotsLoader from "../../atoms/DotsLoader";
const Modal = ({ onCloseModal }) => {
  const [dataa, setDataa] = useState({
    id: "",
    phone: "",
  });

  const { isLoading, wtoPhonenum } = useSelector((state) => state.domains);
  const { industries } = useSelector((state) => state.industries);
  

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(domain_without_phonenum());
    dispatch(get_industries());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCloseModal();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
        {isLoading && <DotsLoader text="Please Wait" />}
        <div className="container-fluid mt-2  text-center ">
          <div className="inline-block bg-white w-3/4 my-0 mx-auto p-4 bg-red-600 rounded  max-h-[87vh]">
            <header className="flex items-center justify-center shadow-lg bg-slate-900 text-white h-[50px] mb-3 text-xl">
              {" "}
              Update Phone Number
            </header>

            <div className="table-responsive h-[60vh] mb-2">
              <table className="table table-striped table-bordered">
                <thead className="z-[1] sticky top-0 bg-white border-t border-[#ccc]">
                  <tr className='className="bg-white border-t border-[#ccc]'>
                  <th className="sticky">#</th>
                    <th className="sticky">Domain</th>
                    <th className="sticky">Industry Name</th>
                    <th className="sticky">Phone</th>
                  </tr>
                </thead>

                <tbody className=" h-[50vh]  mb-2 ">
                  {wtoPhonenum?.data?.map((data, index) => {
                    return (
                      <tr
                        key={index}
                        className="border-b-[1px] border-slate-200 p-4  "
                      >
                        <td>{index+1}</td>
                        <td className="text-left w-6/12">
                          <a
                          href={`https://${data.domain}`}
                          target="_blank"
                          rel="noreferrer"
                          >{`https://${data.domain}`}
                          </a></td>
                        <td>
                          {
                            industries.find(({ id }) => id === data.industry_id)
                              ?.industry_name
                          }
                        </td>
                        <td
                          onClick={() => {
                            setDataa({ id: data.id,phone:"" });
                           
                          }}
                          className="cursor-pointer"
                        >
                          {data.id === dataa.id && (
                            <div className="flex w-full items-center justify-center">
                              <input
                                className=" form-control-sm border-[1px] border-slate-200"
                                onChange={(e) => {
                                  setDataa({ ...dataa, phone: e.target.value });
                                }}
                                value={dataa.phone}
                              />
                              <GrAddCircle
                                className="ml-2"
                                onClick={() => {
                                  try {
                                    const res = dispatch(
                                      update_domain_phonenum(dataa)
                                    );
                                    if (res.payload.status === 200) {
                                      setDataa({ id: "", phone: "" });
                                    }
                                  } catch (err) {
                                    console.log(err);
                                  }
                                }}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Button
              text="Cancel"
              variant="btn_cancel"
              className="ml-3"
              onClick={onCloseModal}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
