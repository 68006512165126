import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/atoms/Button";
import DotsLoader from "../../components/atoms/DotsLoader";
import { get_domain_drd, template_deploy } from "../../redux/domainSlice";
import Select from "react-select";
import { get_industries } from "../../redux/industriesSlice";


function DeploymentForm({ tempId, onCloseModal }) {
  const dispatch = useDispatch();
  const domains = useSelector((state) => state.domains);
  const industries = useSelector((state) => state.industries);
  
  const initialData = {
    id: tempId,
    domain: "",
    industry_id: "",
    deploy_with: "",
  };
  React.useEffect(() => {
    dispatch(get_industries());
    dispatch(get_domain_drd());
  }, []);

  const [data, setData] = React.useState(initialData);
  const addDomain = async () => {

    try {
      const res = await dispatch(template_deploy(data));
      if (res.payload.status === 200) {
        onCloseModal();
      }
    } catch (error) {
      console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
    }
  };
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
      {domains.isLoading && <DotsLoader text="Please Wait" />}
      <div className="container-fluid mt-2 text-center">
        <div className="inline-block bg-white w-1/3 my-0 mx-auto p-5 bg-red-600 rounded">
          <Select
            onChange={(e) =>
              setData({
                ...data,
                industry_id: e.id,
              })
            }
            name={"industry"}
            options={industries.industries}
            getOptionLabel={(options) => options.industry_name}
            getOptionValue={(options) => options.id}
            placeholder={"Select Industries"}
            className="mb-3"
          />
         <input
              className="text-center form-control-sm border !mb-3 w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="Domain"
              name="domain"
              value={data?.domain}
              onChange={(e) =>
                setData({
                  ...data,
                  domain: e.target.value,
                })
              }
            />
         

          <Select
            onChange={(e) =>
              setData({
                ...data,
                deploy_with: e.value,
              })
            }
            name={"domain"}
            options={[
              { label: "Vercel", value: "vercel" },
              { label: "Docker", value: "docker" },
              { label: "AWS", value: "AWS" },
            ]}
            
            placeholder={"Deploy With"}
            className="mb-3"
          />

          <Button text="Save" variant="btn_submit" onClick={addDomain} />
          <Button
            text="Cancel"
            variant="btn_cancel"
            className="ml-3"
            onClick={onCloseModal}
          />
        </div>
      </div>
    </div>
  );
}

export default DeploymentForm;
