import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DotsLoader from "../../components/atoms/DotsLoader";
import Breadcrumbs from "../../components/molecules/Breadcrumbs";
import { get_db_tables } from "../../redux/databaseSlice";

function DatabaseTables() {
  const { isLoading, dbTables } = useSelector((state) => state.database);
  const [searchInput, setSearchInput] = useState("");

  const data = dbTables?.data?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  const params = useParams();
  const { dbName } = params;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (dbName) {
      dispatch(get_db_tables(dbName));
    }
    // eslint-disable-next-line
  }, [dbName]);

  return (
    <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
      <Breadcrumbs />
      {isLoading && <DotsLoader text="Please Wait" />}
      <input
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        className="mt-2 !p-1 border-1 rounded w-1/2"
        placeholder="Search"
      />
      <div className="bg-white !my-3 border rounded overflow-y-auto flex flex-wrap flex-row w-full border-[#ccc]">
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead
              className=""
              style={{
                position: "sticky",
                top: 0,
                left: 230,
                right: 0,
              }}
            >
              <tr>
                <th className="sticky">#</th>
                <th className="sticky">Table Name</th>
                <th className="sticky">Row Count</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((table, index) => {
                let counter = index + 1;
                let { tableName, rowCount } = table;
                return (
                  <tr
                    className="relative cursor-pointer"
                    key={index}
                    onClick={() =>
                      navigate(`/industries/${dbName}/${table.tableName}`)
                    }
                  >
                    <td>{counter}</td>
                    <td>{tableName}</td>
                    <td>{rowCount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default DatabaseTables;
