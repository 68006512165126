import { deployApi } from "../api";

export const getEmails = async () => {
  return await deployApi.get("/api/emails");
};
export const createEmail = async (data) => {
  return await deployApi.post("/api/emails/add_emails", data);
};
export const updateEmail = async (data) => {
  return await deployApi.put(`/api/emails/${data?.id}`, data);
};
export const deleteEmail = async (id) => {
    return await deployApi.delete(`/api/emails/${id}`);
  };