import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { GrAddCircle } from "react-icons/gr";

import { toast } from "react-toastify";
import Button from "../../components/atoms/Button";
import DotsLoader from "../../components/atoms/DotsLoader";
import { add_hours, update_hours } from "../../redux/hoursSlice";
// import { add_hours, update_hours } from "../../redux/roleSlice";
const AddHours = ({ onCloseModal,editingRecord }) => {
  const { isLoading } = useSelector((state) => state.hours);

  const dispatch = useDispatch();

  const [hours, setHours] = useState([
    { day: "mon", opens: "", closes: "" },
    { day: "tue", opens: "", closes: "" },
    { day: "wed", opens: "", closes: "" },
    { day: "thu", opens: "", closes: "" },
    { day: "fri", opens: "", closes: "" },
    { day: "sat", opens: "", closes: "" },
    { day: "sun", opens: "", closes: "" },
  ]);
  const [paramId,setParamId]=useState()
  const inputRefs = React.useRef([]);
  const handleChange = (event, index, key) => {
    // Remove any non-numeric characters and limit the input to 4 characters (HH:MM).
    const value = event.target.value.replace(/[^\d]/g, "").slice(0, 4);
    // Add the ":" character after the first 2 digits (hours).
    const hour = value.length >= 1 ? parseInt(value.substring(0, 2)) : 0;
    const minute = value.length >= 4 ? parseInt(value.substring(2, 4)) : 0;
    if (hour >= 0 && hour <= 24 && minute >= 0 && minute <= 59) {
    const time = value.replace(/^(\d{2})/, "$1:");
    const newHours = [...hours];
    newHours[index][key] = time;
    setHours(newHours);
    const inputIndex = inputRefs.current.indexOf(event.target);
    if (inputIndex < inputRefs.current.length - 1&& value.length === 4) {
      inputRefs.current[inputIndex + 1].focus();
    }
  }else{toast.error(`Enter a valid format 'HH:MM'`)}
  };
  console.log("hourssssset", hours);

 React.useEffect(() => {
  if (editingRecord) {
    const {id,hours}=editingRecord
    setHours(hours);
    setParamId(id)
    
  }
}, [editingRecord]);
  const onSubmit = async () => {
    if(!editingRecord){
      try {
        const res = await dispatch(add_hours(hours));
        if (res.payload.status === 200) {
          toast.success("Hours Added");
          onCloseModal();
        } else {
          toast.error("Hours cloudn't be added");
        }
      } catch (error) {
        console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
      }

    }
    else {
      try {
        const res = await dispatch(update_hours({id:paramId,hours}));
        if (res.payload.status === 200) {
          toast.success("Hours Updated");
          onCloseModal();
        } else {
          toast.error("Hours cloudn't be updated");
        }
      } catch (error) {
        console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
      }

    }
    
  };
  React.useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCloseModal();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  // const handleChange = (day, field, e) => {

  //   // Call the parent component's onChange function with the updated value.
  //   onChange(day, field, time);
  // };
  return (
    <>
      <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
        {/* {isLoading && <DotsLoader text="Please Wait" />} */}
        <div className="container-fluid mt-2 text-center ">
          <div className="inline-block bg-white w-3/5 my-0 mx-auto p-4 bg-red-600 rounded  max-h-[88vh]">
            <header className="flex items-center justify-center shadow-lg bg-slate-900 text-white h-[50px] mb-3 text-xl">
              {`${editingRecord?"Update":"Add" } Service Hours` }
            </header>
            {paramId? <span className="text-red-500 text-lg font-semibold flex justify-start w-full px-3">Set ID :<span className="ml-2">{paramId}</span></span> :""}
            
            <div className=" h-auto mb-2">
              {hours?.map((hour, index) => {
                return (
                  <div key={hour.day} className="flex flex-row items-center justify-center w-full">
                    <label className="text-lg font-medium p-2 w-3/12 capitalize ">
                      {hour.day}
                    </label>
                    <input
                      className="bg-white border border-slate-300 rounded-md p-2 w-1/4 shadow-sm"
                      type="text"
                      placeholder="hh:mm"
                      value={hour.opens}
                      onChange={(event) => handleChange(event, index, "opens")}
                      ref={(el) => inputRefs.current[index * 2] = el}
                    />
                    <input
                      className="bg-white border border-slate-300 rounded-md p-2 w-1/4 ml-2 shadow-sm"
                      type="text"
                      placeholder="hh:mm"
                      value={hour.closes}
                      onChange={(event) => handleChange(event, index, "closes")}
                      ref={(el) => inputRefs.current[index * 2 + 1] = el}
                    />
                  </div>
                );
              })}
            </div>
            <Button
              text="Cancel"
              variant="btn_cancel"
              className="ml-3 mt-2"
              onClick={onCloseModal}
            />
            <Button
              text="Submit"
              variant="btn_submit"
              className="ml-3 mt-2"
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddHours;
