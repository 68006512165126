import { deployApi } from "../api";

export const newDomain = async (payload) => {
  return await deployApi.post(`api/domains/add_domain`, payload);
};

export const getCounty = async () => {
  return await deployApi.get(`api/locations/get_county`);
};

export const getStates = async () => {
  return await deployApi.get(`api/locations/get_states`);
};

export const getCity = async () => {
  return await deployApi.get(`api/locations/get_city`);
};
