import { deployApi } from "../api";

export const getDomainsReport = async (data) => {
  return await deployApi.get("https://domainchecker.ecommcube.com/api/domain_checker/list_domains",{params:data});
};
export const generateReport = async (data) => {
  return await deployApi.post(`https://domainchecker.ecommcube.com/api/domain_checker/check_domains_status`,data);
};
export const regenerateReport = async (domains) => {
    return await deployApi.post(`https://domainchecker.ecommcube.com/api/domain_checker/check_down_domains_status`,domains);
  };