import React from "react";

function QueryResponseModal({ industry, data, handleClose }) {
  return (
    <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40">
      <div className="bg-white p-8 rounded-lg shadow-lg relative min-w-[400px] w-auto">
        <h2 className="text-lg font-semibold mb-4">
          Database Summary for <span className="text-red-500">{industry}</span>
        </h2>
        {data?.map((item, index) => {
          return (
            <div className="my-2 border-b pb-2" key={index}>
              {item && item?.runner ? (
                <div>
                  <span className="text-md font-semibold font_pop mr-2">
                    Runner:
                  </span>
                  <span className="font_pop text-red-500">{item?.runner}</span>
                </div>
              ) : null}
              {item && item?.total_domain > -1 ? (
                <div>
                  <span className="text-md font-semibold font_pop mr-2">
                    Total Domain:
                  </span>
                  <span className="font_pop">{item?.total_domain}</span>
                </div>
              ) : null}

              {item && item?.ready_domain_db > -1 ? (
                <div>
                  <span className="text-md font-semibold font_pop mr-2">
                    Ready Domain Databases:
                  </span>
                  <span className="font_pop">{item?.ready_domain_db}</span>
                </div>
              ) : null}

              {item && item?.pending_domain_db > -1 ? (
                <div>
                  <span className="text-md font-semibold font_pop mr-2">
                    Pending Domain Databases:
                  </span>
                  <span className="font_pop">{item?.pending_domain_db}</span>
                </div>
              ) : null}
            </div>
          );
        })}

        <div className="flex justify-end !mt-4">
          <button
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-blue-600"
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default QueryResponseModal;
