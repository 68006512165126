import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addDid,
  AddVendor,
  createEmail,
  deleteDid,
  deleteEmail,
  deleteVendors,
  getDids,
  getEmails,
  getVendors,
  updateDid,
  updateEmail,
  updateVendors,
} from "../services";

const initialState = {
  isError: false,
  isLoading: false,
  did: [],
  message: "",
};

// vendors
export const get_dids = createAsyncThunk("get_dids", async (thunkAPI) => {
  try {
    return await getDids();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
// Create New emails
export const add_dids = createAsyncThunk("add_dids", async (data, thunkAPI) => {
  try {
    return await addDid(data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
// Update emails
export const update_did = createAsyncThunk(
  "update_did",
  async (data, thunkAPI) => {
    try {
      return await updateDid(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const delete_did = createAsyncThunk(
  "delete_did",
  async (id, thunkAPI) => {
    try {
      return await deleteDid(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const didSlice = createSlice({
  name: "vendorsDid",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.did = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_dids.pending, (state) => {
        state.isLoading = true;
        state.did = [];
      })
      .addCase(get_dids.fulfilled, (state, action) => {
        state.isLoading = false;
        state.did = action.payload?.data;
      })
      .addCase(get_dids.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.did = [];
      })
      .addCase(add_dids.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_dids.fulfilled, (state, action) => {
        state.isLoading = false;
        state.did.push(action.payload.data[0]);
      })
      .addCase(add_dids.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_did.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_did.fulfilled, (state, action) => {
        state.did = state?.did?.filter(
          ({ id }) => id !== action?.payload?.data?.id
        );
        state.isLoading = false;
      })
      .addCase(delete_did.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(update_did.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_did.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.did.findIndex(
          ({ id }) => id === action?.payload?.data[0]?.id
        );
        state.did[result] = {
          ...state.did[result],
          ...action.payload.data[0],
        };
      })
      .addCase(update_did.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { reset } = didSlice.actions;
export default didSlice.reducer;
