import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {  AddTollfree,  getTollfree, } from "../services/tollService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  
  toll:[],
  logs:[],
  logHistory:'',
  callerId:[],
  insurance:[],
  serviceHour:[]
};

export const get_tollfree = createAsyncThunk(
    "get_tollfree",
    async ( thunkAPI) => {
      try {
        return await getTollfree();
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const add_tollfree = createAsyncThunk(
    "add_tollfree",
    async ( data,thunkAPI) => {
      try {
        return await AddTollfree(data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const tollSlice = createSlice({
    name: "tollFree",
    initialState,
    reducers: {
      reset: (state) => {
        state.isError = false;
        state.isLoading = false;
        state.record = [];
        state.message = "";
      },
    },
    extraReducers: (builder) => {
      builder
        
        .addCase(get_tollfree.pending, (state) => {
          state.isLoading = true;
          state.toll = [];
        })
        .addCase(get_tollfree.fulfilled, (state, action) => {
          state.isLoading = false;
          state.toll = action.payload?.data;
        })
        .addCase(get_tollfree.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
          state.toll = [];
        })     
        .addCase(add_tollfree.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(add_tollfree.fulfilled, (state, action) => {
          state.isLoading = false;
          // state.toll.push(action.payload.data);
        })
        .addCase(add_tollfree.rejected, (state, action) => {
          state.isLoading = false;
        })
       
     
       
       
        
       
        
    },
  });
  
  export const { reset } = tollSlice.actions;
  export default tollSlice.reducer;
  