import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/molecules/Modal";
import Select from "react-select";
import { toast } from "react-toastify";
import DotsLoader from "../../components/atoms/DotsLoader";
import { get_industries } from "../../redux/industriesSlice";
import { get_templates } from "../../redux/templateImagesSlice";
import { get_city, get_county, get_states, new_domain } from "../../redux/purchasedDomainSlice";

function AddUpdateDomains({ editingRecord, onCancelForm, modalTitle }) {
  const dispatch = useDispatch();
  const { templates } = useSelector((state) => state.images);
  const { industries } = useSelector((state) => state.industries);
  const { isLoading,states, county, city } = useSelector((state) => state.purchasedDomain);
  const { cloudfrontAccount } = useSelector(
    (state) => state.domains
  );
  const deployWithOption = [
    { label: "Vercel", value: "vercel" },
    { label: "Docker", value: "docker" },
    { label: "AWS", value: "AWS" },
  ];

  const generate_domain_by = [
    { label: "Nation", value: "nation" },
    { label: "State", value: "state" },
    { label: "County", value: "county" },
    { label: "City", value: "city" },
    { label: "City Alias", value: "city_alias" },
  ];
  let initialData = {
    domain: "",
    generate_domain_by: "",
    state: "",
    county: "",
    city: "",
    address: "",
    lat: "",
    lng: "",
    industry_id: '',
    deploy_with: "",
    template_id: '',
    phone:'',
    auth_email:''
  };

  useEffect(() => {
   dispatch(get_industries())
   dispatch(get_templates())
   dispatch(get_states())
   dispatch(get_city())
   dispatch(get_county())

  }, [])
  
  const [data, setData] = useState(initialData);
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const onSubmit = async () => {
      try {
        const res = await dispatch(new_domain(data));
        if (res.payload.status === 201) {
          onCancelForm();
        }
      } catch (error) {
        console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
      }
  };
  return (
    <Modal
      isUpdate={editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={onSubmit}
      onClick={onCancelForm}
    >
      {isLoading && <DotsLoader />}
      <div className=" ">
        <div className="container-fluid border rounded bg-white py-3 w-full flex flex-row justify-between">
          <div className="w-full !mr-2 grid grid-cols-2 gap-x-2">
            <div className="col-span-1">
              <input
                className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
                placeholder="Domain Name"
                name="domain"
                value={data.name}
                onChange={handleInput}
              />
            </div>
            <div className="col-span-1">
              <Select
                onChange={(e) => {
                  setData({
                    ...data,
                    generate_domain_by: e?.value,
                  });
                }}
                menuPosition="fixed"
                name={"generate_domain_by"}
                options={generate_domain_by}
                getOptionLabel={(options) => options.label}
                getOptionValue={(options) => options.value}
                placeholder="Generate Domain By"
                className="!mt-3"
              />
            </div>
                <div className="col-span-1">
                <Select
                onChange={(e) => {
                  setData({
                    ...data,
                    state: e?.state_name,
                  });
                }}
                menuPosition="fixed"
                name={"state"}
                options={states}
                getOptionLabel={(options) => options.state_name}
                getOptionValue={(options) => options.state_name}
                placeholder="Select state"
                className="!mt-3"
              />
              </div>
            <div className="col-span-1">
              <Select
                onChange={(e) => {
                  setData({
                    ...data,
                    county: e?.county_name,
                  });
                }}
                menuPosition="fixed"
                name={"county"}
                options={county}
                getOptionLabel={(options) => options.county_name}
                getOptionValue={(options) => options.county_name}
                placeholder="Select County"
                className="!mt-3"
              />
            </div>
            <div className="col-span-1">
            <Select
                onChange={(e) => {
                  setData({
                    ...data,
                    city: e?.city_name,
                  });
                }}
                menuPosition="fixed"
                name={"city"}
                options={city}
                getOptionLabel={(options) => options.city_name}
                getOptionValue={(options) => options.city_name}
                placeholder="Select City"
                className="!mt-3"
              />
            </div>
            <div className="col-span-1">
              <input
                className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
                placeholder="Address"
                name="address"
                value={data.address}
                onChange={handleInput}
              />
            </div>
            <div className="col-span-1">
              <input
                className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
                placeholder="Lat"
                name="lat"
                value={data.lat}
                onChange={handleInput}
              />
            </div>
            <div className="col-span-1">
              <input
                className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
                placeholder="Long"
                name="lng"
                value={data.lng}
                onChange={handleInput}
              />
            </div>
            <div className="col-span-1">
            <Select
                onChange={(e) => {
                  setData({
                    ...data,
                    industry_id: e?.id,
                  });
                }}
                menuPosition="fixed"
                name={"industry_id"}
                options={industries}
                getOptionLabel={(options) => options.industry_name}
                getOptionValue={(options) => options.id}
                placeholder="Select Industry"
                className="!mt-3"
              />
            </div>
            <div className="col-span-1">
            <Select
                onChange={(e) => {
                  setData({
                    ...data,
                    template_id: e?.id,
                  });
                }}
                menuPosition="fixed"
                name={"template_id"}
                options={templates}
                getOptionLabel={(options) => options.template_name}
                getOptionValue={(options) => options.id}
                placeholder="Select Template"
                className="!mt-3"
              />
            </div>
            <div className="col-span-1">
            <Select
                onChange={(e) => {
                  setData({
                    ...data,
                    deploy_with: e?.value,
                  });
                }}
                menuPosition="fixed"
                name={"deploy_with"}
                options={deployWithOption}
                getOptionLabel={(options) => options.label}
                getOptionValue={(options) => options.value}
                placeholder="Deploy With"
                className="!mt-3"
              />
            </div>
            <div className="col-span-1">
              <input
                className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
                placeholder="Phone Number"
                name="phone"
                value={data.phone}
                onChange={handleInput}
              />
            </div>
            <div className="col-span-1">
            <Select
                onChange={(e) => {
                  setData({
                    ...data,
                    auth_email: e?.auth_email,
                  });
                }}
                menuPosition="fixed"
                name={"Registrar"}
                options={cloudfrontAccount}
                getOptionLabel={(options) => options.auth_email}
                getOptionValue={(options) => options.auth_email}
                placeholder="Select Registrar"
                className="!mt-3"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddUpdateDomains;
