import { deployApi } from "../api";

export const getActiveDomains = async () => {
  return await deployApi.get("/api/domains/list_active_domain_logs");
};
export const getActiveDomainsDetails = async (id) => {
  return await deployApi.get(`/api/domains/list_active_domain_log_details/${id}`);
};
export const resetActiveDomains = async () => {
    return await deployApi.post(`/api/domains/reset_domains_status`);
  };