import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";
import Button from "../../components/atoms/Button";
import DotsLoader from "../../components/atoms/DotsLoader";
import DataTable from "../../components/molecules/DataTable";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";

const SearchLogHistory = ({ editingRecord, onCancelForm, modalTitle }) => {
 
  const { isLoading } = useSelector((state) => state.roles);
  const [searchInput, setSearchInput] = React.useState("");

  const data = editingRecord?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  const columnDefs = [
    { headerName: "#", field: "counter" },
    { headerName: "Created At", field: "created_at",flex: 1 ,minWidth:20 },
    { headerName: "Description", field: "description",flex: 1 ,minWidth:30 },
  ];
  const modalRef = React.useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      onCancelForm();
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onCancelForm();
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  return (
    <div className="w-full h-screen z-[100] fixed top-0 left-0 opacity-100 bg-black/[0.75] flex items-center justify-center">
      <div
        className={`z-10 w-auto md:min-w-[730px] overflow-y-auto bg-neutral-100 p-3.5 border-[10px] border-white`}
          ref={modalRef}
      >
        <div className="w-full max-h-[520px] px-3.5">
          <header className=" h-[60px] flex items-center justify-center shadow-lg bg-slate-900">
            <h1 className=" text-2xl text-white m-4 font-medium">
              {modalTitle}
            </h1>
          </header>
          {isLoading && <DotsLoader />}
          <div className="bg-white !my-3 border rounded">
           
              <DataTable
                columnDefs={columnDefs}
                // onRowClick={onRowClicked}
                items={data?.map((record, index) => {
                  let counter = index + 1;
                  let id = record.id;
                  let description = record.name;
                  let created_at = record.created_at
                    ? dayjs(record.created_at).format("ddd, MMM D, YYYY h:mm A")
                    : null;

                  return {
                    records: record,
                    id,
                    counter,
                    description,
                    created_at,
                  };
                })}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                height="335px"

              />
           
          </div>
          <div className=" text-right">
          <Button
                text="Cancel"
                className="mx-2"
                onClick={onCancelForm}
                variant="btn_cancel"
              />
              </div>
        </div>
      </div>
    </div>
  );
};

export default SearchLogHistory;
