import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getStates, newDomain,getCounty,getCity } from "../services";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  states:[],
  city:[],
  county:[]
};

// Add New Domains
export const new_domain = createAsyncThunk("new_domain", async (data, thunkAPI) => {
  try {
    return await newDomain(data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Get States
export const get_states = createAsyncThunk("get_states", async (thunkAPI) => {
  try {
    return await getStates();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Get County
export const get_county = createAsyncThunk(
  "get_county",
  async ( thunkAPI) => {
    try {
      return await getCounty();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get County
export const get_city = createAsyncThunk(
    "get_city",
    async ( thunkAPI) => {
      try {
        return await getCity();
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
export const purchasedDomainSlice = createSlice({
  name: "purchased_domains",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(new_domain.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(new_domain.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Domain Add Successfully")
      })
      .addCase(new_domain.rejected, (state, action) => {
        state.isLoading = false;
        toast.error("Domain couldn't be Added")
      })
      .addCase(get_states.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_states.fulfilled, (state, action) => {
        state.isLoading = false;
        state.states = action.payload.data
      })
      .addCase(get_states.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_county.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_county.fulfilled, (state, action) => {
        state.isLoading = false;
        state.county = action.payload.data
      })
      .addCase(get_county.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_city.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_city.fulfilled, (state, action) => {
        state.isLoading = false;
        state.city = action.payload.data
      })
      .addCase(get_city.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { reset, logoutUser } = purchasedDomainSlice.actions;
export default purchasedDomainSlice.reducer;
