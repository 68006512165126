import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/atoms/Button";
import { update_template } from "../../redux/domainSlice";
import Select from "react-select";
import DotsLoader from "../../components/atoms/DotsLoader";

function AddUpdateTemplate({ templateData, setTemplateData, onCloseModal }) {
  const dispatch = useDispatch();
  const { templates } = useSelector((state) => state.deploy);
  const { isLoading } = useSelector((state) => state.domains);

  const updateTemplate = async () => {
    try {
      const res = await dispatch(update_template(templateData));
      if (res.payload.status === 200) {
        onCloseModal();
      }
    } catch (error) {
      console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
    }
  };
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
      {isLoading && <DotsLoader text="Please Wait" />}
      <div className="container-fluid mt-2 text-center">
        <div className="inline-block bg-white w-1/3 my-0 mx-auto p-5 bg-red-600 rounded">
          <Select
            onChange={(e) =>
              setTemplateData({
                ...templateData,
                template_id: e.id.toString(),
                template_name: e.template_name,
              })
            }
            name={"industry"}
            options={templates}
            getOptionLabel={(options) => options.template_name}
            getOptionValue={(options) => options.id}
            placeholder={"Select Template"}
            className="mb-3"
            defaultValue={{
              id: templateData.template_id,
              template_name: templateData.template_name,
            }}
          />
          <Button text="Save" variant="btn_submit" onClick={updateTemplate} />
          <Button
            text="Cancel"
            variant="btn_cancel"
            className="ml-3"
            onClick={onCloseModal}
          />
        </div>
      </div>
    </div>
  );
}

export default AddUpdateTemplate;
