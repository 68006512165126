import React from "react";
import { useSelector } from "react-redux";
import DotsLoader from "../../components/atoms/DotsLoader";
import Button from "../../components/atoms/Button";
import ServiceHoursModal from "./serviceHoursModal";
const VendorDetail = ({ onCloseModal, vendorRecord }) => {
  const { isLoading } = useSelector((state) => state.roles);
  const modalRef = React.useRef(null);
  const hoursModalRef = React.useRef(null);
  const [hoursSetId, setHoursSetId] = React.useState("");
  const handleHideDropdown = (event) => {
    if (event.key === "Escape" && !hoursSetId) {
      onCloseModal();
    }
  };

  const handleClickOutside = (event) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target) &&
      !hoursSetId &&
      !hoursModalRef.current
    ) {
      onCloseModal();
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);

  return (
    <>
      <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
        {isLoading && <DotsLoader text="Please Wait" />}
        {hoursSetId && (
          <ServiceHoursModal
            onCloseModal={() => {
              setHoursSetId("");
              hoursModalRef.current = null;
            }}
            setId={hoursSetId}
            hoursModalRef={hoursModalRef}
          />
        )}

        <div className="container-fluid mt-2  text-center">
          <div
            className="inline-block bg-white w-3/4 my-0 mx-auto p-4 bg-red-600 rounded  max-h-[87vh]"
            ref={modalRef}
          >
            <header className="flex items-center justify-center shadow-lg bg-slate-900 text-white h-[50px] mb-3 text-xl">
              Vendor Detail
            </header>

            <div className="table-responsive h-[60vh] mb-2">
              <table className="table table-striped table-bordered">
                <thead className="z-[1] sticky top-0 bg-white border-t border-[#ccc]">
                  <tr className='className="bg-white border-t border-[#ccc]'>
                    <th className="sticky">#</th>
                    <th className="sticky">Name</th>
                    <th className="sticky">Phone</th>
                    <th className="sticky">Phone Type</th>
                    <th className="sticky">Status</th>
                    <th className="sticky">Hours Set ID</th>
                  </tr>
                </thead>

                <tbody className=" h-[50vh]  mb-2 ">
                  {vendorRecord?.map((data, index) => {
                    return (
                      <tr
                        key={index}
                        className="border-b-[1px] border-slate-200 p-4  "
                      >
                        <td>{index + 1}</td>
                        <td className="text-left w-6/12">{data.name}</td>
                        <td>{data.phone}</td>
                        <td>{data.phone_type}</td>
                        <td>{data.status}</td>
                        <td
                          onClick={() => setHoursSetId(data?.service_hours_id)}
                          className="text-blue-500 underline cursor-pointer"
                        >
                          {data.service_hours_id}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Button
              text="Cancel"
              variant="btn_cancel"
              className="ml-3"
              onClick={onCloseModal}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorDetail;
