import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/molecules/DataTable";
import DotsLoader from "../../components/atoms/DotsLoader";
// import AddUpdateRoles from "./AddUpdateRoles";

import dayjs from "dayjs";
import SearchLogHistory from "./SearchHistory";
import { toast } from "react-toastify";
import VendorDetail from "./VendorDetail";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";
import { delete_search_log, get_search_logs, get_search_log_history } from "../../redux/searchLogSlice";

const SearchLogs = () => {
  const { isLoading, logs } = useSelector((state) => state.searchLog);
  const dispatch = useDispatch();
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchInput, setSearchInput] = React.useState("");
  const [vendorRecord, setVendorRecord] = React.useState(null);
  const [isVendor, setIsVendor] = useState(false);
  const [selectedData, setSelectedData] = React.useState([]);

  useEffect(() => {
    dispatch(get_search_logs());
    // eslint-disable-next-line
  }, []);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const cancelVendorFormHandler = () => {
    setVendorRecord(null);
    setIsVendor(false)
  };

  const openFormHandler = (did) => () => {
    setEditingRecord(did);
    setIsEditing(true);
  };
  const openVendorFormHandler = (vendors) => () => {
    setVendorRecord(vendors);
    setIsVendor(true)
    setIsEditing(false);
   
  };

  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    
    { headerName: "City", field: "city",flex: 1 ,minWidth:100 },
    { headerName: "County", field: "county" ,flex: 1 ,minWidth:150},
    { headerName: "Area code", field: "area_code",flex: 1 ,minWidth:100 },
    {
      headerName: "Search Count",
      field:"search_count",
      renderCell: (params) =>
        params?.row? (
          <span
            onClick={()=>onRowClick(params?.row?.id)}
            className="underline text-blue-600 cursor-pointer"
          >
            {params?.row?.search_count}
          </span>
        ) : null,
        flex: 1 ,minWidth:150
    },
    { headerName: "State", field: "state",flex: 1 ,minWidth:100 },
    { headerName: "Zip", field: "zip",width:80 },
    {
      headerName: "Vendors",
      field:"vendors",
      renderCell: (params) =>
        params?.row? (
          <span
            onClick={openVendorFormHandler(params?.row?.vendors)}
            className="underline text-blue-600 cursor-pointer"
          >
            View Vendor
          </span>
        ) : null,
        flex: 1 ,minWidth:100
    },
    
    { headerName: "Created At", field: "created_at" ,flex: 1 ,minWidth:150},
  ];
  const data = logs?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  async function onRowClick(rowId) {
    try {
      const res = await dispatch(get_search_log_history(rowId));
      if (res.payload.status === 200) {
        setEditingRecord(res.payload.data);
        setIsEditing(true);;
        // onCancelForm();
      } else {
        toast.error("Search log history cloudn't be found");
      }
    } catch (error) {
      console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
    }
    // handle row click event here
  }
  const onRowSelectionModelChange=(params)=>{
    const selectedData = params
        setSelectedData(selectedData);

  }
  const deleteLogs = async () => {
    const c = window.confirm(`Are you sure want to delete?`);       
    if (c) {
      try {
        for(const item of selectedData) {
          console.log("delete", item);
          await dispatch(delete_search_log(item));
        }
        window.location.reload();
      } catch (error) {
        console.log("ImagesList.jsx:48 ~ deleteRecordHandler ~ error", error);
        // toast.error("Template Image couldn't be deleted");
      }
    }
  };
  return (
    <>
      {isEditing && (
        <SearchLogHistory
          editingRecord={editingRecord}
          modalTitle="Search Log History"
          onCancelForm={cancelFormHandler}
        />
      )}
      {isVendor && (
        <VendorDetail
          vendorRecord={vendorRecord}
          modalTitle="Vendor Detail"
          onCancelForm={cancelVendorFormHandler}
          onCloseModal={()=>setIsVendor(false)}
        />
      )}
      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
        {isLoading && <DotsLoader text="Please Wait" />}
        <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
          <h1 className="text-2xl font-extrabold">Search Logs</h1>
          <button
          className="border rounded bg-red-600 text-white p-2"
          onClick={deleteLogs}
        >
          Delete
        </button>
        </div>

        <div className="bg-white !my-3 border rounded">
         
            <MuiGrid
              columnDefs={columnDefs}
              // onRowClick={onRowClick}
              onRowSelectionModelChange={onRowSelectionModelChange}
              checkboxSelection
              items={data?.map((record, index) => {
                let counter = index + 1;
                let id = record.id;
                let area_code = record.area_code;
                let city = record.city;
               let vendors = record.vendors;
                let county = record.county;
                let search_count = record.search_count;
                let state = record.state;
                let zip = record.zip;
                
                //   let level = record.level;
                let created_at = record.created_at
                  ? dayjs(record.created_at).format("ddd, MMM D, YYYY h:mm A")
                  : null;

                return {
                  records: record,
                  id,
                  counter,
                  area_code,
                  search_count,
                  city,
                  state,
                  zip,
                  vendors,
                  county,
                  created_at,
                };
              })}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              pagination="No"
            />
         
        </div>
      </div>
    </>
  );
};

export default SearchLogs;
