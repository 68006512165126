import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  login as doLogin,
  logout as doLogout,
  sendResetEmail,
} from "../services/index";
const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  user: user ? user : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  redirectTo:''
};

// Login user
export const login = createAsyncThunk("auth/login", async (data, thunkAPI) => {
  try {
    return await doLogin(data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Logout User
export const logout = createAsyncThunk("auth/logout", async (thunkAPI) => {
  try {
    return await doLogout();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Send Reset Email
export const send_reset_email = createAsyncThunk(
  "auth/recover",
  async (email, thunkAPI) => {
    try {
      return await sendResetEmail(email);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
    logoutUser: (state) => {
      state.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("redirectTo");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "";
        state.user = action.payload?.data?.user;
        // toast.success("Login Successfully");
        state.redirectTo=localStorage.getItem('redirectTo')
        localStorage.setItem(
          "user",
          JSON.stringify(action.payload?.data?.user)
        );
        localStorage.setItem("accessToken", action.payload.data.accessToken);
        localStorage.setItem("refreshToken", action.payload.data.refreshToken);
        localStorage.removeItem('redirectTo')
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message =
          action.meta.requestStatus === "rejected"
            ? "You enter incorrect Username or Password"
            : "";
        state.user = null;
        toast.error("Login failed");
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = null;
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.user = null;
      })
      .addCase(send_reset_email.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(send_reset_email.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.success("Email sent successfully");
        state.message = action.payload?.data?.message;
      })
      .addCase(send_reset_email.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message =
          action.error.message === "Rejected" ? action.payload : "";
        state.user = null;
        toast.error("Email sending failed");
      });
   
   
  },
});

export const { reset, logoutUser } = authSlice.actions;
export default authSlice.reducer;
