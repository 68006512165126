import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createEmail, deleteEmail, getEmails, updateEmail } from "../services";

const initialState = {
    isError: false,
    isLoading: false,
    emails: [],
    message: "",
  };
  

  export const get_emails = createAsyncThunk("get_emails", async (thunkAPI) => {
    try {
      return await getEmails();
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  });
  
  // Create New emails
  export const create_email = createAsyncThunk(
    "create_email",
    async (data, thunkAPI) => {
      try {
        return await createEmail(data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  
  // Update emails
  export const update_email = createAsyncThunk(
    "update_email",
    async (data, thunkAPI) => {
      try {
        return await updateEmail(data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const delete_email = createAsyncThunk(
    "delete_email",
    async (id, thunkAPI) => {
      try {
        return await deleteEmail(id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const rolesSlice = createSlice({
    name: "vendorEmails",
    initialState,
    reducers: {
      reset: (state) => {
        state.isError = false;
        state.isLoading = false;
        state.emails = [];
        state.message = "";
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(get_emails.pending, (state) => {
          state.isLoading = true;
          state.emails = [];
        })
        .addCase(get_emails.fulfilled, (state, action) => {
          state.isLoading = false;
          state.emails = action.payload?.data;
        })
        .addCase(get_emails.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
          state.emails = [];
        })
        .addCase(create_email.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(create_email.fulfilled, (state, action) => {
          console.log("🚀 ~ file: emailsSlice.js:91 ~ .addCase ~ action:", action)
          state.isLoading = false;
          state.emails=[...state.emails,...action.payload.data];
        })
        .addCase(create_email.rejected, (state, action) => {
          state.isLoading = false;
        })
        .addCase(update_email.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(update_email.fulfilled, (state, action) => {
          console.log("🚀 ~ file: emailsSlice.js:102 ~ .addCase ~ action:", action)
          state.isLoading = false;
          const result = state.emails.findIndex(
            ({ id }) => id === action?.payload?.data[0]?.id
          );
          state.emails[result] = {
            ...state.emails[result],
            ...action.payload.data[0],
          };
        })
        .addCase(update_email.rejected, (state) => {
          state.isLoading = false;
        })
        .addCase(delete_email.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(delete_email.fulfilled, (state, action) => {
          state.emails = state?.emails?.filter(({id}) => id !== action?.payload?.data?.id);
          state.isLoading = false;
        })
        .addCase(delete_email.rejected, (state) => {
          state.isLoading = false;
        });  
    },
  });
  
  export const { reset } = rolesSlice.actions;
  export default rolesSlice.reducer;