import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import SubDropdown from "./SubDropdown.jsx";

function Dropdown(props) {
  const { options, main } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const isAllow = (item) => {
    if (item?.permission?.length > 0) {
      if (
        item?.permission?.filter(
          (item) => item === "ALL" ||
          item?.toUpperCase() === user?.role?.name?.toUpperCase()
        ).length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  const location = useLocation();
  const [clickedOn, setClickedOn] = React.useState("");
  React.useEffect(() => {
    let route = location.pathname.split("/")[1];
    setClickedOn(`/${route}`);
  }, [location.pathname]);

  return (
    <div className="w-full transition-all">
      <div className="w-full transition-all">
        {options.map((item, key) => {
        console.log("🚀 ~ file: Dropdown.js:77 ~ {options.map ~ options:", options)

          const { label, to, Icon, children } = item;
          return isAllow(item) ? (
            <div className="w-full transition-all" key={key}>
            {item?.children ? (
                <>
                  <div
                    className="transition-all page_link group hover:text-[#828282] hover:no-underline text-[#828282] transition flex items-center justify-between py-[3px] !px-5 w-full outline-none border-0 cursor-pointer transition-all"
                    onClick={() => {
                      setClickedOn(clickedOn === to ? "" : to);
                      setIsOpen(!isOpen);
                    }}
                  >
                    <div className="flex items-center">
                      <Icon className="icons group-hover:text-primary-100" />
                      <span className="!ml-1">{label}</span>
                    </div>
                    <FaAngleDown />
                  </div>
                  {isOpen && (
                    <SubDropdown
                      label={label}
                      to={`${main}/${to}`}
                      Icon={Icon}
                      options={children}
                      key={key}
                    />
                  )}
                </>
              ) 
             :( <NavLink
                title={item.label}
                to={`${main}/${to}`}
                className="hover:no-underline hover:text-[#828282] page_link group text-[#828282] flex items-center py-[3px] pl-4 w-full outline-none border-0 cursor-pointer transition-all"
                
              >
                <Icon className="group-hover:text-primary-100 icons" />
                <span className="ml-1">{item.label}</span>
              </NavLink>)
            }
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
}

export default Dropdown;
