import React, { useRef, useState } from "react";
import styles from "./styles.module.css";
function useVisible(initialIsVisible) {
  const [isVisible, setIsVisible] = useState(initialIsVisible);

  const inputRef = useRef(null);
  const listRef = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      listRef.current &&
      !listRef.current.contains(event.target)
    ) {
      setIsVisible(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { inputRef, isVisible, setIsVisible, listRef };
}

export default function MultiSelect(props) {
  const { inputRef, isVisible, setIsVisible, listRef } = useVisible(false);

  const [value, setValue] = useState("");
  const suggestionTags = props?.options?.filter((a) =>
    a.domain?.toLowerCase().includes(value?.toLowerCase())
  );

  const isSelected = (val) => {
    if (props.values?.length > 0) {
      if (props.values?.filter((item) => item === val.domain).length > 0) {
        return true;
      }
    }
    return false;
  };
  const isUsed = (val) => {
    if (props.projects?.length > 0) {
      if (
        props.projects?.filter((item) => item.domains.includes(val.domain))
          .length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  return (
    <div className={styles.welcome_bar}>
      <input
        onChange={(e) => setValue(e.target.value)}
        value={value}
        onFocus={() => setIsVisible(!isVisible)}
        className="w-full px-4 border rounded focus:outline-none focus:ring-1 focus:ring-violet-600 text-sm disabled:text-gray-500"
        placeholder={
          props?.values?.toString()
            ? props?.values?.toString()
            : "Select Domains"
        }
        ref={inputRef}
        style={{ height: 40 }}
      />
      {isVisible && (
        <>
          <div className={styles.list} ref={listRef}>
            {suggestionTags?.length > 0 ? (
              <>
                <div className="d-flex flex-row justify-content-between mx-3">
                  <span
                    className="py-2"
                    style={{ cursor: "pointer", color: "#7c3aed" }}
                    onClick={props.onSelectAllDomain}
                  >
                    Select All
                  </span>
                  <span
                    className="ml-3"
                    style={{ cursor: "pointer", color: "#7c3aed", padding: 5 }}
                    onClick={props.onClearAllDomain}
                  >
                    Clear All
                  </span>
                </div>
                {suggestionTags?.map((val, index) => {
                  return isUsed(val) ? null : (
                    <li
                      key={index}
                      onClick={() => {
                        props.addHandler(val.domain);
                        setValue("");
                      }}
                      className={`list-unstyled d-flex justify-content-between align-items-center flex-row w-100 px-3 
                    ${isSelected(val) ? " text-violet-600" : " text-black-500"}
                    `}
                     
                      name="list-item"
                      id="list-item"
                    >
                      <div className="d-flex align-items-center flex-row w-100">
                        {val.domain}
                      </div>
                      {isSelected(val) && (
                        <span
                          className={
                            isSelected(val)
                              ? "text-violet-600"
                              : " text-gray-500"
                          }
                          color="black"
                        >
                          ✓
                        </span>
                      )}
                    </li>
                  );
                })}
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-content">
                <h5 className="font">No Data Found</h5>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
