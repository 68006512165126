import { deployApi } from "../api";

export const getServiceHours = async () => {
    return await deployApi.get("api/custom_vendors/service_hours");
  };
  export const getServiceHoursById = async (id) => {
    return await deployApi.get(`api/custom_vendors/service_hour_details/${id}`);
  };
  export const addHours = async (hours) => {
    return await deployApi.post("api/custom_vendors/service_hours", { hours });
  };
  export const putServiceHours = async ({ id, hours }) => {
    return await deployApi.put(`api/custom_vendors/service_hours/${id}`, {
      hours,
    });
  };