import { deployApi } from "../api";

export const getTemplates = async () => {
  return await deployApi.get(`/api/templates`);
};
export const addImage = async (data) => {
  return await deployApi.post(`api/template-images`, data);
};
export const updateImage = async (data) => {
  return await deployApi.put(`api/template-images`, data);
};
export const getImagesList = async () => {
  return await deployApi.get(`api/template-images`);
};

export const getImageDetail = async (id) => {
  return await deployApi.get(`api/template-images/${id}`);
};
export const deleteTemplateImage = async (id) => {
  return await deployApi.delete(`api/template-images/${id}`);
};
export const updateTemplateNames = async () => {
  return await deployApi.put(`api/templates/update_template_name`);
};