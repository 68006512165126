import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {  deleteSearchLog, searchLog, searchLogHistory,  } from "../services/searchService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  
 
  logs:[],
  logHistory:'',
 
};

export const get_search_logs = createAsyncThunk(
    "get_search_logs",
    async ( thunkAPI) => {
      try {
        return await searchLog();
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const get_search_log_history = createAsyncThunk(
    "get_search_log_history",
    async ( id,thunkAPI) => {
      try {
        return await searchLogHistory(id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const delete_search_log = createAsyncThunk(
    "delete_search_log",
    async ( id,thunkAPI) => {
      try {
        return await deleteSearchLog(id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const searchLogSlice = createSlice({
    name: "searchLog",
    initialState,
    reducers: {
      reset: (state) => {
        state.isError = false;
        state.isLoading = false;
        state.record = [];
        state.message = "";
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(get_search_logs.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(get_search_logs.fulfilled, (state, action) => {
          state.isLoading = false;
          state.logs=action.payload.data;
        })
        .addCase(get_search_logs.rejected, (state, action) => {
          state.isLoading = false;
        })
        .addCase(get_search_log_history.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(get_search_log_history.fulfilled, (state, action) => {
          state.isLoading = false;
          state.logHistory=action.payload.data;
        })
        .addCase(get_search_log_history.rejected, (state, action) => {
          state.isLoading = false;
        })
        .addCase(delete_search_log.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(delete_search_log.fulfilled, (state, action) => {
          // state.logs = state?.logs?.filter(({id}) => id !== action?.payload?.data?.id);
          state.isLoading = false;
        })
        .addCase(delete_search_log.rejected, (state) => {
          state.isLoading = false;
        })
     
       
        
        
        
        
        
       
        
        
    },
  });
  
  export const { reset } = searchLogSlice.actions;
  export default searchLogSlice.reducer;