import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getActiveDomains,
  getActiveDomainsDetails,
  resetActiveDomains,
} from "../services/activeDomains";

const initialState = {
  isError: false,
  isLoading: false,
  activeDomains: [],
  activeDomainDetail: [],
  message: "",
};

// Get Users Roles
export const get_active_domains = createAsyncThunk(
  "get_active_domains",
  async (thunkAPI) => {
    try {
      return await getActiveDomains();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Roles
export const get_active_domains_detail = createAsyncThunk(
  "get_active_domains_detail",
  async (id, thunkAPI) => {
    try {
      return await getActiveDomainsDetails(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Roles
export const reset_active_domains = createAsyncThunk(
  "reset_active_domains",
  async (thunkAPI) => {
    try {
      return await resetActiveDomains();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const activeDomainSlice = createSlice({
  name: "active_domains",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_active_domains.pending, (state) => {
        state.isLoading = true;
        state.activeDomains = [];
      })
      .addCase(get_active_domains.fulfilled, (state, action) => {
        state.isLoading = false;
        state.activeDomains = action.payload?.data;
      })
      .addCase(get_active_domains.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.activeDomains = [];
      })
      .addCase(get_active_domains_detail.pending, (state) => {
        state.isLoading = true;
        state.activeDomainDetail = [];
      })
      .addCase(get_active_domains_detail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.activeDomainDetail = action.payload?.data;
      })
      .addCase(get_active_domains_detail.rejected, (state) => {
        state.isLoading = false;
        state.activeDomainDetail = [];
      })
      .addCase(reset_active_domains.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(reset_active_domains.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Domains status reset successfully")
      })
      .addCase(reset_active_domains.rejected, (state) => {
        state.isLoading = false;
        toast.success("Domains status couldn't be reset")
      });
  },
});

export const { reset } = activeDomainSlice.actions;
export default activeDomainSlice.reducer;
