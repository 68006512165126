import { deployApi } from "../api";

export const getIndustries = async () => {
  return await deployApi.get(`/api/industries`);
};
export const importDB = async ({ file, id }) => {
  return await deployApi.post(`/api/industries/import_industry/${id}`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const importTestDomainDB = async ({ file, domain }) => {
  return await deployApi.post(`/api/industries/import_domain_database/${domain}`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const addIndustries = async (data) => {
  return await deployApi.post("/api/industries", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const updateIndustries = async (data) => {
  return await deployApi.put("/api/industries", data);
};
export const regenrateDomainDB = async (id) => {
  return await deployApi.post(`/api/industries/regenerate_domain_database/${id}`);
};
export const genrateDomainStatusCheck = async (id) => {
  return await deployApi.post(`/api/industries/check_status_generating_domain_database/${id}`);
};
