import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { addNationSiteZips, createServer, deleteServer, getNationSiteZips, getRunners, getServers, updateServer } from "../services/serversServices";

const initialState = {
  isError: false,
  isLoading: false,
  servers: [],
  message: "",
  isLoader: false,
  runners:[],
  nationZips:[]
};
// Get Users Data
export const get_servers = createAsyncThunk(
  "get_servers",
  async ( thunkAPI) => {
    try {
      return await getServers();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_nation_site_zips = createAsyncThunk(
  "get_nation_site_zips",
  async (id, thunkAPI) => {
    try {
      return await getNationSiteZips(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_runners = createAsyncThunk(
  "get_runners",
  async ( thunkAPI) => {
    try {
      return await getRunners();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create New User
export const create_server = createAsyncThunk(
  "create_server",
  async (data, thunkAPI) => {
    try {
      return await createServer(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update User
export const update_server = createAsyncThunk(
  "update_server",
  async (data, thunkAPI) => {
    try {
      return await updateServer(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete User
export const delete_server = createAsyncThunk(
  "delete_server",
  async (id, thunkAPI) => {
    try {
      return await deleteServer(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Delete User
export const add_nation_site_zips = createAsyncThunk(
  "add_nation_site_zips",
  async (data, thunkAPI) => {
    try {
      return await addNationSiteZips(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const serverSlice = createSlice({
  name: "servers",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.servers = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_servers.pending, (state) => {
        state.isLoading = true;
        state.isLoader = true;
        state.servers = [];
      })
      .addCase(get_servers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.servers = action.payload?.data;
      })
      .addCase(get_servers.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.servers = [];
      })
      .addCase(create_server.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_server.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action?.payload?.data?.message)
      })
      .addCase(create_server.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_server.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_server.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action?.payload?.data?.message)
      })
      .addCase(update_server.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_server.fulfilled, (state, action) => {
        const result = state.servers.findIndex(
          ({ id }) => id === action?.payload?.data?.id
        );
        state.servers[result].active = false;
      })
      .addCase(get_runners.pending, (state) => {
        state.isLoading = true;
        state.isLoader = true;
        state.runners = [];
      })
      .addCase(get_runners.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.runners = action.payload?.data;
      })
      .addCase(get_runners.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.runners = [];
      })
      .addCase(get_nation_site_zips.pending, (state) => {
        state.isLoading = true;
        state.isLoader = true;
        state.nationZips = [];
      })
      .addCase(get_nation_site_zips.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.nationZips = action.payload?.data;
      })
      .addCase(get_nation_site_zips.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.nationZips = [];
      })
      .addCase(add_nation_site_zips.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_nation_site_zips.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action?.payload?.data?.message)
      })
      .addCase(add_nation_site_zips.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
export const { reset } = serverSlice.actions;
export default serverSlice.reducer;
