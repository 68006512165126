import React, { useEffect, useState, useMemo } from "react";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/molecules/DataTable";
import DotsLoader from "../../components/atoms/DotsLoader";
import AddUpdateUsers from "./AddUpdateUsers";
import { get_roles } from "../../redux/roleSlice";
import dayjs from "dayjs";
import { get_users } from "../../redux/userSlice";
import { get_industries } from "../../redux/industriesSlice";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";
function Users() {
  const { isLoading, users } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchInput, setSearchInput] = React.useState("");

  useEffect(() => {
    dispatch(get_users());
    dispatch(get_roles());
    dispatch(get_industries());
    // eslint-disable-next-line
  }, []);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const CustomTooltip = (props) => {
   
    const data = useMemo(
      () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
      [props]
    );
    return (
      <div
        className="custom-tooltip"
        style={{ backgroundColor: props.color || "white" }}
      >
        <p className="p-2 overflow-y-auto">
          {data?.records.industries.map((industry, index) => {
            return (
              <span className="flex flex-col" key={index}>
                {index + 1} - {industry?.industry_name}
              </span>
            );
          })}
        </p>
      </div>
    );
  };
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
      tooltipComponent: CustomTooltip,
    };
  }, []);
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Name", field: "name" ,flex: 1 ,minWidth:20},
    { headerName: "User Name", field: "username",flex: 1 ,minWidth:30 },
    { headerName: "Email", field: "email",flex: 1 ,minWidth:30 },
    { headerName: "Role", field: "role" },

    { headerName: "Created At", field: "created_at",flex: 1 ,minWidth:20 },
    {
      headerName: "Industries",
      field: "industries",
      tooltipField: "records",
      tooltipComponentParams: { color: "#ececec" },
      flex: 1 ,minWidth:50
    },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex items-center w-full">
          <FaEdit
            onClick={openFormHandler(params.row.records)}
            className="my_navIcon cursor-pointer"
            size={20}
          />
        </div>
      ),
      width: 100,
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth:10
      },
    },
  ];
  const data = users?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  return (
    <>
      {isEditing && (
        <AddUpdateUsers
          editingRecord={editingRecord}
          modalTitle="Add Users"
          onCancelForm={cancelFormHandler}
        />
      )}
      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
        {isLoading && <DotsLoader text="Please Wait" />}
        <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
          <h1 className="text-2xl font-extrabold">Users</h1>
          <button
            className="border rounded bg-red-600 text-white p-2"
            onClick={openFormHandler(0)}
          >
            Add User
          </button>
        </div>

        <div className="bg-white !my-3 border rounded">
          
            <MuiGrid
              columnDefs={columnDefs}
              //  row={data}
              totalItems={users?.totalItems?users?.totalItems:0}
              items={data?.map((user, index) => {
                console.log("usersss",user)
                let counter = index + 1;
                let id = user.id;
                let name = user.name;
                let email = user.email;
                let username = user.username;
                let industries = user.industries?.map((val, index) => {
                  return val?.industry_name;
                });
                let role = user.role_id?.name;
                let created_at = user.created_at
                  ? dayjs(user.created_at).format("ddd, MMM D, YYYY h:mm A")
                  : null;
                return {
                  records: user,
                  id,
                  counter,
                  name,
                  username,
                  email,
                  role,
                  industries,
                  created_at,
                };
              })}
              // getRowId={"_id"}
              isLoading={isLoading}
              pagination="No"
            />
          
        </div>
      </div>
    </>
  );
}

export default Users;
