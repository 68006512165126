import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import DotsLoader from "../../components/atoms/DotsLoader";
import { delete_virtual_hots, get_virtual_hots } from "../../redux/domainSlice";

function VirtualHost() {
  const { isLoading, virtualHosts } = useSelector((state) => state.domains);
  const dispatch = useDispatch();
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [searchInput, setSearchInput] = React.useState("");
  useEffect(() => {
    dispatch(get_virtual_hots());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Function to handle checkbox changes
  const handleCheckboxChange = (domain) => {
    if (selectedDomains.includes(domain)) {
      setSelectedDomains(selectedDomains.filter((d) => d !== domain));
    } else {
      setSelectedDomains([...selectedDomains, domain]);
    }
  };
  // Function to handle "Select All" checkbox
  const handleSelectAllChange = (checked) => {
    if (checked) {
      setSelectedDomains([...virtualHosts]);
    } else {
      setSelectedDomains([]);
    }
  };
  const deleteDomain = async () => {
    const c = window.confirm(
      `Are you sure want to delete ${selectedDomains.length} domains?`
    );
    if (c) {
      try {
       const res= await dispatch(
          delete_virtual_hots({
            domains: selectedDomains,
          })
        );
        if(res.payload.status===200){
          dispatch(get_virtual_hots());
        }
      } catch (error) {
        console.error("ImagesList.jsx:48 ~ deleteRecordHandler ~ error", error);
      }
    }
  };
  const data = virtualHosts?.filter((e) => e?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase()));
  return (
    <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
      {isLoading ? <DotsLoader /> : null}

      <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
        <h1 className="text-2xl font-extrabold">Virtual Hosts</h1>
        <button
          className={`border rounded bg-red-600 text-white p-2 ${
            selectedDomains?.length === 0 ? "bg-red-600/60" : ""
          }`}
          disabled={selectedDomains?.length === 0}
          onClick={deleteDomain}
        >
          Delete Domains
        </button>
      </div>
      <div className="bg-white !my-3 border rounded">
        <div className="flex items-center justify-between gap-2 px-4 py-2">
          <div className="flex flex-col items-cente justify-center">
            <label className="mb-0 font_pop font-semibold">
              Total Records: {virtualHosts?.length}
            </label>
            <label className={`flex items-center gap-2 mb-0`}>
              <input
                type="checkbox"
                checked={
                  selectedDomains?.length > 0 &&
                  selectedDomains?.length === virtualHosts?.length
                }
                onChange={(e) => handleSelectAllChange(e.target.checked)}
                className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
              />
              <span>{"Select All"}</span>
            </label>
          </div>

          <div className={`relative`}>
            <div className="flex items-center w-full appearance-none px-3 flex-1 border border-gray-300 dark:border-white/25 rounded-md bg-white dark:bg-blue w-fit">
              <FaSearch className="w-5" />
              <input
                placeholder={"Search..."}
                className="bg-transparent flex-1 w-full outline-none ml-3 py-2 appearance-none"
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
              />
            </div>
          </div>
        </div>
        <div className=" h-[calc(100vh-380px)] md:h-[calc(100vh-275px)] overflow-y-scroll scroll-container p-2">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3 text-sm border rounded p-2">
            {data?.length > 0 ? (
              data?.map((domain, index) => (
                <label
                  className={`flex items-center gap-2 cursor-pointer`}
                  key={index}
                >
                  <input
                    type="checkbox"
                    checked={selectedDomains.includes(domain)}
                    onChange={(e) => handleCheckboxChange(domain)}
                    className={`form-checkbox h-5 w-5 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
                  />
                  <span>{domain}</span>
                </label>
              ))
            ) : (
              <div>No record found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VirtualHost;
