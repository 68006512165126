import React, { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/molecules/DataTable";
import DotsLoader from "../../components/atoms/DotsLoader";
import AddVendor from "./AddVendor";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { delete_vendors, get_vendors } from "../../redux/vendorsSlice";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";

const CustomVendor = () => {
    const { isLoading, vendor } = useSelector((state) => state.vendors);
    const dispatch = useDispatch();
    const [editingRecord, setEditingRecord] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [searchInput, setSearchInput] = React.useState("");
  
    useEffect(() => {
      dispatch(get_vendors());
      // eslint-disable-next-line
    }, []);
    const cancelFormHandler = () => {
      setEditingRecord(null);
      setIsEditing(false);
    };
  
    const openFormHandler = (vendor) => () => {
      setEditingRecord(vendor);
  
      setIsEditing(true);
    };
    
      const handleDel = async(id) => {
        const c = window.confirm("Are you sure to perform this action?");
        if (c) {
          try {
            const res = await dispatch(delete_vendors(id));
            if (res.payload.status === 200) {
              toast.success("Vendor deleted");
              window.location.reload();
            } else {
              toast.error("Vendor couldn't be deleted");
            }
          } catch (error) {
            console.log("ImagesList.jsx:48 ~ deleteRecordHandler ~ error", error);
            toast.error("Vendor couldn't be deleted");
          }
        }
      };
    const columnDefs = [
      { headerName: "#", field: "counter", minWidth:10 },
      { headerName: "Name", field: "name", flex: 1 },
      { headerName: "Rating", field: "rating" ,flex:1,minWidth:10 },
      { headerName: "Created At", field: "created_at",flex:1,minWidth:40  },
      {
        field: "actions",
        renderCell: (params) => (
          <>
          {!params.row.records.is_locked?
              <div className="flex items-center w-full">
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon cursor-pointer"
              size={20}
            />
            <FaTrash
              onClick={()=>handleDel(params.row.records.id)}
              className="my_navIcon cursor-pointer !ml-1"
              size={20}
            />
          </div>
          :null
          }
          </>
        ),
        width: 100,
        cellStyle: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex:1,minWidth:10 ,
          width:20
        },
      },
     
    ];
    const data = vendor?.filter((e) => {
      return Object.keys(e)?.some(
        (key) =>
          e[key] &&
         
          e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
      );
    });
  return (
    <>
    {isEditing && (
      <AddVendor
        editingRecord={editingRecord}
        modalTitle="Add Vendor"
        onCancelForm={cancelFormHandler}
      />
    )}
    <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
      {isLoading && <DotsLoader text="Please Wait" />}
      <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
      
      <h1 className="text-2xl font-extrabold">Vendors</h1>
        <button
          className="border rounded bg-red-600 text-white p-2"
          onClick={openFormHandler(0)}
        >
          Add Vendor
        </button>
       
      </div>

      <div className="bg-white !my-3 border rounded">
       
          <MuiGrid
            columnDefs={columnDefs}
            items={data?.map((record, index) => {
                
              let counter = index + 1;
              let id = record.id;
              let name = record.name;
              let rating=record.rating;
            //   let level = record.level;
              let created_at = record.created_at
                ? dayjs(record.created_at).format("ddd, MMM D, YYYY h:mm A")
                : null;
             
              return {
                records: record,
                id,
                counter,
                name,
                rating,
                // level,
                created_at,
              };
            })}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            pagination="No"
          />
       
      </div>
    </div>
  </>
  )
}

export default CustomVendor