import React from "react";
import { Button } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { FileDownloadOutlined } from "@mui/icons-material";
export default function Toolbar(props) {
  const [showExportOptions, setShowExportOptions] = React.useState(false);
  const optionsRef = React.useRef();
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setShowExportOptions(false);
    }
  };
  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setShowExportOptions(false);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  return (
    <GridToolbarContainer className="flex items-center my-1  ">
      <GridToolbarColumnsButton className="text-black btnSecondary" />
      <GridToolbarDensitySelector className="text-black btnSecondary" />
      <GridToolbarFilterButton className="text-black btnSecondary" />
      {/* className="relative text-[#1976d2] float-right border ml-1 min-w-[119px] font-[500] w-full h-[31px] text-sm flex items-center justify-center" */}
      {/* onClick={() => setShowExportOptions(!showExportOptions)} */}
      {props?.exportOption?.length > 0 ? (
        <div className="relative">
          <Button
          variant="text"
          startIcon={<FileDownloadOutlined />}
          sx={{ fontSize: "0.8125rem", background: "" }}
          onClick={() => setShowExportOptions(!showExportOptions)}
          className="text-black btnSecondary relative !min-w-[119px]"
        >
            {/* <FileDownloadOutlined sx={{ width: 20, marginRight: "8px" }} /> */}
            EXPORT
            
          </Button>
          {showExportOptions && (
              <div className="absolute top-[100%] w-[190px] py-2 rounded text-black flex justify-start !mt-1.25 bg-white z-10 shadow-exportbox -ml-px overflow-hidden transition ease-in-out delay-150">
                <ul ref={optionsRef} className="!pl-0 !mb-0 w-full">
                  {props.exportOption?.map((option, index) => {
                    return (
                      <li
                        key={index}
                        className="cursor-pointer w-full !py-1.5 !px-4 hover:bg-[#e1e1e1] h-[36px] text-start font-normal text-base"
                        onClick={option.action}
                      >
                        {option.label}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
        </div>
      ) : (
        <Button
          variant="text"
          startIcon={<FileDownloadOutlined />}
          sx={{ fontSize: "0.8125rem", background: "" }}
          onClick={props?.exportToExcel}
          className="text-black btnSecondary"
        >
          Export
        </Button>
      )}
    </GridToolbarContainer>
  );
}
