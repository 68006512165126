import { configureStore } from "@reduxjs/toolkit";
import activeDomainSlice from "./activeDomainSlice";
import authReducer from "./authSlice";
import bulkImagesSlice from "./bulkImagesSlice";
import CallerIdSlice from "./CallerIdSlice";
import databaseSlice from "./databaseSlice";
import deploySlice from "./deploySlice";
import didSlice from "./didSlice";
import domainIndexReportSlice from "./domainsIndexReportSlice";
import domainSlice from "./domainSlice";
import domainReportSlice from "./domainsReportSlice";
import emailsSlice from "./emailsSlice";
import githubRepoSlice from "./githubRepoSlice";
import hoursSlice from "./hoursSlice";
import industriesSlice from "./industriesSlice";
import InsuranceSlice from "./InsuranceSlice";
import purchasedDomainSlice from "./purchasedDomainSlice";
import roleSlice from "./roleSlice";
import searchLogSlice from "./searchLogSlice";
import serverSlice from "./serverSlice";
import  templateImageSlice  from "./templateImagesSlice";
import tollSlice from "./tollSlice";
import  usersSlice  from "./userSlice";
import vendorsSlice from "./vendorsSlice";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    deploy:deploySlice,
    images:templateImageSlice,
    industries:industriesSlice,
    domains:domainSlice,
    repos:githubRepoSlice,
    database:databaseSlice,
    roles:roleSlice,
    users:usersSlice,
    bulkImages:bulkImagesSlice,
    purchasedDomain:purchasedDomainSlice,
    emails:emailsSlice,
    vendors:vendorsSlice,
    dids:didSlice,
    insurance:InsuranceSlice,
    hours:hoursSlice,
    toll:tollSlice,
    searchLog:searchLogSlice,
    caller:CallerIdSlice,
    activeDomains:activeDomainSlice,
    domainReport:domainReportSlice,
    domainIndexReport:domainIndexReportSlice,
    servers:serverSlice

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
