import React from 'react'
import Button from "../../components/atoms/Button";

const Cloudmodal = ({domain,onClosemodal}) => {
    React.useEffect(() => {
        const close = (e) => {
          if(e.keyCode === 27){
            onClosemodal()
          }
        }
        window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
    },[])
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
      
      <div className="container-fluid mt-2 text-center ">
      
        <div className="inline-block bg-white w-2/4 my-0 mx-auto p-5 bg-red-600 rounded  max-h-[87vh]">
       
       <header className="flex items-center justify-center shadow-lg bg-slate-900 text-white h-[50px] mb-2 text-xl">CloudFront Domain Details</header>
         
        
         <div className="pb-3 font-medium text-base text-right ">Total {domain.length} Records</div>
        
          <ul className="  overflow-y-auto max-h-[55vh]  mb-2">
            {domain?.map((domain, index) => {
             
              return (
                <li
                  key={index}
                  className="border-b-[1px] border-slate-200 p-3 ml-5  list-decimal"
                >
                  
                    <a className="h-6 flex items-center cursor-default text-black-900 cursor-pointer" 
                    target="_blank"
                    rel="noreferrer"
                    href={`https://${domain.domain}`}
                    >{`https://${domain.domain}`}</a>
                   
                 
                </li>
              );
            })}
          </ul>
         
          <Button
            text="Cancel"
            variant="btn_cancel"
            className="ml-3"
            onClick={onClosemodal}
          />
        </div>
      </div>
    </div>
  )
}

export default Cloudmodal