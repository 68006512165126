import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DotsLoader from "../../components/atoms/DotsLoader";
// import DataTable from "../../components/molecules/DataTable";
import AddUpdateDomainRunners from "./AddUpdateDomainRunners";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";
import { get_domain_runners } from "../../redux/domainSlice";
import { FaEdit } from "react-icons/fa";
import { get_industries } from "../../redux/industriesSlice";
import { deployApi } from "../../api";
import { toast } from "react-toastify";

function DomainRunner() {
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchInput, setSearchInput] = React.useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setIsloading] = useState(false);
  const dispatch = useDispatch();
  const { isLoading, domainRunner } = useSelector((state) => state.domains);

  useEffect(() => {
    dispatch(get_industries());
    dispatch(get_domain_runners());
  }, []);

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const setup_runners = async (id) => {
    const c = window.confirm(`Are you sure want to setup the runner?`);
    if (!c) return;
    setIsloading(true);
    try {
      const res = await deployApi.post(
        `/api/domain_runners/runner_setup_process/${id}`
      );
      if (res.status === 200) {
        toast.success("Runner setup successfully");
      } else {
        toast.error(`Runner couldn't be setup`);
      }
      setIsloading(false);
    } catch (err) {
      console.log(
        "🚀 ~ file: index.jsx:42 ~ constsetup_runners=async ~ err:",
        err
      );
      setIsloading(false);
      toast.error(`Runner couldn't be setup`);
    }
  };
  const columnDefs = [
    // Define your columns based on the structure of your domain runners
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "IP", field: "ip", flex: 1 },
    { headerName: "Runner Name", field: "runner_name", flex: 1 },
    { headerName: "Industry", field: "industry_name", flex: 1 },
    { headerName: "PhpMyAdmin Path", field: "phpmyadmin_path", flex: 1 },
    { headerName: "Runner Path", field: "runner_path", flex: 1 },
    { headerName: "Table Name", field: "table_name", flex: 1 },
    { headerName: "LB Server File", field: "lb_server_file", flex: 1 },
    {
      headerName: "Runner Setup",
      field: "setup_status",
      flex: 1,
      minWidth: 100,
      renderCell: (params) =>
        params?.row?.setup_status === "Pending" ? (
          <span
            onClick={() => setup_runners(params.row.records?.id)}
            className="underline text-blue-600 cursor-pointer"
          >
            Setup
          </span>
        ) : null,
    },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex items-center w-full">
          <FaEdit
            onClick={openFormHandler(params.row.records)}
            className="my_navIcon cursor-pointer"
            size={20}
          />
        </div>
      ),
      width: 100,
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 10,
      },
    },
  ];

  const data = domainRunner?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  return (
    <>
      {isEditing && (
        <AddUpdateDomainRunners
          editingRecord={editingRecord}
          modalTitle="Add Domain Runner"
          onCancelForm={cancelFormHandler}
        />
      )}
      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
        {isLoading || loading ? <DotsLoader text="Please Wait" /> : null}
        {!isLoading && (
          <div className="w-full px-4 border rounded focus:outline-none focus:ring-1 focus:ring-violet-600 text-sm disabled:text-gray-500">
            <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
              <h1 className="text-2xl font-extrabold">Domain Runners</h1>
              <button
                className="border rounded bg-red-600 text-white p-2"
                onClick={openFormHandler(0)}
              >
                Add Domain Runner
              </button>
            </div>
            <div className="bg-white mt-3 border rounded">
              <MuiGrid
                columnDefs={columnDefs}
                items={data.map((runner, index) => ({
                  ...runner,
                  records: { ...runner },
                  counter: index + 1,
                  // Additional formatting if needed
                }))}
                isLoading={isLoading}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                pagination="No"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default DomainRunner;
