import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { deployProject, getDomains, getTemplatesRepo } from "../services/index";

const initialState = {
  templates: [],
  domains: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get Domains
export const get_domains = createAsyncThunk(
  "get_domains",
  async ({ id, deploy_with }, thunkAPI) => {
    try {
      return await getDomains(id, deploy_with);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Templates Repo
export const get_templates_repo = createAsyncThunk(
  "get_templates_repo",
  async (thunkAPI) => {
    try {
      return await getTemplatesRepo();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Deploy Projects
export const deploy_project = createAsyncThunk(
  "deploy_project",
  async (data, thunkAPI) => {
    try {
      return await deployProject(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deploySlice = createSlice({
  name: "deploy",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_domains.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(get_domains.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.domains = action.payload?.data;
      })
      .addCase(get_domains.rejected, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.domains = null;
      })
      .addCase(get_templates_repo.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(get_templates_repo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.templates = action.payload?.data;
      })
      .addCase(get_templates_repo.rejected, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.templates = [];
      })
      .addCase(deploy_project.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(deploy_project.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.success("Deploy Successfully");
      })
      .addCase(deploy_project.rejected, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        toast.error("Project couldn't be deployed");
      });
  },
});

export const { reset } = deploySlice.actions;
export default deploySlice.reducer;
