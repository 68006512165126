import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { addIndustries, getIndustries } from "../services";
import { genrateDomainStatusCheck, importDB, importTestDomainDB, regenrateDomainDB, updateIndustries } from "../services/industriesServices";
const initialState = {
  isError: false,
  isLoading: false,
  message: "",
  industries: [],
};

// Add New Image
export const add_industries = createAsyncThunk(
  "add_industries",
  async (data, thunkAPI) => {
    try {
      return await addIndustries(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Update Industries
export const update_industries = createAsyncThunk(
  "update_industries",
  async (data, thunkAPI) => {
    try {
      return await updateIndustries(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Industries
export const get_industries = createAsyncThunk(
  "get_industries",
  async (thunkAPI) => {
    try {
      return await getIndustries();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const import_db_file = createAsyncThunk(
  "import_db_file",
  async ({file, id},thunkAPI) => {
    try {
      return await importDB({file, id});
    } catch (error) {
      const message =
      error.response.data ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const import_testing_domain_db_file = createAsyncThunk(
  "import_testing_domain_db_file",
  async ({file, domain},thunkAPI) => {
    try {
      return await importTestDomainDB({file, domain});
    } catch (error) {
      const message =
      error.response.data ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const regenrate_domain_db = createAsyncThunk(
  "regenrate_domain_db",
  async (id,thunkAPI) => {
    try {
      return await regenrateDomainDB(id);
    } catch (error) {
      const message =
      error.response.data ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const generate_domain_status_check = createAsyncThunk(
  "generate_domain_status_check",
  async (id,thunkAPI) => {
    try {
      return await genrateDomainStatusCheck(id);
    } catch (error) {
      const message =
      error.response.data ||
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const industriesSlice = createSlice({
  name: "industries",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.message = "";
    },
    updateTotalDeployedCount: (state, action) => {
      console.log("🚀 ~ file: industriesSlice.js:125 ~ action:", action)
      const result = state.industries.findIndex(
        ({ industry_name }) => industry_name === action?.payload?.industry
      );
      state.industries[result] = {
        ...state.industries[result],
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(add_industries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_industries.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Industries added successfully");
      })
      .addCase(add_industries.rejected, (state) => {
        state.isLoading = false;
        toast.error("Industries couldn't be added");
      })
      .addCase(update_industries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_industries.fulfilled, (state, action) => {
        console.log("🚀 ~ file: industriesSlice.js:92 ~ .addCase ~ action:", action)
        state.isLoading = false;
        const result = state.industries.findIndex(
          ({ id }) => id === action?.payload?.data[0]?.id
        );
        state.industries[result] = {
          ...state.industries[result],
          ...action.payload.data[0],
        };
        toast.success("Industries updated successfully");
      })
      .addCase(update_industries.rejected, (state) => {
        state.isLoading = false;
        toast.error("Industries couldn't be updated");
      })
      .addCase(get_industries.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(get_industries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "";
        state.industries = action.payload?.data?.records;
      })
      .addCase(get_industries.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(import_db_file.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(import_db_file.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(import_db_file.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(regenrate_domain_db.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(regenrate_domain_db.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(regenrate_domain_db.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(generate_domain_status_check.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(generate_domain_status_check.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(generate_domain_status_check.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(import_testing_domain_db_file.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(import_testing_domain_db_file.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(import_testing_domain_db_file.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { reset,updateTotalDeployedCount } = industriesSlice.actions;
export default industriesSlice.reducer;
