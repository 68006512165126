import { deployApi } from "../api";

export const searchLog = async (data) => {
    return await deployApi.get("api/custom_vendors/search_logs", data);
  };
  export const searchLogHistory = async (data) => {
    return await deployApi.get(`api/custom_vendors/search_logs_history/${data}`);
  };
  export const deleteSearchLog = async (id) => {
    return await deployApi.delete(`/api/custom_vendors/search_log/${id}`);
  };
  