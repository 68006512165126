import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DotsLoader from "../../components/atoms/DotsLoader";
import Modal from "../../components/molecules/Modal";
import { add_tollfree } from "../../redux/tollSlice";
// import { add_tollfree, add_vendor } from "../../redux/roleSlice";

const AddTollfree = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.toll);
  let initialData = {
    phone: "",
    // level: "",
  };

  if (editingRecord) {
    const { id, phone } = editingRecord;
    initialData = {
      id,
      phone,
      //   level,
    };
  }
  const [data, setData] = useState(initialData);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const onSubmit = async () => {
    try {
      const res = await dispatch(add_tollfree(data));
      if (res.payload.status === 201) {
        toast.success("Tollfree Added");
        window.location.reload()
        onCancelForm();
      } else {
        toast.error("Tollfree cloudn't be added");
      }
    } catch (error) {
      console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
    }
  };
  return (
    <Modal
      isUpdate={editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={onSubmit}
      onClick={onCancelForm}
    >
      {isLoading && <DotsLoader />}
      <div className=" ">
        <div className="container-fluid  border rounded bg-white py-3 w-full flex flex-row justify-center">
          <div className="w-full !mr-2 grid grid-cols-1 gap-x-2">
            <div className="col-span-1">
              <input
                className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
                placeholder="Phone"
                name="phone"
                value={data.phone}
                onChange={handleInput}
              />
            </div>
            {/* <div className="col-span-1">
            <input
              className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="Level"
              name="level"
              value={data.level}
              onChange={handleInput}
            />
          </div> */}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddTollfree;
