import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DotsLoader from "../../components/atoms/DotsLoader";
import dayjs from "dayjs";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";
import { get_industries } from "../../redux/industriesSlice";
import {
  get_domain_index_report,
  start_checking_indexing,
} from "../../redux/domainsIndexReportSlice";
import { toast } from "react-toastify";
import { HiOutlineRefresh } from "react-icons/hi";

function DomainIndexReport() {
  const { industries } = useSelector((state) => state.industries);
  const { isLoading, domainIndexReport } = useSelector(
    (state) => state.domainIndexReport
  );
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1,
  });
  const [selectedFilters, setSelectedFilters] = React.useState({
    industry_id: "",
    is_indexed: 0,
  });
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = React.useState("");
  const [selectedData, setSelectedData] = React.useState([]);
  useEffect(() => {
    dispatch(
      get_domain_index_report({
        ...selectedFilters,
        page: +paginationModel.page,
        size: paginationModel.pageSize,
      })
    );
    dispatch(get_industries());
    // eslint-disable-next-line
  }, []);
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...selectedFilters, page: 1, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_domain_index_report({
        ...selectedFilters,
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    {
      headerName: "Domain",
      field: "domain",
      flex: 1,
      renderCell: (params) => {
        const handleClick = (e) => {
          e.stopPropagation(); // Prevent the click event from propagating
          // Your existing code here...
        };
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.value}`}
            onClick={handleClick}
          >
            {params?.value}
          </a>
        );
      },
      minWidth: 100,
    },
    { headerName: "Indexed", field: "is_indexed", flex: 1, minWidth: 100 },
    {
      headerName: "Result Count",
      field: "last_results_count",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Comparision Count",
      field: "compare_to_last",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Last Checked Date",
      field: "last_checked_date",
      flex: 1,
      minWidth: 100,
    },
  ];
  const sortedData = [...domainIndexReport?.data||[]]?.sort((a, b) => {
    // Parsing dates for comparison
    const dateA = new Date(a.last_checked_date);
    const dateB = new Date(b.last_checked_date);

    // Comparing parsed dates
    return dateB - dateA;
  });
  const data = sortedData?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  const onRowSelectionModelChange = (params) => {
    const selectedData = params;
    setSelectedData(selectedData);
  };
  const SingleSelectRenderer = () => {
    return (
      <div className="flex flex-row justify-between">
        <select
          value={selectedFilters.industry_id}
          onChange={(e) => {
            setSelectedFilters({
              ...selectedFilters,
              industry_id: e.target.value,
            });
            dispatch(
              get_domain_index_report({
                ...selectedFilters,
                industry_id: e.target.value,
                page: +paginationModel.page,
                size: paginationModel.pageSize,
              })
            );
          }}
          className="border p-1 rounded h-[35px] ml-2"
        >
          <option value="">Select Industry</option>
          {industries.map((option) => (
            <option
              key={option.id}
              value={option.id}
              className="flex justify-between"
            >
              {option.industry_name}
            </option>
          ))}
        </select>
        <select
          value={selectedFilters.is_indexed}
          onChange={(e) => {
            setSelectedFilters({
              ...selectedFilters,
              is_indexed: e.target.value,
            });
            dispatch(
              get_domain_index_report({
                ...selectedFilters,
                is_indexed: e.target.value,
                page: +paginationModel.page,
                size: paginationModel.pageSize,
              })
            );
          }}
          className="border p-1 rounded h-[35px] ml-2 w-[250px]"
        >
          <option value="">Selected index status</option>
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </select>

        <button className="bg-red-600 text-white ml-2 h-[35px] w-[35px] flex justify-center items-center rounded"
        onClick={()=>dispatch(
          get_domain_index_report({
            ...selectedFilters,
            page: +paginationModel.page,
            size: paginationModel.pageSize,
          })
        )}
        >
          <HiOutlineRefresh/>
        </button>
      </div>
    );
  };
  const handleCheckIndex = async () => {
    try {
      const res = await dispatch(
        start_checking_indexing({ domains: selectedData })
      );
      if (res.payload.status === 200) {
        setSelectedFilters({
          ...selectedFilters,
          is_indexed: 1,
        });
        dispatch(
          get_domain_index_report({
            ...selectedFilters,
            is_indexed: 1,
            page: +paginationModel.page,
            size: paginationModel.pageSize,
          })
        );
        toast.success(res?.payload?.data?res?.payload?.data:"Indexing checked successfully");
      } else {
        toast.error("Couldn't checked the indexing");
      }
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:149 ~ handleCheckIndex ~ err:", err);
      toast.error("Couldn't checked the indexing");
    }
  };
  return (
    <>
      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
        {isLoading && <DotsLoader text="Please Wait" />}
        <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
          <h1 className="text-2xl font-extrabold">Domain Index Report</h1>
          <div className="space-x-2">
            <button
              className="border rounded bg-red-600 text-white p-2"
              onClick={() => {
                const c = window.confirm(
                  `Are you sure want to start domain index checking process?`
                );
                if (c) {
                  handleCheckIndex();
                }
              }}
            >
              Check Index
            </button>
          </div>
        </div>

        <div className="bg-white !my-3 border rounded">
          <MuiGrid
            columnDefs={columnDefs}
            items={data?.map((record, index) => {
              let counter = index + 1;
              let is_indexed = record.is_indexed ? "Yes" : "No";
              let last_checked_date = record.last_checked_date
                ? dayjs(record?.last_checked_date).format(
                    "ddd, MMM D, YYYY h:mm A"
                  )
                : null;
              let domain = record.domain;
              let last_results_count = record.last_results_count;
              let compare_to_last =
                record.compare_to_last > 0
                  ? `Increase by ${record.compare_to_last}`
                  : `Decrease by ${record.compare_to_last}`;
              return {
                records: { ...record, id: domain },
                counter,
                is_indexed,
                last_checked_date,
                domain,
                compare_to_last,
                last_results_count,
              };
            })}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            checkboxSelection
            onRowSelectionModelChange={onRowSelectionModelChange}
            CustomComponent={SingleSelectRenderer}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            paginationMode="server"
            totalItems={domainIndexReport?.totalItems}
            paginationModel={paginationModel}

          />
        </div>
      </div>
    </>
  );
}

export default DomainIndexReport;
