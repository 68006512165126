import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/molecules/DataTable";
import DotsLoader from "../../components/atoms/DotsLoader";
import { cloudfront_account, copy_domains, get_purchased_domains } from "../../redux/domainSlice";
import AddUpdateDomains from "./AddUpdateDomains";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";
function PurchasedDomain() {
  const { isLoading, purchasedDomains } = useSelector((state) => state.domains);
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = React.useState("");
  const [selectedData, setSelectedData] = React.useState([]);
  
  const [editingRecord, setEditingRecord] = React.useState(null);
  const [isEditing, setIsEditing] = React.useState(false);
  useEffect(() => {
    dispatch(get_purchased_domains());
    dispatch(cloudfront_account())
    // eslint-disable-next-line
  }, []);
  const columnDefs = [
    // {
    //   field: "",
    //   checkboxSelection: true,
    //   headerCheckboxSelection: true,
    //   width: 100
    // },
    { headerName: "#", field: "counter", flex:1,minWidth:10,width:10 },
    { headerName: "Domain", field: "domain", flex:1,minWidth:100 ,outerWidth:100},
    { headerName: "Generate Domain By", field: "generate_domain_by",flex:1,minWidth:100  },
   
    { headerName: "County", field: "county" ,flex:1,minWidth:20 },
    { headerName: "City Alias", field: "city_alias",flex:1,minWidth:20  },
    { headerName: "City", field: "city",flex:1,minWidth:20  },
    { headerName: "State", field: "state",flex:1,minWidth:20  },
  ];

  const data = purchasedDomains?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
 
  const onRowSelectionModelChange = (params) => {
   
   
   
    // Do something with the selected rows on the current page
    const selectedData = params
    setSelectedData(selectedData);

  }

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);

    setIsEditing(true);
  };

  const copyDomain=async()=>{
    const c = window.confirm(`Are you sure want to copy ${selectedData?.length} domains`);
if(c){
  try {
    const res = await dispatch(copy_domains(selectedData));
    if (res.payload.status === 200) {
      window.location.reload();
    }
  } catch (error) {
    console.log("ImagesList.jsx:48 ~ deleteRecordHandler ~ error", error);
  }
}
  }
  return (
    <>
      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
      {isEditing && (
        <AddUpdateDomains
          editingRecord={editingRecord}
          modalTitle="Add Domains"
          onCancelForm={cancelFormHandler}
        />
      )}
        {isLoading && <DotsLoader text="Please Wait" />}
        <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
          <h1 className="text-2xl font-extrabold">Purchased Domains</h1>
          <div>
          <button
            className="border rounded bg-red-600 text-white p-2"
            onClick={copyDomain}
          >
            Copy Domains
          </button>
          <button
            className="border rounded bg-red-600 text-white p-2 ml-1"
            onClick={openFormHandler(0)}
          >
            Add Domains
          </button>
          </div>
        </div>

        <div className="bg-white !my-3 border rounded">
         
            <MuiGrid
              columnDefs={columnDefs}
              items={data?.map((user, index) => {
                let counter = index + 1;
                let id = user.id;
                let city = user.city;
                let city_alias = user.city_alias;
                let county = user.county;
                let state = user.state;
                let domain = user.domain;
                let generate_domain_by = user.generate_domain_by;

                return {
                  records: user,
                  id,
                  counter,
                  city,
                  city_alias,
                  county,
                  state,
                  domain,
                  generate_domain_by,
                };
              })}
              tooltipShowDelay={0}
              tooltipHideDelay={4000}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              rowSelection="multiple"
              checkboxSelection
               onRowSelectionModelChange={onRowSelectionModelChange}
               pagination="No"
            />
         
        </div>
      </div>
    </>
  );
}

export default PurchasedDomain;
