import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/molecules/Modal";
import { toast } from "react-toastify";
import DotsLoader from "../../components/atoms/DotsLoader";
import { add_nation_site_zips } from "../../redux/serverSlice";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { useDropzone } from "react-dropzone";
import TagsInput from "../../components/atoms/CustomTagsInput";
function ImportExcel({ editingRecord, onCancelForm, modalTitle, getData }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.servers);
  const { industries } = useSelector((state) => state.industries);
  //   const [zips, setZips] = useState([]);
  let initialData = {
    industry_id: "",
    zips: [],
  };
  const onDrop = useCallback((acceptedFiles) => {
    let files = acceptedFiles[0];
    handleFile(files);
  }, []);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };
  if (editingRecord) {
    // eslint-disable-next-line
    const { industry_id, zips } = editingRecord;
    initialData = {
      industry_id,
      zips,
    };
  }
  const [data, setData] = useState(initialData);

  const onSubmit = async () => {
    try {
      const res = await dispatch(add_nation_site_zips(data));
      if (res.payload.status === 200) {
        dispatch(getData());
        onCancelForm();
      }
    } catch (error) {
      toast.error("Server couldn't be added");
      console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
    }
  };
  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   handleFile(file);
  // };
  const handleFile = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      if (file.name.endsWith(".xlsx")) {
        // Handle Excel file
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        // Find the index of the column with the heading "zip"
        const headerRow = excelData[0];
        // Create an array with the data from the "zip" column
        const zipColumnIndex = headerRow.indexOf("ZipCodes");
        if (zipColumnIndex > -1) {
          const zips = excelData?.slice(1)?.map((item) => item[zipColumnIndex]);
          // Now, zips will contain the data from the "zip" column
          setData({ ...data, zips: zips });
        } else {
          const zips = excelData?.slice(1)?.map((item) => item[0]);
          // Now, zips will contain the data from the "zip" column
          setData({ ...data, zips: zips });
        }
      } else if (file.name.endsWith(".csv")) {
        reader.onload = (e) => {
          const fileData = e.target.result;
          if (file.name.endsWith(".csv")) {
            // Handle CSV file
            Papa.parse(fileData, {
              complete: (result) => {
                console.log("CSV Data:", result.data);
                const headerRow = result.data[0];
                // Create an array with the data from the "zip" column
                const zipColumnIndex = headerRow.indexOf("ZipCodes");
                if (zipColumnIndex > -1) {
                  const zips = result.data
                    .slice(1)
                    .map((row) => row[zipColumnIndex]);
                  // Now, zips will contain the data from the "zip" column
                  setData({ ...data, zips: zips });
                } else {
                  const zips = result.data?.slice(1)?.map((item) => item[0]);
                  // Now, zips will contain the data from the "zip" column
                  setData({ ...data, zips: zips });
                }
              },
              // header: true,
            });
          }
        };

        reader.readAsText(file);
      }
    };

    reader.readAsArrayBuffer(file);
  };
  const generateRandomZipCodes = () => {
    const zipCodes = [];
    for (let i = 0; i < 20; i++) {
      const randomZip = String(Math.floor(10000 + Math.random() * 90000)); // Generates a random 5-digit number
      zipCodes.push({ ZipCodes: randomZip });
    }
    return zipCodes;
  };

  const exportToExcel = () => {
    const zipCodes = generateRandomZipCodes();
    const ws = XLSX.utils.json_to_sheet(zipCodes);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ZipCodes");
    XLSX.writeFile(wb, "zipCodes.xlsx");
    return;
  };
  return (
    <Modal
      isUpdate={editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={onSubmit}
      onClick={() => {}}
    >
      {isLoading && <DotsLoader />}
      <div className="container-fluid max-w-[900px] border rounded bg-white py-3 w-full flex flex-row justify-between">
        <div className="w-full !mr-2 grid grid-cols-2 gap-x-2">
          <div className="text-base font-medium col-span-2">
            <span className="font-semibold text-lg font_pop text-red-600">
              Note:{" "}
            </span>{" "}
            {/* File must be " */}
            <span className="font-semibold text-lg font_pop text-violet-600">
              ".xlsx"
            </span>{" "}
            and
            <span className="font-semibold text-lg font_pop text-violet-600">
              {" "}
              ".csv"
            </span>{" "}
            files can be import here which must has a column header
            named
            <span className="font-semibold text-lg font_pop text-violet-600">
              {" "}
              "ZipCodes".
            </span>
            <span
              className="font-normal text-normal font_pop text-blue-600 underline cursor-pointer ml-3"
              onClick={exportToExcel}
            >
              Sample
            </span>
          </div>
          <div className="col-span-2 !mt-3 flex justify-between">
            <select
              value={data.industry_id}
              onChange={(e) =>
                setData({ ...data, industry_id: e.target.value })
              }
              className="border p-1 rounded h-[35px] ml-2"
            >
              <option value="">Select Industry</option>
              {industries.map((option) => (
                <option
                  key={option.id}
                  value={option.id}
                  className="flex justify-between"
                >
                  {option.industry_name}
                </option>
              ))}
            </select>
            <span className="font_pop text-lg font-semibold">
              Total Zips: {data?.zips?.length}
            </span>
          </div>
          <div className="col-span-2 !mt-3">
            <div
              className={`w-full flex justify-center border-2 border-gray-300 border-dashed rounded-md !p-2 hover:border-gray-400 focus:outline-none ${
                isDragActive ? "bg-gray-100" : "bg-white"
              }`}
              {...getRootProps()}
            >
              <label
                className={`flex justify-center w-full ${"h-[80px]"} px-4 transition appearance-none cursor-pointer`}
              >
                <span className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <span className="font-medium text-gray-600">
                    Drop files to Attach, or{" "}
                    <span className="text-blue-600 underline" onClick={open}>
                      browse
                    </span>
                  </span>
                </span>
                <input {...getInputProps()} />
              </label>
            </div>
          </div>
          <div className="col-span-2 !mt-3">
            <TagsInput
              tags={data?.zips}
              setTags={(tag) => setData({ ...data, zips: tag })}
              placeholder="Add Zip"
            />
          </div>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
}

export default ImportExcel;
