import React from "react";
import Select from "react-select";
import { FaSearch } from "react-icons/fa";
import InputForm from "../../atoms/InputForm";
import Button from "../../atoms/Button";

function Filter({ filterForm, onReset, formik, className, showFilter,onSubmit }) {
  const selectInputRef = React.useRef();
  const [isFilter, setIsFilter] = React.useState(showFilter);
  const style = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      height: "40px",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10,
    }),
    control: (provided) => ({
      ...provided,
      height: "40px",
      minHeight: "40px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "40px",
    }),
  };

  return (
    <form
      className={[
        className,
        `grid grid-cols-1 gap-5 relative ${
          !isFilter ? "border-t border-[#ccc]" : ""
        }`,
      ].join(" ")}
    >
      
      {isFilter && (
        <div className="!p-5 bg-white rounded transition duration-[2s] ease-out">
          <div className="md:grid grid-cols-12 gap-x-2 items-center">
            {filterForm?.map((item, index) => {
              const {
                name,
                placeholder,
                isInput,
                isSelect,
                getOptionLabel,
                getOptionValue,
                options,
                onchange,
                isVisible,
                type,
                
                value,
                isCheckbox
              } = item;
              return (
                <>
                  {isVisible && (
                    <div key={index}  className="col-span-3  md:mt-md-2">
                      {isInput && (
                        <div className="mb-2">
                        <InputForm
                          name={name}
                          placeholder={placeholder}
                          onChange={(e) => onchange(e)}
                          value={value}
                          type={type}
                        />
                      </div>
                        
                      )}
                      {isSelect && (
                        <div className="mb-2">
                          <Select
                            ref={selectInputRef}
                            key={index}
                            styles={style}
                            onChange={onchange}
                           
                            name={name}
                            options={options}
                            getOptionLabel={getOptionLabel}
                            getOptionValue={getOptionValue}
                            placeholder={placeholder}
                            value={value}
                          />
                        </div>
                      )}

                     
                      {
                        isCheckbox&&
                         <div className="flex items-center text-xl font-semibold font_pop text-left">
                         <input
                           id="colorSelector"
                           type="checkbox"
                           className="border-none rounded mx-3 cursor-pointer overflow-hidden"
                           value={value}
                           onChange={(e) => onchange(e)}
                           checked={value === 1 ? true : false}
                         />
                         <label htmlFor="colorSelector" className="!mb-0 text-sm">
                         {placeholder}
                         </label>
                       </div>
                      }
                    </div>
                  )}
                </>
              );
            })}
            
          </div>
          <div className="w-full text-center mt-2 ">
              <div className="inline-block">
                <Button
                  text={"Search"}
                  className="py-1.5 px-3 align-middle mr-2 bg-primary-100 text-white"
                  variant="btn_submit"
                  onClick={onSubmit}
                />
                <Button
                  text="Reset"
                  className="py-1.5 px-3 align-middle bg-menu text-white"
                  variant="btn_cancel"
                  onClick={onReset}
                />
              </div>
            </div>
        </div>
      )}
    </form>
  );
}

export default Filter;
