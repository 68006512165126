import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addImage,
  getImageDetail,
  getImagesList,
  getTemplates,
  updateImage,
  deleteTemplateImage,
  
} from "../services";
import { updateTemplateNames } from "../services/templateImageServices";
const initialState = {
  isError: false,
  isLoading: false,
  imagesList: [],
  templates: [],
  imageDetail: null,
  message: "",
};
// Get Images List
export const get_images_list = createAsyncThunk(
  "get_images_list",
  async (thunkAPI) => {
    try {
      return await getImagesList();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_images_detail = createAsyncThunk(
  "get_images_detail",
  async (id, thunkAPI) => {
    try {
      return await getImageDetail(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add New Image
export const add_image = createAsyncThunk(
  "add_image",
  async (data, thunkAPI) => {
    try {
      return await addImage(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Image
export const update_image = createAsyncThunk(
  "update_image",
  async (data, thunkAPI) => {
    try {
      return await updateImage(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Templates
export const get_templates = createAsyncThunk(
  "get_templates",
  async (thunkAPI) => {
    try {
      return await getTemplates();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Template Names
export const update_template_names = createAsyncThunk(
  "update_template_names",
  async (thunkAPI) => {
    try {
      return await updateTemplateNames();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Delete Template Image
export const delete_template_image = createAsyncThunk(
  "delete_template_image",
  async (id, thunkAPI) => {
    try {
      return await deleteTemplateImage(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const templateImageSlice = createSlice({
  name: "images",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.imagesList = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_images_list.pending, (state) => {
        state.isLoading = true;
        state.imagesList = [];
      })
      .addCase(get_images_list.fulfilled, (state, action) => {
        state.isLoading = false;
        state.imagesList = action.payload?.data;
      })
      .addCase(get_images_list.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.imagesList = [];
      })
      .addCase(add_image.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_image.fulfilled, (state, action) => {
        state.isLoading = false;
        state.imagesList.push(action.payload.data);
        toast.success("Image added successfully");
      })
      .addCase(add_image.rejected, (state, action) => {
        state.isLoading = false;
        toast.error("Image couldn't be added");
      })
      .addCase(update_image.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_image.fulfilled, (state, action) => {
        state.isLoading = false;

        const result = state.imagesList.findIndex(
          ({ id }) => id === action?.payload?.data?.id
        );
        state.imagesList[result] = {
          ...state.imagesList[result],
          ...action.payload.data,
        };
        state.imageDetail = null;
        toast.success("Image update successfully");
      })
      .addCase(update_image.rejected, (state) => {
        state.isLoading = false;
        toast.error("Image couldn't be updated");
      })
      .addCase(get_templates.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(get_templates.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.templates = action.payload?.data;
      })
      .addCase(get_templates.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(get_images_detail.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(get_images_detail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.imageDetail = action.payload?.data;
      })
      .addCase(get_images_detail.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.imageDetail = null;
      })
      .addCase(delete_template_image.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(delete_template_image.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isError = false;
        state.imagesList = state.imagesList.filter(
          (img) => img.id !== +action.payload.data
        );
        state.isLoading = false;
      })
      .addCase(delete_template_image.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(update_template_names.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_template_names.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Template Names update successfully");
      })
      .addCase(update_template_names.rejected, (state) => {
        state.isLoading = false;
        toast.error("Template Names couldn't be updated");
      });
  },
});

export const { reset } = templateImageSlice.actions;
export default templateImageSlice.reducer;
