import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addHours,  getServiceHours, getServiceHoursById, putServiceHours,  } from "../services/HoursService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  
 
  logs:[],
 
  
  serviceHour:[]
};

export const add_hours = createAsyncThunk(
    "add_hours",
    async ( data,thunkAPI) => {
      try {
        return await addHours(data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const update_hours = createAsyncThunk(
    "update_hours",
    async (id, data,thunkAPI) => {
      try {
        return await putServiceHours(id,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const get_service_hours = createAsyncThunk(
    "get_service_hours",
    async ( thunkAPI) => {
      try {
        return await getServiceHours();
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const get_service_hours_by_id = createAsyncThunk(
    "get_service_hours_by_id",
    async (id, thunkAPI) => {
      try {
        return await getServiceHoursById(id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );



  export const hoursSlice = createSlice({
    name: "ServiceHours",
    initialState,
    reducers: {
      reset: (state) => {
        state.isError = false;
        state.isLoading = false;
        state.record = [];
        state.message = "";
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(get_service_hours.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(get_service_hours.fulfilled, (state, action) => {
          state.isLoading = false;
          state.serviceHour = action.payload?.data;
        })
        .addCase(get_service_hours.rejected, (state, action) => {
          state.isLoading = false;
        })
        .addCase(add_hours.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(add_hours.fulfilled, (state, action) => {
          state.isLoading = false;
          state.serviceHour.push(action.payload.data[0]);
        })
        .addCase(add_hours.rejected, (state, action) => {
          state.isLoading = false;
        })
        .addCase(update_hours.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(update_hours.fulfilled, (state, action) => {
          state.isLoading = false;
          const result = state.serviceHour.findIndex(
            ({ id }) => id === action?.payload?.data[0]?.id
          );
          state.serviceHour[result] = {
            ...state.serviceHour[result],
            ...action.payload.data[0],
          };
          // state.serviceHour.push(action.payload.data[0]);
        })
        .addCase(update_hours.rejected, (state, action) => {
          state.isLoading = false;
        })
        .addCase(get_service_hours_by_id.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(get_service_hours_by_id.fulfilled, (state, action) => {
          state.isLoading = false;
          state.serviceHour = action.payload?.data;
        })
        .addCase(get_service_hours_by_id.rejected, (state, action) => {
          state.isLoading = false;
        })
        
        
    },
  });
  
  export const { reset } = hoursSlice.actions;
  export default hoursSlice.reducer;