import React, { useEffect, useState } from "react";
import { FaEdit, FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import DotsLoader from "../../components/atoms/DotsLoader";
import dayjs from "dayjs";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";
import {
  get_active_domains,
  reset_active_domains,
} from "../../redux/activeDomainSlice";
import { useNavigate } from "react-router-dom";

function ActiveDomains() {
  const { isLoading, activeDomains } = useSelector(
    (state) => state.activeDomains
  );
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = React.useState("");
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(get_active_domains());
    // eslint-disable-next-line
  }, []);
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Stopped", field: "is_stopped", flex: 1, minWidth: 100 },
    { headerName: "Started On", field: "started_on", flex: 1, minWidth: 100 },
    { headerName: "Stopped On", field: "stopped_on", flex: 1, minWidth: 100 },
    {
      field: "actions",
      headerName: "Detail",
      renderCell: (params) => (
        <div className="flex items-center w-full">
          <FaEye
            onClick={() => {
              navigate(`/active_domains_detail/${params.row.id}`);
            }}
            className="my_navIcon cursor-pointer"
            size={20}
          />
        </div>
      ),
      width: 100,
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        minWidth: 20,
      },
    },
  ];
  const data = activeDomains?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  return (
    <>
      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
        {isLoading && <DotsLoader text="Please Wait" />}
        <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
          <h1 className="text-2xl font-extrabold">Active Domains</h1>
          <button
            className="border rounded bg-red-600 text-white p-2"
            onClick={() => {
              const c = window.confirm(`Are you sure want to reset domains status?`);
              if (c) {
                dispatch(reset_active_domains());
              }
            }}
          >
            Reset Domains Status
          </button>
        </div>

        <div className="bg-white !my-3 border rounded">
          <MuiGrid
            columnDefs={columnDefs}
            items={data?.map((record, index) => {
              let counter = index + 1;
              let id = record.id;
              let is_stopped = record.is_stopped ? "Yes" : "No";
              let started_on = record.started_on
                ? dayjs(record?.started_on).format("ddd, MMM D, YYYY h:mm A")
                : null;
              let stopped_on = record.stopped_on
                ? dayjs(record?.stopped_on).format("ddd, MMM D, YYYY h:mm A")
                : null;

              return {
                records: record,
                id,
                counter,
                is_stopped,
                started_on,
                stopped_on,
              };
            })}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            pagination="No"
          />
        </div>
      </div>
    </>
  );
}

export default ActiveDomains;
