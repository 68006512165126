import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DotsLoader from "../../components/atoms/DotsLoader";
import Modal from "../../components/molecules/Modal";
import { add_domains, update_domains } from "../../redux/domainSlice";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { get_industries } from "../../redux/industriesSlice";
function AddDomainModal({ onCloseModal, editingRecord, handleNewFilter }) {
  const { states, city, county } = useSelector(
    (state) => state.purchasedDomain
  );
  const { industries } = useSelector((state) => state.industries);
  let initialData = {
    domain: "",
    generate_domain_by: "",
    state: "",
    county: "",
    city: "",
    industry_id: "",
    deploy_with: "",
    phone: "",
    is_test_domain:0
  };
  if (editingRecord) {
    const { id, domain, generate_domain_by, state, county, city, industry_id, deploy_with, phone, is_test_domain } = editingRecord;
    initialData = {
      domain_id:id,
      domain, generate_domain_by, state, county, city, industry_id, deploy_with, phone, is_test_domain
    };
  }
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.domains);
  const [data, setData] = React.useState(initialData);
  console.log("🚀 ~ file: AddDomainModal.jsx:35 ~ AddDomainModal ~ data:", data)
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };
  const addDomain = async () => {
    const action =editingRecord?update_domains:add_domains
    try {
      const res = await dispatch(action(data));
      if (res.payload.status === 200) {
        handleNewFilter()
        onCloseModal();

      }
    } catch (error) {
      console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
    }
  };
  React.useEffect(() => {
    dispatch(get_industries());
  }, []);
  const statesOption = states.map((item) => ({
    label: item.state_name,
    value: item.state_name,
  }));
  const countyOption = county.map((item) => ({
    label: item.county_name,
    value: item.county_name,
  }));
  const cityOption = city.map((item) => ({
    label: item.city_name,
    value: item.city_name,
  }));
  const genByOptions=[
    { label: "City", value: "city" },
    { label: "State", value: "state" },
    { label: "County", value: "county" },
  ]
  const deployWithOptions=[
    { label: "Own Server", value: "OWN_SERVER" },
    { label: "AWS", value: "AWS" },
  ]
  const testDomainOptions=[
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
  ]
  return (
    <Modal
      isUpdate={false}
      title={editingRecord?"Update Domain":"Add Domain"}
      onCancelModal={onCloseModal}
      onSubmit={addDomain}
      onClick={onCloseModal}
    >
      {isLoading && <DotsLoader />}
      <div className="container-fluid max-w-[900px] border rounded bg-white py-3 w-full flex flex-row justify-between">
        <div className="w-full !mr-2 grid grid-cols-2 gap-x-2">
          <div className="col-span-1">
            <input
              className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="Domain"
              name="domain"
              value={data.domain}
              onChange={handleInput}
            />
          </div>
          <div className="col-span-1">
            <input
              className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="Phone"
              name="phone"
              value={data.phone}
              onChange={handleInput}
            />
          </div>
          <div className="col-span-1">
            <CreatableSelect
              onChange={(e) => {
                setData({
                  ...data,
                  generate_domain_by: e?.value?.toString(),
                });
              }}
              name={"generate_domain_by"}
              options={genByOptions}
              placeholder="Domain generate by"
              className="!mt-3 z-30"
              isClearable
              menuPosition="fixed"
              defaultValue={
                editingRecord?.generate_domain_by
                  ? {
                      value: editingRecord?.generate_domain_by,
                      label: editingRecord?.generate_domain_by,
                    }
                  : null
              }
            />
          </div>
          <div className="col-span-1">
            <CreatableSelect
              onChange={(e) => {
                setData({
                  ...data,
                  state: e?.value?.toString(),
                });
              }}
              name={"state"}
              options={statesOption}
              placeholder="Select State"
              className="!mt-3 z-10"
              isClearable
              menuPosition="fixed"
              defaultValue={
                editingRecord?.state
                  ? {
                      value: editingRecord?.state,
                      label: editingRecord?.state,
                    }
                  : null
              }
            />
          </div>
          <div className="col-span-1">
            <CreatableSelect
              onChange={(e) => {
                setData({
                  ...data,
                  county: e?.value?.toString(),
                });
              }}
              name={"county"}
              options={countyOption}
              placeholder="Select County"
              className="!mt-3 z-20"
              isClearable
              menuPosition="fixed"
              defaultValue={
                editingRecord?.county
                  ? {
                      value: editingRecord?.county,
                      label: editingRecord?.county,
                    }
                  : null
              }
            />
          </div>
          <div className="col-span-1">
            <CreatableSelect
              onChange={(e) => {
                setData({
                  ...data,
                  city: e?.value?.toString(),
                });
              }}
              name={"city"}
              options={cityOption}
              placeholder="Select City"
              className="!mt-3 z-10"
              isClearable
              menuPosition="fixed"
              defaultValue={
                editingRecord?.city
                  ? {
                      value: editingRecord?.city,
                      label: editingRecord?.city,
                    }
                  : null
              }
            />
          </div>
          <div className="col-span-1">
            <Select
              onChange={(e) => {
                setData({
                  ...data,
                  industry_id: e?.id?.toString(),
                });
              }}
              name={"industry"}
              options={industries}
              getOptionLabel={(options) => options.industry_name}
              getOptionValue={(options) => options.id}
              placeholder="Select Industry"
              className="!mt-3 z-10"
              menuPosition="fixed"
              defaultValue={
                editingRecord?.industry_id
                  ? {
                      id: editingRecord?.industry_id,
                      industry_name: industries?.find(({id})=>+id === +data?.industry_id)?.industry_name,
                    }
                  : null
              }
            />
          </div>
          <CreatableSelect
            onChange={(e) => {
              setData({
                ...data,
                deploy_with: e?.value?.toString(),
              });
            }}
            name={"deploy_with"}
            options={deployWithOptions}
            placeholder="Select deploy with"
            className="!mt-3 z-10"
            isClearable
            menuPosition="fixed"
            defaultValue={
              editingRecord?.deploy_with
                ? {
                    value: editingRecord?.deploy_with,
                    label: editingRecord?.deploy_with,
                  }
                : null
            }
          />
          <div className="col-span-1 relative mt-2">
          <label htmlFor={"is_test_domain"} className={[`small`, "text-[rgba(0,_0,_0,_0.6)] absolute left-[0] top-0 font-normal text-base truncate max-w-[calc(133%-24px)] translate-x- -translate-y-[0] bg-white scale-75 px-1 z-[1]"].join(" ")}>
          {"Test Domain"}
        </label>
            <Select
              onChange={(e) => {
                setData({
                  ...data,
                  is_test_domain: e?.value,
                });
              }}
              name={"is_test_domain"}
              options={testDomainOptions}
              placeholder="Testing Domain"
              className="!mt-3"
              menuPosition="fixed"
              defaultValue={
                data?.is_test_domain>-1
                  ? {
                      value: +editingRecord?.is_test_domain,
                      label: testDomainOptions?.find(({value})=>+value === +data?.is_test_domain)?.label,
                    }
                  : null
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddDomainModal;
