import React from "react";

import { useDispatch } from "react-redux";
import { useState } from "react";
import DotsLoader from "../../components/atoms/DotsLoader";
import Button from "../../components/atoms/Button";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";
import { set_alternate_area_codes } from "../../redux/domainSlice";
import { toast } from "react-toastify";
import { deployApi } from "../../api";
const SetAlternateModal = ({ onCloseModal, pendingPhone }) => {
  const [searchInput, setSearchInput] = useState("");
  const uniqueAreaCodes = new Set();
  // Loop through each object in the array
  pendingPhone.forEach((item) => {
    if(item.areaCodes?.includes(',')){
      const codes = item.areaCodes.split(",");
      const sortedCodes = codes.map(code => parseInt(code.trim())).sort().join(",");
      uniqueAreaCodes.add(sortedCodes);
      // uniqueAreaCodes.add(item.areaCodes.trim())
    }
    const codes = item.areaCodes.split(","); // Split area codes if there are multiple
    codes.forEach((code) => uniqueAreaCodes.add(code.trim())); // Add each code to the set
  });
  const uniqueAreaCodesArray = [...uniqueAreaCodes];
  const data = uniqueAreaCodesArray?.filter((e) => e?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
  );  // const data = uniqueAreaCodesArray;
  const [dataa, setDataa] = useState(
    data?.map((code) => ({ arecode: code, alternate_code: "" }))
  );
  React.useEffect(() => {
    setDataa(data?.map((code) => ({ arecode: code, alternate_code: "" })))
  }, [searchInput])
  
  const [areaWithout, setAreaWithout] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCloseModal();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isExist = (code) => {
    if (areaWithout?.includes(code)) {
      return true;
    } else {
      return false;
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    {
      field: "arecode",
      headerName: "Area Codes",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "alternate_code",
      headerName: "Alternate Code",
      renderCell: (params) => {
        return (
          <div className="flex flex-col">
            <input
              placeholder={`Alternate code for ${params.row.arecode}`}
              className={`w-full px-4  h-[30px] rounded  text-sm disabled:text-gray-500 
            ${
              isExist(params.row.alternate_code)
                ? "border !border-[#D32F2F] focus:outline-none"
                : "border focus:outline-none focus:ring-1 focus:ring-violet-600"
            }
            `}
              onChange={(e) =>
                updateAlternateCode(params.row.arecode, e.target.value)
              }
              value={params.row.alternate_code}
            />
            {isExist(params.row.alternate_code) ? (
              <small className="text-red-600">
                There is no phone number for this area code
              </small>
            ) : null}
          </div>
        );
      },
      flex: 1,
      minWidth: 150,
    },
  ];
  // Function to update alternate_code based on arecode match
  const updateAlternateCode = (arecode, inputValue) => {
    setDataa((prevData) =>
      prevData.map((item) =>
        item.arecode === arecode
          ? { ...item, alternate_code: inputValue }
          : item
      )
    );
  };
  const handleCheckPhoneAvailability = async () => {
    const payload = dataa
      ?.filter(({ alternate_code }) => alternate_code)
      ?.map(({ alternate_code }) =>
        alternate_code === "" ? null : alternate_code
      );
    setIsLoader(true);
    try {
      const res = await deployApi.post(
        `/api/domains/check_phone_availability`,
        { alternate_area_codes: payload }
      );
      if (res?.status === 200) {
        setAreaWithout(res.data?.area_without_phone);
      }
      setIsLoader(false);
      return res.data?.area_without_phone;
    } catch (err) {
      console.log(
        "🚀 ~ file: Modal.jsx:98 ~ handleCheckPhoneAvailability ~ err:",
        err
      );
      setIsLoader(false);
    }
  };

  const handleSubmit = async () => {
    const wothoutPhoneArray = await handleCheckPhoneAvailability();
    const payload = dataa?.filter(
      ({ alternate_code }) =>
        alternate_code && !wothoutPhoneArray?.includes(alternate_code)
    );
    try {
      const res = await dispatch(set_alternate_area_codes(payload));
      if (res.payload.status === 200) {
        
        if (wothoutPhoneArray?.length>0) {
          toast.info(
            `There is no phone number for ${wothoutPhoneArray?.length} area codes.`
          );
        }else if(res.payload?.data?.length>0){
          toast.success("Alternate area codes set successfully");
        }else{
          toast.error("No any alternate area codes set");
        }
        onCloseModal();
      } else {
        toast.error("Alternate area codes couldn't be set");
      }
    } catch (err) {
      toast.error("Alternate area codes couldn't be set");
      console.log("🚀 ~ file: Modal.jsx:79 ~ handleSubmit ~ err:", err);
    }
  };
  const SingleSelectRenderer = () => {
    return (
      <button
        className="border rounded bg-red-600 text-white p-2 w-fit text-sm flex justify-end mr-2"
        onClick={handleCheckPhoneAvailability}
      >
        Check Phone Availability
      </button>
    );
  };
  
  return (
    <>
      <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
        {isLoader && <DotsLoader text="Please Wait" />}
        <div className="container-fluid mt-2  text-center ">
          <div className="inline-block bg-white w-3/5 my-0 mx-auto p-4 bg-red-600 rounded  max-h-[87vh]">
            <header className="flex items-center justify-center shadow-lg bg-slate-900 text-white h-[50px] mb-3 text-xl">
              Set Alternate Area Codes
            </header>
            <div className="bg-white !my-3 border rounded">
              <MuiGrid
                columnDefs={columnDefs}
                items={dataa?.map((item, index) => {
                  let counter = index + 1;
                  const { arecode, alternate_code } = item;
                  return {
                    records: { ...item, id: index + 1 },
                    counter,
                    arecode,
                    alternate_code,
                  };
                })}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                pagination="No"
                totalItems={
                  dataa?.totalItems ? dataa?.totalItems : dataa?.length
                }
                height={"60vh"}
                showToolbar="No"
                rowHeight={55}
                LineOne2={SingleSelectRenderer}
              />
            </div>
            <Button
              text="Cancel"
              variant="btn_cancel"
              className="ml-3"
              onClick={onCloseModal}
            />
            <Button
              text="Save"
              variant="btn_submit"
              className="ml-3"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SetAlternateModal;
