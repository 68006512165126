function RecordSearch(){
    return(
        <div className="flex gap-2 items-center">
            <input type="text" className="!mr-2 max-h-[38px] bg-white border border-gray-300 text-gray-900 text-sm rounded-[0.2rem] w-full p-2.5 " placeholder="Search"
           
            
            />
        
        </div>
    );
}
export default RecordSearch;