import {
  FaIndustry,
  FaGithub,
  FaAws,
  FaUsers,
  FaCloudUploadAlt,
  FaServer,
  FaCriticalRole,
  FaYandexInternational,
  FaListAlt,
  FaIoxhost,
  FaUserAlt,
} from "react-icons/fa";
import { HiTemplate } from "react-icons/hi";
import { SiVercel, SiAwsamplify } from "react-icons/si";
import { GrDomain } from "react-icons/gr";
export const sideMenu = [
  // {
  //   to: "/add_Project",
  //   label: "Deploy Project",
  //   Icon: FaUserFriends,
  //   permission: ["SUPER ADMINISTRATOR"],
  // },
  // {
  //   to: "/images_list",
  //   label: "Image Section",
  //   Icon: FaImage,
  //   permission: ["SUPER ADMINISTRATOR"],
  // },
  {
    to: "/industries",
    label: "Industries",
    Icon: FaIndustry,
    permission: ["SUPER ADMINISTRATOR", "Content"],
  },
  {
    to: "/templates",
    label: "Templates",
    Icon: HiTemplate,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/domains/AWS",
    label: "Domains",
    Icon: GrDomain,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/active_domains",
    label: "Active Domains",
    Icon: FaUsers,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "purchased_domains",
    label: "Purchased Domains",
    Icon: SiAwsamplify,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/aws_certificates",
    label: "AWS Certificates",
    Icon: FaCloudUploadAlt,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/aws_certificate_domains",
    label: "AWS Certificate Domains",
    Icon: FaCloudUploadAlt,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/cloudflare_accounts",
    label: "Cloudflare Accounts",
    Icon: FaUserAlt,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/detach_domain",
    label: "Detach Domains",
    Icon: FaGithub,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/domain_active_report",
    label: "Domains Active Report",
    Icon: FaListAlt,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/domain_index_report",
    label: "Domains Index Report",
    Icon: FaListAlt,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/virtual_host",
    label: "Virtual Hosts",
    Icon: FaIoxhost,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/github_templates",
    label: "Github Repos",
    Icon: FaGithub,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/pull_to_server",
    label: "Pull To Server",
    Icon: FaServer,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/domains_per_industry",
    label: "Domain per Industry",
    Icon: FaServer,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/roles",
    label: "Roles",
    Icon: FaCriticalRole,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/users",
    label: "Users",
    Icon: FaUsers,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/domain_numbers",
    label: "Domain Numbers",
    Icon: FaListAlt,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/domain_runners",
    label: "Domain Runners",
    Icon: FaListAlt,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/testing_domains",
    label: "Testing Domain",
    Icon: FaListAlt,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/pending_domains_phone",
    label: "Pending Phones",
    Icon: FaListAlt,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/list_alternate_area_codes",
    label: "Alternate Area Codes",
    Icon: FaListAlt,
    permission: ["SUPER ADMINISTRATOR"],
  },
  
  {
    to: "/nation_site_zips",
    label: "Nation Site Zips",
    Icon: FaYandexInternational,
    permission: ["SUPER ADMINISTRATOR"],
  },
  {
    to: "/custom_vendor",
    label: "Custom Vendors",
    Icon: FaUsers,
    permission: ["SUPER ADMINISTRATOR"],
    children: [
      {
        to: "vendor",
        label: "Vendor",
        Icon: FaAws,
        permission: ["SUPER ADMINISTRATOR"],
      },
      {
        to: "dids",
        label: "DIDs",
        Icon: FaAws,
        permission: ["SUPER ADMINISTRATOR"],
      },
      {
        to: "tollfree",
        label: "Tollfree",
        Icon: SiVercel,
        permission: ["SUPER ADMINISTRATOR"],
      },
      {
        to: "search_logs",
        label: "Search Logs",
        Icon: SiVercel,
        permission: ["SUPER ADMINISTRATOR"],
      },
      {
        to: "caller_id",
        label: "Caller ID",
        Icon: SiVercel,
        permission: ["SUPER ADMINISTRATOR"],
      },
      {
        to: "insurance",
        label: "Insurance",
        Icon: SiVercel,
        permission: ["SUPER ADMINISTRATOR"],
      },
      {
        to: "service_hours",
        label: "Service Hours",
        Icon: SiVercel,
        permission: ["SUPER ADMINISTRATOR"],
      },
      {
        to: "emails",
        label: "Emails",
        Icon: SiVercel,
        permission: ["SUPER ADMINISTRATOR"],
      },
    ],
  },
];
