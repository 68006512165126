import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/molecules/Modal";
import { add_domain_runner, update_domain_runner } from "../../redux/domainSlice";
import { toast } from "react-toastify";
import DotsLoader from "../../components/atoms/DotsLoader";
import Select from "react-select";

function AddUpdateDomainRunner({ editingRecord, onCancelForm, modalTitle }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.domains);
  const { industries } = useSelector((state) => state.industries);

  let initialData = {
    ip: '',
    industry_id: '',
    runner_name: '',
    mysql_user: '',
    mysql_password: '',
    phpmyadmin_path: '',
    runner_path: '',
    table_name: '',
    lb_server_file: '',
  };

  if (editingRecord) {
    const {
      id,
      ip,
      industry_id,
      runner_name,
      mysql_user,
      mysql_password,
      phpmyadmin_path,
      runner_path,
      table_name,
      lb_server_file,
    } = editingRecord;
   initialData = {
      id,
      ip,
      industry_id,
      runner_name,
      mysql_user,
      mysql_password,
      phpmyadmin_path,
      runner_path,
      table_name,
      lb_server_file,
    };
  }

  const [data, setData] = useState(initialData);
  console.log("🚀 ~ file: AddUpdateDomainRunners.jsx:56 ~ AddUpdateDomainRunner ~ data:", data)

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const onSubmit = async () => {
    if (!editingRecord) {
      try {
        const res = await dispatch(add_domain_runner(data));
        if (res.payload.status === 200) {
          toast.success('Domain Runner Added Successfully');
          onCancelForm();
        }else{
          toast.error(res.payload?res.payload:"Domain Runner couldn't be added");
        }
      } catch (error) {
        toast.error("Domain Runner couldn't be added");
        console.error('~ onSubmit ~ error', error);
      }
    } else {
      try {
        delete data.mysql_password
        const res = await dispatch(update_domain_runner(data));
        console.log("🚀 ~ file: AddUpdateDomainRunners.jsx:75 ~ onSubmit ~ res:", res)
        if (res.payload.status === 200) {
          toast.success('Domain Runner Update Successfully');
          onCancelForm();
        }else{
          toast.error(res.payload?res.payload:"Domain Runner couldn't be updated");
        }
      } catch (error) {
        toast.error("Domain Runner couldn't be updated");
        console.error('~ onSubmit ~ error', error);
      }
    }
  };

  return (
    <Modal
      isUpdate={editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={onSubmit}
      onClick={onCancelForm}>
      {isLoading && <DotsLoader />}
      <div className="container-fluid max-w-[900px] border rounded bg-white py-3 w-full flex flex-row justify-between">
  <div className="w-full !mr-2 grid grid-cols-2 gap-x-2">
    <div className="col-span-1">
      <input
        className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
        placeholder="IP"
        name="ip"
        value={data.ip}
        onChange={handleInput}
      />
    </div>
    
    <div className="col-span-1">
            <Select
              onChange={(e) => {
                setData({
                  ...data,
                  industry_id: e?.id?.toString(),
                });
              }}
              name={"industry"}
              options={industries}
              getOptionLabel={(options) => options.industry_name}
              getOptionValue={(options) => options.id}
              placeholder="Select Industry"
              className="!mt-3 z-10"
              menuPosition="fixed"
              defaultValue={
                data?.industry_id
                  ? {
                      id: data?.industry_id,
                      industry_name: industries?.find(({id})=>+id === +data?.industry_id)?.industry_name,
                    }
                  : null
              }
            />
          </div>
    <div className="col-span-1">
      <input
        className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
        placeholder="Runner Name"
        name="runner_name"
        value={data.runner_name}
        onChange={handleInput}
        readOnly={editingRecord}
      />
    </div>
      {!editingRecord? <div className="col-span-1">
      <input
        className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
        placeholder="MySQL Password"
        name="mysql_password"
        value={data.mysql_password}
        onChange={handleInput}
      />
    </div>:null}
   
    <div className="col-span-1">
      <input
        className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
        placeholder="phpMyAdmin Path"
        name="phpmyadmin_path"
        value={data.phpmyadmin_path}
        onChange={handleInput}
      />
    </div>
    <div className="col-span-1">
      <input
        className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
        placeholder="Runner Path"
        name="runner_path"
        value={data.runner_path}
        onChange={handleInput}
      />
    </div>
    <div className="col-span-1">
      <input
        className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
        placeholder="Table Name"
        name="table_name"
        value={data.table_name}
        onChange={handleInput}
      />
    </div>
    <div className="col-span-1">
      <input
        className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
        placeholder="LB Server File"
        name="lb_server_file"
        value={data.lb_server_file}
        onChange={handleInput}
      />
    </div>
  </div>
</div>

    </Modal>
  );
}

export default AddUpdateDomainRunner;
