import React from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "./layout";
import AddProject from "./views/AddProject";
import AddImage from "./views/ImageSection/AddImage";
import Login from "./views/Login/Login";
import PasswordRecover from "./views/Login/Recovery";
import Industries from "./views/Industries";
import DomainsList from "./views/Domains";
import DomainRunner from "./views/DomainRunners";
import GithubRepos from "./views/GithubRepo";
import Templates from "./views/Templates";
import DatabaseTables from "./views/DatabaseTables";
import DatabaseRows from "./views/DatabaseRows";
import BulkImages from "./views/BulkImages";
import Roles from "./views/Roles";
import Users from "./views/Users";
import DatabaseTable from "./views/Domains/DatabaseTables";
import DomainDatabaseRows from "./views/Domains/DatabaseRows";
import DomainImages from "./views/Domains/DomainImages";
import PendingDeployments from "./views/Domains/PendingDeployments";
import PurchasedDomain from "./views/PurchasedDomains";
import Cloudfront from "./views/Cloudfront";
import CloudfrontDomains from "./views/Cloudfront/Domains";
import CloudFrontAccount from "./views/Cloudfront/CloudFrontAccount";
import DetachDomain from "./views/DetachDomain";
import CustomVendor from "./views/CustomVendor";
import VendorDids from "./views/VendorDids";
import VendorTollfree from "./views/VendorTollfree";
import SearchLogs from "./views/SearchLogs";
import CallerId from "./views/CallerId";
import InsuranceComp from "./views/InsuranceComp";
import ServiceHours from "./views/ServiceHours";
import Emails from "./views/Emails";
import ActiveDomains from "./views/ActiveDomains";
import ActiveDomainsDetail from "./views/ActiveDomains/DomainDetail";
import DomainsReport from "./views/DomainsReport";
import DomainIndexReport from "./views/DomainsIndexReport";
import VirtualHost from "./views/VirtualHost";
import PullToServer from "./views/PullToServer";
import NationSiteZips from "./views/NationSiteZips";
import DomainNumbers from "./views/DomainNumbers";
import DomainIframes from "./views/DomainIframeGrid";
import TestingDomains from "./views/TestingDomains";
import PendingDomainsPhone from "./views/PendingDomainsPhone";
import AlternateDomainPhone from "./views/AlternateDomainsPhone";
// import HttpRequestTool from "./Test";

const App = () => {
  const { user, redirectTo } = useSelector((state) => state.auth);
  const location = useLocation();
  let routes;
  if (!user) {
    routes = [
      { path: "/login", element: <Login /> },
      { path: "/password-recovery", element: <PasswordRecover /> },
      { path: "*", element: <Navigate to="/login" replace /> },
    ];
  } else {
    routes = [
      { path: "/add_Project", element: <AddProject /> },
      { path: "/add_image", element: <AddImage /> },
      { path: "/industries", element: <Industries /> },
      { path: "/domains/:type", element: <DomainsList /> },
      { path: "/domains/:type/:filter", element: <DomainsList /> },
      { path: "/domains/pending_deployments", element: <PendingDeployments /> },
      { path: "/aws_certificates", element: <Cloudfront /> },
      { path: "/aws_certificate_domains", element: <CloudfrontDomains /> },
      { path: "/cloudflare_accounts", element: <CloudFrontAccount /> },
      { path: "/detach_domain", element: <DetachDomain /> },
      { path: "/domain_active_report", element: <DomainsReport /> },
      { path: "/domain_index_report", element: <DomainIndexReport /> },
      { path: "/domains/database/:dbName", element: <DatabaseTable /> },
      {
        path: "/domains/database/:dbName/:tableName/:tagName?",
        element: <DomainDatabaseRows />,
      },
      // { path: "/bulk_hits", element: <HttpRequestTool /> },
      { path: "/purchased_domains", element: <PurchasedDomain /> },
      { path: "/domains_per_industry", element: <DomainIframes /> },
      { path: "/testing_domains", element: <TestingDomains /> },
      { path: "/template_image/domains/:domain", element: <DomainImages /> },
      { path: "/github_templates", element: <GithubRepos /> },
      { path: "/templates", element: <Templates /> },
      { path: "/pending_domains_phone", element: <PendingDomainsPhone /> },
      { path: "/industries/:dbName", element: <DatabaseTables /> },
      {
        path: "/industries/:dbName/:tableName/:tagName?",
        element: <DatabaseRows />,
      },
      {
        path: "/bulk_images/:tempId?/:industryId?/:folder?",
        element: <BulkImages />,
      },
      { path: "/roles", element: <Roles /> },
      { path: "/custom_vendor/vendor", element: <CustomVendor /> },
      { path: "/custom_vendor/dids", element: <VendorDids /> },
      { path: "/custom_vendor/tollfree", element: <VendorTollfree /> },
      { path: "/custom_vendor/search_logs", element: <SearchLogs /> },
      { path: "/custom_vendor/caller_id", element: <CallerId /> },
      { path: "/domain_numbers", element: <DomainNumbers /> },
      { path: "/custom_vendor/insurance", element: <InsuranceComp /> },
      { path: "/custom_vendor/service_hours", element: <ServiceHours /> },
      { path: "/custom_vendor/emails", element: <Emails /> },
      { path: "/active_domains", element: <ActiveDomains /> },
      { path: "/active_domains_detail/:id", element: <ActiveDomainsDetail /> },
      { path: "/users", element: <Users /> },
      { path: "/virtual_host", element: <VirtualHost /> },
      { path: "/pull_to_server", element: <PullToServer /> },
      { path: "/nation_site_zips", element: <NationSiteZips /> },
      { path: "/list_alternate_area_codes", element: <AlternateDomainPhone /> },
      
      {
        path: "*",
        element: (
          <Navigate
            to={
              redirectTo
                ? redirectTo
                : location.pathname === "/domain_report"
                ? "/domain_active_report"
                : "/industries"
            }
            replace
          />
        ),
      },
      { path: "/domain_runners", element: <DomainRunner /> }
    ];
  }

  React.useEffect(() => {
    if (location.pathname === "/domain_report") {
      localStorage.setItem("redirectTo", "/domain_active_report");
    }
  }, [location]);
  return (
    <Layout>
      <Routes>
        {routes?.map((route, index) => (
          <Route key={index} {...route} />
        ))}
      </Routes>
    </Layout>
  );
};
// arfan+ maqsood foji
export default App;
