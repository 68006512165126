import React,{useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import DotsLoader from "../../components/atoms/DotsLoader";
import dayjs from "dayjs";
import { delete_detach_domain, get_detach_domain } from '../../redux/domainSlice';
import MuiGrid from '../../components/molecules/DataTable/MuiGrid';
const DetachDomain = () => {
    const { isLoading, detachDomain } = useSelector((state) => state.domains);
    
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = React.useState("");
  const [selectedData, setSelectedData] = React.useState([]);
    useEffect(() => {
        dispatch(get_detach_domain());
        // eslint-disable-next-line
      }, []);
      const onRowSelectionModelChange = (params) => {
       
        const selectedData = params
        setSelectedData(selectedData);
      };
      
    const columnDefs = [
        // {
        //   field: "",
        //   checkboxSelection: true,
        //   headerCheckboxSelection: true,
        //   width: 100,
        // },
        { headerName: "#", field: "counter", width: 100 },
       
        
        { headerName: "Industry Name #", field: "industry_name",flex:1,minWidth:30 ,width:30 },
        { headerName: "Domain", field: "domain" ,flex:1,minWidth:40 ,width:60},
      
    
        { headerName: "Created On", field: "created_on",flex:1,minWidth:30  ,width:50},
        { headerName: "Certificate", field: "certificate",flex:1,minWidth:30  },
      ];
      const data = detachDomain?.filter((e) => {
        return Object.keys(e)?.some(
          (key) =>
            e[key] &&
           
            e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
        );
      });
   
      const deleteDomain = async () => {
        const c = window.confirm(`Are you sure want to delete?`);       
        if (c) {
          try {
            await dispatch(
                delete_detach_domain({
                ids: selectedData,
              })
            );
          } catch (error) {
            console.log("ImagesList.jsx:48 ~ deleteRecordHandler ~ error", error);
            // toast.error("Template Image couldn't be deleted");
          }
        }
      };
  return (
    <>
        <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
       

        {isLoading && <DotsLoader text="Please Wait" />}
        <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
          <h1 className="text-2xl font-extrabold">AWS Certificates</h1>
          <div>
         
          <button
            className="border rounded bg-red-600 text-white p-2"
            onClick={deleteDomain}
            // disabled={selectedData.length > 0 ? false : true}
            type="button"
          >
            Delete
          </button>
          </div>
        </div>
        <div className="bg-white !my-3 border rounded">
         
            <MuiGrid
              // rowSelection="multiple"
              onRowSelectionModelChange={onRowSelectionModelChange}
              columnDefs={columnDefs}
              items={data?.map((item, index) => {
               
                let counter = index + 1;
                const {
                  id,
                  industry_id,
                  industry_name,
                  domain,
                } = item;
                let created_on = item.created_on
                  ? dayjs(item.created_on).format("ddd, MMM D, YYYY h:mm A")
                  : null;
                let certificate = item.certificate
               
                return {
                  records: item,
                  id,
                  counter,
                  industry_name,
                  industry_id,
                  domain,
                  certificate,
                  created_on,
                  
                };
              })}
              checkboxSelection
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              pagination="No"
            />
         
        </div>
      </div>
    </>
  )
}

export default DetachDomain