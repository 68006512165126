import React, { useState } from "react";
import { toast } from "react-toastify";
import { deployApi } from "../../api";
import Select from "react-select";
import Loader from "../../components/atoms/Loader";

const Pm2Modal = ({ onClose, selectedData, setSelectedData }) => {
  
  const [isLoader, setIsLoader] = useState(false);
  const methodOption = [{label: "Start", value: "start"},{label: "Restart", value: "restart"},{label: "Stop", value: "stop"}]
  const [method, setMethod] = useState(null);
  async function managePm2() {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (selectedData?.length === 0) {
      return toast.error("Please select the server");
    }
    setIsLoader(true);
    const selectedDataCopy = [...selectedData];
    for (const item of selectedDataCopy) {
      try {
        const {host, app_folder_name, pm2_process_name} = item
        const response = await deployApi.post("/api/runner_setup/manage_pm2_all_runers", {host, app_folder_name, pm2_process_name, commandType: method});

        if (response.status === 200) {
          toast.success(
            `${response?.data?.message} for ${item.name} & ${item.app_folder_name}`
          );
          setSelectedData((prevState) =>
            prevState.filter(({ id }) => id !== item?.id)
          );
        } else {
          toast.error(
            `${response.data?.message} for ${item.name} & ${item.app_folder_name}`
          );
        }
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
        toast.error(
          `${error?.data?.message} for ${item.name} & ${item.app_folder_name}`
        );
      }
    }

    setIsLoader(false);

    // Clear the selectedData after processing all requests
    setSelectedData([]);
  }

  return (
    <>
      <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40">
        <div className="bg-white p-8 rounded-lg shadow-lg relative w-[400px]">
          <h2 className="text-lg font-semibold mb-4">Run Pm2 Command</h2>
          {/* <p className="mb-4">Are you sure you want to restart the application?</p> */}

          <div className=" !px-0.5 !mb-3">
            <label className="block">Select Command:</label>
            <Select
              name="method"
              placeholder="Select Command"
              onChange={(e) => setMethod(e.value)}
              options={methodOption}
              valueKey="value"
              labelKey="label"
              className="w-full"
            />
          </div>
          <div className="flex justify-end !mt-4">
            {isLoader ? (
              <Loader />
            ) : (
              <>
                <button
                  className="px-4 py-2 mr-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  onClick={managePm2}
                >
                  OK
                </button>
                <button
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Pm2Modal;
