import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DotsLoader from "../../components/atoms/DotsLoader";
import { get_templates_repo } from "../../redux/deploySlice";
import {
  cloudfront_account,
  get_domain_drd,
  get_domain_list,
  post_detach_domain,
  set_test_domains,
} from "../../redux/domainSlice";
import AddDomainModal from "./AddDomainModal";
import AddUpdateTemplate from "./AddUpdateTemplateModal";
import { FaBorderAll, FaEdit, FaListUl } from "react-icons/fa";
import { get_industries } from "../../redux/industriesSlice";
import { get_templates } from "../../redux/templateImagesSlice";
import DomainImages from "./DomainImages";
import ARNGenerator from "./ARNGenerator";
import RecordSearch from "../../components/atoms/RecordSearch";
import * as XLSX from "xlsx";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Box, Button } from "@mui/material";
import {
  Search,
  ImportExportOutlined,
  DialpadOutlined,
  Google,
} from "@mui/icons-material";
import { GiBreakingChain } from "react-icons/gi";
import dayjs from "dayjs";
import {
  get_city,
  get_county,
  get_states,
} from "../../redux/purchasedDomainSlice";
import SelectCloudFlare from "./SetCloudFlare";
import GoogleConsole from "./GoogleConsole";
import { toast } from "react-toastify";
import { deployApi } from "../../api";
import CheckDomainModal from "./CheckDomainModal";
function DomainsList() {
  const dispatch = useDispatch();
  const initialTemplate = {
    id: "",
    template_id: "",
    template_name: "",
  };

  const { isLoading, domainList, imageList, cloudfrontAccount } = useSelector(
    (state) => state.domains
  );
  const { industries } = useSelector((state) => state.industries);
  const { templates } = useSelector((state) => state.images);
  const [addDomainModal, setAddDomainModal] = React.useState(false);
  const [templateData, setTemplateData] = React.useState(initialTemplate);
  const [templateModal, setTemplateModal] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState([]);
  const [showImages, setShowImages] = React.useState();
  const [arnModal, setArnModal] = React.useState(false);
  const [cloudFlareModal, setCloudFlareModal] = React.useState(false);
  const [deployeOn, setDeployeOn] = React.useState("");
  const [gridActive, setGridActive] = useState(true);
  const [editingRecord, setEditingRecord] = useState(null);
  const [googleConsole, setGoogleConsole] = useState(false);
  const [domain_count, setDomain_count] = useState('');
  const [isDomainCheck, setIsDomainCheck] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1,
  });

  React.useEffect(() => {
    dispatch(get_templates_repo());
    dispatch(get_templates());
    dispatch(get_domain_drd());
    dispatch(get_industries());
    dispatch(get_states());
    dispatch(get_county());
    dispatch(get_city());
    dispatch(cloudfront_account());
    // eslint-disable-next-line
  }, []);

  const onCloseModal = () => {
    setAddDomainModal(false);
    setTemplateModal(false);
    setEditingRecord(null);
  };
  const openFormHandler = (record) => () => {
    console.log("🚀 ~ file: index.jsx:80 ~ openFormHandler ~ record:", record);
    setEditingRecord(record);

    setAddDomainModal(true);
  };
  const selectAll = () => {
    let id = [];
    if (selectedData?.length !== domainList?.records?.length) {
      // eslint-disable-next-line
      domainList?.records.map((val) => {
        id = [...id, val];
      });
      setSelectedData(id);
    } else if (selectedData?.length === domainList?.records?.length) {
      setSelectedData([]);
    }
  };

  const onSingleselect = (val) => {
    if (selectedData?.length > 0) {
      if (selectedData.filter(({ id }) => id === val.id)?.length > 0) {
        const arr = selectedData.filter(({ id }) => id !== +val.id);
        setSelectedData(arr);
      } else {
        setSelectedData([...selectedData, val]);
      }
    } else {
      setSelectedData([...selectedData, val]);
    }
  };

  const isChecked = (val) => {
    if (selectedData?.length > 0) {
      if (selectedData?.filter(({ id }) => id === val)?.length > 0) {
        return true;
      }
    }
    return false;
  };

  const addDomain = async () => {
    let domains = [];
    const c = window.confirm(`Are you sure want to Add?`);
    // eslint-disable-next-line
    selectedData.map((val) => {
      domains = [...domains, val.domain];
    });
    if (c) {
      try {
        await dispatch(post_detach_domain(domains));
      } catch (error) {
        console.log("ImagesList.jsx:48 ~ deleteRecordHandler ~ error", error);
        // toast.error("Template Image couldn't be deleted");
      }
    }
  };

  const handleConfirm = async () => {
    if (selectedData?.length > 1) {
      return toast.error(
        "Please select only 1 domain for creating testing domain."
      );
    }
    const c = window.confirm(
      `Are you sure you want to mark these domains as test domains?`
    );
    if (!c) {
      return;
    }
    try {
      const domainValues = selectedData.map((item) => item.domain);
      const res = await dispatch(
        set_test_domains({ domains: domainValues, is_test_domain: 1 })
      );
      if (res.payload.status === 200) {
        setSelectedData([]);
        toast.success("Domains testing status updated");
      } else {
        toast.error(res.payload || "Some error occurred");
      }
    } catch (error) {
      toast.error("Some error occurred");
      console.log("🚀 ~ file: index.jsx:163 ~ setTestDomains ~ error:", error);
    }
  };

  const checkDomains = async()=>{
    try {
      const res = await deployApi.get(`/api/helpers/check_domains/${domain_count}`)
      if (res.payload.status === 200) {
        setIsDomainCheck(false);
        setDomain_count('')
        toast.success("Checking Domains process started");
      } else {
        toast.error(res.payload || "Some error occurred");
      }
    } catch (error) {
      toast.error("Some error occurred");
      console.log("🚀 ~ file: index.jsx:163 ~ setTestDomains ~ error:", error);
    }
  }
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const onFilterChange = React.useCallback((filterModel) => {
    // Here you save the data you need from the filter model
    let ruless = [];
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `cpd.${
            rule.field === "industry_name"
              ? "industry_id"
              : rule.field === "template_name"
              ? "template_id"
              : rule.field
          }`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator,
          data: rule.value ? rule.value : null,
        },
      ];
    });
    // ?.toLowerCase()?.includes(searchInput?.toLowerCase())
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
  }, []);

  // const customOperators = [
  //   {
  //     value: "isEmpty",
  //     label: "is empty",
  //     getApplyFilterFn: (filterItem, column) => {
  //       return (params) =>
  //         String(params.value)
  //           .toLowerCase()
  //           .includes(String(filterItem.value).toLowerCase());
  //     },
  //   },
  //   {
  //     value: "isNotEmpty",
  //     label: "is not empty",
  //     getApplyFilterFn: (filterItem, column) => {
  //       console.log("🚀 ~ file: index.jsx:255 ~ DomainsList ~ column:", column);
  //       return (params) =>
  //         String(params.value)
  //           .toLowerCase()
  //           .includes(String(filterItem.value).toLowerCase());
  //     },
  //   },
  //   // Add more custom operators as needed
  // ];
  const customOperatorsYesorNo = [
    {
      value: "yes",
      label: "Yes",
      getApplyFilterFn: (filterItem, column) => {
        return (params) =>
          String(params.value)
            .toLowerCase()
            .includes(String(filterItem.value).toLowerCase());
      },
    },
    {
      value: "no",
      label: "No",
      getApplyFilterFn: (filterItem, column) => {
        console.log("🚀 ~ file: index.jsx:255 ~ DomainsList ~ column:", column);
        return (params) =>
          String(params.value)
            .toLowerCase()
            .includes(String(filterItem.value).toLowerCase());
      },
    },
    // Add more custom operators as needed
  ];
  const columnDefs = [
    {
      field: "ID",
      hide: true,
      renderCell: (params) => (
        <div className="flex justify-center items-center">
          <div
            className="cursor-pointer mt-2"
            onClick={() => onSingleselect(params.row.records)}
          >
            <input
              type="checkbox"
              checked={isChecked(params.row.id)}
              // className="mr-1"
              className={`form-checkbox h-4 w-4 text-[#6366F1] rounded focus:ring-0 cursor-pointer mr-2`}
            />
          </div>
          <div className="flex items-center w-full">
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon cursor-pointer"
              size={20}
            />
          </div>
        </div>
      ),
      width: 80,
      headerName: "",
      filterable: false,
    },
    {
      field: "counter",
      headerName: "#",
      type: "string",
      flex: 1,
      width: 60,
      filterable: false,
    },
    {
      field: "domain",
      headerName: "Domain",

      renderCell: (params) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.value}`}
          >
            {params?.value}
          </a>
        );
      },
      flex: 1,
      minWidth: 150,
    },
    {
      field: "industry_name",
      headerName: "Industry",
      type: "singleSelect",
      flex: 1,
      minWidth: 100,
      getOptionValue: (value) => value?.id,
      getOptionLabel: (value) => value.industry_name,
      valueOptions: industries,
      renderCell: (params) => params.value.industry_name,
      valueGetter: (params) => params.row.industry_name,
      valueFormatter: (params) => params.value.industry_name,
    },
    {
      field: "runner_name",
      headerName: "Runner Name",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "phone",
      headerName: "Phone",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "city",
      headerName: "City",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "county",
      headerName: "County",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "state",
      headerName: "State",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "zip_counts",
      headerName: "Zip Count",
      type: "number",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "is_test_domain",
      headerName: "Test Domain",
      type: "string",
      flex: 1,
      minWidth: 100,
      filterOperators: customOperatorsYesorNo,
    },
    {
      field: "cloudflare",
      headerName: "Cloudflare Email",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "cloudflare_zone_id",
      headerName: "Cloudflare Zone ID",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "deployed_on_host",
      headerName: "Deployed on",
      type: "string",
      flex: 1,
      minWidth: 100,
    },

    {
      field: "google_search_console_credential",
      headerName: "Google Credential",
      type: "string",
      flex: 1,
      minWidth: 100,
      filterOperators: customOperatorsYesorNo,
    },
    {
      field: "is_detached",
      headerName: "Detached",
      type: "string",
      flex: 1,
      minWidth: 100,
      filterOperators: customOperatorsYesorNo,
    },
  ];

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton ref={setFilterButtonEl} />
        <Button
          variant="text"
          onClick={handleNewFilter}
          startIcon={<Search />}
          sx={{ fontSize: "0.8125rem" }}
        >
          Apply filter
        </Button>
        <Button
          variant="text"
          onClick={() => {
            if (selectedData?.length > 0) {
              setArnModal(true);
              setDeployeOn("generate_arn");
            } else {
              alert("Please select domains");
            }
          }}
          startIcon={<DialpadOutlined />}
          sx={{ fontSize: "0.8125rem" }}
        >
          Generate ARN
        </Button>
        <Button
          variant="text"
          onClick={() => {
            if (selectedData?.length > 0) {
              setArnModal(true);
              setDeployeOn("deploy_on_new_server");
            } else {
              alert("Please select domains");
            }
          }}
          startIcon={<DialpadOutlined />}
          sx={{ fontSize: "0.8125rem" }}
        >
          Deploy on server
        </Button>
        <Button
          variant="text"
          onClick={exportData2}
          startIcon={<ImportExportOutlined />}
          sx={{ fontSize: "0.8125rem" }}
        >
          Export
        </Button>
        <Button
          variant="text"
          onClick={addDomain}
          startIcon={<GiBreakingChain color="#1976d2" />}
          sx={{ fontSize: "0.8125rem" }}
        >
          Add to detach
        </Button>
        <Button
          variant="text"
          onClick={() => setAddDomainModal(true)}
          startIcon={<GiBreakingChain color="#1976d2" />}
          sx={{ fontSize: "0.8125rem" }}
        >
          Add New Domain
        </Button>
        <Button
          variant="text"
          onClick={() => {
            if (selectedData?.length > 0) {
              setCloudFlareModal(true);
              setDeployeOn("deploy_on_new_server");
            } else {
              alert("Please select domains");
            }
          }}
          startIcon={<DialpadOutlined />}
          sx={{ fontSize: "0.8125rem" }}
        >
          Update cloudflare
        </Button>
        <Button
          variant="text"
          onClick={() => {
            if (selectedData?.length > 0) {
              setGoogleConsole(true);
            } else {
              alert("Please select domains");
            }
          }}
          startIcon={<Google fontSize="0.6rem" />}
          sx={{ fontSize: "0.8125rem" }}
        >
          Add to Google Console
        </Button>
      </GridToolbarContainer>
    );
  }
  const FilterButton = ({ onClick }) => {
    return (
      <Button variant="contained" color="primary" onClick={onClick}>
        Filters
      </Button>
    );
  };
  const CustomFilterForm = (params) => {
    const { applyFilter, resetFilterButton } = params;
    const handleFilterClick = () => {
      applyFilter();
    };
    return (
      <div>
        <div>{/* render your custom filter form here */}</div>
        <div>
          {/* use the custom FilterButton component */}
          <FilterButton onClick={handleFilterClick} />
          {/* use the default reset filter button component */}
          {resetFilterButton}
        </div>
      </div>
    );
  };
  React.useEffect(() => {
    dispatch(
      get_domain_list({
        page: +paginationModel.page,
        size: paginationModel.pageSize,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });

    dispatch(
      get_domain_list({
        filters: JSON.stringify(queryOptions),
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });

    dispatch(
      get_domain_list({
        filters: JSON.stringify(queryOptions),
        page: 1,
        size: paginationModel.pageSize,
      })
    );
  };
  const exportData2 = async () => {
    const data = domainList?.records;
    const currentDate = new Date();
    const rows = [
      ["Export Date:", dayjs(currentDate).format("ddd, MMM D, YYYY h:mm A")],
      [
        "Sr.",
        "Domains",
        "Industry",
        "runner_name",
        "Phone",
        "City",
        "County",
        "State",
        "Zip Count",
        "Test Domain",
        "Detach",
        "Cloudflare Email",
        "Cloudflare Zone ID",
        "Deployed on",
        "Google Credential",
      ], // Add headings
    ];
    data.forEach((item, index) => {
      const {
        domain,
        industry_name,
        phone,
        city,
        state,
        zip_counts,
        cloudflare_zone_id,
        county,
        deployed_on_host,
        runner_name,
        cloudflare,
      } = item;
      const counter = index + 1;
      const is_detached = item.is_detached ? "Yes" : "No";
      const is_test_domain = item.is_test_domain ? "Yes" : "No";
      const google_search_console_credential =
        item?.google_search_console_credential ? "Yes" : "No";
      const row = [
        counter,
        domain,
        industry_name,
        runner_name,
        phone,

        city,
        county,
        state,
        zip_counts,

        is_test_domain,
        is_detached,
        cloudflare?.auth_email,
        cloudflare_zone_id,

        deployed_on_host,
        google_search_console_credential,
      ];
      rows.push(row);
    });
    const worksheet = XLSX.utils.aoa_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const fileName = "Domains List.xlsx";
    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });

    if (typeof window.navigator.msSaveBlob !== "undefined") {
      // For IE browser
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    }
  };
  return (
    <div className="h-[100vh] w-full overflow-y-auto ">
      {addDomainModal && (
        <AddDomainModal
          onCloseModal={onCloseModal}
          editingRecord={editingRecord}
          handleNewFilter={handleNewFilter}
        />
      )}
      {templateModal && (
        <AddUpdateTemplate
          templateData={templateData}
          setTemplateData={setTemplateData}
          onCloseModal={onCloseModal}
        />
      )}
      {showImages && (
        <DomainImages
          imageList={imageList}
          setTemplateData={setTemplateData}
          onCloseModal={() => setShowImages("")}
        />
      )}
      {arnModal && selectedData?.length > 0 && (
        <ARNGenerator
          onCloseModal={() => setArnModal(false)}
          domains={selectedData}
          templates={templates}
          onSingleselect={onSingleselect}
          unSelect={() => setSelectedData([])}
          deployeOn={deployeOn}
        />
      )}

      {cloudFlareModal && selectedData?.length > 0 && (
        <SelectCloudFlare
          onCloseModal={() => setCloudFlareModal(false)}
          domains={selectedData}
          accounts={cloudfrontAccount}
          onSingleselect={onSingleselect}
          unSelect={() => setSelectedData([])}
          deployeOn={deployeOn}
          handleNewFilter={handleNewFilter}
        />
      )}
      {googleConsole && (
        <GoogleConsole
          onCloseModal={() => setGoogleConsole(false)}
          domains={selectedData}
          templates={templates}
          onSingleselect={onSingleselect}
          unSelect={() => setSelectedData([])}
          deployeOn={deployeOn}
          refreshData={() =>
            dispatch(
              get_domain_list({
                filters: JSON.stringify(queryOptions),
                page: paginationModel.page,
                size: paginationModel.pageSize,
              })
            )
          }
        />
      )}
      {isDomainCheck && (
        <CheckDomainModal
          onCloseModal={() => setIsDomainCheck(false)}
          domain_count={domain_count}
          setDomain_count={setDomain_count}
          handleConfirm={checkDomains}
        />
      )}
      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
        <div className=" !my-3">
          <h1 className="text-xl font-semibold font_pop p-1">Domains</h1>
        </div>
        {isLoading && <DotsLoader text="Please Wait" />}
        <div className="bg-white !my-3 border rounded w-full">
          <div className="px-4">
            <div className="w-full mb-3 flex flex-col md:flex-row gap-2 items-center justify-between">
              <div className="flex flex-col">
                <label>
                  <input
                    type="checkbox"
                    onChange={selectAll}
                    checked={
                      selectedData?.length === domainList?.records?.length
                        ? true
                        : false
                    }
                  />{" "}
                  Select All
                </label>
              </div>
              <div className=" mt-2 flex gap-2 itesm-center">
                <button
                  className="border rounded bg-red-600 text-white p-2"
                  onClick={()=>setIsDomainCheck(true)}
                  type="button"
                >
                  Check Domains
                </button>
                <button
                  className="border rounded bg-red-600 text-white p-2"
                  onClick={handleConfirm}
                  disabled={selectedData.length > 0 ? false : true}
                  type="button"
                >
                  Set Test Domains
                </button>
                <RecordSearch />
                <button
                  className="bg-black text-white p-1 rounded text-xl px-2"
                  onClick={() => setGridActive(!gridActive)}
                >
                  {!gridActive ? <FaBorderAll /> : <FaListUl />}
                </button>
              </div>
            </div>
            {gridActive ? (
              <Box
                sx={{
                  height: "calc(100vh - 125px)",
                  width: "100%",
                  "& .actions": {
                    color: "text.secondary",
                  },
                  "& .textPrimary": {
                    color: "text.primary",
                  },
                  border: 1,
                  borderColor: "grey.400",
                }}
              >
                <DataGridPro
                  rows={
                    domainList?.records?.length > 0
                      ? domainList?.records?.map((record, index) => {
                          let {
                            id,
                            domain,
                            industry_name,
                            state,
                            phone,
                            city,
                            zip_counts,
                            cloudflare_zone_id,
                            county,
                            runner_name,
                            deployed_on_host,
                          } = record;
                          let counter = index + 1;
                          let is_detached = record.is_detached ? "Yes" : "No";
                          let google_search_console_credential =
                            record?.google_search_console_credential
                              ? "Yes"
                              : "No";
                          const is_test_domain = record.is_test_domain
                            ? "Yes"
                            : "No";
                          const cloudflare = record?.cloudflare?.auth_email;
                          return {
                            records: record,
                            counter,
                            id,
                            domain,
                            state,
                            city,
                            zip_counts,
                            cloudflare_zone_id,
                            phone,
                            is_detached,
                            industry_name,
                            county,
                            is_test_domain,
                            google_search_console_credential,
                            deployed_on_host,
                            runner_name,
                            cloudflare,
                          };
                        })
                      : {}
                  }
                  getRowId={(row) => row?.counter}
                  columns={columnDefs}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  rowHeight={40}
                  columnHeaderHeight={36}
                  renderFilterForm={(params) => (
                    <CustomFilterForm {...params} />
                  )}
                  pagination
                  paginationModel={{
                    page: +paginationModel.page - 1,
                    pageSize: +paginationModel.pageSize,
                  }}
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                  rowCount={domainList?.totalItems}
                  pageSizeOptions={[
                    100,
                    200,
                    500,
                    1000,
                    1500,
                    3000,
                    domainList?.totalItems ? domainList?.totalItems : 5000,
                  ]}
                  onPaginationModelChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  paginationMode="server"
                />
              </Box>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DomainsList;
