import React, { useState } from "react";
import { FaEdit, FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import DotsLoader from "../../components/atoms/DotsLoader";
import { get_industries } from "../../redux/industriesSlice";
import { get_nation_site_zips } from "../../redux/serverSlice";
import AddUpdateZips from "./AddUpdateZips";
import ImportExcel from "./ImportData";

function NationSiteZips() {
  const { isLoading, servers, nationZips } = useSelector(
    (state) => state.servers
  );
  console.log(
    "🚀 ~ file: index.jsx:11 ~ NationSiteZips ~ nationZips:",
    nationZips
  );

  const { industries } = useSelector((state) => state.industries);
  const [isAddZip, setIsAddZip] = useState(false);
  const [isImportModal, setIsImportModal] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [filterText, setFilterText] = React.useState("");
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(get_industries());
  }, []);
  const openFormHandler = (record) => {
    setEditingRecord(record);
    setIsAddZip(true);
  };

  const zipCodes = nationZips[0]?.zips?.filter((zip) => zip === filterText);
  return (
    <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
      {isLoading && <DotsLoader text="Please Wait" />}
      {isAddZip ? (
        <AddUpdateZips
          modalTitle="Add/Update Zips"
          onCancelForm={() => setIsAddZip(false)}
          editingRecord={editingRecord}
          getData={get_nation_site_zips}
        />
      ) : null}
      {isImportModal ? (
        <ImportExcel
          modalTitle="Import Zips"
          onCancelForm={() => setIsImportModal(false)}
          editingRecord={editingRecord}
          getData={get_nation_site_zips}
        />
      ) : null}
      <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
        <div className="flex flex-roe">
          <h1 className="text-2xl font-extrabold mr-2">Nation Site Zips</h1>
        </div>
        <div>
        <button
          className="border rounded bg-red-600 text-white p-2"
          onClick={() => setIsImportModal(true)}
          type="button"
        >
          Import Zips
        </button>
        <button
          className="border rounded bg-red-600 text-white p-2"
          onClick={() => setIsAddZip(true)}
          type="button"
        >
          Add Zips
        </button>
        </div>
        
      </div>

      <div className="bg-white !my-3 p-3 border rounded ">
        <div className="items-center !m-2 bg-slate-50 border rounded p-3">
          <div className="flex flex-row items-center gap-x-4">
            <div className="w-full">
              <select
                // value={data.industry_id}
                onChange={(e) => dispatch(get_nation_site_zips(e.target.value))}
                className="border p-1 rounded h-[35px] ml-2 w-ful"
              >
                <option value="">Select Industry</option>
                {industries.map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                    className="flex justify-between"
                  >
                    {option.industry_name}
                  </option>
                ))}
              </select>
            </div>
            {/* <div className="relative w-full">
              {nationZips[0]?.industry_id && nationZips?.length > 0 ? (
                <>
                  <input
                    className={
                      "shadow appearance-none border rounded w-full !py-2 !pr-3 pl-14 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-sm border w-full !pl-15 h-[31px]"
                    }
                    style={{ minHeight: 35 }}
                    placeholder="Search"
                    value={filterText}
                    onChange={(e) => {
                      setFilterText(e.target.value);
                    }}
                  />
                  <div className="absolute top-[5px] left-4 border-r border-[#dee2e6] pr-2.5 flex justify-center items-center h-[25px]">
                    <FaSearch />
                  </div>
                </>
              ) : null}
            </div> */}
          </div>
        </div>
        {nationZips[0]?.industry_id && nationZips?.length > 0 ? (
          nationZips?.map((item, index) => {
            return (
              <details key={index} className="border mt-3 mb-3" open>
                <summary className="flex !flex-row w-full items-center text-normal px-2 font-semibold font_pop justify-between border-b hover:cursor-pointer bg-gray-100 hover:bg-gray-300 py-2 px-1">
                  <span className="text-normal px-2 font-semibold font_pop">{`${
                    industries?.find(({ id }) => id === item?.industry_id)
                      ?.industry_name
                  } | Total Zips: ${item.zips?.length}`}</span>
                  <FaEdit
                    className="cursor-pointer"
                    onClick={(event) => {
                      event.stopPropagation();
                      openFormHandler(item);
                    }}
                  />
                </summary>

                <div
                  className={`form-control inline-flex flex-wrap relative min-h-[40px] max-h-[430px] h-aut overflow-y-auto !rounded-t-none`}
                >
                  {item?.zips?.map((zip, ind) => {
                    return (
                      <div
                        className="badge me-1 pe-1 badge me-1 pe-1 flex items-center justify-between bg-secondary !text-white !mr-2 my-0.5 h-[26.39px]"
                        key={ind}
                      >
                        {zip}
                      </div>
                    );
                  })}
                </div>
              </details>
            );
          })
        ) : (
          <div className="flex justify-center items-center w-full">
            <span className="text-center text-lg font_pop font-bold">
              No record found
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default NationSiteZips;
