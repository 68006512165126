import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createUser,
  deleteUser,
  
  getUsers,
  updateUser,
} from "../services";

const initialState = {
  isError: false,
  isLoading: false,
  users: [],
  message: "",
  isLoader: false,
};
// Get Users Data
export const get_users = createAsyncThunk(
  "get_users",
  async (data, thunkAPI) => {
    try {
      return await getUsers(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create New User
export const create_user = createAsyncThunk(
  "create_user",
  async (data, thunkAPI) => {
    try {
      return await createUser(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update User
export const update_user = createAsyncThunk(
  "update_user",
  async (data, thunkAPI) => {
    try {
      return await updateUser(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete User
export const delete_user = createAsyncThunk(
  "delete_user",
  async (id, thunkAPI) => {
    try {
      return await deleteUser(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.users = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_users.pending, (state) => {
        state.isLoading = true;
        state.isLoader = true;
        state.users = [];
      })
      .addCase(get_users.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.users = action.payload?.data;
      })
      .addCase(get_users.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.users = [];
      })
      .addCase(create_user.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_user.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users.push(action.payload.data);
      })
      .addCase(create_user.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_user.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_user.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.users.findIndex(
          ({ id }) => id === action?.payload?.data?.id
        );
        state.users[result] = {
          ...state.users[result],
          ...action.payload.data,
        };
      })
      .addCase(update_user.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_user.fulfilled, (state, action) => {
        const result = state.users.findIndex(
          ({ id }) => id === action?.payload?.data?.id
        );
        state.users[result].active = false;
      });
  },
});

export const { reset } = usersSlice.actions;
export default usersSlice.reducer;
