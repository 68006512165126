import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DotsLoader from "../../components/atoms/DotsLoader";
import dayjs from "dayjs";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";
import {
  generate_domain_report,
  get_domain_report,
  regenerate_domain_report,
} from "../../redux/domainsReportSlice";
import { get_industries } from "../../redux/industriesSlice";
import { HiOutlineRefresh } from "react-icons/hi";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { DialpadOutlined, FileDownloadOutlined } from "@mui/icons-material";
import SelectCloudFlare from "../Domains/SetCloudFlare";
import { cloudfront_account } from "../../redux/domainSlice";
import { deployApi } from "../../api";
import Loader from "../../components/atoms/Loader";
function DomainsReport() {
  const { cloudfrontAccount } = useSelector((state) => state.domains);
  const location = useLocation();

  const [deployeOn, setDeployeOn] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [isLoader, setIsLoader] = React.useState(false);
  const [cloudFlareModal, setCloudFlareModal] = React.useState(false);
  function CustomToolbar(props) {
    return (
      <GridToolbarContainer className="flex items-center my-1  ">
        <GridToolbarColumnsButton className="text-black btnSecondary" />
        <GridToolbarDensitySelector className="text-black btnSecondary" />
        <GridToolbarFilterButton className="text-black btnSecondary" />
        <Button
          variant="text"
          onClick={() => {
            if (selectedData?.length > 0) {
              setCloudFlareModal(true);
              setDeployeOn("deploy_on_new_server");
            } else {
              alert("Please select domains");
            }
          }}
          startIcon={<DialpadOutlined />}
          sx={{ fontSize: "0.8125rem" }}
        >
          Update cloudflare
        </Button>
      </GridToolbarContainer>
    );
  }
  const { industries } = useSelector((state) => state.industries);
  const { isLoading, domainReport } = useSelector(
    (state) => state.domainReport
  );
  const [selectedFilters, setSelectedFilters] = React.useState({
    industry_id: "",
    up_status: 0,
  });
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = React.useState("");
  const [selectedData, setSelectedData] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState("");
  const [isModalOpen, setIsModalOpen] = React.useState(null);
  const redirectTo = localStorage.getItem("redirectTo");
  useEffect(() => {
    if (location.pathname === "/domain_active_report" && redirectTo) {
      localStorage.removeItem("redirectTo");
    }
    dispatch(get_domain_report(selectedFilters));
    dispatch(get_industries());
    dispatch(cloudfront_account());
    // eslint-disable-next-line
  }, []);
  const onSingleSelect = ({ checked, data }) => {
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            (domain) => domain?.id !== data?.id
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:116 ~ onSingleSelect ~ err:", err);
    }
  };
  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = data?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (data) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter(({ domain }) => domain === data.domain).length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={selectedData?.length === data?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <>
          {isSelected(params.row.records) && isLoader ? (
            <Loader />
          ) : (
            <input
              type="checkbox"
              checked={isSelected(params.row.records)}
              onChange={(e) =>
                onSingleSelect({
                  checked: e.target.checked,
                  data: params.row.records,
                })
              }
              className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
            />
          )}
        </>
      ),
      disableColumnMenu: true,
    },
    { headerName: "#", field: "counter", width: 60 },
    {
      headerName: "Domain",
      field: "domain",
      renderCell: (params) => {
        const handleClick = (e) => {
          e.stopPropagation(); // Prevent the click event from propagating
          // Your existing code here...
        };
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.value}`}
            onClick={handleClick}
          >
            {params?.value}
          </a>
        );
      },
      flex: 1,
      minWidth: 130,
    },
    { headerName: "Runner", field: "runner_name", width: 80 },
    {
      headerName: "Cloudflare account",
      field: "auth_email",
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: "Cloudflare Zone",
      field: "cloudflare_zone_id",
      flex: 1,
      minWidth: 120,
    },

    {
      headerName: "Active",
      field: "up_status",
      width: 60,
      align: "center",
      headerAlign: "center",
    },

    {
      headerName: "Status Code",
      field: "status_code",
      width: 100,
      align: "center",
      headerAlign: "center",
    },

    { headerName: "Method", field: "method", width: 100 },
    {
      headerName: "Last Checked Date",
      field: "last_checked_date",
      flex: 1,
      minWidth: 100,
    },
  ];
  const data = domainReport?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  const SingleSelectRenderer = () => {
    return (
      <div className="flex flex-row justify-between">
        <select
          value={selectedFilters.industry_id}
          onChange={(e) => {
            setSelectedFilters({
              ...selectedFilters,
              industry_id: e.target.value,
            });
            dispatch(
              get_domain_report({
                ...selectedFilters,
                industry_id: e.target.value,
              })
            );
          }}
          className="border p-1 rounded h-[35px] ml-2"
        >
          <option value="">Select Industry</option>
          {industries.map((option) => (
            <option
              key={option.id}
              value={option.id}
              className="flex justify-between"
            >
              {option.industry_name}
            </option>
          ))}
        </select>
        <select
          value={selectedFilters.up_status}
          onChange={(e) => {
            setSelectedFilters({
              ...selectedFilters,
              up_status: e.target.value,
            });
            dispatch(
              get_domain_report({
                ...selectedFilters,
                up_status: e.target.value,
              })
            );
          }}
          className="border p-1 rounded h-[35px] ml-2 w-[250px]"
        >
          <option value="">Active</option>
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </select>
        <button
          className="bg-red-600 text-white ml-2 h-[35px] w-[35px] flex justify-center items-center rounded"
          onClick={() => dispatch(get_domain_report(selectedFilters))}
        >
          <HiOutlineRefresh />
        </button>
      </div>
    );
  };

  function CustomModal({ isOpen, onClose }) {
    const domains = selectedData?.map(({ domain }) => domain);
    const action =
      isOpen === "regenerate_domain_report"
        ? regenerate_domain_report
        : generate_domain_report;
    const payload =
      isOpen === "regenerate_domain_report"
        ? { domains: domains, method: selectedOption }
        : { method: selectedOption };
    const handleConfirm = () => {
      // Perform the action here with the selectedOption
      if (!selectedOption) {
        return toast.error("Please select a method");
      }
      dispatch(action(payload));
      // Close the modal
      onClose();
    };

    return (
      <>
        {isOpen && (
          <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg">
              <h2 className="text-lg font-semibold mb-4">Confirmation</h2>
              <p className="mb-4">
                Are you sure you want to start the domain checking process?
              </p>
              <label className="block mb-4">
                Select an option:
                <select
                  className="w-full p-2 border border-gray-300 rounded"
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  <option value="">Select Method</option>
                  <option value="http://">http://</option>
                  <option value="https://">https://</option>
                  <option value="http://www">http://www</option>
                  <option value="https://www">https://www</option>
                </select>
              </label>
              <div className="flex justify-end">
                <button
                  className="px-4 py-2 mr-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  onClick={handleConfirm}
                >
                  OK
                </button>
                <button
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  const onSingleselect = (val) => {
    if (selectedData?.length > 0) {
      if (
        selectedData.filter(({ domain }) => domain === val?.domains)?.length > 0
      ) {
        const arr = selectedData.filter(
          ({ domain }) => domain !== val?.domains
        );
        setSelectedData(arr);
      } else {
        setSelectedData([...selectedData, val]);
      }
    } else {
      setSelectedData([...selectedData, val]);
    }
  };
  const handleUpdateCloudFlare = async (type) => {
    if (selectedData?.length === 0)
      return toast.error("Please select the domains");
    const c = window.confirm(
      type === "update"
        ? "Are you sure want to update cloudflare status for selected domains?"
        : "Are you sure want to delete selected domains from cloudflare?"
    );
    if (!c) return;
    setLoader(true);
    const domains = selectedData?.map(({ domain }) => domain);
    try {
      const res = await deployApi.post(
        `/api/data/update_cloudflare_status`,
        {
          domains: domains,
        },
        { params: { deleteFromCloudflare: type === "delete" ? true : false } }
      );
      if (res.status === 200) {
        toast.success(
          type === "update"
            ? "Cloudflare status updated"
            : "Domains deleted from cloudflare "
        );
      } else {
        toast.error(
          type === "update"
            ? "Cloudflare status couldn't be updated"
            : "Domains couldn't be deleted from cloudflare "
        );
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      toast.error(
        type === "update"
          ? "Cloudflare status couldn't be updated"
          : "Domains couldn't be deleted from cloudflare "
      );
      console.log("🚀 ~ handleUpdateCloudFlare ~ err:", err);
    }
  };
  async function setLBServer() {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (selectedData?.length === 0) {
      return toast.error("Please select the server");
    }
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    const runners = [
      ...new Set(
        selectedData
          ?.map((item) => item?.runner_name)
          .filter((name) => name !== null)
      ),
    ];
    setIsLoader(true);
    const selectedDataCopy = [...runners];
    for (const item of selectedDataCopy) {
      try {
        const response = await deployApi.get(
          `https://${item}.ecommcube.com/api/lb_server/setAllDomains`
        );

        if (response.status === 200) {
          toast.success(`${response?.data?.message} for ${item}`);
          setSelectedData((prevState) =>
            prevState.filter(({ runner_name }) => runner_name !== item)
          );
        } else {
          toast.error(`${response.data?.message} for ${item}`);
        }
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
        toast.error(`${error?.data?.message} for ${item}`);
      }
    }

    setIsLoader(false);
    // Clear the selectedData after processing all requests
    setSelectedData([]);
  }
  const handleMoveDomains = async () => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    setLoader(true);
    try {
      const res = await deployApi.get(
        `https://domainchecker.ecommcube.com/api/domain_checker/create_table`
      );
      if (res.status === 200) {
        toast.success("Domains moved successfully");
      } else {
        toast.error("Domains couldn't be moved successfully");
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      toast.error("Domains couldn't be moved successfully");
      console.log("🚀 ~ handleMoveDomains ~ err:", err);
    }
  };
  return (
    <>
      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
        {isLoading || loader ? <DotsLoader text="Please Wait" /> : null}
        <CustomModal
          isOpen={isModalOpen}
          onClose={() => {
            setSelectedOption("");
            setIsModalOpen(null);
          }}
          // onConfirm={dispatch(regenerate_domain_report({ domains: selectedData }))}
        />
        {cloudFlareModal && selectedData?.length > 0 && (
          <SelectCloudFlare
            onCloseModal={() => setCloudFlareModal(false)}
            domains={selectedData}
            accounts={cloudfrontAccount}
            onSingleselect={onSingleselect}
            unSelect={() => setSelectedData([])}
            deployeOn={deployeOn}
            handleNewFilter={() => dispatch(get_domain_report(selectedFilters))}
            crossIcon={"No"}
          />
        )}
        <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
          <h1 className="text-2xl font-extrabold">Domains Active Report</h1>
          <div className="space-x-2">
          <button
              className="border rounded bg-red-600 text-white p-2"
              onClick={handleMoveDomains}
            >
              Move Domains
            </button>
            <button
              className="border rounded bg-red-600 text-white p-2"
              onClick={setLBServer}
            >
              Set LB server
            </button>
            <button
              className="border rounded bg-red-600 text-white p-2"
              onClick={() => {
                handleUpdateCloudFlare("update");
              }}
            >
              Update cloudflare status
            </button>
            <button
              className="border rounded bg-slate-900 text-white p-2"
              onClick={() => {
                handleUpdateCloudFlare("delete");
              }}
            >
              Delete from cloudflare
            </button>
            <button
              className="border rounded bg-red-600 text-white p-2"
              onClick={() => {
                const c = window.confirm("Have you moved the new purchased domains?")
                if (!c) return 
                setIsModalOpen("generate_domain_report");
              }}
            >
              Start Testing for All Domains
            </button>
            <button
              className="border rounded bg-red-600 text-white p-2"
              onClick={() => {
                setIsModalOpen("regenerate_domain_report");
              }}
              disabled={selectedData?.length === 0}
            >
              Recheck selected Domains
            </button>
          </div>
        </div>

        <div className="bg-white !my-3 border rounded">
          <MuiGrid
            columnDefs={columnDefs}
            items={data?.map((record, index) => {
              let counter = index + 1;
              let up_status = record.up_status ? "Yes" : "No";
              let last_checked_date = record.last_checked_date
                ? dayjs(record?.last_checked_date).format(
                    "ddd, MMM D, YYYY h:mm A"
                  )
                : null;
              let domain = record.domain;
              let method = record.method;
              let status_code = record.status_code;
              const { runner_name, auth_email, cloudflare_zone_id } = record;
              return {
                records: { ...record, id: domain },
                counter,
                up_status,
                last_checked_date,
                domain,
                method,
                status_code,
                runner_name,
                auth_email,
                cloudflare_zone_id,
              };
            })}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            CustomComponent={SingleSelectRenderer}
            customToolbar={CustomToolbar}
            pagination="No"
          />
        </div>
      </div>
    </>
  );
}

export default DomainsReport;
