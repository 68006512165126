import { deployApi } from "../api";

export const addBulkImages = async (data) => {
  return await deployApi.post("/api/templates/upload_image", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const editBulkImages = async (data) => {
  return await deployApi.put("api/templates/edit_image", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const getIndustriesByTemp = async (template_name) => {
  return await deployApi.get(`api/template-images/industry/${template_name}`);
};
export const getfoldersByIndustry = async (template_name, industry_name) => {
  return await deployApi.get(
    `api/template-images/image_type/${template_name}/${industry_name}`
  );
};

export const getImagesByType = async (
  template_name,
  industry_name,
  image_type
) => {
  return await deployApi.get(
    `api/template-images/image_path/${template_name}/${industry_name}/${image_type}`
  );
};

export const deleteBulkImg = async (id) => {
  return await deployApi.post(`api/templates/delete_template_image`, {
    ids: id,
  });
};

export const deleteByFolderName = async (payload) => { 
  return await deployApi.delete(
    `/api/templates/delete_template_image_by_folderName`,
    {data:payload}
  );
};

export const getServices = async (industryId) => {
  return await deployApi.get(`api/industries/get_services/${industryId}`);
};
export const getBlogs = async (industryId) => {
  return await deployApi.get(`api/industries/get_blogs/${industryId}`);
};
export const copyImages = async (data) => {
  return await deployApi.post(`/api/templates/copy_images`, data);
};
