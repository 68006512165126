import React, { useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/molecules/Modal";
import { copy_images, get_image_types } from "../../redux/bulkImagesSlice";
import DotsLoader from "../../components/atoms/DotsLoader";
import { get_industries } from "../../redux/industriesSlice";
import { get_templates } from "../../redux/templateImagesSlice";
import {  useParams } from "react-router-dom";
import { ReactMultiSelect } from "../../components/atoms/MultiReactSelect";

function CopyImages({
  onCancelForm,
  modalTitle,
  selectedData,
  setSelectedData,
}) {
  const dispatch = useDispatch();
  const { templates } = useSelector((state) => state.images);
  const { industries } = useSelector((state) => state.industries);
 
  const params = useParams();
  
  const { tempId, industryId } = params;

  const { isLoading, folders } = useSelector((state) => state.bulkImages);

  const [folderOptions, setFolderOption] = React.useState([]);

  let initialData = {
    old_template_id: templates?.find(
      ({ template_name }) => template_name === tempId
    )?.id,
    old_industry_id: industries?.find(
      ({ industry_name }) => industry_name === industryId
    )?.id,
    image_type: "",
    new_template_id: "",
    new_industry_id: "",
  };

  const [data, setData] = useState(initialData);
 

  const onSubmit = async () => {
    let image_types=[]
    selectedData?.map((type)=>{
image_types=[...image_types,type.value]
    })
    console.log({...data, image_type:image_types.toString()});
    try {
      const res = await dispatch(copy_images({...data, image_type:image_types.toString()}));
      if (res.payload.status === 201) {
      
        // onCancelForm()
      }
    } catch (error) {
      console.log("~ file: CopyImages.jsx:111 ~ onSubmit ~ error", error);
    }
  };
  React.useEffect(() => {
    if (folders?.length > 0) {
      let arr = [];
      const uniqueArray = Array.from(new Set(folders)).filter(
        (item) => item !== ""
      );
      // eslint-disable-next-line
      uniqueArray?.map((val) => {
        arr = [...arr, { label: val, value: val }];
        setFolderOption(arr);
      });
    }
  }, [folders]);

  React.useEffect(() => {
    if (data.old_industry_id && data.old_template_id) {
      dispatch(
        get_image_types({
          template_name: templates?.find(
            ({ id }) => id === +data?.old_template_id
          )?.template_name,
          industry_name: industries?.find(
            ({ id }) => id === +data?.old_industry_id
          )?.industry_name,
        })
      );
    }
    // eslint-disable-next-line
  }, [data.old_industry_id, data.old_template_id]);

  React.useEffect(() => {
    if (industries.length === 0 || !industries) {
      dispatch(get_industries());
    }
    if (templates.length === 0 || !templates) {
      dispatch(get_templates());
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (tempId && templates) {
        setData({
          ...data,
          old_template_id: templates?.find(
            ({ template_name }) => template_name === tempId
          )?.id,
        });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates]);

  React.useEffect(() => {
    
    if (industryId && industries) {
      setData({
        ...data,
        old_industry_id: industries?.find(
          ({ industry_name }) => industry_name === industryId
        )?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industries]);

  React.useEffect(() => {
    if (!isLoading) {
      setSelectedData((prevState) =>
        prevState.filter((item) => folders.includes(item.value))
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folders]);
  return (
    <Modal
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      {isLoading && <DotsLoader />}
      <div className=" ">
        <div className="container-fluid py-3 w-full flex flex-row justify-center">
          <div className="w-1/2 !mr-2 bg-white p-2 border rounded">
            <h1 className="text-xl !mb-1 font_pop font-semibold">
              Tranfer From
            </h1>
            <Select
              onChange={(e) => {
                setData({
                  ...data,
                  old_template_id: e.id,
                  new_template_id:templates.find(({template_name})=>template_name===e.template_name?.replaceAll("Next-11","Next-13"))?.id
                });
              }}
              name={"old_templates"}
              options={templates}
              getOptionLabel={(options) => options.template_name}
              getOptionValue={(options) => options.id}
              placeholder={"Select Template"}
              className="!mb-3"
              menuPosition="fixed"
              defaultValue={
                data.old_template_id
                  ? {
                      id: data?.old_template_id,
                      template_name: templates?.find(
                        ({ id }) => id === data?.old_template_id
                      )?.template_name,
                    }
                  : null
              }
              value={
                data.old_template_id
                  ? {
                      id: data?.old_template_id,
                      template_name: templates?.find(
                        ({ id }) => id === data?.old_template_id
                      )?.template_name,
                    }
                  : null
              }
            />
            <Select
              onChange={(e) => {
                setData({
                  ...data,
                  old_industry_id: e?.id,
                  new_industry_id:e.id
                });
              }}
              name={"old_industry"}
              options={industries}
              getOptionLabel={(options) => options.industry_name}
              getOptionValue={(options) => options.id}
              placeholder="Select Industry"
              className="!mb-3"
              menuPosition="fixed"
              defaultValue={
                data.old_industry_id
                  ? {
                      id: data?.old_industry_id,
                      industry_name: industries?.find(
                        ({ id }) => id === data?.old_industry_id
                      )?.industry_name,
                    }
                  : null
              }
              value={
                data.old_industry_id
                  ? {
                      id: data?.old_industry_id,
                      industry_name: industries?.find(
                        ({ id }) => id === data?.old_industry_id
                      )?.industry_name,
                    }
                  : null
              }
            />
            <ReactMultiSelect
              options={folderOptions}
              value={selectedData}
              onChange={setSelectedData}
              labelProps={"label"}
              valueProps={"value"}
              menuPosition="fixed"
              defaultValue={
                selectedData
                  ? selectedData?.map((ele) => ({
                      value: ele,
                      label: ele,
                    }))
                  : null
              }
              placeholder="Select Types"
              className="!mt-3"
            />
          </div>
          <div className="w-1/2 !ml-2 bg-white p-2 border rounded">
            <h1 className="text-xl !mb-1 font_pop font-semibold">Tranfer To</h1>
            <Select
              onChange={(e) => {
                setData({
                  ...data,
                  new_template_id: e.id
                });
              }}
              name={"new_templates"}
              options={templates}
              getOptionLabel={(options) => options.template_name}
              getOptionValue={(options) => options.id}
              placeholder={"Select Template"}
              className="!mb-3"
              menuPosition="fixed"
              value={
                data.new_template_id
                  ? {
                      id: data?.new_template_id,
                      template_name: templates?.find(
                        ({ id }) => id === data?.new_template_id
                      )?.template_name,
                    }
                  : null
              }

            />
            <Select
              onChange={(e) => {
                setData({
                  ...data,
                  new_industry_id: e?.id
                });
              }}
              name={"new_industries"}
              options={industries}
              getOptionLabel={(options) => options.industry_name}
              getOptionValue={(options) => options.id}
              placeholder="Select Industry"
              className="!mb-3"
              menuPosition="fixed"
              value={
                data.new_industry_id
                  ? {
                      id: data?.new_industry_id,
                      industry_name: industries?.find(
                        ({ id }) => id === data?.new_industry_id
                      )?.industry_name,
                    }
                  : null
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CopyImages;
