import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import TagsInput from "../../components/atoms/CustomTagsInput";
import DotsLoader from "../../components/atoms/DotsLoader";
import Modal from "../../components/molecules/Modal";
import { add_cloud_flare, update_cloud_flare } from "../../redux/domainSlice";
import { create_role, update_role } from "../../redux/roleSlice";

function AddUpdateCloudFlare({ editingRecord, onCancelForm, modalTitle }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.roles);
  let initialData = {
    auth_key: "",
    auth_email: "",
    is_available: "",
    account_id: "",
    token: "",
    email_password: "",
    email_phone: "",
    reconvery_email: "",
    note: "",
  };

  if (editingRecord) {
    const {
      id,
      auth_key,
      auth_email,
      is_available,
      account_id,
      token,
      email_password,
      email_phone,
      reconvery_email,
      note,
    } = editingRecord;
    initialData = {
      id,
      auth_key,
      auth_email,
      is_available,
      account_id,
      token,
      email_password,
      email_phone,
      reconvery_email,
      note,
    };
  }
  const [data, setData] = useState(initialData);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const onSubmit = async () => {
    if (!editingRecord) {
      try {
        const res = await dispatch(add_cloud_flare({ ...data }));
        if (res.payload.status === 201) {
          // toast.success("Cloud Flare added successfully");
          onCancelForm();
        }
      } catch (error) {
        console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
        // toast.error("Cloud Flare couldn't be added");
      }
    } else {
      try {
        const res = await dispatch(update_cloud_flare({ ...data }));
        if (res.payload.status === 200) {
          // toast.success("Cloud Flare updated successfully");
          onCancelForm();
        }
      } catch (error) {
        console.log("~ file: AddImage.jsx:120 ~ onSubmit ~ error", error);
        // toast.error("Cloud Flare couldn't be added");
      }
    }
  };

  return (
    <Modal
      isUpdate={editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={onSubmit}
      onClick={onCancelForm}
    >
      <div className=" ">
        <div className="container-fluid  border rounded bg-white py-3 w-full flex flex-col justify-between">
          <div className="w-full !mr-2 grid grid-cols-2 gap-x-2">
            <div className="col-span-1">
              <input
                className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
                placeholder="Auth Key"
                name="auth_key"
                value={data?.auth_key}
                onChange={handleInput}
              />
            </div>
            <div className="col-span-1">
              <input
                className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
                placeholder="Account Id"
                name="account_id"
                value={data?.account_id}
                onChange={handleInput}
              />
            </div>
            <div className="col-span-1">
              <select
                value={data.is_available}
                onChange={handleInput}
                className="border p-1 rounded h-[40px] !mt-3 w-full"
                name="is_available"
              >
                <option value={1}>Active</option>
                <option value={0}>Inactive</option>
              </select>
            </div>
          </div>
          <div className="!mt-3">
            <input
              className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="Email"
              name="auth_email"
              value={data?.auth_email}
              onChange={handleInput}
            />
          </div>
          <div className="!mt-3">
            <input
              className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="Email Password"
              name="email_password"
              value={data?.email_password}
              onChange={handleInput}
            />
          </div>
          <div className="!mt-3">
            <input
              className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="Email Phone"
              name="email_phone"
              value={data?.email_phone}
              onChange={handleInput}
            />
          </div>
          <div className="!mt-3">
            <input
              className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="Recovery Email"
              name="reconvery_email"
              value={data?.reconvery_email}
              onChange={handleInput}
            />
          </div>
          <div className="!mt-3">
            <input
              className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="Note"
              name="note"
              value={data?.note}
              onChange={handleInput}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddUpdateCloudFlare;
