import React, { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/molecules/DataTable";
import DotsLoader from "../../components/atoms/DotsLoader";
// import AddUpdateRoles from "./AddUpdateRoles";
import dayjs from "dayjs";
import AddDids from "./AddDids";
import { toast } from "react-toastify";
import { delete_did, get_dids } from "../../redux/didSlice";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";

const VendorDids = () => {
    const { isLoading, did } = useSelector((state) => state.dids);
    const dispatch = useDispatch();
    const [editingRecord, setEditingRecord] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [searchInput, setSearchInput] = React.useState("");
  
    useEffect(() => {
      dispatch(get_dids());
      // eslint-disable-next-line
    }, []);
    const cancelFormHandler = () => {
      setEditingRecord(null);
      setIsEditing(false);
    };
  
    const openFormHandler = (did) => () => {
      setEditingRecord(did);
  
      setIsEditing(true);
    };
   
    const columnDefs = [
      { headerName: "#", field: "counter", width: 100 },
      { headerName: "Phone", field: "phone", flex: 1 },
      { headerName: "Area Code", field: "area_code" ,flex: 1 ,minWidth:60},
      { headerName: "Created At", field: "created_at" ,flex: 1 ,minWidth:100},
      { headerName: "Updated At", field: "updated_at" ,flex: 1 ,minWidth:100},
      {
        field: "actions",
        flex: 1 ,minWidth:80,
        renderCell: (params) => (
          <div className="flex items-center w-full">
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon cursor-pointer"
              size={20}
            />
            <FaTrash
              onClick={() => handleDel(params.row.records.id)}
              className="my_navIcon cursor-pointer !ml-1"
              size={20}
            />
          </div>
        ),
        width: 60,
        cellStyle: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    ];
    const handleDel = async (id) => {  
      const c = window.confirm("Are you sure to perform this action?");
      if (c) {
        try {
          const res = await dispatch(delete_did(id));
          if (res.payload.status === 200) {
            toast.success("DID deleted");
            window.location.reload();
          } else {
            toast.error("DID couldn't be deleted");
          }
        } catch (error) {
          console.log("ImagesList.jsx:48 ~ deleteRecordHandler ~ error", error);
          toast.error("DID couldn't be deleted");
        }
      }
    };
    const data = did?.filter((e) => {
      return Object.keys(e)?.some(
        (key) =>
          e[key] &&
         
          e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
      );
    });
  return (
    <>
    {isEditing && (
      <AddDids
        editingRecord={editingRecord}
        modalTitle="Add DIDs"
        onCancelForm={cancelFormHandler}
      />
    )}
    <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
      {isLoading && <DotsLoader text="Please Wait" />}
      <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
      
      <h1 className="text-2xl font-extrabold">DIDs</h1>
        <button
          className="border rounded bg-red-600 text-white p-2"
          onClick={openFormHandler(0)}
        >
          Add DID
        </button>
      </div>

      <div className="bg-white !my-3 border rounded">
       
          <MuiGrid
            columnDefs={columnDefs}
            items={data?.map((record, index) => {
             
              let counter = index + 1;
              let id = record.id;
              let area_code = record.area_code;
              let phone = record.phone;
            //   let level = record.level;
              let created_at = record.created_at
                ? dayjs(record.created_at).format("ddd, MMM D, YYYY h:mm A")
                : null;
                let updated_at = record.updated_at
                ? dayjs(record.updated_at).format("ddd, MMM D, YYYY h:mm A")
                : null;
             
              return {
                records: record,
                id,
                counter,
                area_code,
                phone,
                created_at,
                updated_at,
              };
            })}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            pagination="No"
          />
      
      </div>
    </div>
  </>
  )
}

export default VendorDids