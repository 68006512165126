import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import DotsLoader from "../../components/atoms/DotsLoader";
// import { get_service_hours } from "../../redux/roleSlice";
import dayjs from "dayjs";

import { toast } from "react-toastify";
import AddHours from "./AddHours";
import { get_service_hours } from "../../redux/hoursSlice";

const ServiceHours = () => {
  const { isLoading, serviceHour } = useSelector((state) => state.hours);
  const [editingRecord, setEditingRecord] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(get_service_hours());
    // eslint-disable-next-line
  }, []);

  const newData = serviceHour.map((item) => {
    return {
      ...item,
      hours: JSON.parse(item.hours !== undefined ? item.hours : null),
    };
  });

  console.log("newdataaa", newData);

  const openFormHandler = (item) => () => {
    setEditingRecord(item);
    setShowModal(true);
  };
  function formatTime(time) {
    if (!time) {
      return "";
    }
    const [hour, minute] = time.split(":");
    const date = new Date();
    date.setHours(hour);
    date.setMinutes(minute);
    return date.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  }
  return (
    <>
      {showModal && (
        <AddHours
          editingRecord={editingRecord}
          // modalTitle="Search Log History"
          onCloseModal={() => setShowModal(false)}
        />
      )}

      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
        {isLoading && <DotsLoader text="Please Wait" />}
        <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
          <h1 className="text-2xl font-extrabold">Service Hours</h1>
          <button
            className="border rounded bg-red-600 text-white p-2"
            onClick={openFormHandler(0)}
          >
            Add Hours
          </button>
        </div>

        <div className="bg-white !my-3 border rounded p-5">
          <div class="container mx-auto">
            <div class="grid grid-cols-6 gap-5">
              {newData?.map((item) => {
                return (
                  <div className="bg-white rounded-lg overflow-hidden mr-2 shadow-md col-span-1">
                    <div className="!px-1 h-auto ">
                      <span className="flex items-end justify-between w-full py-3">
                        <span className="text-red-500 text-medium font-semibold">
                          Set ID:<span className="ml-2">{item?.id}</span>
                        </span>
                        <FaEdit
                          onClick={openFormHandler(item)}
                          className="my_navIcon cursor-pointer"
                          size={20}
                        />
                      </span>

                      {item?.hours !== undefined &&
                        item?.hours?.map(({ day, opens, closes }) => {
                          return (
                            <div className="flex flex-wrap items-start justify-between">
                              <div className="text-base font-medium px-2 !py-0.5 w-auto capitalize">
                                {day}
                              </div>
                              <div className="text-sm text-gray-700 px-2 py-1.5 w-auto text-start">
                                {`${opens} ${
                                  opens && closes && " - "
                                } ${closes}`}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceHours;
