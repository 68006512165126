import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  add_image,
  get_images_detail,
  get_templates,
  update_image,
} from "../../redux/templateImagesSlice";
import Modal from "../../components/molecules/Modal";
import { toast } from "react-toastify";
import { get_industries } from "../../redux/industriesSlice";

function AddImage({ editingRecord, onCancelForm, modalTitle }) {
  const dispatch = useDispatch();
  const { isLoading, templates, imageDetail } = useSelector(
    (state) => state.images
  );
  const { industries } = useSelector((state) => state.industries);
  const getImageDetail = async () => {
    try {
      const res = await dispatch(get_images_detail(editingRecord));
      if (res.payload.status === 200) {
        const {
          id,
          path,
          template_id,
          template_name = templates?.find(
            (temp) => temp.template_id === template_id
          )?.template_name,
          industry_id,
          image_name,
          alt,
          title,
        } = res.payload.data[0];
        setData({
          id: id?.toString(),
          template_id: template_id?.toString(),
          template_name,
          industry_id: industry_id?.toString(),
          image_name,
          alt,
          title,
        });
        setFile(path);
      } else {
        toast.error("Data couldn't be loaded");
      }
    } catch (error) {
      console.log(
        "Error ~ file: AddUpdateForm.jsx:157 ~ getJob ~ error",
        error
      );
      toast.error("Couldn't get data");
    }
  };
  useEffect(() => {
    if (editingRecord > 0) {
      getImageDetail();
    }
    // eslint-disable-next-line
  }, []);
  let initialData = {
    template_id: "",
    template_name: "",
    industry_id: "",
    image_name: "",
    alt: "",
    title: "",
  };
  const [data, setData] = useState(initialData);
  const [file, setFile] = useState();
  const onDrop = useCallback((acceptedFiles) => {
    let files = acceptedFiles;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = () => {
      let base64 = fileReader.result;
      setFile(base64);
    };
  }, []);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });
  useEffect(() => {
    dispatch(get_templates());
    dispatch(get_industries());
    // eslint-disable-next-line
  }, []);
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const onSubmit = async () => {
    if (!editingRecord) {
      try {
        const res = await dispatch(
          add_image(file.includes("base64") ? { ...data, file: file } : data)
        );
        if (res.payload.status === 201) {
          onCancelForm();
        }
      } catch (error) {
        console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
      }
    } else {
      try {
        const res = await dispatch(
          update_image(file.includes("base64") ? { ...data, file: file } : data)
        );
        if (res.payload.status === 200) {
          onCancelForm();
        }
      } catch (error) {
        console.log("~ file: AddImage.jsx:120 ~ onSubmit ~ error", error);
      }
    }
  };

  return (
    <Modal
      isUpdate={editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={onSubmit}
      isLoading={isLoading}
      onClick={onCancelForm}
    >
      <div className=" ">
        <div className="container-fluid border rounded bg-white py-3 w-full flex flex-row justify-between">
          <div className="w-1/2 !mr-2">
            <Select
              onChange={(e) => {
                setData({
                  ...data,
                  industry_id: e?.id?.toString(),
                });
              }}
              defaultValue={
                imageDetail
                  ? {
                      id: imageDetail?.industry_id?.toString(),
                      industry_name: industries?.find(
                        (temp) => temp.id === imageDetail?.industry_id
                      )?.industry_name,
                    }
                  : null
              }
              name={"industry"}
              options={industries}
              getOptionLabel={(options) => options.industry_name}
              getOptionValue={(options) => options.id}
              placeholder="Select Industry"
              value={
                data
                  ? {
                      id: data.industry_id,
                      industry_name: industries?.find(
                        (temp) => temp.id === +data?.industry_id
                      )?.industry_name,
                    }
                  : null
              }
              className="!mb-3"
            />
            <Select
              onChange={(e) => {
                setData({
                  ...data,
                  template_id: e.id?.toString(),
                  template_name: e.template_name,
                });
              }}
              defaultValue={
                imageDetail
                  ? {
                      template_id: imageDetail?.template_id?.toString(),
                      template_name: templates?.find(
                        (temp) => temp.template_id === imageDetail?.template_id
                      )?.template_name,
                    }
                  : null
              }
              name={"industry"}
              options={templates}
              getOptionLabel={(options) => options.template_name}
              getOptionValue={(options) => options.template_id}
              placeholder={"Select Template"}
              value={
                data && {
                  template_id: data.template_id?.toString(),
                  template_name: data.template_name,
                }
              }
            />
            <input
              className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="Image Name"
              name="image_name"
              value={data.image_name}
              onChange={handleInput}
            />
            <input
              className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="Title"
              name="title"
              value={data.title}
              onChange={handleInput}
            />
            <input
              className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="Alt"
              name="alt"
              value={data.alt}
              onChange={handleInput}
            />
          </div>
          <div className="w-1/2 !ml-2 h-full">
            <div
              className={`w-full flex justify-center border-2 border-gray-300 border-dashed rounded-md !p-2 hover:border-gray-400 focus:outline-none ${
                isDragActive ? "bg-gray-100" : "bg-white"
              }`}
              {...getRootProps()}
            >
              {file ? (
                <img
                  src={file}
                  className="max-h-[330px] text-center rounded-md cursor-pointer"
                  onClick={open}
                  alt="img"
                />
              ) : (
                <label
                  className={`flex justify-center w-full h-[280px] px-4 transition appearance-none cursor-pointer`}
                >
                  <span className="flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      />
                    </svg>
                    <span className="font-medium text-gray-600">
                      Drop files to Attach, or{" "}
                      <span className="text-blue-600 underline" onClick={open}>
                        browse
                      </span>
                    </span>
                  </span>
                  <input {...getInputProps()} />
                </label>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddImage;
