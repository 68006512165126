import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DotsLoader from "../../components/atoms/DotsLoader";
import dayjs from "dayjs";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";
import { get_active_domains_detail } from "../../redux/activeDomainSlice";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import Toolbar from "../../components/molecules/DataTable/Toolbar";
function ActiveDomainsDetail() {
  const { isLoading, activeDomainDetail } = useSelector(
    (state) => state.activeDomains
  );
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = React.useState("");

  useEffect(() => {
    dispatch(get_active_domains_detail(id));
    // eslint-disable-next-line
  }, []);
  function exportToExcel() {
    const data = activeDomainDetail;
    const currentDate = new Date();
    const rows = [
      ["Active Domains Detail","Export Date:", dayjs(currentDate).format("ddd, MMM D, YYYY h:mm A")],
      ["Domains", "Phone", "Hit Counter", "Marked Active On"], // Add headings
    ];

    data.forEach((item) => {
      const { domain, phone, hit_counter, marked_active_on } = item;
      rows.push([
        domain,
        phone,
        hit_counter,
        dayjs(marked_active_on).format("ddd, MMM D, YYYY h:mm A"),
      ]); // Add a row without domains
    });

    const worksheet = XLSX.utils.aoa_to_sheet(rows);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const fileName = "data.xlsx";

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    if (typeof window.navigator.msSaveBlob !== "undefined") {
      // For IE browser
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    }
  }

  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "Domain", field: "domain", flex: 1, minWidth: 300 },
    { headerName: "Phone", field: "phone", flex: 1, minWidth: 100 },
    { headerName: "Hit Counter", field: "hit_counter", flex: 1, minWidth: 100 },
    {
      headerName: "Marked Active On",
      field: "marked_active_on",
      flex: 1,
      minWidth: 100,
    },
  ];
  const data = activeDomainDetail?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  return (
    <>
      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
        {isLoading && <DotsLoader text="Please Wait" />}
        <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
          <h1 className="text-2xl font-extrabold">Active Domain Detail</h1>
        </div>
        <div className="bg-white !my-3 border rounded">
          <MuiGrid
            columnDefs={columnDefs}
            items={data?.map((record, index) => {
              let counter = index + 1;
              let id = record.id;
              let domain = record?.domain;
              let phone = record?.phone;
              let hit_counter = record?.hit_counter;
              let marked_active_on = record.marked_active_on
                ? dayjs(record?.marked_active_on).format(
                    "ddd, MMM D, YYYY h:mm A"
                  )
                : null;
              return {
                records: record,
                id,
                counter,
                domain,
                phone,
                hit_counter,
                marked_active_on,
              };
            })}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            customToolbar={Toolbar}
            componentsProps={{exportToExcel}}
            pagination="No"
          />
        </div>
      </div>
    </>
  );
}

export default ActiveDomainsDetail;
