import { deployApi } from "../api";

export const getDids = async () => {
  return await deployApi.get("api/custom_vendors/dids");
};
export const addDid = async (data) => {
  return await deployApi.post("api/custom_vendors/add_dids", data);
};
export const updateDid = async (data) => {
  return await deployApi.put(`api/custom_vendors/did/${data?.id}`, data);
};
export const deleteDid = async (id) => {
  return await deployApi.delete(`/api/custom_vendors/did/${id}`);
};
