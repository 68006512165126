import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { addInDb, getGithubRepo } from "../services";
const initialState = {
  isError: false,
  isLoading: false,
  message: "",
  repos: [],
};

// Get Github Repo
export const get_github_repo = createAsyncThunk(
  "get_github_repo",
  async (thunkAPI) => {
    try {
      return await getGithubRepo();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Add in DB
export const add_in_db = createAsyncThunk(
    "add_in_db",
    async (full_name,thunkAPI) => {
      try {
        return await addInDb(full_name);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


export const githubRepoSlice = createSlice({
  name: "github_repos",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_github_repo.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(get_github_repo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "";
        state.repos = action.payload?.data;
      })
      .addCase(get_github_repo.rejected, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.repos = [];
      })
      .addCase(add_in_db.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(add_in_db.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "";

        const result = state.repos.findIndex(
          ({ id }) => id === +action?.payload?.data?.github_id
        );
        state.repos[result] = {
          ...state.repos[result],
          is_added_in_db:true
        };
        toast.success("Template successfully added in DB")
      })
      .addCase(add_in_db.rejected, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        toast.error("Template couldn't be added in DB")

      });;
  },
});

export const { reset } = githubRepoSlice.actions;
export default githubRepoSlice.reducer;
