import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/molecules/Modal";
import DotsLoader from "../../components/atoms/DotsLoader";
import { update_domain_numbers } from "../../redux/domainSlice";
import { toast } from "react-toastify";
function UpdateNumber({ editingRecord, onCancelForm, modalTitle }) {
  console.log("🚀 ~ file: UpdateNumberModal.jsx:8 ~ UpdateNumber ~ editingRecord:", editingRecord)
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.roles);

  let initialData = [];

  if (editingRecord) {
    const rec = editingRecord?.map(({ domain, callCenter_phone }) => ({
      domain,
      phone: callCenter_phone,
    }));
    initialData = [...rec];
  }
  const [data, setData] = useState(initialData);
  const onSubmit = async () => {
    // if (!data?.phone) {
    //   return toast.error("Please select a phone number");
    // }
    // try {
    //   const res = await dispatch(update_domain_numbers(data));
    //   if (res.payload.status === 200) {
    //     onCancelForm();
    //   }
    // } catch (error) {
    //   console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
    // }

    

    // if (isLoader) {
    //   return toast.error("Please wait until the previous process is complete.");
    // }
    if (data?.length === 0) {
      return toast.error("Please select the domain");
    }
    // setIsLoader(true);
    const selectedDataCopy = [...data];
    for (const item of selectedDataCopy) {
      try {
        const res = await dispatch(update_domain_numbers(item));

        if (res.status === 200) {
          toast.success(
            `${res?.data?.message} for ${item.name} & ${item.app_folder_name}`
          );
          setData((prevState) =>
            prevState.filter(({ domain }) => domain !== item?.domain)
          );
        } else {
          toast.error(
            `${res.data?.message} for ${item.name} & ${item.app_folder_name}`
          );
        }
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
        toast.error(
          `${error?.data?.message} for ${item.name} & ${item.app_folder_name}`
        );
      }
    }

    // setIsLoader(false);

    // Clear the selectedData after processing all requests
    // setData([]);
  };
  // checked={editingRecord?.phone === data.phone}
  const isChecked = (phone) => {
    if (data?.length > 0) {
      if (data?.filter((item) => item.phone === phone).length > 0) {
        return true;
      }
    }
    return false;
  };
  return (
    <Modal
      isUpdate={editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={onSubmit}
      onClick={onCancelForm}
    >
      <div className="w-full !mr-2 grid grid-cols-2 gap-x-2 overflow-y-auto bg-gray-200 p-2 flex items-center">
        <div>
          Total:{editingRecord?.length} | Purchased: {editingRecord?.filter(({ purchased_phone }) => !!purchased_phone)?.length||0} | Phone: {editingRecord?.filter(({phone})=>!!phone)?.length||0} | Call Center: {editingRecord?.filter(({callCenter_phone})=>!!callCenter_phone && !isNaN(Number(callCenter_phone)))?.length||0}
        </div>

        <div className="col-span-1">
          <div
            className={`form-control-sm  w-full flex space-x-3 items-center pl-2 p-1 !border rounded`}
          >
            <div className="flex flex-col">
              <div
                className={`!cursor-pointer self-center flex flex-row item-center`}
              >
                <input
                  name={`phone_all`}
                  id={`phone_all`}
                  type={"radio"}
                  // checked={isChecked(item?.phone)}
                  // value={item?.phone}
                  className={`h-3.7 self-center cursor-pointer`}
                  onChange={(e) => {}}
                />
                <label
                  className="medium pl-1 w-full !mb-0 cursor-pointer"
                  htmlFor={`phone_all`}
                >
                  Select Purchased
                </label>
              </div>
            </div>
            <div className="flex flex-col">
              <div
                className={`!cursor-pointer self-center flex flex-row item-center`}
              >
                <input
                  name={`callCenter_phone_all`}
                  id={`callCenter_phone_all`}
                  type={"radio"}
                  // checked={isChecked(item?.callCenter_phone)}
                  // value={item?.callCenter_phone}
                  className={`h-3.7 self-center cursor-pointer`}
                  onChange={(e) => {}}
                />
                <label
                  className="medium pl-1 w-full !mb-0 cursor-pointer"
                  htmlFor={`callCenter_phone_all`}
                >
                 Select Phone
                </label>
              </div>
            </div>
            <div className="flex flex-col">
              <div
                className={`!cursor-pointer self-center flex flex-row item-center`}
              >
                <input
                  name={`callCenter_phone_all`}
                  id={`callCenter_phone_all`}
                  type={"radio"}
                  // checked={isChecked(item?.callCenter_phone)}
                  // value={item?.callCenter_phone}
                  className={`h-3.7 self-center cursor-pointer`}
                  onChange={(e) => {}}
                />
                <label
                  className="medium pl-1 w-full !mb-0 cursor-pointer"
                  htmlFor={`callCenter_phone_all`}
                >
                  Select Call Center
                </label>
              </div>
            </div>
          </div>
        </div>
        </div>
      {isLoading && <DotsLoader />}
      <div className="container-fluid max-w-[900px] border rounded bg-white py-3 w-full flex flex-col justify-between">
      
        <div className="w-full !mr-2 grid grid-cols-2 gap-x-2 h-[50vh] overflow-y-auto">
          {editingRecord?.map((item, index) => {
            return (
              <>
                <div className="col-span-1">
                  <input
                    className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
                    placeholder="Domain"
                    name="domain"
                    value={item.domain}
                    readOnly
                  />
                </div>
                <div className="col-span-1">
                  <div
                    className={`form-control-sm !mt-3 w-full flex space-x-3 items-center pl-2 p-1 !border rounded`}
                  >
                    <div className="flex flex-col">
                      <span className="font-semibold bg-whit w-auto">
                        Phone
                      </span>
                      <div
                        className={`!cursor-pointer self-center flex flex-row item-center`}
                      >
                        <input
                          name={`phone_${index}`}
                          id={`phone_${index}`}
                          type={"radio"}
                          checked={isChecked(item?.phone)}
                          value={item?.phone}
                          className={`h-3.7 self-center cursor-pointer`}
                          onChange={(e) => {
                            const recordIndex = data?.findIndex(
                              ({ domain }) => domain === item?.domain
                            );
                            if (recordIndex > -1) {
                              setData((prevState) => {
                                const updatedData = [...prevState];
                                updatedData[recordIndex] = {
                                  ...updatedData[recordIndex],
                                  phone: e.target.value,
                                };
                                return updatedData;
                              });
                            }
                          }}
                        />
                        <label
                          className="medium pl-1 w-full !mb-0 cursor-pointer"
                          htmlFor={`phone_${index}`}
                        >
                          {item?.phone}
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-semibold bg-white">
                        Call Center Phone
                      </span>
                      <div
                        className={`!cursor-pointer self-center flex flex-row item-center`}
                      >
                        <input
                          name={`callCenter_phone_${index}`}
                          id={`callCenter_phone_${index}`}
                          type={"radio"}
                          checked={isChecked(item?.callCenter_phone)}
                          value={item?.callCenter_phone}
                          className={`h-3.7 self-center cursor-pointer`}
                          onChange={(e) => {
                            const recordIndex = data?.findIndex(
                              ({ domain }) => domain === item?.domain
                            );
                            if (recordIndex > -1) {
                              setData((prevState) => {
                                const updatedData = [...prevState];
                                updatedData[recordIndex] = {
                                  ...updatedData[recordIndex],
                                  phone: e.target.value,
                                };
                                return updatedData;
                              });
                            }
                          }}
                        />
                        <label
                          className="medium pl-1 w-full !mb-0 cursor-pointer"
                          htmlFor={`callCenter_phone_${index}`}
                        >
                          {item?.callCenter_phone}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </Modal>
  );
}

export default UpdateNumber;
