import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addInsurance, deleteInsurance, getInsurance,   updateInsurance } from "../services/InsuranceService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  insurance:[],
  
};

export const get_insurance = createAsyncThunk(
    "get_insurance",
    async ( thunkAPI) => {
      try {
        return await getInsurance();
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const add_insurance = createAsyncThunk(
    "add_insurance",
    async ( data,thunkAPI) => {
      try {
        return await addInsurance(data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const update_insurance = createAsyncThunk(
    "update_insurance",
    async ( data,thunkAPI) => {
      try {
        return await updateInsurance(data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  export const delete_insurance = createAsyncThunk(
    "delete_insurance",
    async (id, thunkAPI) => {
      try {
        return await deleteInsurance(id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const InsuranceSlice = createSlice({
    name: "Insurance",
    initialState,
    reducers: {
      reset: (state) => {
        state.isError = false;
        state.isLoading = false;
        state.record = [];
        state.message = "";
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(get_insurance.pending, (state) => {
          state.isLoading = true;
          // state.callerId = [];
        })
        .addCase(get_insurance.fulfilled, (state, action) => {
          state.isLoading = false;
          state.insurance = action.payload?.data;
        })
        .addCase(get_insurance.rejected, (state) => {
          state.isLoading = false;
          
        })
        .addCase(add_insurance.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(add_insurance.fulfilled, (state, action) => {
          state.isLoading = false;
          state.insurance.push(action.payload.data[0]);
        })
        .addCase(add_insurance.rejected, (state, action) => {
          state.isLoading = false;
        })
       
        .addCase(delete_insurance.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(delete_insurance.fulfilled, (state, action) => {
          state.insurance = state?.insurance?.filter(({id}) => id !== action?.payload?.data?.id);
          state.isLoading = false;
        })
        .addCase(delete_insurance.rejected, (state) => {
          state.isLoading = false;
        })
        .addCase(update_insurance.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(update_insurance.fulfilled, (state, action) => {
          state.isLoading = false;
          const result = state.insurance.findIndex(
            ({ id }) => id === action?.payload?.data[0]?.id
          );
          state.insurance[result] = {
            ...state.insurance[result],
            ...action.payload.data[0],
          };
        })
        .addCase(update_insurance.rejected, (state) => {
          state.isLoading = false;
        })
        
    },
  });
  
  export const { reset } = InsuranceSlice.actions;
  export default InsuranceSlice.reducer;