import { deployApi } from "../api";

export const getDbTables = async (dbName) => {
  return await deployApi.get(`api/industries/dbTables/${dbName}`);
};

export const getTableRows = async (dbName, tableName) => {
  return await deployApi.get(`api/industries/tableRows/${dbName}/${tableName}`);
};

export const exportTable = async (dbName, tableName) => {
  return await deployApi.get(`api/industries/export_table/${dbName}/${tableName}`);
};

export const exportSampleTable = async (dbName, tableName) => {
  return await deployApi.get(`api/industries/export_sample_table/${dbName}/${tableName}`);
};

export const importExcel = async (file,dbName, tableName) => {
  return await deployApi.post(`api/industries/import_table/${dbName}/${tableName}`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const updateTableRow = async (dbName, tableName, data) => {
  return await deployApi.put(
    `api/industries/editTableRows/${dbName}/${tableName}`,
    data
  );
};

export const deleteTableRow = async (dbName, tableName, ids) => {
  return await deployApi.post(
    `api/industries/deleteTableRows/${dbName}/${tableName}`,
    {"id":ids}
  );
};

export const addTableRow = async (dbName, tableName, data) => {
  return await deployApi.post(
    `api/industries/addTableRows/${dbName}/${tableName}`,
    data
  );
};
export const downloadDatabse = async (dbName) => {
  return await deployApi.get(`api/industries/export_database_new/${dbName}`);
};
export const updateDatabse = async (id) => {
  return await deployApi.post(`api/industries/curd_operation_for_database/${id}`);
};