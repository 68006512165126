import React from "react";
import Button from "../../components/atoms/Button";

function DomainImages({ imageList, onCloseModal }) {
  const [bg_color, setBg_color] = React.useState("#fff");
  const [imgUrl, setImgUrl] = React.useState();
  const [isHover, setIsHover] = React.useState(1);
  const modalRef = React.useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      onCloseModal();
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onCloseModal();
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  return (
    <div
      className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60"
     
    >
      <div ref={modalRef}>
        {imgUrl && (
          <div
            className="bg-[rgba(0,_0,_0,_0.7)] absolute top-0 bottom-0 left-0 right-0 z-20 flex justify-center items-center cursor-pointer max-h-screen"
            onClick={() => setImgUrl()}
          >
            <div className="w-auto h-auto bg-contain bg-no-repeat bg-center bg-violet-200 max-w-[80vw] max-h-[90vh]">
              <img
                src={imgUrl}
                className="w-full h-full max-w-[80vw] max-h-[90vh]"
                alt="img"
              />
            </div>
          </div>
        )}
        <div
          className="container-fluid mt-2"
          
        >
          <div className="inline-block bg-white w-full h-[calc(95vh-50px)] my-0 mx-auto px-5 py-2 bg-red-600 rounded">
            <div className="flex flex-row items-center justify-between">
              <div className="flex items-center text-xl font-semibold font_pop">
                Total Images:{" "}
                <span className="ml-2">
                  {imageList ? imageList?.length : 0}
                </span>
              </div>
              <div className="flex items-center">
                <label htmlFor="colorSelector">Change Background</label>
                <input
                  id="colorSelector"
                  type="color"
                  className="border-none h-[40px] rounded mx-3 cursor-pointer"
                  value={bg_color}
                  onChange={(e) => setBg_color(e.target.value)}
                />
              </div>
            </div>
            {imageList?.length > 0 ? (
              <ul className="min-w-[200px] h-[calc(80vh-50px)] border-t border-[#ddd] overflow-y-auto bg-white text-violet-600 z-50 border-l border-[#ddd] border-r shadow-lg flex flex-wrap justify-center">
                {imageList?.map((temp, index) => {
                  return (
                    <li
                      key={index}
                      className=" transition ease-in-out delay-150 relative text-black p-2.5 border-b border-[#ddd] cursor-pointer flex flex-row items-center justify-center w-[210px] max-h-[210px] shadow-lg"
                      onMouseEnter={() => setIsHover(index)}
                      onMouseLeave={() => setIsHover("")}
                    >
                      <img
                        src={temp?.path}
                        alt={temp?.alt}
                        style={{ backgroundColor: bg_color }}
                      />
                      {isHover === index && (
                        <>
                          <div
                            className={`absolute flex flex-col justify-center top-0 left-0 right-0 bottom-0 bg-black/70 !p-2 text-sm text-white
                          ${isHover === index ? "slide-down" : "slide-up"}
                          `}
                            onClick={() => setImgUrl(temp?.path)}
                          >
                            <div className="text-sm text-white font-medium">
                              TagName:{" "}
                              <span className="text-xs font-normal">
                                {temp.tagName}
                              </span>
                            </div>
                            <div className="text-sm text-white font-medium">
                              Title:{" "}
                              <span className="text-xs">{temp.title}</span>
                            </div>
                            <div className="text-sm text-white font-medium">
                              Alt: <span className="text-xs">{temp.alt}</span>
                            </div>
                            <div className="text-sm text-white font-medium">
                              Size: <span className="text-xs">{temp.size}</span>
                            </div>
                          </div>
                        </>
                      )}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div className="text-center mt-3 text-3xl min-w-[200px] h-[calc(76vh-50px)] border-t border-[#ddd] overflow-y-auto bg-white text-violet-600 z-50 border-l border-[#ddd] border-r shadow-lg flex flex-wrap justify-center items-center">
                No images found
              </div>
            )}

            <div className="flex justify-center">
              <Button
                text="Cancel"
                variant="btn_cancel"
                className="mt-3 text-center"
                onClick={onCloseModal}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DomainImages;
