// const SelectInput = ({ name, className, options, onChange, value }) => {
//     return (
//       <select name={name} className={className} onChange={onChange} value={value}>
//         {options?.map((option, index) => (
//           <option key={index} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//     );
//   };
  
//   export default SelectInput;
  
  import { FormControl, InputLabel, Select } from "@mui/material";

const SelectInput = ({
  name,
  label,
  value,
  options = [],
  valueProp,
  labelProp,
  disabled,
  onChange,
  nullOption
}) => {
  return (
    <FormControl
      sx={{
        mt: 0,
        minWidth: 120,
        borderColor: "#a9a9a9",
        background: "transparent",
      }}
      fullWidth
      variant="outlined"
    >
      <InputLabel htmlFor={label}>{label}</InputLabel>
      <Select
        sx={{
          borderColor: "#a9a9a9",
          mt: 0,
          background: "#fff",
        }}
        defaultValue=""
        id={name}
        label={label}
        size="small"
        fullWidth
        native
        name={name}
        variant="outlined"
        value={value}
        onChange={({ target: { value } }) => {
            onChange(value);

        }}
        disabled={disabled}
        // error={formik.errors[name] && formik.touched[name] ? true : false}
      >
        {nullOption!=="No"?
          <option value="" label={label}></option>
          :null
        }
        
        {options.length > 0 &&
          options.map((option) => (
            <option key={option[valueProp]} value={option[valueProp]}>
              {option[labelProp]}
            </option>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
