import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DotsLoader from "../../components/atoms/DotsLoader";
import Button from "../../components/atoms/Button";
import { get_service_hours_by_id } from "../../redux/hoursSlice";

const ServiceHoursModal = ({ onCloseModal, setId, hoursModalRef }) => {
  const { isLoading, serviceHour } = useSelector((state) => state.hours);
  const modalRef = React.useRef(null);
  const dispatch = useDispatch();
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      onCloseModal();
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onCloseModal();
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);

  React.useEffect(() => {
    dispatch(get_service_hours_by_id(setId));
    // eslint-disable-next-line
  }, []);
  const serviceHours = JSON.parse(
    serviceHour[0]?.hours !== undefined ? serviceHour[0]?.hours : null
  );
  return (
    <>
      <div
        className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60"
        ref={hoursModalRef}
      >
        {isLoading && <DotsLoader />}
        <div className="container-fluid mt-2 text-center ">
          <div
            className="inline-block bg-white w-3/6 my-0 mx-auto p-4 bg-red-600 rounded  max-h-[87vh] justify-center "
            ref={modalRef}
          >
            <header className="flex items-center justify-center shadow-lg bg-slate-900 text-white h-[50px] mb-3 text-xl">
              Service Hours
            </header>
            <div className="bg-white rounded-lg overflow-hidden mr-2  col-span-1 flex justify-center items-center">
              <div className="!px-1 h-auto  w-1/3 shadow-md mb-3">
                <span className="text-red-500 text-medium font-semibold text-center">
                  Set ID:<span className="ml-2">{setId}</span>
                </span>
                {serviceHours !== undefined &&
                  serviceHours?.length > 0 &&
                  serviceHours?.map(({ day, opens, closes }) => {
                    return (
                      <div className="flex flex-wrap items-start justify-between">
                        <div className="text-base font-medium px-2 !py-0.5 w-auto capitalize">
                          {day}
                        </div>
                        <div className="text-sm text-gray-700 px-2 py-1.5 w-auto text-start">
                          {`${opens} ${opens && closes && " - "} ${closes}`}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <Button
              text="Cancel"
              variant="btn_cancel"
              className="ml-3"
              onClick={onCloseModal}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceHoursModal;
