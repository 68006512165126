import { deployApi } from "../api";

export const getDomains = async (id, deploy_with) => {
  return await deployApi.get(`/api/domains/${id}?deploy_with=${deploy_with}`);
};

export const getTemplatesRepo = async () => {
  return await deployApi.get(`/api/templates`);
};

export const deployProject = async (data) => {
  if (data?.deploy_with === "AWS") {
    return await deployApi.post("/api/deploy/aws_domains", data);
  } else {
    return await deployApi.post("/api/deploy/domains", data);
  }
};
