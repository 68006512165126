import { deployApi } from "../api";

export const getVendors = async () => {
  return await deployApi.get("api/custom_vendors/vendors");
};
export const AddVendor = async (data) => {
  return await deployApi.post("api/custom_vendors/add_vendor", data);
};
export const updateVendors = async (data) => {
  return await deployApi.put(`api/custom_vendors/vendor/${data?.id}`, data);
};
export const deleteVendors = async (id) => {
  return await deployApi.delete(`api/custom_vendors/vendor/${id}`);
};
