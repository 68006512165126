import React, { useState } from "react";
import { BiRefresh } from "react-icons/bi";
import Iframe from "react-iframe";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/atoms/Button";
import DotsLoader from "../../components/atoms/DotsLoader";
import { get_domain_per_industry } from "../../redux/domainSlice";

function DomainIframes() {
  const { isLoading, domainPerIndustry } = useSelector(
    (state) => state.domains
  );
  const dispatch = useDispatch();

  const [groupedData, setGroupedData] = useState([]);
  const [searchInput, setSearchInput] = React.useState("");
  const [domainCount, setDomainCount] = React.useState(2);
  const iframeRefs = React.useRef([]);
  const [iframeLoading, setIframeLoading] = useState(
    Array(domainPerIndustry?.length).fill(true)
  );
  const [iframeKeys, setIframeKeys] = useState({});
  React.useEffect(() => {
    dispatch(get_domain_per_industry(domainCount));
  }, []);
  React.useEffect(() => {
    if (domainPerIndustry?.length > 0) {
      const newArray = domainPerIndustry.reduce((acc, item) => {
        const foundIndex = acc.findIndex(
          (el) =>
            el.industry_id === item.industry_id &&
            el.industry_name === item.industry_name &&
            el.runner_name === item.runner_name &&
            el.ip === item.ip
        );

        if (foundIndex !== -1) {
          acc[foundIndex].domains.push(item.domain);
        } else {
          acc.push({
            industry_id: item.industry_id,
            industry_name: item.industry_name,
            runner_name: item.runner_name,
            ip: item.ip,
            domains: [item.domain],
          });
        }
        return acc;
      }, []);
      setGroupedData(newArray);
      setIframeLoading(Array(domainPerIndustry?.length).fill(true));
    }
  }, [domainPerIndustry]);
  const setIframeLoadingState = (index, isLoading) => {
    const newLoadingState = [...iframeLoading];
    newLoadingState[index] = isLoading;
    setIframeLoading(newLoadingState);
  };

  const handleIframeReload = (index) => {
    setIframeKeys((prevKeys) => ({
      ...prevKeys,
      [index]: Date.now(), // Update the key with a new value (timestamp) to trigger reload
    }));
  };

  const searchedData = groupedData?.filter((entry) => {
    // Check if any of the specified fields or domains match the search input
    return (
      entry.industry_name.toLowerCase().includes(searchInput?.toLowerCase()) ||
      entry.runner_name.toLowerCase().includes(searchInput?.toLowerCase()) ||
      entry.ip.toLowerCase().includes(searchInput?.toLowerCase()) ||
      entry.domains.some((domain) =>
        domain.toLowerCase().includes(searchInput?.toLowerCase())
      )
    );
  });
  return (
    <div className="!my-3 borde rounded w-full">
      {isLoading ? <DotsLoader /> : null}
      <div className="bg-white p-2">
        <div className="flex justify-start items-center grid grid-cols-2 gap-x-2">
          <div className="flex flex-row items-center justify-between">
            <div className="w-fit min-w-[250px] text-base font-normal font-mont text-heading my-2">
              Displaying {groupedData?.length > 0 ? 1 : 0}-{groupedData?.length}{" "}
              of {groupedData?.length} result
            </div>
            <div className="relative w-full col-span-12 md:col-span-8">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                className="h-[31px] bg-white border border-gray-300 text-gray-900 text-sm rounded-[0.2rem] w-full pl-12 p-2.5 "
                placeholder="Search"
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row items-center justify-end">
            <input
              type="text"
              className="h-[31px] bg-white border border-gray-300 text-gray-900 text-sm rounded-[0.2rem] w-[250px] p-2.5 "
              placeholder="Enter the domain count per"
              value={domainCount}
              onChange={(e) => {
                setDomainCount(e.target.value);
              }}
            />
            <Button
              text={"Search"}
              className="py-1.5 px-3 align-middle mr-2 bg-primary-100 text-white ml-3"
              variant="btn_submit"
              onClick={() => dispatch(get_domain_per_industry(domainCount))}
            />
          </div>
        </div>
        <div className="border h-[85vh] overflow-y-auto w-full p-2">
          {searchedData?.map((data, index) => {
            return (
              <div className="mb-3 py-2">
                <div className="bg-gray-100 p-1.5 mb-1 flex justify-between">
                  <div className="">
                    <span className="text-lg font-semibold font_pop">
                      {`${index + 1}. `}
                    </span>
                    <span className="text-lg font-semibold font_pop">
                      {`Industry ID: ${data?.industry_id} | `}
                    </span>
                    <span className="text-lg font-semibold font_pop">
                      {`Industry Name: ${data?.industry_name} | `}
                    </span>
                    <span className="text-lg font-semibold font_pop">
                      {`Runner Name: ${data?.runner_name} | `}
                    </span>
                    <span className="text-lg font-semibold font_pop">
                      {`IP: ${data?.ip}`}
                    </span>
                  </div>
                  <div className="cursor-pointer w-[30px] h-[30px] ">
                    <BiRefresh
                      size={25}
                      onClick={() => handleIframeReload(index)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-2 grid-view-container">
                  {data?.domains?.map((domain, index2) => {
                    return (
                      <div className=" p-1 border relative" key={index}>
                        {iframeLoading[index] && (
                          <DotsLoader
                            onClick={() => setIframeLoadingState(index, false)}
                          />
                        )}
                        <div
                          className="relative"
                          //   onClick={(event) => {
                          //     // event.preventDefault();
                          //     onSingleselect(data?.id);
                          //   }}
                        >
                          <Iframe
                            ref={(iframe) =>
                              (iframeRefs.current[index] = iframe)
                            }
                            url={`https://${domain}/contact-us`}
                            width="100%"
                            height="300px"
                            id=""
                            className="pointer-events-none"
                            display="block"
                            position="relative"
                            onLoad={() => setIframeLoadingState(index, false)}
                            loading="lazy"
                            key={iframeKeys[index]}
                            // onLoad={() => setIframeLoading(false)}
                          />
                          {/* The checkbox is placed absolutely on top of the iframe */}
                          {/* <input
                              type="checkbox"
                              onChange={() => onSingleselect(data?.id)}
                              checked={isChecked(data.id)}
                              className="absolute top-2 left-2"
                            /> */}
                        </div>
                        <div className="flex justify-between mt-1">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://${domain}`}
                            className="text-center text-lg font-semibold font_pop"
                          >
                            {`${domain}`}
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default DomainIframes;
