import React, { useState,useRef } from "react";
import { FaListUl } from "react-icons/fa";
import Iframe from "react-iframe";
import DotsLoader from "../../components/atoms/DotsLoader";
import {BiRefresh} from 'react-icons/bi'
function GridView({
  data,
  setView,
  searchInput,
  setSearchInput,
  // totalItems,
  setSelectedData,
  selectedData,
  CustomComponent,
  selectedIndustry
}) {
  const iframeRefs = useRef([]);

  // const handleIframeReload = (index) => {
  //   console.log("🚀 ~ file: GridView.jsx:22 ~ handleIframeReload ~ iframeRefs.current[index]:", iframeRefs.current[index])

  //   console.log("🚀 ~ file: GridView.jsx:20 ~ handleIframeReload ~ index:", index)
  //   if (iframeRefs.current[index]) {
  //     iframeRefs.current[index].src = `https://${data[index]?.domain_url}/contact-us`;
  //   }
  // };
  const [page, setPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);
  const pageSize=10
  // const [currentItems, setCurrentItems] = useState([]);
  // const count = Math.ceil(data?.length / pageSize);
  const offset = (page - 1) * pageSize;
  // const lastPageIndex = +pageSize - 1;
  const lastItemIndex = Math.min(offset + pageSize, data?.length);
  const currentItems = data.slice(0, lastItemIndex);
  const [iframeLoading, setIframeLoading] = useState(Array(data?.length).fill(true));
  console.log("🚀 ~ file: GridView.jsx:92 ~ iframeLoading:", iframeLoading)
  const selectAll = () => {
    let id = [];
    if (selectedData?.length !== data.length) {
      // eslint-disable-next-line
      data.map((val) => {
        id = [...id, val?.id];
      });
      setSelectedData(id);
    } else if (selectedData?.length === data.length) {
      setSelectedData([]);
    }
  };
  // State to track if more items are loading
  const [loadingMore, setLoadingMore] = useState(false);

  // Function to load more items
  const loadMoreItems = () => {
    setLoadingMore(true);
    const nextPage = page + 1;
    setPage(nextPage);
    setLoadingMore(false);

  };
  // Scroll event listener to trigger loading more items
  const SCROLL_THRESHOLD = 50;
  React.useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.querySelector(".grid-view-container"); // Give your grid container a class "grid-view-container"
      const isScrolledToBottom =
        scrollContainer.scrollHeight - scrollContainer.scrollTop <=
        scrollContainer.clientHeight + SCROLL_THRESHOLD;

      if (!loadingMore && isScrolledToBottom) {
        loadMoreItems();
      }
    };

    const scrollContainer = document.querySelector(".grid-view-container"); // Give your grid container a class "grid-view-container"
    scrollContainer.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingMore, page]);
  React.useEffect(() => {
    setPage(1);
    setIframeLoading(Array(data?.length).fill(true))
  }, [searchInput,selectedIndustry]);
  const onSingleselect = (val) => {
    if (selectedData?.length > 0) {
      if (selectedData.filter((id) => id === val).length > 0) {
        const arr = selectedData.filter((id) => id !== val);
        setSelectedData(arr);
      } else {
        setSelectedData([...selectedData, val]);
      }
    } else {
      setSelectedData([...selectedData, val]);
    }
  };
  const isChecked = (val) => {
    if (selectedData?.length > 0) {
      if (selectedData?.filter((id) => id === val).length > 0) {
        return true;
      }
    }
    return false;
  };
  // Function to set the loading state for a specific iframe by its index
  const setIframeLoadingState = (index, isLoading) => {
    const newLoadingState = [...iframeLoading];
    newLoadingState[index] = isLoading;
    setIframeLoading(newLoadingState);
  };
  const [iframeKeys, setIframeKeys] = useState({});

  const handleIframeReload = (index) => {
    setIframeKeys((prevKeys) => ({
      ...prevKeys,
      [index]: Date.now(), // Update the key with a new value (timestamp) to trigger reload
    }));
  };
  return (
    <div className="!my-3 border rounded">
      <div className="bg-white p-2">
        <div className="grid grid-cols-12">
          <div className="col-span-6 flex items-center justify-between">
            <div className="w-fit py-[5px] text-base font-normal font-mont text-heading my-2">
              Displaying {currentItems?.length > 0 ? 1 : 0}-
              {currentItems?.length} of {data?.length} result
            </div>
            {
              CustomComponent?<CustomComponent/>:null
            }
          </div>
          <div className="col-span-6 flex items-center justify-end">
            <div className="flex justify-beteen items-center mb-2">
              <div className="mt-2 mt-md-0">
                <div className="relative w-full col-span-12 md:col-span-8">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    type="text"
                    className="h-[31px] bg-white border border-gray-300 text-gray-900 text-sm rounded-[0.2rem] w-full pl-12 p-2.5 "
                    placeholder="Search"
                    value={searchInput}
                    onChange={(e) => {
                      setSearchInput(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex ml-2">
                <div className={["result"].join(" ")}>
                  <button
                    className="bg-black text-white p-1 rounded text-xl px-2"
                    onClick={() => setView(1)}
                  >
                    {<FaListUl />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center mb-2">
          <div className="mt-2 mt-md-0">
            <div className="flex items-center justify-center text-lg font-semibold font_pop text-left">
              <input
                id="colorSelector"
                type="checkbox"
                className="border-none rounded mx-1 cursor-pointer overflow-hidden"
                // value={bg_color}
                onChange={selectAll}
                checked={
                  data?.length > 0 && selectedData?.length === data?.length
                    ? true
                    : false
                }
              />
              <label htmlFor="colorSelector" className="!mb-0">
                Select All
              </label>
            </div>
          </div>
          <div className="flex ml-2">
            <span>Selected Records: {selectedData?.length}</span>
          </div>
        </div>
        <div className="border h-[78vh] grid grid-cols-3 gap-2 grid-view-container overflow-y-auto">
          {currentItems?.map((data, index) => {
            return (
              <div className=" p-1 border relative" key={index}>
                {iframeLoading[index] && <DotsLoader onClick={()=>setIframeLoadingState(index, false)}/>}
                <div
                  className="relative"
                  // onClick={(event) => {
                  //   // event.preventDefault();
                  //   onSingleselect(data?.id);
                  // }}
                >
                  <Iframe
                    ref={(iframe) => (iframeRefs.current[index] = iframe)}
                    url={`https://${data?.domain_url}/contact-us`}
                    width="100%"
                    height="300px"
                    id=""
                    className="pointer-events-none"
                    display="block"
                    position="relative"
                    onLoad={() => setIframeLoadingState(index, false)}
                    loading="lazy"
                    key={iframeKeys[index]} 
                    // onLoad={() => setIframeLoading(false)}
                  />
                  {/* The checkbox is placed absolutely on top of the iframe */}
                  <input
                    type="checkbox"
                    onChange={() => onSingleselect(data?.id)}
                    checked={isChecked(data.id)}
                    className="absolute top-2 left-2"
                  />
                  <div className="absolute top-2 right-2 cursor-pointer bg-white w-[30px] h-[30px] ">
                  <BiRefresh size={25} onClick={()=>handleIframeReload(index)}/>
                </div>
                </div>
                <div className="flex justify-between mt-1">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://${data?.domain_url}`}
                    className="text-center text-lg font-semibold font_pop"
                  >
                    {`${index + 1}. ${data?.domain_url}`}
                  </a>
                  <span className="text-lg font-semibold font_pop">{data?.id}</span>
                </div>
              </div>
            );
          })}
        </div>
        {loadingMore && <div>Loading...</div>} {/* Show a loading indicator */}
      </div>
    </div>
  );
}

export default GridView;
