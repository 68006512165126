import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDomainsIndexReport, startCheckingIndexReport } from "../services/domainIndexReportServices";

const initialState = {
  isError: false,
  isLoading: false,
  domainIndexReport: {},
  message: "",
};

// Get Users Roles
export const get_domain_index_report = createAsyncThunk(
  "get_domain_index_report",
  async (domains,thunkAPI) => {
    try {
      return await getDomainsIndexReport(domains);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Roles
export const start_checking_indexing = createAsyncThunk(
  "start_checking_indexing",
  async (domains,thunkAPI) => {
    try {
      return await startCheckingIndexReport(domains);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const domainIndexReportSlice = createSlice({
  name: "domains_index_report",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.domainIndexReport = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_domain_index_report.pending, (state) => {
        state.isLoading = true;
        state.domainIndexReport = [];
      })
      .addCase(get_domain_index_report.fulfilled, (state, action) => {
        state.isLoading = false;
        state.domainIndexReport = action.payload?.data;
      })
      .addCase(get_domain_index_report.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.domainIndexReport = [];
      })
      .addCase(start_checking_indexing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(start_checking_indexing.fulfilled, (state) => {
        state.isLoading = false;
        
      })
      .addCase(start_checking_indexing.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { reset } = domainIndexReportSlice.actions;
export default domainIndexReportSlice.reducer;
