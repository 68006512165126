import React, { useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { toast } from "react-toastify";
import { deployApi } from "../../api";
import { useSelector } from "react-redux";
import Loader from "../../components/atoms/Loader";
const CustomModal = ({ onClose,setQueryResponse }) => {
  const { runners } = useSelector((state) => state.servers);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedRunner, setSelectedRunner] = useState([]);
  const [selectedFunction, setSelectedFunction] = useState([]);
  const [isCustom, setIsCustom] = useState(false);
  const [query, setQuery] = useState("");
  const handleConfirm = async () => {
    if ((selectedRunner?.length === 0 || !query) && isCustom ) {
      return toast.error("Runner and SQL Query are required ");
    }

    if ((selectedRunner?.length === 0 || selectedFunction?.length === 0) && !isCustom ) {
      return toast.error("Runner and Functions are required ");
    }

    setIsLoader(true);
    let array = [...selectedRunner];
    for (const item of selectedRunner) {
      const runnerName = runners?.find(({ api_url }) => api_url === item)?.name;
      try {
        const response = await deployApi.post(`${item}`, { sql_query:isCustom?query:`UPDATE cron_functions SET ready_to_run = 1 WHERE fun_name IN ('${selectedFunction.join("','")}')`});

        if (response.status === 200) {
          setQueryResponse(prevShellResponse => [
            ...prevShellResponse,
            { runner: runnerName, response: JSON.stringify(response.data?.data )}
          ]);
          toast.success(`Query completed for ${runnerName}`);
          array = array?.filter((api_url) => api_url !== item);
          setSelectedRunner((prevState) =>
            prevState.filter((api_url) => api_url !== item)
          );
        } else {
          console.error(
            `Request for ${item.name} failed with status ${response.status}`
          );
          toast.error(
            `Query for ${runnerName} failed with status ${response.status}, ${
              response.data?.data?.message
                ? response.data?.data?.message
                : response.data
            } `
          );
        }
      } catch (error) {
        console.error(`Request for ${runnerName} encountered an error:`, error);
        toast.error(
          `Query for ${runnerName} failed with status ${
            error?.response?.status
          }, ${
            error?.response?.data?.data?.message
              ? error?.response?.data?.data?.message
              : error?.response?.data
          } `
        );
      }
    }
    if (array?.length === 0) {
      toast.success("Process Complete");
      onClose();
    }
    setIsLoader(false);
  };

  const functionsOptions = [
  {label:"updateDomainTable", value:"updateDomainTable"},
  {label:"createDomainDB", value:"createDomainDB"},
  {label:"setDomainImages", value:"setDomainImages"},
  {label:"openDomainFirstTime", value:"openDomainFirstTime"},
  {label:"updateDomainCloudflare", value:"updateDomainCloudflare"},
  {label:"createVirtualHost", value:"createVirtualHost"},
  {label:"updateDomainPhoneNumber", value:"updateDomainPhoneNumber"},
  {label:"createDomainZone", value:"createDomainZone"},
  {label:"crudOprForIndustryDomainDB", value:"crudOprForIndustryDomainDB"},
  {label:"regenerateDomainDBByIndustry", value:"regenerateDomainDBByIndustry"},
  {label:"checkIndustryDatabaseUpdate", value:"checkIndustryDatabaseUpdate"},
  {label:"checkDomainAddedInLBServer", value:"checkDomainAddedInLBServer"},
  {label:"addDomainInLBServer", value:"addDomainInLBServer"} ]
  return (
    <>
      <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40">
        <div className="bg-white p-8 rounded-lg shadow-lg relative w-[400px]">
          <h2 className="text-lg font-semibold mb-4">Run Query</h2>
          {/* <p className="mb-4">Are you sure you want to restart the application?</p> */}

          <div className=" !px-0.5 !mb-3">
            <label className="block">Select Runners:</label>
            <MultiSelect
              // styles={style}
              placeholder="Runners"
              options={runners}
              optionLabel="name"
              optionValue="api_url"
              filter
              maxSelectedLabels={2}
              value={selectedRunner}
              onChange={(e) => setSelectedRunner(e.value)}
              className="h-[38px] w-full !rounded-[4px] border !border-[#e5e7eb] hover:border-[#ced4da]"
            />
          </div>
          <div className="flex items-center justify-start text-xl font-semibold font_pop text-left">
              <input
                id="colorSelector"
                type="checkbox"
                className="border-none rounded mr-2 cursor-pointer overflow-hidden"
                // value={bg_color}
                onChange={()=>{
                  // setQuery(isCustom?"":`UPDATE master_database.cron_functions SET ready_to_run = 1 WHERE fun_name IN ${selectedFunction.join(",")}`)
                  setIsCustom(!isCustom)}}
                checked={isCustom}
              />
              <label htmlFor="colorSelector" className="!mb-0 text-sm">
                Custom Query
              </label>
            </div>
           {isCustom?<div>   <label className="block">Enter a SQL Query:</label>
          <div className="relative flex flex-row">
            <textarea
              className="bg-white border border-gray-300 text-sm rounded w-full p-2.5 "
              onChange={(e) => setQuery(e.target.value)}
              value={query}
              name="pass"
              placeholder={"Enter a SQL Query"}
              // autoFocus={true}
            />
          </div> </div>: <div className=" !px-0.5 !mb-3">
            <label className="block">Select Functions:</label>
            <MultiSelect
              // styles={style}
              placeholder="Select Functions"
              options={functionsOptions}
              optionLabel="label"
              optionValue="value"
              filter
              maxSelectedLabels={2}
              value={selectedFunction}
              onChange={(e) => setSelectedFunction(e.value)}
              className="h-[38px] w-full !rounded-[4px] border !border-[#e5e7eb] hover:border-[#ced4da]"
            />
          </div>}
          <div className="flex justify-end !mt-4">
            {isLoader ? (
              <Loader />
            ) : (
              <>
                <button
                  className="px-4 py-2 mr-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  onClick={handleConfirm}
                >
                  OK
                </button>
                <button
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomModal;
