import dayjs from "dayjs";
import React, { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DotsLoader from "../../components/atoms/DotsLoader";
import Breadcrumbs from "../../components/molecules/Breadcrumbs";
import DataTable from "../../components/molecules/DataTable";
import MuiGrid from "../../components/molecules/DataTable/MuiGrid";

import { delete_insurance, get_insurance } from "../../redux/InsuranceSlice";
import AddInsurance from "./AddInsurance";

const InsuranceComp = () => {
  const dispatch = useDispatch();
  const { isLoading, insurance } = useSelector((state) => state.insurance);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchInput, setSearchInput] = React.useState("");

  const openFormHandler = (record) => () => {
    setEditingRecord(record);

    setIsEditing(true);
  };
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  React.useEffect(() => {
    dispatch(get_insurance());
    // eslint-disable-next-line
  }, []);
  const columnDefs = [
    { headerName: "#", field: "counter" },
    { headerName: "Name", field: "name", flex: 1 ,minWidth:20},
    { headerName: "Phone", field: "phone", flex: 1 ,minWidth:20},
    { headerName: "Description", field: "description", flex: 1,minWidth:20 },
    { headerName: "Created At", field: "created_at", flex: 1,minWidth:20 },
    { headerName: "Updated At", field: "updated_at", flex: 1 ,minWidth:20},
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex items-center w-full">
          <FaEdit
            onClick={openFormHandler(params.row.records)}
            className="my_navIcon cursor-pointer"
            size={20}
          />
          <FaTrash
            onClick={() => handleDel(params.row.records.id)}
            className="my_navIcon cursor-pointer !ml-1"
            size={20}
          />
        </div>
      ),
      width: 100,
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  ];
  const handleDel = async (id) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_insurance(id));
        if (res.payload.status === 200) {
          toast.success("Insurance Company deleted");
          window.location.reload();
        } else {
          toast.error("Insurance Company couldn't be deleted");
        }
      } catch (error) {
        console.log("ImagesList.jsx:48 ~ deleteRecordHandler ~ error", error);
        toast.error("Insurance Company couldn't be deleted");
      }
    }
  };
  const data = insurance?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  return (
    <>
      {isEditing && (
        <AddInsurance
          editingRecord={editingRecord}
          modalTitle="Add Insurance"
          onCancelForm={cancelFormHandler}
        />
      )}
      {/* {isLoading && <DotsLoader text="Please Wait" />} */}
      <div className="container-fluid bg-[#f5f5f5] min-h-screen overflow-y-auto">
        <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
          <h1 className="text-2xl font-extrabold">Insurance Company</h1>
          <button
            className="border rounded bg-red-600 text-white p-2 hover:bg-red-700"
            onClick={openFormHandler(0)}
          >
            Add Insurance
          </button>
        </div>

        <div className="bg-white !my-3 border rounded">
         
            <MuiGrid
              columnDefs={columnDefs}
              items={data?.map((record, index) => {
                // console.log("insuranceeee",record)
                let counter = index + 1;
                let id = record.id;
                let name = record.name;
                let phone = record.phone;
                let description = record.description;

                let created_at = record.created_at
                  ? dayjs(record.created_at).format("ddd, MMM D, YYYY h:mm A")
                  : null;
                let updated_at = record.updated_at
                  ? dayjs(record.updated_at).format("ddd, MMM D, YYYY h:mm A")
                  : null;

                return {
                  records: record,
                  counter,
                  id,
                  name,
                  phone,
                  description,
                  created_at,
                  updated_at,
                };
              })}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              pagination="No"
            />
         
        </div>
      </div>
    </>
  );
};

export default InsuranceComp;
