import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getBlogs } from "../services/bulkImageServices";
import {
  addBulkImages,
  copyImages,
  deleteBulkImg,
  deleteByFolderName,
  editBulkImages,
  getfoldersByIndustry,
  getImagesByType,
  getIndustriesByTemp,
  getServices,
} from "../services/index";

const initialState = {
  templates: [],
  industries: [],
  folders: [],
  images: [],
  services: [],
  blogs: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Add Bulk Images
export const add_bulk_images = createAsyncThunk(
  "add_bulk_images",
  async (data, thunkAPI) => {
    try {
      return await addBulkImages(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const update_bulk_images = createAsyncThunk(
  "update_bulk_images",
  async (data, thunkAPI) => {
    try {
      return await editBulkImages(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Industries
export const get_industries_by_temp = createAsyncThunk(
  "get_industries_by_temp",
  async (template_name, thunkAPI) => {
    try {
      return await getIndustriesByTemp(template_name);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const copy_images = createAsyncThunk(
  "copy_images",
  async (data, thunkAPI) => {
    try {
      return await copyImages(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Image Types
export const get_image_types = createAsyncThunk(
  "get_image_types",
  async ({ template_name, industry_name }, thunkAPI) => {
    try {
      return await getfoldersByIndustry(template_name, industry_name);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Images By Types
export const get_images_by_type = createAsyncThunk(
  "get_images_by_type",
  async ({ template_name, industry_name, image_type }, thunkAPI) => {
    try {
      return await getImagesByType(template_name, industry_name, image_type);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Industries
export const delete_bulk_image = createAsyncThunk(
  "delete_bulk_image",
  async (id, thunkAPI) => {
    try {
      return await deleteBulkImg(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const delete_by_folders = createAsyncThunk(
  "delete_by_folders",
  async (payload, thunkAPI) => {
    try {
      return await deleteByFolderName(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Services
export const get_services = createAsyncThunk(
  "get_services",
  async (industryId, thunkAPI) => {
    try {
      return await getServices(industryId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_blogs = createAsyncThunk(
  "get_blog",
  async (industryId, thunkAPI) => {
    try {
      return await getBlogs(industryId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const bulkImagesSlice = createSlice({
  name: "bulk_images",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(add_bulk_images.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_bulk_images.fulfilled, (state, action) => {
        state.isLoading = false;
        state.images = state.images.concat(action.payload.data.imageData);
        toast.success("Images added successfully");
      })
      .addCase(add_bulk_images.rejected, (state) => {
        state.isLoading = false;
        toast.error("Images couldn't be added");
      })
      .addCase(get_industries_by_temp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_industries_by_temp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.industries = action.payload.data;
      })
      .addCase(get_industries_by_temp.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_image_types.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_image_types.fulfilled, (state, action) => {
        state.isLoading = false;
        state.folders = action.payload.data;
      })
      .addCase(get_image_types.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_images_by_type.pending, (state) => {
        state.isLoading = true;
        state.images = [];
      })
      .addCase(get_images_by_type.fulfilled, (state, action) => {
        state.isLoading = false;
        state.images = action.payload.data;
      })
      .addCase(get_images_by_type.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_services.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_services.fulfilled, (state, action) => {
        state.isLoading = false;
        state.services = action.payload.data;
      })
      .addCase(get_services.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_bulk_image.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(delete_bulk_image.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isError = false;
        // eslint-disable-next-line array-callback-return
        action?.payload?.data?.ids.map((val)=>{
          state.images = state.images.filter(
            (img) => img.id !== +val
          );
        })
        
        state.isLoading = false;
      })
      .addCase(delete_bulk_image.rejected, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(update_bulk_images.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_bulk_images.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.images.findIndex(
          ({ id }) => id === action?.payload?.data?.id
        );
        state.images[result] = {
          ...state.images[result],
          ...action.payload.data,
        };
      })
      .addCase(update_bulk_images.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(copy_images.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(copy_images.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success("Images transfer successfully");
      })
      .addCase(copy_images.rejected, (state) => {
        state.isLoading = false;
        toast.error("Images couldn't be transfered");
      })
      .addCase(delete_by_folders.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(delete_by_folders.fulfilled, (state, action) => {
        console.log("🚀 ~ file: bulkImagesSlice.js:303 ~ .addCase ~ action:", action)
        state.isSuccess = true;
        state.isError = false;
          // eslint-disable-next-line array-callback-return
          action.payload.data.deleted_Images.map((val)=>{
            state.images = state?.images?.filter(
              (img) => img !== val
            );
            state.folders = state?.images?.filter(
              (img) => img !== val
            );
            state.industries = state?.images?.filter(
              (img) => img !== val
            );
          })
        state.isLoading = false;
      })
      .addCase(delete_by_folders.rejected, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      }).addCase(get_blogs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_blogs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.blogs = action.payload.data;
      })
      .addCase(get_blogs.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { reset } = bulkImagesSlice.actions;
export default bulkImagesSlice.reducer;
