import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/molecules/Modal";
import Select from "react-select";
import { create_user, update_user } from "../../redux/userSlice";
import { toast } from "react-toastify";
import { ReactMultiSelect } from "../../components/atoms/MultiReactSelect";
import DotsLoader from "../../components/atoms/DotsLoader";

function AddUpdateUsers({ editingRecord, onCancelForm, modalTitle }) {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.roles);
  const { industries } = useSelector((state) => state.industries);

  let initialData = {
    name: "",
    email: "",
    username: "",
    password: "",
    role_id: "",
    user_type: "API",
    industries: [],
  };

  if (editingRecord) {
    let arr = [];
    // eslint-disable-next-line
    editingRecord?.industries?.map((val) => {
      arr = [...arr, val?.id];
    });
    const { id, name, email, username, role_id, user_type } = editingRecord;
    initialData = {
      id,
      name: name,
      email,
      username,
      role_id: role_id?.id,
      user_type,
      industries: arr,
    };
  }
  const user = JSON.parse(localStorage.getItem("user"));

  const [data, setData] = useState(initialData);
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const onSubmit = async () => {
    let arr = [];
    // eslint-disable-next-line
    selected?.map((val) => {
      arr = [...arr, val?.id];
    });
  
    if (!editingRecord) {
      try {
        const res = await dispatch(create_user({ ...data, industries: arr }));
        if (res.payload.status === 201) {
          toast.success("User Added Successfully");
          onCancelForm();
        }
      } catch (error) {
        toast.error("User couldn't be added");
        console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
      }
    } else {
      try {
        const res = await dispatch(update_user({ ...data, industries: arr }));
        if (res.payload.status === 200) {
          toast.success("User Update Successfully");
          onCancelForm();
        }
      } catch (error) {
        toast.error("User couldn't be updated");
        console.log("~ file: AddImage.jsx:120 ~ onSubmit ~ error", error);
      }
    }
  };
 
  const [selected, setSelected] = useState(
    editingRecord ? editingRecord?.industries : []
  );
  return (
    <Modal
      isUpdate={editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={onSubmit}
      onClick={onCancelForm}>
      {isLoading && <DotsLoader />}
        <div className="container-fluid max-w-[900px] border rounded bg-white py-3 w-full flex flex-row justify-between">
          <div className="w-full !mr-2 grid grid-cols-2 gap-x-2">
            <div className="col-span-1">
              <input
                className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
                placeholder="Name"
                name="name"
                value={data.name}
                onChange={handleInput}
              />
            </div>
            <div className="col-span-1">
              <input
                className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
                placeholder="Email"
                name="email"
                value={data.email}
                onChange={handleInput}
              />
            </div>
            {!editingRecord && (
              <>
                <div className="col-span-1">
                  <input
                    className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
                    placeholder="User Name"
                    name="username"
                    value={data.username}
                    onChange={handleInput}
                  />
                </div>
              </>
            )}
            {user?.role?.name === "SUPER ADMINISTRATOR" && (
              <div className="col-span-1">
                <input
                  className="form-control-sm border !mt-3 w-full h-[40px] focus:shadow-none focus:border-0"
                  placeholder="Password"
                  name="password"
                  value={data.password}
                  onChange={handleInput}
                />
              </div>
            )}
            <div className="col-span-1">
              <Select
                onChange={(e) => {
                  setData({
                    ...data,
                    role_id: e?.id?.toString(),
                  });
                }}
                menuPosition="fixed"
                name={"role_id"}
                options={record}
                getOptionLabel={(options) => options.name}
                getOptionValue={(options) => options.id}
                defaultValue={
                  editingRecord
                    ? {
                        id: editingRecord?.role_id?.id,
                        name: editingRecord?.role_id?.name,
                      }
                    : null
                }
                placeholder="Select Role"
                className="!mt-3"
              />
            </div>
            <div className="col-span-1">
              <ReactMultiSelect
                options={industries}
                value={selected}
                onChange={setSelected}
                labelProps={"industry_name"}
                valueProps={"id"}
                defaultValue={
                  editingRecord
                    ? editingRecord.industries?.map((ele) => ({
                        industry_name: ele?.industry_name,
                        id: ele?.id,
                      }))
                    : null
                }
                placeholder="Select Industries"
                className="!mt-3"
              />
            </div>
          </div>
        </div>
      {/* </div> */}
    </Modal>
  );
}

export default AddUpdateUsers;
