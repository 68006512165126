import React from "react";
import Sidebar2 from "../components/organisms/Sidebar";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { FaBars } from "react-icons/fa";
import { useState } from "react";
import "./index.css";
import Modal from "../components/organisms/Sidebar/Modal";

function Layout({ children }) {
  const { user } = useSelector((state) => state.auth);
  const [isActive, setActive] = useState(false);
  const [modal, setModal] = useState(false);
  const ToggleClass = () => {
    setActive(!isActive); 
   };

  
  return (
    <>
     
      <div className={isActive ? "openSideNav" : null} >
      <div className="menu-backdrop" onClick={ToggleClass}></div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      
      {user ? (
        <div className="flex  flex-nowrap relative overflow-hidden max-w-[100vw]">
          <Sidebar2 setModal={setModal}/>
          {modal && 
          <Modal onCloseModal={()=>setModal(false)}/>
          }
      
          <div
            className="w-full flex flex-col md:flex-row justify-center h-screen  right-container relative"
          >
            <div className="cursor-pointer nav-toggler  mt-5 pt-2 pb-2" onClick={ToggleClass}>
              <button className="bg-black text-white p-1 text-lg rounded  ml-2"><FaBars /></button>
           
          </div>
            {children}
          </div>
        </div>
      ) : (
        children
      )}
      </div>
    </>
  );
}

export default Layout;
