import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Modal from "../../components/molecules/Modal";
import { import_db_file } from "../../redux/industriesSlice";

function ImportExcel({
  editingRecord,
  onCancelForm,
  modalTitle,
}) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.industries);

  const [file, setFile] = useState();
  const onDrop = useCallback((acceptedFiles) => {
    let files = acceptedFiles[0];
    setFile(files);
  }, []);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });
  
  const onSubmit = async () => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const res = await dispatch(
        import_db_file({ file: formData, id:editingRecord })
      );
      if (res.payload.status === 200) {
        // window.location.reload();
        toast.success(res.payload?.data?.message)
        onCancelForm();
      }else{
        toast.error(res.payload?.data?.message?res.payload?.data?.message:res.payload?res.payload:`File couldn't be import`)
      }
    } catch (error) {
      toast.error(error.payload?.data?.message?error.payload?.data?.message:error.payload?error.payload:`File couldn't be import`)
    }
  };
  return (
    <Modal
      isUpdate={editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={onSubmit}
      isLoading={isLoading}
      onClick={onCancelForm}
    >
      {/* {isLoading?<DotsLoader/>:null} */}
      <div className="mt-3 flex justify-center items-center !p-3">
        <div className="w-full flex flex-col justify-center items-center !p-3 bg-[#fff]">
          <div className="text-base font-medium">
            <span className="font-semibold text-lg font_pop text-red-600">
              Note:{" "}
            </span>{" "}
            File name must be "
            <span className="font-semibold text-lg font_pop text-violet-600">
              db.xlsx
            </span>
            " to import in this table
          </div>

          <div className="flex justify-center w-full">
            <div
              className={`!mt-3 w-2/3 flex justify-center border-2 border-gray-300 border-dashed rounded-md !p-2 hover:border-gray-400 focus:outline-none ${
                isDragActive ? "bg-gray-100" : "bg-white"
              }`}
              {...getRootProps()}
            >
              <label
                className={`flex justify-center w-full h-[130px] px-4 transition appearance-none cursor-pointer`}
              >
                {file ? (
                  <span className="flex items-center space-x-2">
                    <div className="flex flex-col text-center">
                      <span className="font-medium text-gray-600">
                        File Name: {file?.name}
                      </span>
                      <span className="text-gray-600" onClick={open}>
                        Size:{" "}
                        {file?.size > 1000 && file.size < 1000000
                          ? (file.size / 1000).toFixed(2) + " KB"
                          : (file.size / 1000000).toFixed(2) + " MB"}
                      </span>
                    </div>
                  </span>
                ) : (
                  <span className="flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      />
                    </svg>
                    <span className="font-medium text-gray-600">
                      Drop files to Attach, or{" "}
                      <span className="text-blue-600 underline" onClick={open}>
                        browse
                      </span>
                    </span>
                  </span>
                )}
                <input {...getInputProps()} />
              </label>
            </div>
          </div>

          {/* <label
            className="hover:underline cursor-pointer mt-2 font_pop text-blue-600"
            onClick={() =>
              dispatch(
                export_sample_table({ dbName: dbName, tableName: tableName })
              )
            }
          >
            Download Sample Table
          </label> */}
        </div>
      </div>
    </Modal>
  );
}

export default ImportExcel;
