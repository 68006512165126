import React, { useRef } from "react";
import Button from "../../atoms/Button";
function useVisible(onCancelModal) {
  const modalRef = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      onCancelModal();
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onCancelModal();
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { modalRef };
}

const Modal = (props) => {
  const { modalRef } = useVisible(props.onClick);

  return (
    <div className="w-full h-screen z-[100] fixed top-0 left-0 opacity-100 bg-black/[0.75] flex items-center justify-center">
      <div
        className={`z-10 w-auto md:min-w-[730px] max-h-[calc(100vh-130px)] overflow-y-auto bg-neutral-100 p-3.5 border-[10px] border-white`}
        ref={modalRef}
      >
        <div className="w-full max-h-[520px px-3.5">
          {!props.hideHeader && (
            <header className=" h-[60px] flex items-center justify-center shadow-lg bg-slate-900">
              <h1 className=" text-2xl text-white m-4 font-medium">
              {props.isUpdate
                  ? props.title.replaceAll("Add", "Update")
                  : props.title}
              </h1>
            </header>
          )}

          <div className="!pt-4">{props.children}</div>
          {!props.hideButtons && (
            <div className="!p-4 !py-4 text-right">
              {props.optional && (
                <Button
                  text={props.optionalText}
                  className="mx-2"
                  onClick={props.optionalonClick}
                  variant="btn_cancel"
                />
              )}

              <Button
                text="Cancel"
                className="mx-2"
                onClick={props.onCancelModal}
                variant="btn_cancel"
              />
              <Button
                text={"Submit"}
                className="mr-2"
                onClick={props.onSubmit}
                isLoading={props?.isLoading}
                variant="btn_submit"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Modal;
