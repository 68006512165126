import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import {
  add_new_record,
  add_table_rows,
  edit_record,
  get_custom_tags,
  update_table_rows,
} from "../../redux/databaseSlice";
import { toast } from "react-toastify";
import Button from "../../components/atoms/Button";
import { FaSearch } from "react-icons/fa";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { SelectButton } from "primereact/selectbutton";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import InputIcon from "@mui/icons-material/Input";
import CancelIcon from "@mui/icons-material/Cancel";
import { deployApi } from "../../api";
import DotsLoader from "../../components/atoms/DotsLoader";
function AddUpdateRows({
  editingRecord,
  onCancelForm,
  isNew,
  dbName,
  tableName,
  refreshData
}) {
  const dispatch = useDispatch();
  const { isLoading, tableRows, customTags } = useSelector(
    (state) => state.database
  );
  const [isEdit, setIsEdit] = useState(editingRecord);
  const [isTags, setIsTags] = useState("current");
  const [isMode, setIsMode] = useState("edit");
  const [isCell, setIsCell] = useState();
  const [loading, setLoading] = useState(false);

  const [tagsArray, setTagsArray] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [rowModesModel, setRowModesModel] = React.useState({});
  const handleShowTags = (value) => {
    setTagsArray([]);
    if (typeof value !== "string") return;
    if (value?.match(/\[.*?\]/g)) {
      let tags = [];
      value?.match(/\[.*?\]/g)?.map((tag) => {
        if (customTags?.data?.find(({ tagName }) => tagName === tag)) {
          const isTag = customTags?.data?.find(
            ({ tagName }) => tagName === tag
          );
          if (!tags.filter(({ tagName }) => tagName === tag).length > 0) {
            tags = [...tags, isTag];
          }
        }
      });
      setTagsArray(tags);
    }
  };

  React.useEffect(() => {
    if (isTags === "current") {
      handleShowTags(isEdit[0][isCell]);
    } else if (isTags === "all") {
      setTagsArray(customTags?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCell, isEdit, isTags, customTags?.data]);

  function getKeyOfEmptyValue(obj) {
    for (let key in obj) {
      if (!obj[key] || obj[key] === "") {
        return key; // Return the key with an empty value
      }
    }
    return null; // Return null if no empty values found
  }

  const onSubmit = async () => {
    const emptyKey = getKeyOfEmptyValue(isEdit[0]);
    if (emptyKey) {
      const c = window.confirm(
        `Some fields are empty, are you sure want to save it?`
      );
      if (!c) {
        return setIsCell(emptyKey);
      }
    }
    if (isNew) {
      try {
        const res = await dispatch(
          add_table_rows({ dbName: dbName, tableName: tableName, data: isEdit })
        );
        if (res.payload.status === 200) {
          toast.success("Data Added Successfully");
          dispatch(add_new_record(isEdit));
          refreshData()
          onCancelForm();
        } else {
          toast.error("Data couldn't be Added");
        }
      } catch (error) {
        console.log(
          "Error ~ file: AddUpdateForm.jsx:157 ~ getJob ~ error",
          error
        );
        toast.error("Data couldn't be Added");
      }
    } else {
      try {
        const res = await dispatch(
          update_table_rows({
            dbName: dbName,
            tableName: tableName,
            data: isEdit,
          })
        );
        if (res.payload.status === 200) {
          toast.success("Data update successfully");
          refreshData()
          dispatch(edit_record(isEdit));
          onCancelForm();
        } else {
          toast.error("Data couldn't be updated");
        }
      } catch (error) {
        console.log(
          "Error ~ file: AddUpdateForm.jsx:157 ~ getJob ~ error",
          error
        );
        toast.error("Data couldn't be updated");
      }
    }

  };

  const updateState = (index) => (e) => {
    const newArray = isEdit?.map((item, i) => {
      if (index === i) {
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });
    setIsEdit(newArray);
  };

  const onRemove = (id) => {
    const arr = isEdit.filter((row) => row.id !== +id);
    setIsEdit(arr);
  };

  const addNewrecord = () => {
    if (isEdit.length > 0) {
      let newObj = Object.keys(isEdit[0])?.reduce((accumulator, key) => {
        return { ...accumulator, [key]: "" };
      }, {});
      let id = Math.max(...isEdit?.map((o) => o.id));
      newObj = { ...newObj, id: id + 1 };
      setIsEdit([...isEdit, newObj]);
    } else if (tableRows.data.length > 0) {
      let newObj = Object.keys(tableRows?.data[0])?.reduce(
        (accumulator, key) => {
          return { ...accumulator, [key]: "" };
        },
        {}
      );
      let id = Math.max(...tableRows?.data?.map((o) => o.id));
      newObj = { ...newObj, id: id + 1 };
      setIsEdit([...isEdit, newObj]);
    }
  };

  React.useEffect(() => {
    if (isEdit.length > 0) {
      setIsCell(Object.keys(isEdit[0])[1]);
    }
    // eslint-disable-next-line
  }, []);
  const modalRef = React.useRef(null);
  const inputRef = React.useRef(null);
  const options = Object?.keys(isEdit[0] ?? {})?.map((header, index) => ({
    value: header,
    label: header?.replace("_", " "),
  }));
  const replaceTags = (text) => {
    let replacedText = text;

    tagsArray?.forEach((tag) => {
      const regex = new RegExp(
        tag?.tagName.replace(/\[/g, "\\[")?.replace(/\]/g, "\\]"),
        "g"
      );
      const descriptions = tag.description.split("||");
      const randomIndex = Math.floor(Math.random() * descriptions.length);
      replacedText = replacedText?.replace(regex, descriptions[randomIndex]);
    });

    return replacedText;
  };
  const formatMarkdown = (text) => {
    let textWithLineBreaks = text.replace(
      /\r\r\r\n\s*\r\r\r\n/g,
      "<br><br>\n\n"
    );
    textWithLineBreaks = textWithLineBreaks.replace(
      /\r\n\s*\r\n/g,
      "<br><br>\n\n"
    );
    textWithLineBreaks = textWithLineBreaks.replace(/\n\s*\n/g, "<br><br>\n\n");
    // const textWithLineBreaks = text.replace(/(\r\n\s*){2,}/g, '\r<br>\n\n');
    // const textWithSpaces = textWithLineBreaks.replace(/ /g, '&nbsp;');
    return textWithLineBreaks;
  };

  const insertTextAtCursor = (textToInsert) => {
    const input = inputRef.current;
    if (input) {
      const start = input.selectionStart;
      const end = input.selectionEnd;
      const currentValue = input.value;

      const newValue =
        currentValue.substring(0, start) +
        textToInsert +
        currentValue.substring(end);

      input.value = newValue;
      const targetName = input.getAttribute("name");
      // Update state using setIsEdit
      const updatedArray = isEdit.map((item, index) => {
        if (index === 0) {
          return { ...item, [targetName]: newValue };
        } else {
          return item;
        }
      });
      setIsEdit(updatedArray);
      // Set the cursor position after inserted text
      input.selectionStart = input.selectionEnd = start + textToInsert.length;
      // input.focus();
    }
  };
  const filteredTags = tagsArray?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: "view" } });
  };

  const handleEditClick = (id) => () => {
    const lengthOfRowModesModel = Object.keys(rowModesModel).length;
    if (lengthOfRowModesModel) {
      const updatedRowModesModel = {}; // Create a new object for updates

      Object.keys(rowModesModel).forEach((id) => {
        updatedRowModesModel[id] = { mode: "view", ignoreModifications: true };
      });

      // Perform a single state update after creating the updated object
      setRowModesModel({
        ...rowModesModel,
        ...updatedRowModesModel,
        [id]: { mode: "edit" },
      });
    } else {
      setRowModesModel({ ...rowModesModel, [id]: { mode: "edit" } });
    }
  };
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: "view", ignoreModifications: true },
    });
  };

  const handleRowEditStart = (_params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (_params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleSaveTags = async (newRow) => {
    const updatedRow = newRow;
    delete newRow.counter;
    setLoading(true);
    try {
      deployApi
        .put(`api/industries/editTableRows/${dbName}/custom_tag`, [newRow])
        .then(() => {
          toast.success("Tag update successfully");
          dispatch(
            get_custom_tags({ dbName: dbName, tableName: "custom_tag" })
          );
        })
        .catch((error) => {
          console.log(error);
          toast.error("Tag couldn't be updated");
        })
        .finally(() => {
          setLoading(false);
        });
      return updatedRow;
    } catch (error) {
      console.log(
        "Error ~ file: AddUpdateForm.jsx:157 ~ getJob ~ error",
        error
      );
      toast.error("Tag couldn't be updated");
    }
  };

  // const highlightMatch = (text) => {
  //   if (!searchInput) {
  //     return text; // Return the original text if the search term is empty
  //   }
  //   const regex = new RegExp(`(${searchInput})`, 'gi');
  //   console.log("🚀 ~ file: AddUpdateRows.jsx:326 ~ highlightMatch ~ regex:", regex)
  //   return text.replace(regex, '<mark style="background-color:#ff9632">$1</mark>');
  // };
  const highlightMatch = (text) => {
    if (!searchInput) {
      return text; // Return the original text if the search term is empty
    }

    const escapedInput = searchInput.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(`(${escapedInput})`, "gi");
    return text.replace(regex, (match) => {
      if (match === "||") {
        return '<mark style="background-color:#ff9632">||</mark>'; // Highlight || as text string
      } else {
        return `<mark style="background-color:#ff9632">${match}</mark>`; // Highlight the searchInput term
      }
    });
  };
  return (
    <div className="w-full h-screen z-[100] fixed top-0 left-0 opacity-100 bg-black/[0.75] flex items-center justify-center">
      {loading ? <DotsLoader /> : null}

      <div
        className={`z-10 w-[95%] min-w-[730px] max-h-[calc(100vh-30px)] h-[90vh] overflow-y-auto bg-neutral-100 p-3.5 pt-0 border-[10px] border-white`}
        ref={modalRef}
      >
        <div className="w-full max-h-[520px] px-3.5">
          <header className=" h-auto flex items-center justify-center shadow-lg">
            <h1 className=" text-2xl text-violet-600 m- font-medium">
              {isNew ? "Add new content" : "Update content"}
              {/* {modalTitle} */}
            </h1>
          </header>
          <div className="border-y border-1 bg-[#f7f8f8] p-[6px] pl-3.7 !pr-4 grid grid-cols-2 gap-x-2">
            <div className="col-span-1 flex flex-col">
              <div className="flex flex-row items-center space-x-2">
                <div className="flex items-center !w-1/2 appearance-none px-3 flex- border border-gray-300 dark:border-white/25 rounded-md bg-white dark:bg-blue">
                  <FaSearch className="w-5" />
                  <input
                    placeholder={"Search..."}
                    className="bg-transparent flex-1 w-full outline-none ml-3 py-2 appearance-none"
                    onChange={(e) => setSearchInput(e.target.value)}
                    value={searchInput}
                  />
                </div>
                <ul className="flex flex-row items-center w-fit !mb-0 !pl-0">
                  <li
                    className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                      isTags === "current"
                        ? "border-b-2 border-red-700 text-red-700"
                        : ""
                    }`}
                    onClick={() => setIsTags("current")}
                  >
                    Current Tags
                  </li>
                  <li
                    className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                      isTags === "all"
                        ? "border-b-2 border-red-700 text-red-700"
                        : ""
                    }`}
                    onClick={() => setIsTags("all")}
                  >
                    All Tags
                  </li>
                </ul>
              </div>

              <div style={{ height: "65vh", width: "100%" }}>
                <DataGridPro
                  columns={[
                    { headerName: "#", field: "counter", width: 50 },
                    { headerName: "TagName", field: "tagName", width: 80,
                    renderCell: (params) => {
                      return (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: highlightMatch(params.row.tagName),
                          }}
                        />
                      );
                    }, },
                    {
                      headerName: "Description",
                      field: "description",
                      flex: 1,
                      editable: true,
                      renderCell: (params) => {
                        return (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: highlightMatch(params.row.description),
                            }}
                          />
                        );
                      },
                    },
                    {
                      field: "actions",
                      type: "actions",
                      cellClassName: "actions",
                      disableColumnMenu: false,
                      width: 100,
                      filterable: false,
                      getActions: (params) => {
                        const isInEditMode =
                          rowModesModel[params.id]?.mode === "edit";
                        if (isInEditMode) {
                          return [
                            <GridActionsCellItem
                              icon={<SaveIcon />}
                              label="Save"
                              onClick={handleSaveClick(params.id)}
                              className="focus:outline-none"
                            />,
                            <GridActionsCellItem
                              icon={<CancelIcon />}
                              label="Cancel"
                              className="textPrimary focus:outline-none"
                              onClick={handleCancelClick(params.id)}
                              color="inherit"
                            />,
                          ];
                        } else {
                          return [
                            <GridActionsCellItem
                              icon={<InputIcon />}
                              label="Insert Tag"
                              className="textPrimary focus:outline-none"
                              onClick={() =>
                                insertTextAtCursor(
                                  isEdit[0][isCell]?.length > 0
                                    ? ` ${params.row.tagName}`
                                    : params.row.tagName
                                )
                              }
                              color="inherit"
                            />,
                            <GridActionsCellItem
                              icon={<EditIcon />}
                              label="Edit"
                              className="textPrimary focus:outline-none"
                              onClick={handleEditClick(params.id)}
                              color="inherit"
                            />,
                          ];
                        }
                      },
                    },
                  ]}
                  onCellEditStart={(params, event) =>
                    (event.defaultMuiPrevented = true)
                  }
                  onCellEditStop={(params, event) =>
                    (event.defaultMuiPrevented = true)
                  }
                  processRowUpdate={handleSaveTags}
                  rows={filteredTags?.map((item, index) => {
                    let counter = index + 1;
                    const { id, tagName, description } = item;
                    return {
                      id,
                      counter,
                      tagName,
                      description,
                    };
                  })}
                  editMode="cell"
                  getRowId={(row) => {
                    return row.id; // Ensure to return the row.id value
                  }}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStart={handleRowEditStart}
                  onRowEditStop={handleRowEditStop}
                  onProcessRowUpdateError={console.error}
                  rowModesModel={rowModesModel}
                />
              </div>
            </div>

            <div className="col-span-1 flex flex-col">
              <div className="flex flex-row items-center space-x-2 h-[42px]">
                <ul className="flex flex-row items-center justify-center w-fit !mb-0 !pl-0">
                  <li
                    className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                      isMode === "edit"
                        ? "border-b-2 border-red-700 text-red-700"
                        : ""
                    }`}
                    onClick={() => setIsMode("edit")}
                  >
                    Edit Mode
                  </li>
                  <li
                    className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                      isMode === "read"
                        ? "border-b-2 border-red-700 text-red-700"
                        : ""
                    }`}
                    onClick={() => setIsMode("read")}
                  >
                    Read Mode
                  </li>
                </ul>
              </div>
              <div className="card flex justify-center mt-">
                <SelectButton
                  value={isCell}
                  onChange={(e) => setIsCell(e.value)}
                  optionLabel="label"
                  options={options}
                  className="flex flex-row space-x-2 capitalize focus:shadow-none justify-center"
                />
              </div>
              {isMode === "read" ? (
                <ReactMarkdown
                  className="p-2"
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                >
                  {formatMarkdown(replaceTags(isEdit[0][isCell]))}
                </ReactMarkdown>
              ) : (
                // <div className="p-2">{replaceTags(isEdit[0][isCell])}</div>
                <td>
                  <TextareaAutosize
                    value={isEdit[0][isCell]}
                    style={{
                      width: "auto",
                      minWidth: "100%",
                    }}
                    ref={inputRef}
                    onChange={updateState(0)}
                    name={isCell}
                    className="!px-2 focus:outline-none border-2 border-dashed focus:border-violet-600"
                    autoFocus={true}
                    disabled={isCell === "id" ? true : false}
                    onFocus={(e) =>
                      (e.currentTarget.selectionEnd = e.target.value.length)
                    }
                  />
                </td>
              )}
            </div>
          </div>
          {/* <div className="!pt-4">
            <div className="bg-white !my-3 border rounded overflow-y-auto flex flex-wrap flex-row w-full border-[#ccc]">
              {isEdit?.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-striped table-bordered">
                    <thead className="sticky top-0">
                      <tr>
                        {Object?.keys(isEdit[0] ?? {})?.map((header, index) => {
                          return (
                            <>
                              <th
                                key={index}
                                className="capitalize"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {header?.replace("_", " ")}
                              </th>
                            </>
                          );
                        })}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isEdit?.map((val, index, key) => {
                        return (
                          <tr key={index}>
                            {Object.entries(isEdit[index]).map(
                              ([key, value]) => {
                                return (
                                  <>
                                    {val?.id === isEdit[isIndex]?.id &&
                                    key === isCell ? (
                                      <td>
                                        <TextareaAutosize
                                          value={value}
                                          style={{
                                            width: "auto",
                                            minWidth: "100%",
                                          }}
                                          onChange={updateState(index)}
                                          name={isCell}
                                          className="!px-2 focus:outline-none border-2 border-dashed focus:border-violet-600"
                                          autoFocus={true}
                                          disabled={
                                            isCell === "id" ? true : false
                                          }
                                          onFocus={(e) =>
                                            (e.currentTarget.selectionEnd =
                                              e.target.value.length)
                                          }
                                        />
                                      </td>
                                    ) : (
                                      <td
                                        onClick={() => {
                                          setIsCell(key);
                                          setIsIndex(index);
                                        }}
                                      >
                                        {value}
                                      </td>
                                    )}
                                  </>
                                );
                              }
                            )}

                            <td
                              className="flex flex-row items-center justify-around border-0"
                              style={{ justifyContent: "space-around" }}
                            >
                              <FcCancel
                                size={25}
                                className="cursor-pointer !ml-1"
                                onClick={() => onRemove(val.id)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : null}
              {isNew && (
                <div className="float-right w-full">
                  <button
                    className="btn mt-2 mb-2 float-right mr-2"
                    style={{
                      backgroundColor: "#7c3aed",
                      color: "#fff",
                      height: 36,
                    }}
                    onClick={addNewrecord}
                    type="button"
                  >
                    Add More
                  </button>
                </div>
              )}
            </div>
          </div> */}
          <div className="!p-4 !py-4 text-right">
            <Button
              text="Cancel"
              className="mx-2"
              onClick={onCancelForm}
              variant="btn_cancel"
            />
            <Button
              text={"Submit"}
              className="mr-2"
              onClick={onSubmit}
              isLoading={isLoading}
              variant="btn_submit"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUpdateRows;
