import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MultiSelect from "../components/atoms/MultiSelect";
import Select from "react-select";
import { MdCancel } from "react-icons/md";
import DotsLoader from "../components/atoms/DotsLoader";
import {
  deploy_project,
  get_domains,
  get_templates_repo,
} from "../redux/deploySlice";
import { useDispatch, useSelector } from "react-redux";
import { get_industries } from "../redux/industriesSlice";
const AddProjects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading, templates, domains } = useSelector(
    (state) => state.deploy
  );

  const { industries } = useSelector((state) => state.industries);
  if (!localStorage.getItem("accessToken")) {
    navigate("/login");
  }
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [githubName, setGithubName] = useState();

  const [deployWith, setDeployWith] = useState("vercel");
  const [projects, setProjects] = useState([]);
  const [showAvail, setShowAvail] = useState(false);
  const [showUsed, setShowUsed] = useState(false);

  useEffect(() => {
    dispatch(get_templates_repo());
    dispatch(get_industries());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(get_domains({ id: selectedIndustry, deploy_with: deployWith }));
    // eslint-disable-next-line
  }, [selectedIndustry, deployWith]);

  const onSelectDomain = (domain) => {
    if (selectedDomains?.length > 0) {
      if (selectedDomains?.filter((item) => item === domain).length > 0) {
        setSelectedDomains(
          selectedDomains.filter((item) => {
            return item !== domain;
          })
        );
      } else {
        setSelectedDomains([...selectedDomains, domain]);
      }
    } else {
      setSelectedDomains([...selectedDomains, domain]);
    }
  };

  const onRemoveDomain = (domain) => {
    if (projects.length > 0) {
      const index = projects.findIndex((item) => item.domains.includes(domain));
      let newProduct = [...projects];
      if (projects.filter((item) => item.domains.includes(domain)).length > 0) {
        newProduct[index].domains = newProduct[index].domains
          .split(",")
          .filter((val) => {
            return val !== domain;
          })
          .toString();
        if (!newProduct[index].domains) {
          newProduct.splice(index, 1);
        }
        setProjects(newProduct);
      }
    }
  };

  const onClearAllDomain = () => {
    setSelectedDomains([]);
  };

  const addProject = () => {
    if (selectedDomains && selectedTemplate) {
      if (
        projects.filter(
          (item) =>
            item.template === selectedTemplate &&
            item.deploy_with === deployWith
        ).length > 0
      ) {
        const index = projects.findIndex(
          (item) =>
            item.template === selectedTemplate &&
            item.deploy_with === deployWith
        );
        let newProject = [...projects];
        newProject[index].domains =
          newProject[index].domains + "," + selectedDomains.toString();
        setProjects(newProject);
        setSelectedDomains([]);
        setSelectedTemplate("");
      } else {
        const newProject = {
          domains: selectedDomains.toString(),
          template_name: selectedTemplate,
          github_full_name: githubName,
        };
        setProjects((projects) => [...projects, newProject]);
        setSelectedDomains([]);
        setSelectedTemplate("");
      }
    } else {
      alert("Please fill in the details");
    }
  };
  const removeProject = (index) => () => {
    if (window.confirm("Are you sure to remove this project from the list?")) {
      const newProjects = [...projects];
      newProjects.splice(index, 1);
      setProjects(newProjects);
    }
  };

  const onDeploy = async () => {
    if (projects) {
      dispatch(deploy_project({ deploy_with: deployWith, copies: projects }));
    } else {
      alert("Please fill and save all the forms");
    }
  };
  const style = {
    container: (provided) => ({
      ...provided,
      width: "324px",
      height: "40px",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10,
    }),
    control: (provided) => ({
      ...provided,
      height: "40px",
      minHeight: "40px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "40px",
    }),
  };
  const isUsed = (val) => {
    if (projects?.length > 0) {
      if (
        projects?.filter((item) => item.domains.includes(val.domain)).length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  const onSelectAllDomain = () => {
    let domain = [];
    domains?.map((item) => {
      if (!isUsed(item)) {
        return (domain = [...domain, item.domain]);
        // eslint-disable-next-line
      } else return;
    });
    setSelectedDomains(domain);
  };
  const isSelected = (val) => {
    if (selectedDomains.length > 0) {
      if (selectedDomains.filter((item) => item === val.domain).length > 0) {
        return true;
      }
    }
    return false;
  };
  let availCount = 0;
  let usedCount = 0;

  domains?.map((domain) => {
    return isUsed(domain)
      ? (usedCount = +usedCount + 1)
      : (availCount = +availCount + 1);
  });
  return (
    <div className="container-fluid bg-[#f5f5f5] min-h-screen">
      {isLoading && <DotsLoader text={"Please Wait"} />}
      <h1 className="text-2xl font-extrabold mt-4">Industries & Domains</h1>
      <div className="container-fluid border rounded bg-white py-3 w-full mt-3">
        <div className="flex justify-between items-center"></div>
        <div className="d-flex align-items-center w-100 justify-content-center mb-3">
          <div className="col-3 w-100 pl-0">
            <Select
              name="depoy_with"
              placeholder="Deploy With"
              onChange={(e) => setDeployWith(e.value)}
              defaultValue={{ label: "Vercel", value: "vercel" }}
              options={[
                { label: "Vercel", value: "vercel" },
                { label: "Docker", value: "docker" },
                { label: "AWS", value: "AWS" },
              ]}
              valueKey="value"
              labelKey="label"
              className="w-full"
            />
          </div>
          <Select
            styles={style}
            onChange={(e) => {
              if (projects.length > 0) {
                if (
                  window.confirm(
                    "Data will be lost, Are you sure to change the industry?"
                  )
                ) {
                  setProjects([]);
                  setSelectedIndustry(e.id);
                }
              } else {
                setSelectedIndustry(e.id);
              }
            }}
            name={"industry"}
            options={industries}
            getOptionLabel={(options) => options.industry_name}
            getOptionValue={(options) => options.id}
            placeholder={"Select Industry"}
          />
        </div>

        {domains?.length > 0 && (
          <div className="row mb-3">
            <div className="col-6 d-flex flex-column">
              <span
                className="border rounded border-violet-600 text-center p-1 hover:bg-violet-600 hover:text-white hover:cursor-pointer text-violet-600"
                onClick={() => setShowAvail(!showAvail)}
              >
                Available Domains ({availCount})
              </span>
              {showAvail && (
                <div
                  className="d-flex flex-column pt-2 shadow rounded-bottom shadow-violet-100"
                  style={{ height: 100, overflowY: "auto" }}
                >
                  {domains?.map((domain, index) => {
                    return isUsed(domain) ? null : (
                      <li
                        key={index}
                        onClick={() => {
                          onSelectDomain(domain.domain);
                        }}
                        className={`list-unstyled d-flex justify-content-between align-items-center flex-row w-100 px-3 
                          ${
                            isSelected(domain)
                              ? " text-violet-600"
                              : " text-black-500"
                          }
                          hover:cursor-pointer
                          hover:bg-gray-200
                          w-100
                        `}
                        name="list-item"
                        id="list-item"
                      >
                        <div className="d-flex align-items-center flex-row w-100 font_mont">
                          {domain.domain}
                        </div>
                        {isSelected(domain) && (
                          <span
                            className={
                              isSelected(domain)
                                ? "text-violet-600"
                                : " text-gray-500"
                            }
                            color="black"
                          >
                            ✓
                          </span>
                        )}
                      </li>
                    );
                  })}
                </div>
              )}
            </div>

            <div className="col-6 d-flex flex-column">
              <span
                className="border rounded border-violet-600 text-center p-1 hover:bg-violet-600 hover:text-white hover:cursor-pointer text-violet-600"
                onClick={() => setShowUsed(!showUsed)}
              >
                Used Domains ({usedCount})
              </span>
              {showUsed && (
                <div
                  className="d-flex flex-column pl-3 pt-2 shadow rounded-bottom shadow-violet-100"
                  style={{ height: 100, overflowY: "auto" }}
                >
                  {domains?.map((domain, index) => {
                    return isUsed(domain) ? (
                      <li
                        key={index}
                        onClick={() => {
                          onRemoveDomain(domain.domain);
                        }}
                        className={`list-unstyled d-flex justify-content-between align-items-center flex-row w-100 px-3 
                          hover:cursor-pointer
                          hover:bg-gray-200
                          w-100
                        `}
                        name="list-item"
                        id="list-item"
                      >
                        <div className="d-flex align-items-center flex-row w-100 font_mont">
                          {domain.domain}
                        </div>
                        <MdCancel
                          size={20}
                          color="#7c3aed"
                          onClick={() => onRemoveDomain(domain.domain)}
                        />
                      </li>
                    ) : null;
                  })}
                </div>
              )}
            </div>
          </div>
        )}

        <div className="row align-items-center  w-100 border-bottom pb-2 mx-1">
          <span className="ml-3">{+projects?.length + 1}</span>

          <div className="col-5 w-100 pr-0">
            <MultiSelect
              label="Domains"
              name="domains"
              options={domains}
              values={selectedDomains}
              valueKey="domain"
              labelKey="domain"
              addHandler={onSelectDomain}
              projects={projects}
              onSelectAllDomain={onSelectAllDomain}
              onClearAllDomain={onClearAllDomain}
            />
          </div>
          <div className="col-5 w-100 pl-0">
            <Select
              onChange={(e) => {
                setSelectedTemplate(e.template_name);
                setGithubName(e.github_full_name);
              }}
              name={"industry"}
              options={templates}
              getOptionLabel={(options) => options.template_name}
              getOptionValue={(options) => options.template_name}
              placeholder={"Select Template"}
            />
          </div>
          <div className="col-1">
            <button
              className="btn"
              style={{ backgroundColor: "#7c3aed", color: "#fff", height: 40 }}
              onClick={addProject}
              type="button"
              disabled={selectedDomains && selectedTemplate ? false : true}
            >
              Add
            </button>
          </div>
        </div>
        <h3 className="col-6 text-xl font-extrabold my-3">Projects</h3>

        <ol className="max-h-[260px] overflow-y-auto">
          {projects?.map((project, index) => (
            <div key={index}>
              <details>
                <summary className="flex flex-row items-center justify-between hover:cursor-pointer hover:bg-gray-100 py-2 px-1">
                  <div className="text-sm text-gray-900">
                    <span className="ml-3">{+index + 1}</span>
                    <span className="ml-4">{project.template_name}</span>
                  </div>
                  <span>{deployWith}</span>
                  <IconButton
                    iconClass="fas fa-times"
                    onClick={removeProject(index)}
                  />
                </summary>
                <div className="flex flex-col">
                  <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="overflow-hidden">
                        <table className="min-w-full ml-5">
                          <tbody>
                            <tr className="bg-white border-b">
                              <th
                                scope="col"
                                className="text-xs font-bold text-gray-900 py-2"
                              >
                                Domains
                              </th>
                              <td className="text-sm text-gray-900 font-light text-xs py-2 whitespace-nowrap">
                                {project?.domains
                                  ?.split(",")
                                  .map((domain, index) => (
                                    <div
                                      key={index}
                                      className="h-6 flex items-center cursor-default"
                                    >
                                      <span className="text-black-900">
                                        {domain}
                                      </span>
                                      <MdCancel
                                        size={20}
                                        className="ml-1 cursor-pointer"
                                        color="#7c3aed"
                                        onClick={() => onRemoveDomain(domain)}
                                      />
                                    </div>
                                  ))}
                              </td>
                            </tr>
                            <tr className="bg-white border-b">
                              <th
                                scope="col"
                                className="text-xs font-bold text-gray-900 py-2"
                              >
                                Template
                              </th>
                              <td className="text-sm text-gray-900 font-light text-xs py-2 whitespace-nowrap">
                                {project.template_name}
                              </td>
                            </tr>
                            <tr className="bg-white border-b">
                              <th
                                scope="col"
                                className="text-xs font-bold text-gray-900 py-2"
                              >
                                Github Name
                              </th>
                              <td className="text-sm text-gray-900 font-light text-xs py-2 whitespace-nowrap">
                                {project.github_full_name}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </details>
              <hr className="border-violet-100 my-2" />
            </div>
          ))}
        </ol>
      </div>
      {projects.length > 0 && (
        <div className="d-flex justify-content-center">
          <button
            className="btn mt-2 mb-2"
            style={{ backgroundColor: "#7c3aed", color: "#fff", height: 36 }}
            onClick={onDeploy}
            type="button"
          >
            Deploy
          </button>
        </div>
      )}
    </div>
  );
};
export default AddProjects;

const IconButton = ({ iconClass, onClick, disabled = false }) => {
  return (
    <button
      onClick={onClick}
      className={`mr-2 text-lg hover:text-violet-900 text-gray-500 mr-5`}
      disabled={disabled}
    >
      <i className={iconClass}></i>
    </button>
  );
};
