import { deployApi } from "../api";

export const getInsurance = async () => {
    return await deployApi.get("api/custom_vendors/insurance_comp");
  };
  export const addInsurance = async (data) => {
    return await deployApi.post("api/custom_vendors/add_insurance_comp", data);
  };
  export const updateInsurance = async (data) => {
    return await deployApi.put(`api/custom_vendors/insurance_comp/${data?.id}`,data);
  };
  export const deleteInsurance = async (id) => {
    return await deployApi.delete(`/api/custom_vendors/insurance_comp/${id}`);
  };