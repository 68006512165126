import { deployApi } from "../api";
export const getDomainList = async (data) => {
  
  return await deployApi.get(`/api/domains`, {
    params: data,
  });
};
export const getVerification = async (id) => {
  return await deployApi.get(`api/domains/vercel_verify?id=${id}`);
};
export const getPendingDomainsPhone = async (id) => {
  return await deployApi.get(`/api/domains/pendingDomainsPhone`);
};

export const addDomain = async (data) => {
  return await deployApi.post(`/api/domains/add_domain`, data);
};

export const deleteDomain = async (id) => {
  return await deployApi.delete(`api/domains/delete_deployment/${id}`);
};

export const updateTemplate = async (data) => {
  return await deployApi.put(`api/domains/template`, data);
};

export const singleDeploy = async (id) => {
  return await deployApi.post(`api/domains/deploy/${id}`);
};
export const deleteDomainImages = async (id) => {
  return await deployApi.delete(`api/templates/delete_domain_images/`,{data:id});
};
export const generateARN = async (domain) => {
  return await deployApi.post(`api/domains/${domain?.endPoint}`, domain);
};
export const addGoogleSearchDomain = async (domain) => {
  return await deployApi.post(`api/google_seo/add_google_search_console_domain`, domain);
};
export const getGoogleConsoles = async () => {
  return await deployApi.get(`api/google_seo/google_search_console_list`);
};
export const updateDomainsCloudflare = async (domain) => {
  return await deployApi.post(`/api/domains/update_domain_cloudflare_account`, domain);
};
export const addNewDomain = async (domain) => {
  return await deployApi.post(`/api/domains/add_domain`, domain);
};
export const updateDomain = async (domain) => {
  return await deployApi.post(`/api/domains/update_domain`, domain);
};
export const setDomainImages = async (domain) => {
  return await deployApi.post(`/api/domains/set_img`, domain);
};
export const updateDomainImages = async (id) => {
  return await deployApi.put(`api/industries/update_images_by_industry/${id}`);
};
export const deleteDatabases = async (ids) => {
  return await deployApi.delete(`api/domains/delete_databases`, {data:ids});
};
export const deleteDeployment = async (ids) => {
  return await deployApi.delete(`api/domains/delete_deployment`, {data:ids});
};
export const getDomainImages = async (domain) => {
  return await deployApi.get(`api/template-images/domain?domain=${domain}`);
};
export const getPendingDeployments = async () => {
  return await deployApi.get(`/api/domains/aws/pendingDeployments`);
};
export const deployAwsDomains = async (arn) => {
  return await deployApi.post(`/api/deploy/aws_domains`, arn);
};
export const reDeploy = async (id) => {
  return await deployApi.post(`api/domains/redeploy/${id}`);
};
export const getAllDomainDrd = async () => {
  return await deployApi.get("api/domains/get_all");
};
export const getDomainDbTables = async (dbName) => {
  return await deployApi.get(`api/domains/dbTables/${dbName}`);
};

export const getDomainTableRows = async (dbName, tableName) => {
  return await deployApi.get(`api/domains/tableRows/${dbName}/${tableName}`);
};

export const exportDomainTable = async (dbName, tableName) => {
  return await deployApi.get(`api/domains/export_table/${dbName}/${tableName}`);
};

export const exportDomainSampleTable = async (dbName, tableName) => {
  return await deployApi.get(
    `api/domains/export_sample_table/${dbName}/${tableName}`
  );
};

export const importDomainExcel = async (file, dbName, tableName) => {
  return await deployApi.post(
    `api/domains/import_table/${dbName}/${tableName}`,
    file,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const templateDeploy = async (data) => {
  return await deployApi.post(`/api/templates/deploy`, data);
};

export const templateReDeploy = async (id) => {
  return await deployApi.put(`/api/templates/re_deploy/${id}`);
};
export const getPurchasedDomains = async () => {
  return await deployApi.get(`api/domains/list_purchased_domains`);
};

export const copyDomains = async (ids) => {
  return await deployApi.post(`api/domains/copy_domains`, { domain_ids: ids });
};

export const getCloudFront = async () => {
  return await deployApi.get(`api/aws/cloudfront`);
};
export const getDomainPerIndustry = async (count) => {
  return await deployApi.get(`/api/domains/domains_per_industry?domain_count=${count}`);
};
export const redeployCloudFront = async (ids) => {
  return await deployApi.post(`/api/aws/cloudfront/redeploy`, { ids: ids });
}
export const getDomainWtOPhoneNum = async () => {
  return await deployApi.get(`api/domains/domain_without_phone`);
};
export const updateDomainPhoneNum = async ({id,phone}) => {
  return await deployApi.put(`api/domains/update_domain_phone/${id}`,{phone});
};
export const getCloudFrontById = async (id) => {
  return await deployApi.get(`/api/aws/cloudfront/get_cloudfront_domain/${id}`);
};
export const getCloudFlareAccount = async () => {
  return await deployApi.get(`/api/registrar/list`);
};
export const getCloudDomainCount = async (id) => {
  return await deployApi.get(`api/aws/cloudfront/get_domains_count/${id}`);
};
export const addCloudFlare = async (data) => {
  return await deployApi.post(`api/registrar`,data);
};
export const updateCloudFlare = async (data) => {
  return await deployApi.put(`api/registrar/${data.id}`, data);
};
export const getDetachDomain = async (id) => {
  return await deployApi.get(`api/aws/detach_domain`);
};
export const PostDetachDomain = async (domains) => {
  return await deployApi.post(`api/aws/detach_domain`,{domains});
};
export const DeleteDetachDomain = async (id) => {
  return await deployApi.delete(`api/aws/detach_domain`,{data:id});
};
export const listVirtualHosts = async () => {
  return await deployApi.get(`https://domain-launcher.ecommcube.com/list_virtual_hosts`);
};
export const deleteVirtualHosts = async (domain) => {
  return await deployApi.post(`https://domain-launcher.ecommcube.com/delete_virtual_hots`, domain);
};
export const getDomainNumbers = async () => {
  return await deployApi.get(`api/data/domain_number_data`);
};
export const updateDomainNumbers = async (data) => {
  return await deployApi.put(`api/data/domain_number_data`, data);
};
export const domain_number_set_pending = async (data) => {
  return await deployApi.put(`api/data/domain_number_set_pending`);
};

export const getDomainRunners = async (data) => {
  return await deployApi.get(`api/domain_runners`);
};

export const updateDomainRunner = async (data) => {

  return await deployApi.put(`api/domain_runners/${data.id}`, data);
};

export const addDomainRunner = async (data) => {
  return await deployApi.post(`api/domain_runners`, data);
};

export const setTestDomains = async (domains) => {
  return await deployApi.post(`api/domains/set_test_domains`, domains);
};

export const setAlternateAreaCode = async (data) => {
  return await deployApi.post(`/api/domains/set_alternate_area_codes`, data);
};
export const checkPhoneAvailability = async (data) => {
  return await deployApi.post(`/api/domains/check_phone_availability`, data);
};

export const getAlternateDomainPhone = async (id) => {
  return await deployApi.get(`api/domains/list_alternate_area_codes`);
};