import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {createRole, getRoles, getTollfree, updateRole } from "../services";
import { deleteCallerId,getCallerId } from "../services/CallerService";


const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  
 
  callerId:[],
  
};

export const get_callerId = createAsyncThunk(
  "get_callerId",
  async ( thunkAPI) => {
    try {
      return await getCallerId();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const delete_callerId = createAsyncThunk(
  "delete_callerId",
  async (id, thunkAPI) => {
    try {
      return await deleteCallerId(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const CallerIdSlice = createSlice({
  name: "CallerIdSlice",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
     
     
      
      .addCase(get_callerId.pending, (state) => {
        state.isLoading = true;
        // state.callerId = [];
      })
      .addCase(get_callerId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.callerId = action.payload?.data;
      })
      .addCase(get_callerId.rejected, (state) => {
        state.isLoading = false;
        
      })
      .addCase(delete_callerId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_callerId.fulfilled, (state, action) => {
       
        state.isLoading = false;
      })
      .addCase(delete_callerId.rejected, (state) => {
        state.isLoading = false;
      })
      
      
      
      
     
      
      
  },
});

export const { reset } = CallerIdSlice.actions;
export default CallerIdSlice.reducer;