import React, { useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/molecules/Modal";
import {
  add_bulk_images,
  get_blogs,
  get_image_types,
  get_services,
  update_bulk_images,
} from "../../redux/bulkImagesSlice";
import DotsLoader from "../../components/atoms/DotsLoader";
import { get_industries } from "../../redux/industriesSlice";
import { get_templates } from "../../redux/templateImagesSlice";
import { toast } from "react-toastify";
import {AiFillCloseCircle} from "react-icons/ai"

function AddImage({ onCancelForm, modalTitle, editingRecord }) {
  const dispatch = useDispatch();
  const { templates } = useSelector((state) => state.images);
  const { industries } = useSelector((state) => state.industries);
  const [wrongSize, setWrongSize] = React.useState([]);
  const { isLoading, folders, services,blogs } = useSelector(
    (state) => state.bulkImages
  );
  const [folderOptions, setFolderOption] = React.useState([]);

  let initialData = {
    template_id: "",
    industry_id: "",
    image_type: "",
    alt: "",
    title: "",
    tag_name: "",
    service_id: "",
    blog_id: "",

  };

  if (editingRecord) {
    let arr = [];
    // eslint-disable-next-line
    editingRecord?.industries?.map((val) => {
      arr = [...arr, val?.id];
    });
    const {
      id,
      template,
      industry,
      image_type,
      alt,
      title,
      tag_name,
      service,
    } = editingRecord;
    initialData = {
      id,
      template_id:template?.id,
      industry_id:industry?.id,
      image_type,
      alt,
      title,
      tag_name,
      service_id:service?.id?service?.id:"",
    };
  }
  const [data, setData] = useState(initialData);
  
  const [files, setFiles] = useState([]);
  
 
  const onDrop = (acceptedFiles) => {
    setFiles([
      ...files,
      ...acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    ]);
  };
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const onSubmit = async () => {
    const formData = new FormData();
    files?.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("template_id", data.template_id);
    formData.append("industry_id", data.industry_id);
    formData.append("image_type", data.image_type);
    formData.append("alt", data.alt);
    formData.append("title", data.title);
    formData.append("tag_name", data.tag_name);
    formData.append("service_id", data.service_id);
    formData.append("blog_id", data.blog_id);

    if (files?.length > 0) {
      if (!editingRecord) {
        try {
          const res = await dispatch(add_bulk_images(formData));
          if (res.payload.status === 200) {
            setFiles([]);
            setWrongSize([])
            
          }
        } catch (error) {
          console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
        }
      } else {
        formData.append("id", data?.id);

        try {
          const res = await dispatch(update_bulk_images(formData));
          if (res.payload.status === 200) {
            setFiles([]);
            onCancelForm();
          }
        } catch (error) {
          console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
        }
      }
    } else {
      toast.error("File not attached");
    }
  };
  React.useEffect(() => {
    if (folders?.length > 0) {
      let arr = [];
      const uniqueArray = Array.from(new Set(folders)).filter(
        (item) => item !== ""
      );
      // eslint-disable-next-line
      uniqueArray?.map((val) => {
        arr = [...arr, { label: val, value: val }];
        setFolderOption(arr);
      });
    }
  }, [folders]);
  React.useEffect(() => {
    if (data.industry_id && data.template_id) {
      dispatch(
        get_image_types({
          template_name: templates?.find(({ id }) => id === +data?.template_id)
            ?.template_name,
          industry_name: industries?.find(({ id }) => id === +data?.industry_id)
            ?.industry_name,
        })
      );
    }
    // eslint-disable-next-line
  }, [data.industry_id, data.template_id]);
  React.useEffect(() => {
    if (industries.length === 0 || !industries) {
      dispatch(get_industries());
    }
    if (templates.length === 0 || !templates) {
      dispatch(get_templates());
    }
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    if (data.industry_id) {
      dispatch(get_services(data.industry_id));
    }
    if (data.industry_id) {
      dispatch(get_blogs(data.industry_id));
    }
    // eslint-disable-next-line
  }, [data.industry_id]);
  const RemoveImage=(idx)=>{
    setFiles((olditem)=>olditem.filter((val,index)=>index!==idx))
    



  }
  const checkImageDiamension=({width,height})=>{
    if (width===512 && height===512){
      return true
    }else{
      return false
    }
  }
  return (
    <Modal
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={onSubmit}
      isLoading={isLoading}
      optional={true}
      optionalText="Clear All"
      optionalonClick={()=>setFiles([])}
    >
      {isLoading && <DotsLoader />}
      <div className=" ">
        <div className="container-fluid border rounded bg-white py-3 w-full flex flex-row justify-center">
          <div className="w-1/2 !mr-2">
            <Select
              onChange={(e) => {
                setData({
                  ...data,
                  template_id: e.id?.toString(),
                });
              }}
              name={"templates"}
              options={templates}
              getOptionLabel={(options) => options.template_name}
              getOptionValue={(options) => options.id}
              placeholder={"Select Template"}
              className="!mb-3"
              defaultValue={
                editingRecord
                  ? {
                      id: editingRecord?.template?.id,
                      template_name: editingRecord?.template?.template_name,
                    }
                  : null
              }
              menuPosition="fixed"
            />
            <Select
              onChange={(e) => {
                setData({
                  ...data,
                  industry_id: e?.id?.toString(),
                });
              }}
              name={"industry"}
              options={industries}
              getOptionLabel={(options) => options.industry_name}
              getOptionValue={(options) => options.id}
              placeholder="Select Industry"
              className="!mb-3"
              defaultValue={
                editingRecord
                  ? {
                      id: editingRecord?.industry?.id,
                      industry_name: editingRecord?.industry?.industry_name,
                    }
                  : null
              }
              menuPosition="fixed"
            />
            <Select
              onChange={(e) => {
                setData({
                  ...data,
                  service_id: e?.id?.toString(),
                });
              }}
              name={"service"}
              options={services}
              getOptionLabel={(options) => options.service_name}
              getOptionValue={(options) => options.id}
              placeholder="Select Service"
              className="!mb-3"
              defaultValue={
                editingRecord
                  ? {
                      id: editingRecord?.service?.id,
                      service_name: editingRecord?.service?.service_name,
                    }
                  : null
              }
              menuPosition="fixed"
            />
            <Select
              onChange={(e) => {
                setData({
                  ...data,
                  blog_id: e?.id,
                });
              }}
              name={"blog"}
              options={blogs}
              getOptionLabel={(options) => options.title}
              getOptionValue={(options) => options.id}
              placeholder="Select Blog"
              className="!mb-3"
              defaultValue={
                editingRecord
                  ? {
                      id: editingRecord?.blog?.id,
                      title: editingRecord?.blog?.title,
                    }
                  : null
              }
              menuPosition="fixed"
            />
            <CreatableSelect
              onChange={(e) => {
                setData({
                  ...data,
                  image_type: e?.value?.toString(),
                });
              }}
              name={"type"}
              options={
                folderOptions.length > 0
                  ? folderOptions
                  : [
                      { label: "favicon", value: "favicon" },
                      { label: "banners", value: "banners" },
                    ]
              }
              placeholder="Select Image Type"
              className="!mb-3"
              isClearable
              defaultValue={
                editingRecord
                  ? {
                      label: editingRecord?.image_type,
                      value: editingRecord?.image_type,
                    }
                  : null
              }
              menuPosition="fixed"
            />
            <input
              className="form-control-sm border !mb-3 w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="Tag Name"
              name="tag_name"
              value={data?.tag_name}
              onChange={handleInput}
            />
            <input
              className="form-control-sm border !mb-3 w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="Image Title"
              name="title"
              value={data?.title}
              onChange={handleInput}
            />
            <input
              className="form-control-sm border !mb-3 w-full h-[40px] focus:shadow-none focus:border-0"
              placeholder="Alt"
              name="alt"
              value={data?.alt}
              onChange={handleInput}
            />
          </div>
          <div className="w-1/2 !ml-2 h-full">
            <div
              className={`w-full flex justify-center border-2 border-gray-300 border-dashed rounded-md !p-2 hover:border-gray-400 focus:outline-none ${
                isDragActive ? "bg-gray-100" : "bg-white"
              }`}
              {...getRootProps()}
            >
              <label
                className={`flex justify-center w-full ${
                  files?.length > 0 ? "h-[80px]" : "h-[280px]"
                } px-4 transition appearance-none cursor-pointer`}
              >
                <span className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <span className="font-medium text-gray-600">
                    Drop files to Attach, or{" "}
                    <span className="text-blue-600 underline" onClick={open}>
                      browse
                    </span>
                  </span>
                </span>
                <input {...getInputProps()} />
              </label>
            </div>
            <div className="flex flex-wrap justify-center">
           
              {files?.length > 0
                ? files?.map((path, index) => {
                  console.log("imageeeiddd",path)
                    return (
                      <>
                      <AiFillCloseCircle onClick={()=>RemoveImage(index)}/>
                     
                        <img
                      src={path.preview}
                      className="m-2 max-h-[180px] max-w-[180px] text-center rounded-md cursor-pointer"
                     
                      style={{ backgroundColor:!wrongSize?.filter((ind)=>ind===index).length>0? "white":'rgba(255,0,0,0.3)',padding:!wrongSize?.filter((ind)=>ind===index).length>0? 0:20 }}
                      alt="img"
                      key={index}
                      onLoad={(e) => {!checkImageDiamension({width:e.target.naturalWidth,height:e.target.naturalHeight})&&setWrongSize([...wrongSize,index])}}
                    />
                     
                      </>
                     
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddImage;
