import axios from "axios";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
export const deployApi = axios.create({
  baseURL: process.env.REACT_APP_DEPLOY_API,
  headers: {
    "Content-Type": "application/json",
  },
});
let key;

deployApi.interceptors.request.use(
  async (config) => {
    key = JSON.stringify(config);
    const { accessToken, refreshToken } = localStorage;

    
    if (accessToken) {
      const jwt = jwtDecode(accessToken);
      const isExpired = dayjs.unix(jwt.exp).diff(dayjs()) < 3;
      if (!isExpired) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      } else {
        try {
          const res = await axios.get("/api/refresh", {
            baseURL: process.env.REACT_APP_DEPLOY_API,
            headers: { refreshToken },
          });

          if (res.status === 200) {
            localStorage.setItem("accessToken", res.data.accessToken);
            config.headers.Authorization = `Bearer ${res.data.accessToken}`;
          } else {
            logout(refreshToken);
          }
        } catch (err) {
          logout(refreshToken);
          return null;
        }
      }
    }

    return config;
  },
  (error) => {
    console.error("Error to call API", error);
  }
);
let isRefreshing = false;
let refreshTokens = [];

deployApi.interceptors.response.use(
  (response) => {
    
    return response;
  },
  async (error) => {
    

    const originalRequest = error.config;
    const { refreshToken } = localStorage;

    if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const res = await axios.get("/api/refresh", {
            baseURL: process.env.REACT_APP_DEPLOY_API,
            headers: { refreshToken },
          });
          if (res.status === 200) {
            localStorage.setItem("accessToken", res.data.accessToken);
            deployApi.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${res.data.accessToken}`;
            refreshTokens.forEach((cb) => cb(res.data.accessToken));
            refreshTokens.length = 0;
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${res.data.accessToken}`;
            return deployApi(originalRequest);
          } else {
            logout(refreshToken);
          }
        } catch (e) {
          logout(refreshToken);

          console.error(e);
        } finally {
          isRefreshing = false;
        }
      }

      return new Promise((resolve) => {
        refreshTokens.push((token) => {
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          resolve(deployApi(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  }
);
function logout(refreshToken) {
  localStorage.removeItem("user");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");

  axios.get("/api/logout", {
    baseURL: process.env.REACT_APP_DEPLOY_API,
    headers: { refreshToken },
  });

  window.location.reload();
}
