import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../img/logo2.png";
import logiBg from "../../img/login-bg.jpg";
import { login } from "../../redux/authSlice";
import DotsLoader from "../../components/atoms/DotsLoader";

const INITIAL_DATA = {
  username: "",
  password: "",
};

const Login = () => {
  const { isLoading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(INITIAL_DATA);
  const [formErrors, setFormErrors] = useState(INITIAL_DATA);

  const handleChange = ({ target: { name, value } }) => {
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const validate = (formData) => {
    const formErrors = { ...INITIAL_DATA };
    let isValid = true;
    for (const key in formData) {
      if (!formData[key]) {
        formErrors[key] = "This field is required";
        isValid = false;
      }
    }
    return { isValid, formErrors };
  };

  const handleSubmit = async () => {
    const { isValid, formErrors } = validate(formData);
    setFormErrors(formErrors);
    if (isValid) {
      dispatch(login(formData));
    } else {
      alert("Invalid username or password");
    }
  };

  return (
    <>
    <style jsx>
      {`
      .loginBg{background: url(${logiBg})no-repeat; background-size: cover;}
      `}
    </style>
    <div className="flex w-full items-center justify-center min-h-screen loginBg">
      {isLoading && <DotsLoader text="Please Wait" />}
      <div className="!px-8 !py-6 !mt-4 text-left bg-white shadow-lg w-10/12 sm:w-9/12 md:w-6/12 lg:w-5/12 xl:w-4/12">
        <h3 className="text-2xl font-bold text-center"><img src={logo} alt="Callrings CRM" className="w-[250px] inline-block" /></h3>
        <form>
          <div className="!mt-4">
            <div>
              <label className="block text-gray-800 text-sm" htmlFor="username">
                username
              </label>
              <input
                type="text"
                name="username"
                placeholder="Username"
                className="w-full !px-4 !py-2 !mt-2 border rounded focus:outline-none focus:ring-1 focus:ring-violet-600"
                value={formData.username}
                onChange={handleChange}
              />
              {formErrors.username && (
                <span className="text-xs tracking-wide text-red-600">
                  {formErrors.username}
                </span>
              )}
            </div>
            <div className="!mt-4">
              <label className="block text-gray-800 text-sm">password</label>
              <input
                type="password"
                name="password"
                placeholder="Password"
                className="w-full !px-4 !py-2 !mt-2 border rounded focus:outline-none focus:ring-1 focus:ring-violet-600"
                value={formData.password}
                onChange={handleChange}
              />
              {formErrors.password && (
                <span className="text-xs tracking-wide text-red-600">
                  {formErrors.password}
                </span>
              )}
            </div>
            <div className="w-full flex justify-center items-center !mt-1">
              <NavLink
                to={`/password-recovery`}
                className="text-red-400 text-base underline hover:text-red-600"
              >
                Forgot Password?
              </NavLink>
            </div>

            <div className="flex items-baseline justify-between">
              <button
                className="!px-6 !py-2 !mt-4 !mb-2 block w-full px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-normal uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-violet-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-violet-800 active:shadow-lg transition duration-150 ease-in-out"
                onClick={handleSubmit}
                type="button"
              >
                Login
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    </>
  );
};

export default Login;
