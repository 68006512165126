import React, { useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { toast } from "react-toastify";
import { deployApi } from "../../api";
import { useSelector } from "react-redux";
import Loader from "../../components/atoms/Loader";
const ShellModal = ({ onClose, setShellResponse, shellResponse, setErrorMsg }) => {
  const { runners } = useSelector((state) => state.servers);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedRunner, setSelectedRunner] = useState([]);
  const [query, setQuery] = useState("");
  const handleConfirm = async () => {
    if (selectedRunner?.length === 0 || !query) {
      return toast.error("Runner and Shell Query are required ");
    }

    setIsLoader(true);
    let array = [...selectedRunner];
    for (const item of selectedRunner) {
      const runnerName = runners?.find(({ api_url }) => api_url === item)?.name;
      try {    
        const response = await deployApi.post(`${item?.replaceAll('run_query_on_server','run_shell_command_on_server')}`, { command: query });

        console.log("🚀 ~ file: ShellModal.jsx:26 ~ handleConfirm ~ response:", response)
        if (response.status === 200) {
          setShellResponse(prevShellResponse => [
            ...prevShellResponse,
            { runner: runnerName, response: response.data }
          ]);
          toast.success(`Query completed for ${runnerName}`);
          array = array?.filter((api_url) => api_url !== item);
          setSelectedRunner((prevState) =>
            prevState.filter((api_url) => api_url !== item)
          );
        } else {
          console.error(
            `Request for ${runnerName} failed with status ${response.status}`
          );
          toast.error(
            `Query for ${runnerName} failed with status ${response.status}, ${
              response.data?.data?.message
                ? response.data?.data?.message
                : response.data
            } `
          );
        }
      } catch (error) {
        console.error(`Request for ${runnerName} encountered an error:`, error);
        toast.error(
          `Query for ${runnerName} failed with status ${
            error?.response?.status
          }, ${
            error?.response?.data?.data?.message
              ? error?.response?.data?.data?.message
              : error?.response?.data
          } `
        );
      }
    }
    if (array?.length === 0) {
      toast.success("Process Complete");
      onClose();
    }
    setIsLoader(false);
  };
  return (
    <>
      <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40">
        <div className="bg-white p-8 rounded-lg shadow-lg relative w-[400px]">
          <h2 className="text-lg font-semibold mb-4">Run Shell Query</h2>
          {/* <p className="mb-4">Are you sure you want to restart the application?</p> */}

          <div className=" !px-0.5 !mb-3">
            <label className="block">Select Runners:</label>
            <MultiSelect
              // styles={style}
              placeholder="Runners"
              options={runners}
              optionLabel="name"
              optionValue="api_url"
              filter
              maxSelectedLabels={2}
              value={selectedRunner}
              onChange={(e) => setSelectedRunner(e.value)}
              className="h-[38px] w-full !rounded-[4px] border !border-[#e5e7eb] hover:border-[#ced4da]"
            />
          </div>
          <label className="block">Enter a Shell Query:</label>
          <div className="relative flex flex-row">
            <textarea
              className="bg-white border border-gray-300 text-sm rounded w-full p-2.5 "
              onChange={(e) => setQuery(e.target.value)}
              value={query}
              name="pass"
              placeholder={"Enter a Shell Query"}
              // autoFocus={true}
            />
          </div>
          <div className="flex justify-end !mt-4">
            {isLoader ? (
              <Loader />
            ) : (
              <>
                <button
                  className="px-4 py-2 mr-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  onClick={handleConfirm}
                >
                  OK
                </button>
                <button
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShellModal;
