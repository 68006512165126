import * as _ from "lodash";
import React, { useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
const DataTable = (props) => {
  const recordsPerPage = 15000;
 
  const [page, setPage] = React.useState(1);

  const gridRef = useRef();
  const pagedTableData = _.chunk(props?.items, recordsPerPage);
  const offset = (page - 1) * recordsPerPage;
  const lastItemIndex = offset + pagedTableData[page - 1]?.length;
  const { items, columnDefs, onCellClicked, defaultColDef } = props;
  const onPaginationChanged = (params) => {
     const currentPage = params.api.paginationGetCurrentPage()+1;
     setPage(currentPage)

  };
  const defaultColDef2 = useMemo(() => {
    return {
      ...defaultColDef,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, [defaultColDef]);

  return (
    <div className="bg-white p-2">
      <div className="ml-auto mr-auto" style={{ overflowX: "hidden" }}>
        <>
          <div className="flex justify-between items-center mb-2">
            <div className={["result"].join(" ")}>
              <div className="w-fit py-[5px] text-base font-normal font-mont text-heading my-2">
                Displaying {props?.items?.length > 0 ? offset + 1 : 0}-
                {lastItemIndex ? lastItemIndex : 0} of {props?.items?.length}{" "}
                result
              </div>
            </div>
           
            <div className="flex gap-2 items-center">
              <input type="text" className="!mr-2 max-h-[38px] bg-white border border-gray-300 text-gray-900 text-sm rounded-[0.2rem] w-full p-2.5 " placeholder="Search"
              onChange={(e)=>props?.setSearchInput(e.target.value)}
              value={props?.searchInput}
              
              />
              
            </div>
          
          </div>
        </>
        <div
          className="ag-theme-alpine"
          style={{ height: props.height?props.height:"80vh", width: "auto" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={items ? items : []}
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={recordsPerPage}
            domLayout={"autoHeight"}
            defaultColDef={defaultColDef2}
            onCellClicked={onCellClicked}
            tooltipShowDelay={0}
            gridOptions={{ floatingFilter: true }}
            rowSelection={props?.rowSelection}
            suppressRowClickSelection={true}
            suppressCellFocus={true}
            onSelectionChanged={props?.onSelectionChanged}
            onPaginationChanged={onPaginationChanged}
            onRowClicked={props?.onRowClicked}
            enableCellTextSelection={true}
          />
        </div>
      </div>
    </div>
  );
};

export default DataTable;
